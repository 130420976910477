import "./_metronic/assets/fonticon/fonticon.css";
import "./_metronic/assets/keenicons/duotone/style.css";
import "./_metronic/assets/keenicons/outline/style.css";
import "./_metronic/assets/keenicons/solid/style.css";
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";

import { registerLicense } from "@syncfusion/ej2-base";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// Apps
// import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import { AuthProvider, setupAxios } from "./app/pages/account-management/auth";
import { AppRoutes } from "./app/routing/AppRoutes";

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios);
Chart.register(...registerables);
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY || "");
const queryClient = new QueryClient();
const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      {/* <MetronicI18nProvider> */}
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
      {/* </MetronicI18nProvider> */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
