import { useDebounce } from "_metronic/helpers";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";

interface propsType {
  label?: string;
  name?: any;
  classNameDropdown?: string;
  classNameLabel?: string;
  disabled?: boolean;
  required?: boolean;
  values?: any;
  options?: Array<any>;
  formik?: any;
  clearable?: boolean;
  onChange?: (e: any) => void;
  allowNoneValue?: boolean;
  onSearch?: any;
  [restProp: string]: any;
  closeMenuOnSelect?: boolean;
}
export const InputDropdown = (props: propsType) => {
  const {
    name,
    label,
    formik,
    classNameDropdown,
    classNameLabel,
    onSearch,
    values,
    clearable = true,
    required,
    onChange,
    // allowNoneValue = true,
    options = [],
    closeMenuOnSelect = true,
    ...restProps
  } = props;

  const [searchInput, setSearchInput] = useState("");

  const debouncedSearchTerm = useDebounce(searchInput, 700);

  const filterOption = useCallback((candidate: any, input: any) => {
    setSearchInput(input);
    return true; // if not search, then all match
  }, []);

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <div>
      <label className={clsx(classNameLabel, { required: required })}>
        {label}
      </label>

      <div style={restProps?.style}>
        {onSearch ? (
          <Select
            isClearable={clearable}
            isDisabled={restProps?.disabled}
            closeMenuOnSelect={closeMenuOnSelect}
            value={{
              value: formik?.values?.[name] || values,
              label: options?.find(
                (item) => item.value === (formik?.values[name] || values)
              )?.label,
            }}
            filterOption={filterOption}
            options={options}
            onChange={(e) => {
              onChange && onChange(e);
              if (name && formik) {
                if (e) {
                  formik?.setFieldValue(name, e?.value);
                } else {
                  formik?.setFieldValue(name, e);
                }
              }
            }}
          />
        ) : (
          <Select
            isClearable={clearable}
            isDisabled={restProps?.disabled}
            closeMenuOnSelect={closeMenuOnSelect}
            value={{
              value: formik?.values?.[name] || values,
              label: options?.find(
                (item) => item.value === (formik?.values[name] || values)
              )?.label,
            }}
            options={options}
            onChange={(e) => {
              onChange && onChange(e);
              if (name && formik) {
                if (e) {
                  formik?.setFieldValue(name, e?.value);
                } else {
                  formik?.setFieldValue(name, e);
                }
              }
            }}
          />
        )}
      </div>

      {formik?.touched[name] && formik?.errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{formik?.errors[name]}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputDropdown;
