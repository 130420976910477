import { toAbsoluteUrl } from "_metronic/helpers";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import { alertSuccess } from "_metronic/helpers/toastify";
import {
  DefaultCell,
  IsActiveCell,
  SelectionCell,
} from "app/components/Table/columns";
import { DateCell } from "app/components/Table/columns/DateCell";
import { Header, SelectionHeader } from "app/components/Table/header";
import { Column } from "react-table";

import { ItemModel } from "./_models";
import { syncData } from "./_requests";

const DEFAULT_IMAGE = "/media/svg/files/blank-image.svg";

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Strava"
        className="min-w-300px"
      />
    ),
    id: "strava",
    Cell: ({ ...props }) => (
      <div className="d-flex align-items-center">
        <div
          className="bg-light rounded overflow-hidden"
          style={{
            height: 80,
            flex: "0 0 80px",
            overflow: "hidden",
          }}
        >
          <img
            src={
              props.data[props.row.index]?.user?.avatar ||
              toAbsoluteUrl(DEFAULT_IMAGE)
            }
            alt=""
            className="h-100 w-100 border"
            style={{ objectFit: "contain" }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = toAbsoluteUrl(DEFAULT_IMAGE);
            }}
          />
        </div>

        <div
          className={`d-flex justify-content-start flex-column text-truncate`}
          style={{ marginLeft: 16 }}
        >
          <p className="mb-0">ID: {props.data[props.row.index].social_id}</p>
          <p className="mb-0">
            Username: {props.data[props.row.index].social_username}
          </p>
        </div>
      </div>
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="User"
        className="min-w-300px"
      />
    ),
    id: "users",
    Cell: ({ ...props }) => (
      <>
        <p className="mb-0">
          Fullname: {props.data[props.row.index].user.fullname || "-"}
        </p>
        <p className="mb-0">
          Email: {props.data[props.row.index].user.email || "-"}
        </p>
        <p className="mb-0">
          Phone: {props.data[props.row.index].user.phone || "-"}
        </p>
      </>
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    id: "isActive",
    Cell: ({ ...props }) => (
      <>
        <IsActiveCell value={props.data[props.row.index]?.user?.isActive} />
      </>
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày kết nối"
        className="min-w-150px"
      />
    ),
    id: "createdAt",
    Cell: ({ ...props }) => (
      <>
        <DefaultCell value={props.data[props.row.index]?.createdAt} />
      </>
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Lấy dữ liệu gần nhất"
        className="min-w-200px"
      />
    ),
    id: "syncAt",
    Cell: ({ ...props }) => (
      <>
        <DateCell value={props.data[props.row.index]?.syncAt} />
      </>
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Lần hoạt động gần nhất"
        className="min-w-200px"
      />
    ),
    id: "latestRecord",
    Cell: ({ ...props }) => (
      <>
        <DateCell value={props.data[props.row.index]?.latestRecord} />
      </>
    ),
  },

  {
    Header: "",
    id: "action",
    Cell: (props: any) => (
      <div className="text-end d-flex gap-2 min-w-200px justify-content-end">
        <button
          className="btn btn-sm btn-info"
          onClick={() => {
            if (
              props.data[props.row.index]?.user &&
              props.data[props.row.index]?.user.id
            )
              syncData(props.data[props.row.index]?.user.id).then(() => {
                alertSuccess("Yêu cầu thành công, vui lòng chờ trong giây lát");
              });
          }}
        >
          Lấy dữ liệu
        </button>
      </div>
    ),
  },
];

export { COLUMNS };
