import { KTCard, KTCardBody } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { FormikProvider, useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ItemModel as AttributeInterface } from "../../../attribute/core/_models";
import { getList as getAttributeList } from "../../../attribute/core/_requests";
import { formEditType } from "../../core/_models";
import { removeDuplicates } from "../../utils";
import Attribute from "../attribute/Attribute";
import VariantItemsTable from "../attribute/VariantItemsTable";

const VariantStep = ({ dataProduct }: { dataProduct: any }) => {
  const { id: idProduct } = useParams();
  const formik = useFormikContext<formEditType>();

  const [attributes, setAttributes] = useState<AttributeInterface[]>([]);

  const initPage = async () => {
    if (!dataProduct || dataProduct.length === 0) return;

    const listAttributeItem = dataProduct[0].items.map(
      (variant: any) => variant.attributeId
    );

    const arrayAttributeParent = dataProduct.map(
      (variant: any) => variant.items
    );

    const listConcat: any = [];

    arrayAttributeParent.forEach((item: any) => {
      listConcat.push(...item);
    });

    const listAttribute = listConcat.map((item: any) => {
      return {
        attributeId: item.attributeId,
        attributeItems: [],
      };
    });

    const uniqueListAttribute = listAttribute.filter(
      (obj: any, index: number, self: any) =>
        index ===
        self.findIndex((item: any) => item.attributeId === obj.attributeId)
    );

    const listData = uniqueListAttribute.map((unique: any) => {
      const filter = listConcat
        .filter((item: any) => item.attributeId === unique.attributeId)
        .map((el: any) => el.id);
      return {
        attributeId: unique.attributeId,
        attributeItems: removeDuplicates(filter),
      };
    });

    listData.forEach((item: any) => {
      formik.setFieldValue(
        `attributeItems.${item.attributeId}`,
        item.attributeItems
      );
    });

    const listVariantCombination = dataProduct.map((variant: any) =>
      variant.items.map((item: any) => item.id)
    );

    formik.setFieldValue("variantCombination", listVariantCombination);
    const listDataTable = dataProduct.map((variant: any) => {
      const listId = variant.items.map((item: any) => item.id);

      return {
        price: variant.price,
        attributeItems: listId,
        id: variant.id,
        stock: variant.stock,
        sku: variant.sku,
        sellPrice: variant.sellPrice,
        shortId: variant.shortId || "",
        productVariantId: variant.productVariantId || "",
        image: variant.images.length > 0 ? variant.images[0] : "",
      };
    });

    formik.setFieldValue("variantTables", listDataTable);
    formik.setFieldValue("attributes", listAttributeItem);
  };

  const variantOptions = useMemo(() => {
    return mapValue(attributes);
  }, [attributes]);

  const allItems = useMemo(() => {
    return attributes.map((att) => att.items).flat();
  }, [attributes]);

  const findItemNameById = useCallback(
    (id: string) => {
      return allItems.find((a) => a?.id === id)?.title || "";
    },
    [allItems]
  );

  const variantOptionsRemain = useCallback(
    (id: string) => {
      const optionTaken = formik.values?.attributes.filter((v) => v !== id);

      return variantOptions?.filter(
        (v?: any) => !optionTaken?.includes(v.value as string)
      );
    },
    [variantOptions, formik?.values?.attributes]
  );

  useEffect(() => {
    getAttributeList({ limit: 1000, page: 1, isVariant: true }).then((x) =>
      setAttributes(x.data || [])
    );
  }, []);

  useEffect(() => {
    if (!idProduct) return;
    initPage();
  }, [idProduct, dataProduct]);

  return (
    <>
      <KTCard>
        <KTCardBody>
          <div className="fv-row mb-7">
            <FormikProvider value={formik}>
              <div className="form">
                <div className="d-flex flex-column flex-lg-row">
                  <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                    <div className="fv-row mb-7">
                      <label className="fw-bold fs-6 ">Mẫu mã</label>

                      {formik?.values?.attributes.map(
                        (id: string, index: number) => (
                          <div key={id} className="mt-6">
                            <Attribute
                              value={id}
                              options={variantOptionsRemain(id)}
                              onChange={(value) => {
                                const attributes = [
                                  ...formik.values?.attributes,
                                ];

                                formik.setFieldValue(
                                  `attributeItems.${value}`,
                                  []
                                );
                                attributes[index] = value;

                                formik.setFieldValue("attributes", attributes);
                              }}
                              onDelete={() => {
                                const attributes = [
                                  ...formik.values?.attributes,
                                ];
                                formik.setFieldValue(
                                  `attributeItems.${id}`,
                                  []
                                );

                                formik.setFieldValue(
                                  "attributes",
                                  attributes.filter((v) => v !== id)
                                );
                              }}
                            />
                          </div>
                        )
                      )}

                      {variantOptionsRemain("").length > 0 && (
                        <div className="row mx-6 mt-7">
                          <div className="w-200px">
                            <button
                              className="btn btn-sm btn-light-primary"
                              type="button"
                              onClick={() => {
                                const attributes = [
                                  ...formik.values?.attributes,
                                ];
                                const optionId = variantOptionsRemain("")[0]
                                  .value as string;

                                attributes.push(optionId);

                                formik.setFieldValue(
                                  `attributeItems.${optionId}`,
                                  []
                                );

                                formik.setFieldValue("attributes", attributes);
                              }}
                            >
                              <i className="ki-duotone ki-plus fs-3"></i>Thêm
                              tùy chọn khác
                            </button>
                          </div>
                        </div>
                      )}

                      <div
                        className="row mx-6 mt-16"
                        style={{ overflow: "auto" }}
                      >
                        <VariantItemsTable
                          findItemNameById={findItemNameById}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FormikProvider>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  );
};

export default VariantStep;
