import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import MediaPage from "./components/Media";

const Breadcrumbs: Array<PageLink> = [
  {
    title: "Thư viện",
  },
  {
    isSeparator: true,
  },
];

const MediaManagement = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Thư viện</PageTitle>
              <MediaPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default MediaManagement;
