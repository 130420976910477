import { ID } from "_metronic/helpers";
import { AxiosResponse } from "axios";
import api, { deleteDataItem, getData, postData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `gifts`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, { ...query, platform: process.env.REACT_APP_PLATFORM });

const getProductList = async (): Promise<QueryResponse> =>
  getData("product", {
    limit: 50,
    page: 1,
    isGift: true,
    platform: process.env.REACT_APP_PLATFORM,
    isActive: true,
  });

const getVoucherList = async (): Promise<QueryResponse> =>
  getData("vouchers", {
    platform: process.env.REACT_APP_PLATFORM,
    limit: 50,
    page: 1,
    isGift: true,
    isActive: true,
  });

const getItem = async (id: ID): Promise<ItemModel> => getData(URL + "/" + id);

const getProductDetail = async (id: ID): Promise<any> =>
  getData("product/" + id);

const createItem = async (query: any): Promise<QueryResponse> =>
  postData(URL, query);

const editItem = (query: any, id: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`${URL}/${id}`, query)
    .then((d: AxiosResponse<any>) => d?.data);

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

export {
  bulkDelete,
  createItem,
  deleteItem,
  editItem,
  getItem,
  getList,
  getProductDetail,
  getProductList,
  getVoucherList,
};
