import { KTCard } from "_metronic/helpers/components/KTCard";
import { ConvertArrayToTree } from "_metronic/helpers/formatData/tree";
import { editSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import Button from "app/components/Button";
import { Input } from "app/components/Form";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import * as Yup from "yup";

import { initialMenuItemData, MenuItem } from "../core/_models";
import { editItem, getItem } from "../core/_requests";

export const menuItemSchema = Yup.object().shape({
  url: Yup.string().required("Vui lòng điền đủ thông tin"),
  name: Yup.string()
    // .min(3, "Tên phải trên 3 ký tự")
    // .max(50, "Tên phải dưới 50 kí tự")
    .required("Vui lòng điền đủ thông tin"),
});

const Menu = () => {
  const { id } = useParams();
  const [data, setData] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<Array<any>>([]);
  const [parentItem, setParent] = useState<string | null>(null);
  const [currentItem, setCurrentItem] = useState<MenuItem>(initialMenuItemData);

  useEffect(() => {
    initialPage();
  }, [id]);

  const initialPage = async () => {
    if (id) {
      const menu = await getItem(id);
      if (menu.items) setData(menu.items);
    }
  };

  useEffect(() => {
    const tree = ConvertArrayToTree(data, null);
    setState(tree);
  }, [data]);

  const setMenu = async (list: MenuItem[], parent?: MenuItem) => {
    const newList = data;
    list.forEach((item, index) => {
      const menuItemIndex = data.findIndex((x) => x.id === item.id);
      newList[menuItemIndex].orderNumber = index;
    });

    setData(newList);
    const tree = ConvertArrayToTree(newList, null);
    setState(tree);
  };

  const SortList = (list: any[], parent?: MenuItem) => {
    list = list.sort((a, b) => a.orderNumber - b.orderNumber);
    return (
      <ReactSortable
        className="list"
        delay={5}
        group={parent ? parent.name : `menu`}
        list={list}
        invertSwap={true}
        setList={(list, sortable, store) => {
          if (store.dragging) {
            setMenu(list, parent);
          }
        }}
      >
        {list.map((item) => (
          <div
            className="w-100 item"
            data-id={item.id}
            style={{
              marginLeft: parent ? "20px" : "0px",
            }}
            key={item.id}
          >
            <div className="d-flex">
              <button className="d-inline-flex w-100 pointer align-items-center justify-content-between mb-4 p-4">
                <div className="d-flex align-items-center gap-4">
                  <i className="bi bi-arrows-move"></i>
                  <h3 className="mb-0 text-black">{item.name}</h3>
                </div>
                <div className="d-flex align-items-center gap-4">
                  <i
                    className="bi bi-pencil"
                    onClick={() => setCurrentItem(item)}
                  ></i>
                  <i
                    className="bi bi-plus"
                    onClick={() => setParent(item.id)}
                  ></i>
                  <i
                    className="bi bi-trash"
                    onClick={() => remove(item.id)}
                  ></i>
                </div>
              </button>
            </div>
            {item.children && item.children.length > 0 ? (
              SortList(item.children, item)
            ) : (
              <></>
            )}
          </div>
        ))}
      </ReactSortable>
    );
  };

  const formik = useFormik({
    initialValues: currentItem,
    enableReinitialize: true,
    validationSchema: menuItemSchema,
    onSubmit: async (values, actions) => {
      if (currentItem.id) {
        const newList = data.map((x: any) => {
          if (x.id === currentItem.id) {
            x = {
              ...values,
            };
          }

          return x;
        });
        setData(newList);
      } else {
        const newList = [
          ...data,
          {
            id: "id" + Math.random().toString(16).slice(2),
            name: values.name,
            url: values.url,
            parent: parentItem,
            orderNumber: data.filter((x) => !!x.parent).length,
          },
        ];
        setData(newList);
      }

      setParent(null);
      setCurrentItem(initialMenuItemData);
      actions.resetForm({
        values: initialMenuItemData,
      });
    },
  });

  const save = async () => {
    setLoading(true);
    if (id) {
      await editItem({ items: data }, id).then(() => {
        editSuccess();
        initialPage();
        setLoading(false);
      });
    }
  };

  const remove = async (id: string) => {
    const newList = data?.filter((x) => x.id !== id) || [];
    setData(newList);
  };

  const CreateForm = () => (
    <form className="form" onSubmit={formik.handleSubmit} noValidate>
      <div className="d-flex flex-column scroll-y me-n7 pe-7">
        <div className="fv-row mb-7">
          <Input
            formik={formik}
            classNameLabel="required fw-bold fs-6 mb-2"
            classNameInput="form-control form-control-solid mb-3 mb-lg-0"
            label="Tên"
            placeholder="Tên"
            name="name"
            disabled={formik.isSubmitting || isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.name}
          />
        </div>
        <div className="fv-row mb-7">
          <Input
            formik={formik}
            label="Url"
            placeholder="Url"
            name="url"
            type="url"
            classNameInput="form-control form-control-solid mb-3 mb-lg-0"
            classNameLabel="required fw-bold fs-6 mb-2"
            disabled={formik.isSubmitting || isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.url}
          />
        </div>
        <div className="text-right pt-5">
          <Button
            type="submit"
            className="btn btn-primary w-100"
            disabled={!formik.isValid}
          >
            {currentItem ? "Thay đổi" : "Thêm mới"}
          </Button>
        </div>
      </div>
    </form>
  );

  return (
    <>
      <PageTitle>{id && id.toLocaleUpperCase()}</PageTitle>
      <div className="row">
        <div className="col-md-4">
          <KTCard className="px-5 py-5">{CreateForm()}</KTCard>
        </div>
        <div className="col-md-8">
          <KTCard className="px-5 pt-5 pb-2">
            <button
              type="button"
              className="btn btn-primary mb-10"
              onClick={() => save()}
              disabled={isLoading}
            >
              Lưu
            </button>
            {SortList(state)}
          </KTCard>
        </div>
        <Modal
          show={!!parentItem || !!currentItem.id}
          onHide={() => {
            setParent(null);
            setCurrentItem(initialMenuItemData);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{currentItem.name || "Thêm mới"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{CreateForm()}</Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Menu;
