interface Props {
  productCategory?: any;
  formik?: any;
}

const Checkbox = (props: {
  id: string;
  title: string;
  children: any;
  formik: any;
  categoryId: string;
}) => {
  const { id, title, children, formik, categoryId } = props;

  return (
    <div className="component mb-5" style={{ marginLeft: 20 }}>
      <input
        className="form-check-input"
        style={{ marginRight: 12 }}
        type="checkbox"
        checked={formik?.values?.productTypeIds?.some(
          (selected?: any) => selected === id
        )}
        onChange={(e) => {
          if (e.target.checked) {
            formik.setFieldValue("categoryIds", [
              ...formik.values.categoryIds,
              categoryId,
            ]);

            formik?.setFieldValue("productTypeIds", [
              ...formik.values.productTypeIds,
              id,
            ]);
          } else {
            formik?.setFieldValue("productTypeIds", [
              ...formik.values.productTypeIds?.filter(
                (typeId?: any) => typeId !== id
              ),
            ]);
          }
        }}
      />

      {title}

      {children && (
        <div className="children mt-5">
          {children.map((child: any, index: number) => (
            <div key={index + title}>
              <Checkbox {...child} formik={formik} categoryId={categoryId} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CategoryCheckbox = ({ productCategory, formik }: Props) => {
  function renderComponent(component: any, categoryId: any) {
    if (formik) {
      return (
        <Checkbox {...component} formik={formik} categoryId={categoryId} />
      );
    }

    return <></>;
  }

  return (
    <>
      {productCategory?.map((item?: any, index?: number) => {
        return (
          <div key={index}>
            {/* Category */}
            <div className="mb-5">
              <input
                className="form-check-input"
                type="checkbox"
                checked={formik.values.categoryIds?.some(
                  (selected?: any) => selected === item?.id
                )}
                onChange={(e) => {
                  if (!e.target.checked) {
                    const uniqueArray = formik.values.productTypeIds?.filter(
                      (typeId?: any) =>
                        !item?.types
                          ?.map((item?: any) => item?.id)
                          .some((otherItem?: any) => otherItem === typeId)
                    );

                    formik.setFieldValue("categoryIds", [
                      ...formik.values.categoryIds?.filter(
                        (cateId?: any) => cateId !== item?.id
                      ),
                    ]);

                    formik.setFieldValue("productTypeIds", [...uniqueArray]);
                  } else {
                    formik.setFieldValue("categoryIds", [
                      ...formik.values.categoryIds,
                      item?.id,
                    ]);
                  }
                }}
              />
              <span style={{ marginLeft: 10 }}>{item?.title}</span>
            </div>

            {/* Types */}
            {item.types?.map((type?: any, index?: number) => {
              return <div key={index}>{renderComponent(type, item?.id)}</div>;
            })}
          </div>
        );
      })}
    </>
  );
};

export default CategoryCheckbox;
