import { KTIcon } from "_metronic/helpers";
import { FC } from "react";

type Props = {
  onCancel: any;
  onConfirm: any;
};

const DeleteConfirmModal: FC<Props> = ({ onCancel, onConfirm }) => {
  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-450px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Delete</h2>
              <button
                className="btn btn-icon btn-sm btn-active-icon-primary mb-2"
                onClick={() => onCancel()}
              >
                <KTIcon iconName="cross" className="fs-1" />
              </button>
            </div>
            <div className="modal-body">
              <p className="modal-label fw-bolder text-center my-2">
                Bạn có chắc muốn xoá? Hành động này không thể khôi phục lại
                được.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => onCancel()}
              >
                Đóng
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => onConfirm(e)}
              >
                Xoá
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default DeleteConfirmModal;
