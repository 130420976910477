import { ID, Response } from "_metronic/helpers";

export interface CategoryType {
  alias: string;
  id: string;
  title: string;
}

export type ItemModel = {
  statusCode?: number;
  prices?: Array<any>;
  id?: ID;
  data?: any;
  code?: string;
  status?: string;
  productId?: string;
  storeId?: string;
  store?: any;
  product?: any;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export const initialData: ItemModel = {
  isActive: true,
  code: "",
  status: "",
};

export type QueryResponse = Response<Array<ItemModel>>;
