import clsx from "clsx";
import React from "react";

interface SideBarProps {
  children: React.ReactNode;
  className?: string;
  required?: boolean;
}

const Label = (props: SideBarProps) => {
  const { required, children, className } = props;

  return (
    <label className={clsx("mb-2 fw-bold fs-6 d-flex ", className)}>
      <span>{children}</span>
      {required && (
        <span
          className="fv-plugins-message-container"
          style={{ marginLeft: 6 }}
        >
          <div className="fv-help-block">
            <span role="alert">*</span>
          </div>
        </span>
      )}
    </label>
  );
};

export default Label;
