import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Banners",
    path: "/banner",
  },
  {
    isSeparator: true,
  },
];

const BannerPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Danh sách banners</PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="/create"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Thêm mới banners</PageTitle>
              <EditPage />
            </>
          }
        />
        <Route
          path="/:id/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Chỉnh sửa banners</PageTitle>
              <EditPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default BannerPage;
