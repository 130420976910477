import convertCurrency from "_metronic/helpers/convertData/convert-currency";
import moment from "moment";

const OverviewCard = ({ data }: { data: any }) => {
  return (
    <div className="card mb-6 mb-xl-9">
      <div className="card-body pt-9 pb-0">
        {/*begin::Details*/}
        <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
          {/*begin::Image*/}
          <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
            <img className="mw-50px mw-lg-75px" src={data?.image} alt="sd" />
          </div>
          {/*end::Image*/}
          {/*begin::Wrapper*/}
          <div className="flex-grow-1">
            {/*begin::Head*/}
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              {/*begin::Details*/}
              <div className="d-flex flex-column">
                {/*begin::Status*/}
                <div className="d-flex align-items-center mb-1">
                  <a
                    href="#test"
                    className="text-gray-800 text-hover-primary fs-2 fw-bold me-3"
                  >
                    {data?.title}
                  </a>
                  {data?.isActive ? (
                    <span className="badge badge-light-success me-auto">
                      Kích hoạt
                    </span>
                  ) : (
                    <span className="badge badge-light-danger me-auto">
                      Ngừng kích hoạt
                    </span>
                  )}
                </div>
                {/*end::Status*/}
                {/*begin::Description*/}
                <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">
                  {data?.alias}
                </div>
                {/*end::Description*/}
              </div>
              {/*end::Details*/}
              {/*begin::Actions*/}

              {/*end::Actions*/}
            </div>
            {/*end::Head*/}
            {/*begin::Info*/}
            <div className="d-flex flex-wrap justify-content-start">
              {/*begin::Stats*/}
              <div className="d-flex flex-wrap">
                {/*begin::Stat*/}
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                  {/*begin::Number*/}
                  <div className="d-flex align-items-center">
                    <div className="fs-4 fw-bold">
                      {moment(data?.startedAt).format("DD/MM/YYYY")} -{" "}
                      {moment(data?.endedAt).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  {/*end::Number*/}
                  {/*begin::Label*/}
                  <div className="fw-semibold fs-6 text-gray-400">
                    Thời gian
                  </div>
                  {/*end::Label*/}
                </div>
                {/*end::Stat*/}

                {/*begin::Stat*/}
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                  {/*begin::Number*/}
                  <div className="d-flex align-items-center">
                    <div
                      className="fs-4 fw-bold counted"
                      data-kt-countup="true"
                      data-kt-countup-value={15000}
                      data-kt-countup-prefix="$"
                      data-kt-initialized={1}
                    >
                      {convertCurrency(data?.price)}
                    </div>
                  </div>
                  {/*end::Number*/}
                  {/*begin::Label*/}
                  <div className="fw-semibold fs-6 text-gray-400">Giá</div>
                  {/*end::Label*/}
                </div>
                {/*end::Stat*/}
              </div>
              {/*end::Stats*/}
            </div>
            {/*end::Info*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/* end::Details*/}
      </div>
    </div>
  );
};

export default OverviewCard;
