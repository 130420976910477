import { Response } from "_metronic/helpers";

export type ItemModel = {
  id?: string;
  title: string;
  alias: string;
  image: string;
  description: string;
  sport?: any;

  isActive: boolean;
  isTour: boolean;
  isChallenge: boolean;
  isHideFilter: boolean;

  seoTitle: string;
  seoKeyword: string;
  seoDescription: string;

  createdBy?: any;
  updatedBy?: any;
  createdAt?: string;
  updatedAt?: string;
};

export const initialData = {
  title: "",
  alias: "",
  image: "",
  description: "",
  sport: null,
  isActive: true,
  isTour: false,
  isChallenge: false,
  isHideFilter: false,
  seoTitle: "",
  seoKeyword: "",
  seoDescription: "",
};

export type QueryResponse = Response<Array<ItemModel>>;
