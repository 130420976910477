export const activeOption: any = [
  {
    label: "Kích hoạt",
    value: true,
  },
  {
    label: "Ngưng kích hoạt",
    value: false,
  },
];
