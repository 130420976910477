import { MenuComponent } from "_metronic/assets/ts/components";
import { initialQueryState } from "_metronic/helpers";
import Button from "app/components/Button";
import { Input } from "app/components/Form";
import SortList from "app/components/ToolbarTable/SortList";
import { FC, useEffect, useState } from "react";

type Props = {
  useQueryRequest: any;
  useQueryResponse: any;
  sortContent?: any;
};

const HeaderSort: FC<Props> = ({
  sortContent,
  useQueryRequest,
  useQueryResponse,
}) => {
  const { updateState, state } = useQueryRequest();
  const [filterData, setFilter] = useState({ ...state });
  const { isLoading } = useQueryResponse();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const resetData = () => {
    updateState({ ...initialQueryState });
    setFilter(initialQueryState);
  };

  const handleChange = (key: string, value: any) => {
    setFilter({
      ...filterData,
      [key]: value,
    });
  };

  return (
    <SortList>
      <div
        className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
        data-kt-menu="true"
      >
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Sắp xếp theo</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5" data-kt-user-table-sort="form">
          {sortContent}
          <div className="d-flex">
            <Input
              classNameInput="form-check-input me-3"
              name="desc"
              type="radio"
              values="desc"
              checked={
                filterData.orderBy === "desc" &&
                filterData.sortBy === "createdAt"
              }
              onChange={(e) => {
                handleChange("orderBy", "desc");
                handleChange("sortBy", "createdAt");
              }}
            />
            <div className="text-gray-800">Mới nhất</div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              disabled={isLoading}
              onClick={resetData}
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-sort="reset"
            >
              Đặt lại
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              onClick={() => updateState(filterData)}
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-sort="sort"
            >
              Sắp Xếp
            </Button>
          </div>
        </div>
      </div>
    </SortList>
  );
};

export { HeaderSort };
