import { getData, updateData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = "menu";

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, query);

const getItem = async (alias: string): Promise<ItemModel> =>
  getData(URL + "/" + alias);

const editItem = (query: any, alias: string): Promise<ItemModel> =>
  updateData(URL, query, alias);

export { editItem, getItem, getList };
