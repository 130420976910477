import { Response } from "_metronic/helpers";
export interface ItemModel {
  id: string;
  name: string;
  address: string;
  email: string;
  phone: string;
  fax: string;
  map: string;
  lng: string;
  lat: string;
  managerId?: string;
  provinceId?: string;
  districtId?: string;
  wardId?: string;
  isShowOnMap: boolean;
  isRental?: boolean;
  manager: any;
  isActive: boolean;
  image: string;
  open: string;
  close: string;
  maxDaysBooking: number;
  gallery: string[];
  daysOff: Date[];
}
export const initialData: any = {
  name: "",
  address: "",
  email: "",
  phone: "",
  fax: "",
  map: "",
  lng: "",
  lat: "",
  isShowOnMap: false,
  manager: "",
  isActive: true,
  isRental: false,
  image: "",
  maxDaysBooking: 14,
  open: "08:00",
  close: "20:00",
  gallery: [],
  daysOff: [],
};

export type QueryResponse = Response<Array<ItemModel>>;
