import React, { FC } from "react";
import { Link } from "react-router-dom";

type Props = {
  value?: string | number | React.ReactNode;
  className?: string;
  link?: string;
};

const DefaultCell: FC<Props> = ({ link, value, className }) => {
  return (
    <span className={className}>
      {link ? (
        <Link to={link} target="_blank">
          {value}
        </Link>
      ) : (
        value
      )}
    </span>
  );
};

export { DefaultCell };
