export const ConvertArrayToTree = (
  array: Array<any>,
  parent?: string | null
): Array<any> => {
  return array
    .filter((item) => item.parent === parent)
    .map((child: any) => ({
      ...child,
      children: ConvertArrayToTree(array, child.id),
    }));
};
