import convertCurrency from "_metronic/helpers/convertData/convert-currency";
import { convertDate } from "_metronic/helpers/convertData/convert-date";

import Item from "./Item";

const PriceTable = ({ data }: { data?: any }) => {
  return (
    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
      {/*begin::Card header*/}
      <div className="card-header">
        <div className="card-title">
          <h2>Thông tin đơn hàng</h2>
        </div>
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body pt-0">
        <div className="table-responsive">
          {/*begin::Table*/}
          <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
            <thead>
              <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th className="min-w-175px">Sản phẩm</th>
                <th className="min-w-100px text-end">Code</th>
                <th className="min-w-250px text-end">Chi nhánh</th>
              </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">
              <Item data={data} />
              <tr>
                <td colSpan={2} className="text-end">
                  Tổng giá trị hàng hóa
                </td>
                <td className="text-end">{convertCurrency(data?.subtotal)}</td>
              </tr>
              {/* <tr>
                <td colSpan={2} className="text-end">
                  Phí thu hộ
                </td>
                <td className="text-end">
                  {convertCurrency(data?.paymentFee)}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="text-end">
                  Phí vận chuyển
                </td>
                <td className="text-end">
                  {convertCurrency(data?.shippingFee)}
                </td>
              </tr> */}
              <tr>
                <td colSpan={2} className="text-end">
                  Giảm giá
                </td>
                <td className="text-end">
                  - {convertCurrency(data?.discount)}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="fs-3 text-dark text-end">
                  Tổng cộng
                </td>
                <td className="text-dark fs-3 fw-bolder text-end">
                  {convertCurrency(data?.totalPrice as number)}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="">
                  <span className="fw-bolder fs-3">
                    Thời gian bắt đầu thuê:
                  </span>{" "}
                  {convertDate(data?.info?.startTime) || "-"}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="">
                  <span className="fw-bolder fs-3">Thời gian sử dụng:</span>{" "}
                  {data?.info?.hoursUse + " giờ" || "-"}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="">
                  <span className="fw-bolder fs-3">Chiều cao:</span>{" "}
                  {data?.info?.height || "-"}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="">
                  <span className="fw-bolder fs-3">
                    Thời gian trễ (nếu có):
                  </span>{" "}
                  {data?.info?.expired || "-"}
                </td>
              </tr>
            </tbody>
          </table>
          {/*end::Table*/}
        </div>
      </div>
      {/*end::Card body*/}
    </div>
  );
};

export default PriceTable;
