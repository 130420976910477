import { getData } from "services/api";

import { QueryResponse } from "./_models";

const URL = `points`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, { ...query, status: "used" });

export { getList };
