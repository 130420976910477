import { getCSSVariableValue } from "_metronic/assets/ts/_utils";
import { WithChildren } from "_metronic/helpers";
import { MasterLayout } from "_metronic/layout/MasterLayout";
import AdminsPage from "app/pages/account-management/admin";
import ProfilePage from "app/pages/account-management/profile";
import RolesPage from "app/pages/account-management/role";
import BikeFixingPage from "app/pages/bike-management/bike-fixing";
import BikeRenewPage from "app/pages/bike-management/bike-renew-condition";
import BikeRenewRequestPage from "app/pages/bike-management/bike-renew-request";
import BikeRentalBookingPage from "app/pages/bike-management/bike-rental-booking";
import BikeServicesPage from "app/pages/bike-management/bike-service";
import CategorysPage from "app/pages/blog-management/category";
import PostsPage from "app/pages/blog-management/post";
import TagsPage from "app/pages/blog-management/tag";
import BikeRentalPage from "app/pages/booking-management/bike-rental";
import GroupPage from "app/pages/community-management/group";
import PointsManagement from "app/pages/community-management/points";
import StravaPage from "app/pages/community-management/strava";
import UsersPage from "app/pages/community-management/user";
import BannerPage from "app/pages/content-management/banner";
import ContactManagement from "app/pages/content-management/contact";
import MediaManagement from "app/pages/content-management/media";
import NotificationManagement from "app/pages/content-management/notification";
import ShippingManagement from "app/pages/content-management/shippingMethod";
import SportPage from "app/pages/content-management/sport";
import StoreManagement from "app/pages/content-management/store";
import TestimonialPage from "app/pages/content-management/testimonial";
import ThemePage from "app/pages/content-management/theme";
import EventCategoryPage from "app/pages/event-management/category";
import EventCommentPage from "app/pages/event-management/comment";
import EventPage from "app/pages/event-management/events";
import TourPage from "app/pages/event-management/tours";
import ActivityLogManagement from "app/pages/report-management/activity-logs";
import PointHistoryManagement from "app/pages/report-management/point-history";
import StoreReportManagement from "app/pages/report-management/store-report";
import ReportManagement from "app/pages/report-management/user-report";
import ViewManagement from "app/pages/report-management/views";
import AttributePage from "app/pages/sale-management/attribute";
import BrandPage from "app/pages/sale-management/brand";
import CollectionPage from "app/pages/sale-management/collection";
import ProductCommentPage from "app/pages/sale-management/comment";
import DealManagement from "app/pages/sale-management/deal";
import GiftManagement from "app/pages/sale-management/gift";
import OrderPage from "app/pages/sale-management/order";
import ProductPage from "app/pages/sale-management/product";
import ProductCategoryPage from "app/pages/sale-management/product-category";
import ProductTypePage from "app/pages/sale-management/type";
import VoucherManagement from "app/pages/sale-management/voucher";
import AppKeyPage from "app/pages/system-management/appKey";
import PaymentManagement from "app/pages/system-management/paymentMethod";
import SettingPage from "app/pages/system-management/setting";
import SettingPointManagement from "app/pages/system-management/settingPoint";
import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import ProductTagPage from "../pages/sale-management/product-tag";
import EmailTemplatePage from "../pages/system-management/emailTemplate";

const PrivateRoutes = () => {
  const Page = lazy(() => import("../pages/content-management/page"));
  const ROUTES = [
    {
      path: "admin/*",
      view: () => <AdminsPage />,
    },
    {
      path: "role/*",
      view: () => <RolesPage />,
    },
    {
      path: "profile/*",
      view: () => <ProfilePage />,
    },
    {
      path: "post/*",
      view: () => <PostsPage />,
    },
    {
      path: "payment/*",
      view: () => <PaymentManagement />,
    },
    {
      path: "category/*",
      view: () => <CategorysPage />,
    },
    {
      path: "shipping/*",
      view: () => <ShippingManagement />,
    },
    {
      path: "vouchers/*",
      view: () => <VoucherManagement />,
    },
    {
      path: "points/*",
      view: () => <PointsManagement />,
    },
    {
      path: "point-history/*",
      view: () => <PointHistoryManagement />,
    },
    {
      path: "contact/*",
      view: () => <ContactManagement />,
    },
    {
      path: "views/*",
      view: () => <ViewManagement />,
    },
    {
      path: "tag/*",
      view: () => <TagsPage />,
    },
    {
      path: "report/*",
      view: () => <ReportManagement />,
    },
    {
      path: "store-report/*",
      view: () => <StoreReportManagement />,
    },
    {
      path: "log/*",
      view: () => <ActivityLogManagement />,
    },
    {
      path: "store/*",
      view: () => <StoreManagement />,
    },
    {
      path: "page/*",
      view: () => <Page />,
    },
    {
      path: "banner/*",
      view: () => <BannerPage />,
    },
    {
      path: "testimonial/*",
      view: () => <TestimonialPage />,
    },
    {
      path: "setting/general",
      view: () => <SettingPage />,
    },
    {
      path: "setting/point",
      view: () => <SettingPointManagement />,
    },
    {
      path: "theme/*",
      view: () => <ThemePage />,
    },
    {
      path: "product/*",
      view: () => <ProductPage />,
    },
    {
      path: "bike-rental/*",
      view: () => <BikeRentalPage />,
    },
    {
      path: "bike-fixing/*",
      view: () => <BikeFixingPage />,
    },
    {
      path: "bike-renew-condition/*",
      view: () => <BikeRenewPage />,
    },
    {
      path: "bike-renew-request/*",
      view: () => <BikeRenewRequestPage />,
    },
    {
      path: "bike-services/*",
      view: () => <BikeServicesPage />,
    },
    {
      path: "product-attribute/*",
      view: () => <AttributePage />,
    },
    {
      path: "brand/*",
      view: () => <BrandPage />,
    },
    {
      path: "collection/*",
      view: () => <CollectionPage />,
    },
    {
      path: "product-category/*",
      view: () => <ProductCategoryPage />,
    },
    {
      path: "product-comment/*",
      view: () => <ProductCommentPage />,
    },
    {
      path: "product-type/*",
      view: () => <ProductTypePage />,
    },
    {
      path: "product-tag/*",
      view: () => <ProductTagPage />,
    },
    {
      path: "event/*",
      view: () => <EventPage />,
    },
    {
      path: "event-comment/*",
      view: () => <EventCommentPage />,
    },
    {
      path: "tour/*",
      view: () => <TourPage />,
    },
    {
      path: "event-category/*",
      view: () => <EventCategoryPage isTour={false} />,
    },
    {
      path: "tour-category/*",
      view: () => <EventCategoryPage isTour={true} />,
    },
    {
      path: "sport/*",
      view: () => <SportPage />,
    },
    {
      path: "user/*",
      view: () => <UsersPage />,
    },
    {
      path: "orders/*",
      view: () => <OrderPage />,
    },
    {
      path: "strava/*",
      view: () => <StravaPage />,
    },
    {
      path: "group/*",
      view: () => <GroupPage />,
    },
    {
      path: "campaigns/*",
      view: () => <DealManagement />,
    },
    {
      path: "bike-rental-booking/*",
      view: () => <BikeRentalBookingPage />,
    },
    {
      path: "gift/*",
      view: () => <GiftManagement />,
    },
    {
      path: "notification/*",
      view: () => <NotificationManagement />,
    },
    {
      path: "app-key/*",
      view: () => <AppKeyPage />,
    },
    {
      path: "media/*",
      view: () => <MediaManagement />,
    },
    {
      path: "email-template/*",
      view: () => <EmailTemplatePage />,
    },
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        {ROUTES.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<SuspensedView>{route.view()}</SuspensedView>}
          />
        ))}
        {/* <Route path="*" element={<Navigate to="/error/404" />} /> */}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
