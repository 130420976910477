import { PageTitle } from "_metronic/layout/core";
import CustomTabs from "app/components/Tabs";
import { Outlet, Route, Routes } from "react-router-dom";

import { GeneralSetting } from "./component/GeneralSetting";

const SettingPointManagement = () => {
  const tabs = [
    {
      id: "general",
      name: "Thông tin cơ bản",
      content: () => <GeneralSetting />,
    },
  ];

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/"
          element={
            <>
              <PageTitle>Thiết lập điểm số</PageTitle>

              <div className="form">
                <CustomTabs
                  className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
                  items={tabs}
                />
              </div>
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default SettingPointManagement;
