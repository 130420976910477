import { ID, Response } from "_metronic/helpers";

export interface CategoryType {
  alias: string;
  id: string;
  title: string;
}

export interface ProductTypeType {
  alias: string;
  id: string;
  title: string;
}

export type ItemModel = {
  id?: ID;
  title: string;
  alias: string;
  platform?: any;
  isActive?: boolean;
  createdBy?: any;
  createdAt?: any;
  updatedAt?: any;
  updatedBy?: any;
  name?: string;
  items?: ItemModel[];
  categories: CategoryType[];
  types: ProductTypeType[];
};

export const initialData: any = {
  isVariant: false,
  id: null,
  title: "",
  // platform: Object.keys(PlatformEnum)?.map((item) => ({
  //   label: PlatformEnum[item as keyof typeof PlatformEnum],
  //   value: item,
  // })),
  // platform: [
  //   {
  //     label: process.env.REACT_APP_PLATFORM,
  //     value: process.env.REACT_APP_PLATFORM,
  //   },
  // ],
  alias: "",
  isActive: true,
  categories: [],
  types: [],
  productTypeIds: [],
};

export type QueryResponse = Response<Array<ItemModel>>;
