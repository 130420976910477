import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import BulkCreatePage from "./components/bulkCreatePage";
import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";
import { ListDetailWrapper } from "./components/ListDetail";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Xe đạp cho thuê",
    path: "/bike-rental",
  },
  {
    isSeparator: true,
  },
];

const BikeRentalPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách xe đạp cho thuê
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="/:id/create"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Thêm mới xe đạp cho thuê
              </PageTitle>
              <BulkCreatePage />
            </>
          }
        />
        <Route
          path="/:id/detail"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách cửa hàng cho thuê
              </PageTitle>
              <ListDetailWrapper />
            </>
          }
        />
        <Route
          path=":id/:child/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Chỉnh sửa xe đạp cho thuê
              </PageTitle>
              <EditPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default BikeRentalPage;
