import { ID } from "_metronic/helpers";
import { UserRoleEnum } from "constants/enum";
import { deleteDataItem, getData, postData, updateData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `store`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, query);

const getItem = async (id: ID): Promise<ItemModel> => getData(URL + "/" + id);

const createItem = async (query: any): Promise<QueryResponse> =>
  postData(URL, query);

const editItem = (query: any, id: ID): Promise<ItemModel> =>
  updateData(URL, query, id);

const getAdminList = async (query: any): Promise<QueryResponse> => {
  return getData("users", { ...query, role: UserRoleEnum.MANAGER.toString() });
};

const getAdmin = async (userId: string): Promise<QueryResponse> => {
  return getData(`users/${userId}`);
};

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

export {
  bulkDelete,
  createItem,
  deleteItem,
  editItem,
  getAdmin,
  getAdminList,
  getItem,
  getList,
};
