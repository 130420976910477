import convertCurrency from "_metronic/helpers/convertData/convert-currency";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  ActionDeleteCell,
  ActionEditCell,
  CreatedByColumn,
  DefaultCell,
  IsActiveCell,
  ItemColumn,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const formatDiscountVoucher = (value?: any, type?: string) => {
  switch (type) {
    case "fixed":
      return convertCurrency(value);

    case "percentage":
      return `${value}%`;

    default:
      return convertCurrency(value);
  }
};

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tiêu đề"
        className="min-w-300px"
      />
    ),
    accessor: "image",
    Cell: ({ ...props }) => (
      <ItemColumn value={props.data[props.row.index]} isAlias={false} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Code"
        className="min-w-150px"
      />
    ),
    accessor: "code",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].code} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Type"
        className="min-w-150px"
      />
    ),
    accessor: "type",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].type} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Giá trị giảm"
        className="min-w-150px"
      />
    ),
    id: "discountValue",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={formatDiscountVoucher(
          props.data[props.row.index].discountValue,
          props.data[props.row.index].discountType
        )}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Số lượt đã dùng"
        className="min-w-150px"
      />
    ),
    accessor: "usedNumber",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].usedNumber} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    accessor: "isActive",
    Cell: ({ ...props }) => (
      <IsActiveCell value={props.data[props.row.index].isActive} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-150px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className=" d-flex gap-2 min-w-200px justify-content-end">
        <ActionEditCell
          data={props.row.original}
          useListView={useListView}
          isModal={false}
          path={`/vouchers/${props.data[props.row.index].id}/edit`}
        />
        <ActionDeleteCell data={props.row.original} useListView={useListView} />
      </div>
    ),
  },
];

export { COLUMNS };
