import { ID, KTCard, KTCardBody } from "_metronic/helpers";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import {
  // alertSuccess,
  createSuccess,
  updateSuccess,
} from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import Button from "app/components/Button";
import { StickyCard } from "app/components/Card/Index";
import {
  Input,
  MultiSelectDropdown,
  Slug,
  Switcher,
} from "app/components/Form";
import SEO from "app/components/Form/SEO/Index";
import TextArea from "app/components/Form/TextArea";
import TextEditor from "app/components/Form/TextEditor";
import CustomTabs from "app/components/Tabs";
import FileUpload from "app/components/Upload/FileUpload";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { getProfile } from "app/pages/account-management/profile/core/_requests";
import { TEditPage } from "constants/type";
import { CONTENT_VALIDATE_RULE } from "constants/validate-rule";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEventCategories, getProvinces, getSports } from "services/actions";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import {
  createItem,
  editItem,
  getAdminList,
  getGroupList,
  getItem,
  getList,
  getUserList,
} from "../core/_requests";

const OWNER_MODEL_DATA = [
  {
    label: "Admin",
    value: "Admin",
  },
  {
    label: "User",
    value: "User",
  },
  {
    label: "Group",
    value: "Group",
  },
];

const EditPage: FC<TEditPage> = ({ breadcrumbs }) => {
  const { id } = useParams();

  const [data, setData] = useState<ItemModel>(initialData);
  const [events, setEvents] = useState<ItemModel[]>([]);
  const [sports, setSports] = useState<any>([]);
  const [provinces, setProvinces] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [ownerList, setOwnerList] = useState<any>([]);
  const [selectedOwner, setSelectedOwner] = useState<any>();
  const { isLoading } = useQueryResponse();

  console.log(provinces);

  const navigate = useNavigate();

  const validation = Yup.object().shape({
    // ...SEO_VALIDATE_RULE,
    ...CONTENT_VALIDATE_RULE,
    // excerpt: Yup.string().required("Hãy nhập mô tả ngắn"),
    sports: Yup.array().min(1, "Hãy chọn ít nhất 1 môn thể thao"),
    categories: Yup.array().min(1, "Hãy chọn ít nhất 1 danh mục"),
    province: Yup.array().min(1, "Hãy chọn tỉnh thành"),
    address: Yup.string().required("Hãy nhập thông tin địa chỉ"),
  });

  const handleCreateEvent = async (query: any) => {
    await createItem({
      ...query,
      isActive: query.isPreview ? false : query.isActive,
    }).then(() => {
      createSuccess();
      if (query.isPreview) {
        window.open(
          `${process.env.REACT_APP_WEBSITE_URL}/event-detail/${query.alias}?isPreview=true`
        );
      } else {
        navigate("/event");
      }
    });
  };

  const handleEditEvent = async (query: any) => {
    let isActive = query.isActive;

    if (query.isPreview) {
      isActive = false;
    }

    editItem({ ...query, isActive }, id).then(updateSuccess);
  };

  const submit = async (query: any) => {
    if (id) {
      handleEditEvent(query);
    } else {
      handleCreateEvent(query);
    }
  };

  useEffect(() => {
    initialPage(id);
  }, [id]);

  const initialPage = async (id?: ID) => {
    if (id) {
      getItem(id)
        .then((data) => {
          setSelectedOwner({ model: data?.ownerModel, id: data?.owner?.id });
          initData(data);
        })
        .catch((e) => {
          console.log(e);
          navigate("/event");
        });
    } else {
      initData(initialData);
    }

    await Promise.all([
      getSports({ limit: 10, page: 1 })
        .then((x) => setSports(x.data))
        .catch((x) => console.log(x)),
      getEventCategories({ limit: 10, page: 1, isTour: false })
        .then((x) => setCategories(x.data))
        .catch((x) => console.log(x)),

      getList({ limit: 10, page: 1 })
        .then((x) => setEvents(x?.data || []))
        .catch((x) => console.log(x)),
    ]);
  };

  const initData = (data: ItemModel) => {
    setData({
      ...data,
      disabled: !!data?.disabled,
      hideOwner: !!data?.hideOwner,
      categories: mapValue(data?.categories),
      sports: mapValue(data.sports),
      requiredEvents: mapValue(data.requiredEvents),
      province: data.province
        ? [{ label: data.province.name, value: data.province.id }]
        : [],
    });

    getProfile().then((data) =>
      setData((prev) => {
        return {
          ...prev,
          owner: data.id,
        };
      })
    );
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let query = {
        ...values,
        sports: values.sports
          ?.map((x: any) => x?.value || null)
          .filter((x: any) => x),
        categories: values.categories
          ?.map((x: any) => x?.value || null)
          .filter((x: any) => x),
        province: values.province
          ? values.province
              .map((x: any) => x?.value || null)
              .filter((x: any) => x)[0]
          : null,
        noLimitTime: Boolean(values?.noLimitTime || false),
        startedAt: !values.noLimitTime && values.startedAt,
        endedAt: !values.noLimitTime
          ? values.endedAt
          : new Date(
              new Date().setFullYear(new Date().getFullYear() + 100)
            ).toDateString(),
        registerStartDate: values.registerStartDate,
        registerEndDate: !values.noLimitTime
          ? values.registerEndDate
          : new Date(
              new Date().setFullYear(new Date().getFullYear() + 100)
            ).toDateString(),
        isTour: false,
        services: values.services?.map((item) => {
          return {
            ...item,
            options: item.options.map((option) => ({
              ...option,
              value: option.initValue - (values.price || 0),
            })),
          };
        }),
      };

      if (!selectedOwner?.id) {
        query = {
          ...query,
          ownerModel: selectedOwner?.model || "Admin",
          owner: values?.owner?.id,
        };
      } else {
        query = {
          ...query,
          owner: selectedOwner?.id,
        };
      }

      await submit(query);
      setSubmitting(false);
    },
  });

  const tabs = [
    {
      id: "general",
      name: "Thông tin cơ bản",
      content: () => (
        <KTCard>
          <KTCardBody>
            <div className="fv-row mb-7">
              <Input
                required
                formik={formik}
                type="text"
                label="Tên"
                placeholder="Tên"
                name="title"
                disabled={formik?.isSubmitting}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                values={formik?.values?.title}
              />
            </div>
            <div className="fv-row mb-7">
              <Slug formik={formik} value={formik.values.alias || ""} />
            </div>
            <div className="my-4">
              <Input
                label="Ngày đăng"
                placeholder="Ngày đăng"
                type="datetime-local"
                name="publishedDate"
                disabled={formik.isSubmitting}
                onChange={(val) => formik.setFieldValue("publishedDate", val)}
                onBlur={formik.handleBlur}
                values={formik.values.publishedDate}
              />
            </div>
            <div className="fv-row mb-7">
              <FileUpload
                name="certTemplate"
                requireType={["html"]}
                description="Định dạng của file phải là định dạng .html"
                autoUpload
                value={formik.values.certTemplate}
                label="Certificate Template"
                uploaded={(val: string) => {
                  formik.setFieldValue("certTemplate", val);
                }}
              />
            </div>
            <div className="fv-row mb-7">
              <FileUpload
                name="bibTemplate"
                requireType={["html"]}
                description="Định dạng của file phải là định dạng .html"
                autoUpload
                value={formik.values.bibTemplate}
                label="BIB Template"
                uploaded={(val: string) => {
                  formik.setFieldValue("bibTemplate", val);
                }}
              />
            </div>
            {!formik.values.hideOwner && (
              <div className="row">
                <label htmlFor="" className="fw-bold fs-6">
                  Ban tổ chức
                </label>
                <div className="col-md-6 mb-7">
                  <div className="d-block d-lg-flex">
                    <MultiSelectDropdown
                      formik={formik}
                      options={mapValue(OWNER_MODEL_DATA)}
                      singleSelect
                      name="ownerModel"
                      classNameSelect="mb-3 mb-lg-0 p-0 custom-multi-select"
                      onSelect={(values: any) => {
                        formik.setFieldValue("ownerModel", values[0].value);
                        setSelectedOwner((prev: any) => {
                          return {
                            ...prev,
                            id: null,
                          };
                        });
                      }}
                      onRemove={(values: any) => {
                        formik.setFieldValue("ownerModel", values[0].value);
                      }}
                      values={[
                        {
                          label: formik.values.ownerModel,
                          value: formik.values.ownerModel,
                        },
                      ]}
                    />
                    <MultiSelectDropdown
                      formik={formik}
                      options={mapValue(ownerList)}
                      singleSelect
                      name="owner"
                      classLabel="fw-bold fs-6 mb-2"
                      classNameSelect="mb-3 mb-lg-0 p-0 custom-multi-select"
                      onSelect={(values: any) => {
                        setSelectedOwner((prev: any) => {
                          return {
                            ...prev,
                            id: values?.[0]?.value,
                          };
                        });
                      }}
                      onRemove={(values: any) => {
                        setSelectedOwner((prev: any) => {
                          return {
                            ...prev,
                            id: values?.[0]?.value,
                          };
                        });
                      }}
                      values={mapValue(
                        ownerList.filter(
                          (item?: any) =>
                            item?.id ===
                            (selectedOwner?.id || formik.values?.owner)
                        )
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
            {!formik.values.noLimitTime && (
              <div className="row">
                <div className="col-md-6 mb-7">
                  <Input
                    label="Ngày đăng ký"
                    placeholder="Ngày đăng ký"
                    type="datetime-local"
                    name="registerStartDate"
                    disabled={formik.isSubmitting}
                    onChange={(val) => {
                      formik.setFieldValue("registerStartDate", val);
                    }}
                    onBlur={formik.handleBlur}
                    values={formik.values.registerStartDate}
                    // min={convertDate(new Date(new Date().setDate(-1)))}
                  />
                </div>
                <div className="col-md-6 mb-7">
                  <Input
                    // valid={(date?: any) => {
                    //   return date.isAfter(
                    //     moment(formik.values.registerStartDate)
                    //   );
                    // }}
                    label="Ngày kết thúc đăng ký"
                    placeholder="Ngày kết thúc đăng ký"
                    type="datetime-local"
                    name="registerEndDate"
                    disabled={formik.isSubmitting}
                    onChange={(val) => {
                      formik.setFieldValue("registerEndDate", val);
                    }}
                    onBlur={formik.handleBlur}
                    values={formik.values.registerEndDate}
                  />
                </div>
                <div className="col-md-6 mb-7">
                  <Input
                    // valid={(date?: any) => {
                    //   return date.isAfter(
                    //     moment(formik.values.registerEndDate)
                    //   );
                    // }}
                    label="Ngày bắt đầu"
                    placeholder="Ngày bắt đầu"
                    type="datetime-local"
                    name="startedAt"
                    disabled={formik.isSubmitting}
                    onChange={(val) => {
                      formik.setFieldValue("startedAt", val);
                    }}
                    onBlur={formik.handleBlur}
                    values={formik.values.startedAt}
                    min={convertDate(new Date().toDateString())}
                  />
                </div>
                <div className="col-md-6 mb-7">
                  <Input
                    // valid={(date?: any) => {
                    //   return date.isAfter(moment(formik.values.startedAt));
                    // }}
                    label="Ngày kết thúc"
                    placeholder="Ngày kết thúc"
                    type="datetime-local"
                    name="endedAt"
                    disabled={formik.isSubmitting}
                    onChange={(val) => {
                      formik.setFieldValue("endedAt", val);
                    }}
                    onBlur={formik.handleBlur}
                    values={formik.values.endedAt}
                    min={convertDate(formik.values.startedAt)}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-6 mb-7">
                <Input
                  label="Giá tối thiểu"
                  placeholder="Giá tối thiểu"
                  name="price"
                  type="number"
                  min="0"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.price}
                />
              </div>
              <div className="col-md-6 mb-7">
                <Input
                  label="Giá khuyến mãi"
                  placeholder="Giá khuyến mãi"
                  name="sellPrice"
                  type="number"
                  min="0"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.sellPrice}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-7">
                <TextEditor
                  label="Phần thưởng"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onBlur={formik.handleBlur}
                  values={formik.values.award}
                  name="award"
                />
              </div>
              <div className="col-md-6 mb-7">
                <Input
                  formik={formik}
                  label="Hoàn thành"
                  placeholder="VD: Hoàn thành trong một lần"
                  name="rule"
                  type="text"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.rule}
                />
              </div>
              {formik.values.isChallenge ? (
                <div className="col-md-6 mb-7">
                  <Input
                    label="Thử thách"
                    placeholder="Thử thách"
                    name="challenge"
                    min="3"
                    max="255"
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    values={formik.values.challenge}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="col-md-6 mb-7">
                <Input
                  label="Level"
                  placeholder="Level"
                  name="level"
                  type="number"
                  // min="0"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.level}
                />
              </div>

              <div className="col-md-6 mb-7">
                <Input
                  label="Giới hạn người tham gia"
                  placeholder="Giới hạn người tham gia"
                  name="limitMember"
                  type="number"
                  min="0"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.limitMember}
                />
              </div>
            </div>
            <div className="fv-row mb-7">
              <Input
                label="Mức giá theo km"
                name="services"
                formik={formik}
                type="services"
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={
                  formik.values.services?.find((x) => x.key === "distance")
                    ?.options || []
                }
              />
            </div>
          </KTCardBody>
        </KTCard>
      ),
    },
    {
      id: "content",
      name: "Nội dung",
      content: () => (
        <>
          <KTCard>
            <KTCardBody>
              <div className="fv-row mb-7">
                <TextArea
                  label="Mô tả ngắn"
                  rows={6}
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.excerpt}
                  name="excerpt"
                />
              </div>
              <div className="fv-row mb-7">
                <TextEditor
                  label="Mô tả"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onBlur={formik.handleBlur}
                  values={formik.values.description}
                  name="description"
                />
              </div>

              <div className="fv-row mb-7">
                <TextEditor
                  label="Chi tiết phần thưởng"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onBlur={formik.handleBlur}
                  values={formik.values.awardTerm}
                  name="awardTerm"
                />
              </div>
              <div className="fv-row mb-7">
                <TextEditor
                  label="Điều khoản"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onBlur={formik.handleBlur}
                  values={formik.values.term}
                  name="term"
                />
              </div>
            </KTCardBody>
          </KTCard>
          <SEO className="mt-10" formik={formik} isLoading={isLoading} />
        </>
      ),
    },
    {
      id: "advance",
      name: "Thông tin nâng cao",
      content: () => (
        <KTCard>
          <KTCardBody>
            <div className="row">
              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={mapValue(sports)}
                  required
                  label="Môn thể thao"
                  name="sports"
                  onSearch={async (x: any) => {
                    const result = await getSports({ search: x });
                    setSports(result.data || []);
                  }}
                  onSelect={(values) => {
                    formik.setFieldValue("sports", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("sports", values);
                  }}
                  values={formik.values.sports}
                />
              </div>
              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={mapValue(categories)}
                  required
                  label="Danh mục"
                  name="categories"
                  onSearch={async (x: any) => {
                    const result = await getEventCategories({
                      search: x,
                      limit: 10,
                      page: 1,
                      isTour: false,
                    });
                    setCategories(result.data || []);
                  }}
                  onSelect={(values) => {
                    formik.setFieldValue("categories", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("categories", values);
                  }}
                  values={formik.values.categories}
                />
              </div>
              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={mapValue(events)}
                  label="Sự kiện phải hoàn thành"
                  name="requiredEvents"
                  onSearch={async (x: any) => {
                    const result = await getList({
                      search: x,
                      limit: 10,
                      page: 1,
                    });
                    setEvents(result.data || []);
                  }}
                  onSelect={(values) => {
                    formik.setFieldValue("requiredEvents", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("requiredEvents", values);
                  }}
                  values={formik.values.requiredEvents}
                />
              </div>
              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={mapValue(provinces)}
                  onSearch={async (x: any) => {
                    const result = await getProvinces({ search: x });
                    setProvinces(mapValue(result) || []);
                  }}
                  singleSelect
                  label="Tỉnh thành"
                  required
                  name="province"
                  classLabel="fw-bold fs-6 mb-2"
                  classNameSelect="form-control-solid mb-3 mb-lg-0 custom-multi-select"
                  onSelect={(values: any) => {
                    formik.setFieldValue("province", values);
                  }}
                  onRemove={(values: any) => {
                    formik.setFieldValue("province", values);
                  }}
                  values={formik.values.province}
                />
              </div>
              <div className="col-md-12 mb-7">
                <Input
                  required
                  label="Địa chỉ"
                  placeholder="Địa chỉ"
                  name="address"
                  min="3"
                  type="text"
                  max="255"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.address}
                />
              </div>
            </div>
          </KTCardBody>
        </KTCard>
      ),
    },
    {
      id: "gallery",
      name: "Hình ảnh",
      content: () => (
        <KTCard>
          <KTCardBody>
            <ImageUploadGlobal
              formik={formik}
              name="gallery"
              path="/events"
              multiple={true}
            />
          </KTCardBody>
        </KTCard>
      ),
    },
  ];

  useEffect(() => {
    if (formik.values.ownerModel) {
      switch (formik.values.ownerModel) {
        case "Admin":
          getAdminList({ limit: 20, page: 1 })
            .then((x) => {
              setOwnerList(x.data);
            })
            .catch((x) => console.log(x));
          break;

        case "User":
          getUserList({ limit: 20, page: 1 })
            .then((x) => {
              setOwnerList(x.data);
            })
            .catch((x) => console.log(x));
          break;

        case "Group":
          getGroupList({ limit: 20, page: 1 })
            .then((x) => {
              setOwnerList(x.data);
            })
            .catch((x) => console.log(x));
          break;
      }
    }
  }, [formik.values.ownerModel]);

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>
        {data?.title ? data.title : "Thêm mới"}
      </PageTitle>

      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <CustomTabs
              className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
              items={tabs}
            />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyCard isSticky={false}>
              <ImageUploadGlobal
                formik={formik}
                required
                className="mx-auto w-100 border mb-10"
                path="/events"
              />
              <div className="fv-row mb-7">
                <Switcher
                  label="Trạng thái"
                  name="isActive"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={formik.values.isActive}
                />
              </div>

              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Công khai"
                  name="visible"
                  onChange={formik.handleChange}
                  value={formik.values.visible}
                />
              </div>
              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Ẩn đơn vị tổ chức"
                  name="hideOwner"
                  onChange={formik.handleChange}
                  value={formik.values.hideOwner}
                />
              </div>
              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Ẩn thông tin"
                  name="disabled"
                  onChange={formik.handleChange}
                  value={formik.values.disabled}
                />
              </div>
              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Thử thách?"
                  name="isChallenge"
                  onChange={formik.handleChange}
                  value={formik.values.isChallenge}
                />
              </div>
              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Cung đường?"
                  name="isRoad"
                  onChange={formik.handleChange}
                  value={formik.values.isRoad}
                />
              </div>
              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Không hạn chế thời gian"
                  name="noLimitTime"
                  onChange={formik.handleChange}
                  value={formik.values.noLimitTime}
                />
              </div>

              <div className="fv-row mb-7">
                <Switcher
                  label="Nổi bật"
                  name="isFeatured"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={!!formik.values.isFeatured}
                />
              </div>

              <div className="fv-row mb-7">
                <Switcher
                  label="Hoàn thành trong 1 lần"
                  name="isCompletedOnce"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={!!formik.values.isCompletedOnce}
                />
              </div>

              <div className="fv-row mb-7">
                <Switcher
                  label="Bảng xếp hạng theo tuần"
                  name="isFilterWeekly"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={!!formik.values.isFilterWeekly}
                />
              </div>

              <div className="fv-row mb-7">
                <Switcher
                  label="Xem trước"
                  name="isPreview"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={formik.values.isPreview}
                />
              </div>

              <div className="text-end mt-12">
                <Button
                  type="button"
                  onClick={formik.handleSubmit}
                  disabled={
                    formik?.isSubmitting ||
                    !formik?.isValid ||
                    !formik?.touched ||
                    isLoading
                  }
                  className="btn btn-primary btn-sm"
                >
                  {id ? "Cập nhật" : "Thêm mới"}
                </Button>
              </div>
            </StickyCard>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
