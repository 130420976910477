import { KTCard } from "_metronic/helpers";
import { updateSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { Input, TextArea } from "app/components/Form";
import { FormField } from "app/components/Form/Field";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Setting } from "../core/_models";
import { getSetting, updateSetting } from "../core/_requests";

export const GeneralSetting = () => {
  const isLoading = false;

  const [data, setData] = useState<Setting>({});

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    getSetting().then((result) => setData({ ...data, ...result }));
  };

  const fetchUpdateSetting = async (params: any) => {
    try {
      await updateSetting(params).then(updateSuccess);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      siteTitle: Yup.string()
        // .min(1, "Tên trang phải trên 1 ký tự")
        // .max(150, "Tên trang tối đa 150 ký tự")
        .required("Vui lòng điền đủ thông tin"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await fetchUpdateSetting(values);
      setSubmitting(false);
    },
  });

  const CONTACT_FIELDS = [
    {
      name: "contactCompany",
      label: "Công ty",
      required: false,
      type: "text",
    },
    {
      name: "contactAddress",
      label: "Địa chỉ",
      required: false,
      type: "textarea",
    },
    {
      name: "contactEmail",
      label: "Email",
      required: false,
      type: "text",
    },
    {
      name: "contactPhone",
      label: "Hotline",
      required: false,
      type: "text",
    },
    {
      name: "contactPhone2",
      label: "CSKH",
      required: false,
      type: "text",
    },
    {
      name: "slogan",
      label: "Slogan",
      required: false,
      type: "text",
    },
  ];

  return (
    <form className="mt-7" onSubmit={formik.handleSubmit} noValidate>
      <KTCard>
        <div className="card-body">
          <h2>Thông tin chung</h2>
          <div className="fv-row">
            <div className="fs-6 fw-semibold mt-2 mb-3">Logo</div>
            <ImageUploadGlobal
              name="siteLogo"
              className="col-lg-2 border mb-7"
              path="/settings"
              formik={formik}
            />

            <div className="fs-6 fw-semibold mt-2 mb-3">Site Image</div>
            <ImageUploadGlobal
              name="siteImage"
              className="col-lg-2 border mb-7"
              path="/settings"
              formik={formik}
            />
          </div>
          <div className="fv-row mb-7">
            <Input
              formik={formik}
              label="Site Title"
              placeholder="Site Title"
              name="siteTitle"
              disabled={formik.isSubmitting || isLoading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              values={formik.values.siteTitle}
            />
          </div>
          <div className="fv-row mb-7">
            <TextArea
              formik={formik}
              label="Site Description"
              placeholder="Site Description"
              name="siteDescription"
              disabled={formik.isSubmitting || isLoading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              values={formik.values.siteDescription}
            />
          </div>
          <div className="row">
            <div className="col-lg-6 mb-7">
              <Input
                formik={formik}
                label="Appstore"
                name="appstoreUrl"
                disabled={formik.isSubmitting || isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={formik.values.appstoreUrl}
              />
            </div>
            <div className="col-lg-6 mb-7">
              <Input
                formik={formik}
                label="CH Play"
                name="chplayUrl"
                disabled={formik.isSubmitting || isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={formik.values.chplayUrl}
              />
            </div>
          </div>
          <div className="text-end mt-12 mb-12">
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={
                isLoading ||
                formik?.isSubmitting ||
                !formik?.isValid ||
                !formik?.touched
              }
            >
              Xác nhận
            </Button>
          </div>
        </div>
      </KTCard>
      <KTCard className="mt-5">
        <div className="card-body">
          <h2>Liên hệ</h2>
          <div className="row">
            {CONTACT_FIELDS.map((x) => {
              const fieldData = { ...x, formik };
              return (
                <React.Fragment key={x.name}>
                  <div className="col-lg-6 mb-7">{FormField(fieldData)}</div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="text-end mt-12 mb-12">
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={
                isLoading ||
                formik?.isSubmitting ||
                !formik?.isValid ||
                !formik?.touched
              }
            >
              Xác nhận
            </Button>
          </div>
        </div>
      </KTCard>
    </form>
  );
};

export default GeneralSetting;
