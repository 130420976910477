import { ID } from "_metronic/helpers";
import { createSuccess } from "_metronic/helpers/toastify";
import { AxiosResponse } from "axios";
import api, { deleteDataItem, getData, postData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `product`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, query);

const getListVariant = async (query: any): Promise<QueryResponse> =>
  getData("/product-variant", query);

const getItem = async (id: ID): Promise<ItemModel> =>
  getData(URL + "/" + id, { platform: process.env.REACT_APP_PLATFORM });

const createItem = async (query: any): Promise<any> =>
  postData(URL, query).then((res) => {
    if (res.statusCode && res.statusCode > 300) {
      return false;
    }

    createSuccess();
    return res;
  });

const editItem = (query: any, id: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`${URL}/${id}`, query)
    .then((d: AxiosResponse<any>) => d?.data || false);

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

const createVariant = async (query: any): Promise<QueryResponse> =>
  postData("product-variant", query);

const editVariant = (query: any, id: ID): Promise<QueryResponse> =>
  api({ isToken: true })
    .put(`product-variant/${id}`, query)
    .then((d: AxiosResponse<any>) => d?.data || false);

const deleteVariant = async (id: any): Promise<QueryResponse> =>
  api({ isToken: true }).delete(`product-variant/${id}`);

const getCommentsByProduct = async (id: ID): Promise<any> =>
  getData("comment", {
    target: id,
    targetModel: "Product",
    limit: 1000,
  });

const approveComment = (id: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`comment/approval`, { ids: [id] })
    .then((d: AxiosResponse<any>) => d?.data || false);

const rejectComment = (id: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`comment/reject`, { ids: [id] })
    .then((d: AxiosResponse<any>) => d?.data || false);

const replyComment = async (query: any): Promise<QueryResponse> =>
  postData("comment/reply", query);

export {
  approveComment,
  bulkDelete,
  createItem,
  createVariant,
  deleteItem,
  deleteVariant,
  editItem,
  editVariant,
  getCommentsByProduct,
  getItem,
  getList,
  getListVariant,
  rejectComment,
  replyComment,
};
