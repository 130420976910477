import { ID } from "_metronic/helpers";
import { AxiosResponse } from "axios";
import api, { deleteDataItem, getData } from "services/api";

import { QueryResponse } from "./_models";

const URL = `users`;

const getItem = async (query: any): Promise<QueryResponse> => {
  return getData(URL, query);
};

const getDetail = async (id: ID): Promise<QueryResponse> => {
  return getData(URL + "/" + id);
};

const syncData = async (query?: any): Promise<any> =>
  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/user-ranking`,
    query
  );

const getDetailByAction = async (
  action: string,
  id: ID,
  query?: any
): Promise<QueryResponse> => {
  return getData(URL + "/" + id + "/" + action, { ...query });
};

const createItem = async (query: any): Promise<QueryResponse> => {
  return api({ isToken: true })
    .post(`${URL}`, query)
    .then((d: AxiosResponse<QueryResponse>) => d?.data);
};

const editItem = async (query: any, id: ID): Promise<QueryResponse> => {
  return api({ isToken: true })
    .patch(`${URL}/${id}`, query)
    .then((d: AxiosResponse<QueryResponse>) => d?.data);
};

const deleteItem = async (id: ID): Promise<QueryResponse> => {
  return api({ isToken: true })
    .delete(`${URL}/${id}`)
    .then((d: AxiosResponse<QueryResponse>) => d?.data);
};

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

const bulkSync = async (ids: Array<ID>): Promise<void> => {
  await syncData({ user: ids });
};

export {
  bulkDelete,
  bulkSync,
  createItem,
  deleteItem,
  editItem,
  getDetail,
  getDetailByAction,
  getItem,
  syncData,
};
