import { KTCard } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import { FormField } from "app/components/Form/Field";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem, getItem, getList } from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const [collections, setCollections] = useState<ItemModel[]>([]);
  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) =>
        setData({
          ...x,
          platform: x?.platform?.map((item?: string) => ({
            label: item,
            value: item,
          })),
        })
      )
      .catch((e) => {
        console.log(e);
        navigate("/collection");
      });
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (id) {
          await editItem(
            {
              ...values,
              isActive: !!values?.isActive,
              platform: [process.env.REACT_APP_PLATFORM],
            },
            id
          ).then(updateSuccess);
        } else {
          await createItem({
            ...values,
            platform: [process.env.REACT_APP_PLATFORM],
            isActive: !!values?.isActive,
          }).then(() => {
            createSuccess();
            navigate("/collection");
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "title",
      required: true,
      type: "text",
    },
    {
      label: "Alias",
      name: "alias",
      required: true,
      type: "alias",
    },
    {
      label: "Nội dung",
      name: "description",
      required: false,
      type: "editor",
    },
    {
      label: "Collection cha",
      name: "parent",
      required: false,
      type: "dropdown",
      options: collections,
      onSearch: (e: string) => {
        getList({ search: e, limit: 10, noPaginate: true }).then((data) => {
          setCollections(mapValue(data).filter((x: any) => x.value !== id));
        });
      },
    },
  ];

  return (
    <>
      {id && <PageTitle>{data.title}</PageTitle>}
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };
                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={formik.handleSubmit}
            >
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 border mb-10"
                path="/collection"
              />
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
