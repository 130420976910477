import { ID } from "_metronic/helpers";
import { deleteDataItem, getData, postData, updateData } from "services/api";

import { QueryResponse } from "./_models";

const URL = `post`;

const getListTag = (search?: string): Promise<any> =>
  getData("tag", { search, limit: 10, page: 1, isActive: true });

const getListCategories = (search?: string): Promise<any> =>
  getData("category", {
    isActive: true,
    search,
    limit: 10,
    noPaginate: true,
    platform: process.env.REACT_APP_PLATFORM,
  });

const getItem = async (id: ID): Promise<QueryResponse> =>
  getData(URL + "/" + id);

const getList = (query: any): Promise<QueryResponse> => getData(URL, query);

const createItem = (query: any): Promise<any> => postData(URL, query);

const editItem = (query: any, id: ID): Promise<QueryResponse> =>
  updateData(URL, query, id);

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

export {
  bulkDelete,
  createItem,
  deleteItem,
  editItem,
  getItem,
  getList,
  getListCategories,
  getListTag,
};
