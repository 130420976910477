import { KTCard, KTCardBody } from "_metronic/helpers";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import convertTime from "_metronic/helpers/convertData/convert-time";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getDetailByAction } from "../core/_requests";

export const Activities = () => {
  const [activities, setActivities] = useState<any>();
  const { id } = useParams<string>();

  const convertPace = (distance: number, time: number) => {
    if (time < 60) {
      return `${(distance / time).toFixed(2)}km/s`;
    } else if (time < 3600) {
      return `${(distance / (time / 60)).toFixed(2)}km/m`;
    } else {
      return `${(distance / (time / 3600)).toFixed(2)}km/h`;
    }
  };

  useEffect(() => {
    getDetailByAction("activity", id)
      .then((x) => setActivities(x.data))
      .catch((x) => console.log(x));
  }, [id]);

  return (
    <KTCard>
      <div className="card-header">
        <div className="card-title">
          <h4 className="h4">
            Tổng số hoạt động:{" "}
            <span className="mx-1 text-primary">{activities?.length}</span> Hoạt
            động
          </h4>
        </div>
      </div>
      <KTCardBody>
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
              <tr>
                <th className="min-w-200px">Nội dung</th>
                <th className="min-w-100px">Ngày tạo</th>
                <th className="min-w-150px">Môn thể thao</th>
                <th className="min-w-150px">Quãng đường</th>
                <th className="min-w-150px">Thời gian</th>
                <th className="min-w-150px">Tốc độ trung bình</th>
              </tr>
            </thead>
            <tbody className="fw-6 fw-semibold text-gray-600">
              {activities?.map((item: any, index: number) => (
                <tr className="row-member" key={index}>
                  <td>
                    <p className="fw-bolder">{item.data.name}</p>
                  </td>
                  <td>{convertDate(item.createdAt)}</td>
                  <td>{item.data?.sport_type}</td>
                  <td>{(item.distance / 1000).toFixed(2)}km</td>
                  <td>{convertTime(item.elapsedTime)}</td>
                  <td>{convertPace(item.distance / 1000, item.elapsedTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default Activities;
