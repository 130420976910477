import { useLayout } from "_metronic/layout/core";
import {
  DetailsView,
  FileManagerComponent,
  Inject,
  NavigationPane,
  Toolbar,
} from "@syncfusion/ej2-react-filemanager";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { useEffect, useRef, useState } from "react";

import { ajaxConfig } from "../FileManager/config";

const FileManagerModal = () => {
  const dialogObj = useRef<DialogComponent>(null);
  const filemanagerObj = useRef<FileManagerComponent>(null);

  const {
    isShowFilemanager,
    setSelectedFiles,
    fileManagerConfig,
    closeFilemanager,
  } = useLayout();

  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const menuOpen = (args: any) => {
    if (filemanagerObj.current)
      for (const i in args.items) {
        if (
          args.items[i].id ===
          filemanagerObj.current.element.id + "_cm_select"
        ) {
          args.items[i].iconCss = "e-icons e-fe-tick";
        }
      }
  };

  const menuClick = (args: any) => {
    if (args.item.text === "Select") {
      if (filemanagerObj.current) {
        const items = [];
        const files = filemanagerObj.current.getSelectedFiles();
        for (let index = 0; index < files.length; index++) {
          const file: any = files[index];
          if (file.isFile) {
            items.push({
              name: file.name,
              size: file.size,
              type: file.type,
              path: file.path,
              url: file.publicUrl,
              widgetId: fileManagerConfig.id,
            });
          }
        }
        setSelectedItems(selectedItems.map((x: any) => x.name));
        setSelectedFiles(items);
      }

      closeFilemanager();
      if (dialogObj.current) dialogObj.current.hide();
    }
  };

  useEffect(() => {
    if (isShowFilemanager) {
      if (dialogObj.current) {
        dialogObj.current.show();
        if (filemanagerObj.current) {
          filemanagerObj.current.path = filemanagerObj.current.path.replace(
            "//",
            "/"
          );
          // filemanagerObj.current.path = fileManagerConfig.path || "/upload";
          filemanagerObj.current.selectedItems =
            fileManagerConfig.selectedFiles || [];
          setSelectedItems(fileManagerConfig.selectedFiles || []);
          filemanagerObj.current.refresh();
        }
      }
    }
  }, [isShowFilemanager]);

  return (
    <DialogComponent
      width="850px"
      id={`ImageUploadGlobal`}
      target="#kt_app_wrapper"
      ref={dialogObj}
      allowDragging={true}
      header="Media"
      isModal={true}
      minHeight={400}
      showCloseIcon={true}
      visible={false}
      open={() => null}
      close={() => closeFilemanager()}
      position={{ X: "center", Y: "center" }}
      animationSettings={{ effect: "None" }}
    >
      <FileManagerComponent
        id={`filemanagerGlbal`}
        ref={filemanagerObj}
        ajaxSettings={ajaxConfig}
        sortBy="dateModified"
        sortOrder="Descending"
        path="/tmp"
        toolbarSettings={{
          items: [
            "NewFolder",
            "Upload",
            "Delete",
            // "Cut",
            // "Copy",
            "Rename",
            "SortBy",
            "Refresh",
            "Selection",
            // "View",
            // "Details",
          ],
        }}
        contextMenuSettings={{
          file: [
            "Open",
            "|",
            "Cut",
            "Copy",
            "Delete",
            "Rename",
            "|",
            "Details",
            "Select",
          ],
          folder: ["Open", "Delete", "Rename"],
        }}
        menuClick={menuClick}
        menuOpen={menuOpen}
        // enablePersistence={true}
        allowMultiSelection={true}
      >
        <Inject services={[NavigationPane, DetailsView, Toolbar]} />
      </FileManagerComponent>
    </DialogComponent>
  );
};

export default FileManagerModal;
