import { ID, KTCard } from "_metronic/helpers";
import { updateSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { InputDropdown } from "app/components/Form";
import CustomTabs from "app/components/Tabs";
import { BikeRentalBookingStatusEnum } from "constants/enum";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ItemModel } from "../core/_models";
import { editItem, getItem } from "../core/_requests";
import Logs from "./Logs";
import ConfirmModal from "./Modal";
import PriceTable from "./PriceTable/index";

const EditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<ItemModel>({} as ItemModel);
  const [status, setStatus] = useState(data?.status);
  const [confirmChangeStatus, setConfirmChangeStatus] = useState(false);

  const submit = async (query?: any) => {
    await editItem(query, id).then(updateSuccess);
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const query = {
          status: status,
          logs: values.logs,
          isFee: values.isFee,
          needFixed: values.needFixed,
          extraFee: values.extraFee || 0,
          expiredHours: values.expiredHours || 0,
        };

        setConfirmChangeStatus(false);
        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const renderOption = () => {
    switch (formik.values.status) {
      case "CANCEL":
        return [
          {
            label: "CANCEL",
            value: "CANCEL",
          },
        ];

      case "BOOKING":
        return Object.keys(BikeRentalBookingStatusEnum)
          .map((item) => ({
            label:
              BikeRentalBookingStatusEnum[
                item as keyof typeof BikeRentalBookingStatusEnum
              ],
            value: item,
          }))
          .filter(
            (item) =>
              item.value === BikeRentalBookingStatusEnum.IN_PROCCESS ||
              item.value === BikeRentalBookingStatusEnum.CANCEL ||
              item.value === BikeRentalBookingStatusEnum.BOOKING
          );

      case "IN_PROCCESS":
        return Object.keys(BikeRentalBookingStatusEnum)
          .map((item) => ({
            label:
              BikeRentalBookingStatusEnum[
                item as keyof typeof BikeRentalBookingStatusEnum
              ],
            value: item,
          }))
          .filter(
            (item) =>
              item.value === BikeRentalBookingStatusEnum.FINISH ||
              item.value === BikeRentalBookingStatusEnum.IN_PROCCESS
          );

      case "FINISH":
        return Object.keys(BikeRentalBookingStatusEnum)
          .map((item) => ({
            label:
              BikeRentalBookingStatusEnum[
                item as keyof typeof BikeRentalBookingStatusEnum
              ],
            value: item,
          }))
          .filter((item) => item.value === BikeRentalBookingStatusEnum.FINISH);
    }
  };

  const tabs = [
    {
      id: "general",
      name: "Thông tin chung",
      content: () => (
        <>
          <KTCard className="p-10 mb-7">
            <div className="fv-row mb-7">
              <InputDropdown
                formik={formik}
                label={"Trạng thái đơn hàng"}
                options={renderOption()}
                onChange={(e) => {
                  setStatus(e.value);
                }}
                classNameLabel="form-label fs-6 fw-bold"
                classNameDropdown="form-select form-select-solid fw-bolder"
                values={status}
              />
            </div>

            <div className="text-end">
              <Button
                type="button"
                className="btn btn-primary "
                onClick={() => {
                  if (status === BikeRentalBookingStatusEnum.FINISH) {
                    setConfirmChangeStatus(true);
                  } else {
                    formik.handleSubmit();
                  }
                }}
                disabled={status === formik?.values?.status}
              >
                Cập nhật
              </Button>
            </div>
          </KTCard>

          <PriceTable data={data} />
        </>
      ),
    },

    {
      id: "logs",
      name: "Logs",
      content: () => (
        <>
          <Logs data={data?.logs} formik={formik} />
        </>
      ),
    },
  ];

  useEffect(() => {
    initialPage(id);
  }, [id]);

  const initialPage = async (id?: ID) => {
    getItem(id)
      .then((data) => {
        setStatus(data?.status);
        setData({ ...data });
      })
      .catch((e) => {
        console.log(e);
        navigate("/bike-rental-booking");
      });
  };

  return (
    <>
      <CustomTabs
        className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
        items={tabs}
      />

      {confirmChangeStatus ? (
        <ConfirmModal formik={formik} setIsOpen={setConfirmChangeStatus} />
      ) : (
        <></>
      )}
    </>
  );
};

export default EditPage;
