import { ID } from "_metronic/helpers";
import api, {
  deleteDataItem,
  getData,
  postData,
  updateData,
} from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `event`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, {
    limit: 10,
    page: 1,
    sortBy: "createdAt",
    orderBy: "desc",
    ...query,
    type: "event",
    isActive: true,
  });

const getItem = async (id: ID): Promise<ItemModel> => getData(URL + "/" + id);

const createItem = async (query: any): Promise<QueryResponse> =>
  postData(URL, { ...query, type: "event" });

const editItem = (query: any, id: ID): Promise<ItemModel> =>
  updateData(URL, { ...query, type: "event" }, id);

const syncData = async (query?: any): Promise<any> => {
  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/event-member-ranking`,
    query
  );

  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/event-group-ranking`,
    query
  );

  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/event-ranking`,
    query
  );
};
// await api({ isToken: true }).post(
//   `${process.env.REACT_APP_API_URL}/activity/sync/event-group-ranking`,
//   query
// );

const deleteItem = async (id: ID) => {
  await deleteDataItem(URL, id);
  await getData(`/${URL}/clean`);
};

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

const getAdminList = async (query: any): Promise<QueryResponse> => {
  return getData("admins", query);
};

const getUserList = async (query: any): Promise<QueryResponse> => {
  return getData("users", query);
};

const getGroupList = async (query: any): Promise<QueryResponse> => {
  return getData("group", query);
};

const duplicate = (id: ID): Promise<ItemModel> =>
  api({ isToken: true }).post(`${URL}/${id}/duplicate`);

const bulkSync = async (ids: Array<ID>): Promise<void> => {
  await syncData({ event: ids });
};

export {
  bulkDelete,
  bulkSync,
  createItem,
  deleteItem,
  duplicate,
  editItem,
  getAdminList,
  getGroupList,
  getItem,
  getList,
  getUserList,
  syncData,
};
