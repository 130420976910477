import { KTIcon, useDebounce } from "_metronic/helpers";
import { FC, useEffect, useState } from "react";

type Props = {
  updateState: any;
  placeholderSearch?: string;
  state?: any;
};

const HeaderSearch: FC<Props> = ({
  updateState,
  state = {},
  placeholderSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const debouncedSearchTerm = useDebounce(searchTerm, 700);
  // Effect for API call
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        ...state,
        page: 1,
        search: debouncedSearchTerm,
      });
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="card-title">
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative my-1">
        <KTIcon iconName="magnifier" className="fs-1 position-absolute ms-6" />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid w-300px ps-14"
          placeholder={placeholderSearch}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  );
};

export { HeaderSearch };
