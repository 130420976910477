import { KTIcon } from "_metronic/helpers";
import Button from "app/components/Button";
import { ReactNode } from "react";

interface PropsType {
  children: ReactNode;
}

export const FilterList = (props: PropsType) => {
  const { children } = props;

  return (
    <>
      <Button
        type="button"
        className="btn btn-light-primary me-3"
        data-kt-menu-trigger="click"
        // data-kt-menu-placement="bottom-end"
      >
        <KTIcon iconName="filter" className="fs-2" />
        Lọc
      </Button>
      {children}
    </>
  );
};

export default FilterList;
