import CustomTabs from "app/components/Tabs";

// import AdvanceSetting from "./component/AdvanceSetting";
import GeneralSetting from "./component/GeneralSetting";

const SettingPage = () => {
  const tabs = [
    {
      id: "general",
      name: "Thông tin cơ bản",
      content: () => <GeneralSetting />,
    },
    // {
    //   id: "advance",
    //   name: "Thông tin nâng cao",
    //   content: () => <AdvanceSetting />,
    // },
  ];
  return (
    <div className="form">
      <CustomTabs
        className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
        items={tabs}
      />
    </div>
  );
};

export default SettingPage;
