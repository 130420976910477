import { KTCard } from "_metronic/helpers";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { Switcher } from "app/components/Form";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import CustomTabs from "app/components/Tabs";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem, getItem } from "../core/_requests";
import AdvanceSetting from "./AdvanceSetting";
import GeneralSetting from "./GeneralSetting";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);

  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();

  const validation = Yup.object().shape({
    // ...CONTENT_VALIDATE_RULE,
  });

  const submit = async (query: any) => {
    try {
      if (id) {
        await editItem(query, id).then(updateSuccess);
      } else {
        await createItem(query).then((data) => {
          if (data.statusCode && data.statusCode > 300) {
            return;
          }

          createSuccess();
          navigate("/store");
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const initPage = async () => {
    if (id) {
      await getItem(id)
        .then(async (x) => {
          setData({
            ...x,
            isRental: !!x.isRental,
          });
        })
        .catch((e) => {
          navigate("/store");
        });
    } else {
      setData(initialData);
    }
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const query = {
          ...values,
          manager: values?.managerId || "",
          province: values?.provinceId || "",
          district: values?.districtId || "",
          ward: values?.wardId || "",
          id: values?.id || null,
          isActive: !!values?.isActive,
        };
        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    initPage();
  }, []);

  const tabs = [
    {
      id: "general",
      name: "Thông tin cơ bản",
      content: () => <GeneralSetting formik={formik} />,
    },
    {
      id: "advance",
      name: "Điều kiện",
      content: () => <AdvanceSetting formik={formik} />,
    },
  ];

  return (
    <>
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              <CustomTabs
                className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
                items={tabs}
              />
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={() => {
                setTimeout(() => formik.handleSubmit(), 1000);
              }}
            >
              <ImageUploadGlobal
                className="mx-auto w-100 border mb-10"
                path="/products"
                formik={formik}
              />

              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Cho thuê xe"
                  name="isRental"
                  onChange={formik.handleChange}
                  value={formik.values.isRental}
                />
              </div>
              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Hiện bản đồ"
                  name="isShowOnMap"
                  onChange={formik.handleChange}
                  value={formik.values.isShowOnMap}
                />
              </div>
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
