import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  ActionDeleteCell,
  ActionEditCell,
  DefaultCell,
  IsActiveCell,
  SelectionCell,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tên chi nhánh"
        className="min-w-200px"
      />
    ),
    accessor: "name",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={props.data[props.row.index].name}
        link={`${props.data[props.row.index].id}/edit`}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Địa chỉ"
        className="min-w-250px"
      />
    ),
    id: "address",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].address} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Số điện thoại"
        className="min-w-150px"
      />
    ),
    id: "phone",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].phone} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    accessor: "isActive",
    Cell: ({ ...props }) => (
      <IsActiveCell value={props.data[props.row.index].isActive} />
    ),
  },

  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className=" d-flex gap-2 min-w-200px justify-content-end">
        <ActionEditCell
          data={props.row.original}
          useListView={useListView}
          isModal={false}
          path={`/store/${props.data[props.row.index].id}/edit`}
        />
        <ActionDeleteCell data={props.row.original} useListView={useListView} />
      </div>
    ),
  },
];

export { COLUMNS };
