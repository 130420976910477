import { toAbsoluteUrl } from "_metronic/helpers";

export const Feeds = () => {
  const data = [
    {
      title: "Bài viết 1",
      status: true,
      owner: "Nguyễn văn A",
      timePost: "06:00 Sáng, 01/09/2023",
      achievements: "Chạy bộ 15km",
    },
    {
      title: "Bài viết 2",
      status: false,
      owner: "Nguyễn văn B",
      timePost: "06:00 Sáng, 01/09/2023",
      achievements: "Chạy bộ 15km",
    },
    {
      title: "Bài viết 3",
      status: true,
      owner: "Nguyễn văn C",
      timePost: "06:00 Sáng, 01/09/2023",
      achievements: "Đạp xe 45km",
    },
    {
      title: "Bài viết 4",
      status: true,
      owner: "Nguyễn văn D",
      timePost: "06:00 Sáng, 01/09/2023",
      achievements: "Chạy bộ 15km",
    },
    {
      title: "Bài viết 5",
      status: false,
      owner: "Nguyễn văn E",
      timePost: "06:00 Sáng, 01/09/2023",
      achievements: "Đạp xe 50km",
    },
    {
      title: "Bài viết 6",
      status: true,
      owner: "Nguyễn văn F",
      timePost: "06:00 Sáng, 01/09/2023",
      achievements: "Chạy bộ 15km",
    },
  ];
  return (
    <>
      <h4 className="h4 mb-6">
        Tổng số bài viết:{" "}
        <span className="mx-1 text-primary">{data.length}</span> bài viết
      </h4>
      <div className="row g-6 g-xl-9">
        <div className="col-md-6 col-xxl-6">
          <div className="card" style={{ border: "1px solid #F1F1F2" }}>
            <div className="card-body d-flex flex-center flex-column pt-12 p-9">
              <div className="symbol symbol-65px symbol-circle mb-5">
                <img
                  src={toAbsoluteUrl("/media/svg/files/blank-image.svg")}
                  alt="avatar"
                />
                <div className="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
              </div>
              <a
                href="##"
                className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
              >
                Tên bài viết
              </a>
              <div className="fw-semibold text-info mb-3">
                Đăng bài vào lúc: 6:00 sáng, ngày 01/09/2023
              </div>
              <div className="mb-6">
                Được đăng bởi <span className="text-primary">Admin</span>
              </div>
              <div className="d-flex flex-center flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">
                    Số lượt thích
                  </div>
                  <div className="fw-semibold text-gray-400">
                    <span className="text-primary">4</span> người thích
                  </div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">
                    Số lượt bình luận
                  </div>
                  <div className="fw-semibold text-gray-400">
                    <span className="text-primary">10</span> người bình luận
                  </div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">
                    Số lượt chia sẻ
                  </div>
                  <div className="fw-semibold text-gray-400">
                    <span className="text-primary">0</span> người chia sẻ
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-6">
          <div className="card">
            <div className="card-body d-flex flex-center flex-column pt-12 p-9">
              <div className="symbol symbol-65px symbol-circle mb-5">
                <span className="symbol-label fs-2x fw-semibold text-primary bg-light-primary">
                  S
                </span>
                <div className="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
              </div>
              <a
                href="##"
                className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
              >
                Sean Bean
              </a>
              <div className="fw-semibold text-gray-400 mb-6">
                Developer at Loop Inc
              </div>
              <div className="d-flex flex-center flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$14,560</div>
                  <div className="fw-semibold text-gray-400">Earnings</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">23</div>
                  <div className="fw-semibold text-gray-400">Tasks</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$236,400</div>
                  <div className="fw-semibold text-gray-400">Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-6">
          <div className="card">
            <div className="card-body d-flex flex-center flex-column pt-12 p-9">
              <div className="symbol symbol-65px symbol-circle mb-5">
                <img src="assets/media//avatars/300-1.jpg" alt="" />
              </div>

              <a
                href="##"
                className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
              >
                Alan Johnson
              </a>
              <div className="fw-semibold text-gray-400 mb-6">
                Web Designer at Nextop Ltd.
              </div>

              <div className="d-flex flex-center flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$14,560</div>
                  <div className="fw-semibold text-gray-400">Earnings</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">23</div>
                  <div className="fw-semibold text-gray-400">Tasks</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$236,400</div>
                  <div className="fw-semibold text-gray-400">Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-6">
          <div className="card">
            <div className="card-body d-flex flex-center flex-column pt-12 p-9">
              <div className="symbol symbol-65px symbol-circle mb-5">
                <img src="assets/media//avatars/300-14.jpg" alt="" />
              </div>
              <a
                href="##"
                className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
              >
                Robert Doe
              </a>
              <div className="fw-semibold text-gray-400 mb-6">
                Marketing Analytic at Avito Ltd.
              </div>
              <div className="d-flex flex-center flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$14,560</div>
                  <div className="fw-semibold text-gray-400">Earnings</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">23</div>
                  <div className="fw-semibold text-gray-400">Tasks</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$236,400</div>
                  <div className="fw-semibold text-gray-400">Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-6">
          <div className="card">
            <div className="card-body d-flex flex-center flex-column pt-12 p-9">
              <div className="symbol symbol-65px symbol-circle mb-5">
                <img src="assets/media//avatars/300-6.jpg" alt="" />
              </div>
              <a
                href="##"
                className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
              >
                Olivia Wild
              </a>
              <div className="fw-semibold text-gray-400 mb-6">
                Art Director at Seal Inc.
              </div>
              <div className="d-flex flex-center flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$14,560</div>
                  <div className="fw-semibold text-gray-400">Earnings</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">23</div>
                  <div className="fw-semibold text-gray-400">Tasks</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$236,400</div>
                  <div className="fw-semibold text-gray-400">Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-6">
          <div className="card">
            <div className="card-body d-flex flex-center flex-column pt-12 p-9">
              <div className="symbol symbol-65px symbol-circle mb-5">
                <span className="symbol-label fs-2x fw-semibold text-warning bg-light-warning">
                  A
                </span>
                <div className="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
              </div>
              <a
                href="##"
                className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
              >
                Adam Williams
              </a>
              <div className="fw-semibold text-gray-400 mb-6">
                System Arcitect at Wolto Co.
              </div>
              <div className="d-flex flex-center flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$14,560</div>
                  <div className="fw-semibold text-gray-400">Earnings</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">23</div>
                  <div className="fw-semibold text-gray-400">Tasks</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$236,400</div>
                  <div className="fw-semibold text-gray-400">Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-6">
          <div className="card">
            <div className="card-body d-flex flex-center flex-column pt-12 p-9">
              <div className="symbol symbol-65px symbol-circle mb-5">
                <span className="symbol-label fs-2x fw-semibold text-info bg-light-info">
                  P
                </span>
                <div className="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
              </div>
              <a
                href="##"
                className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
              >
                Peter Marcus
              </a>
              <div className="fw-semibold text-gray-400 mb-6">
                Art Director at Novica Co.
              </div>
              <div className="d-flex flex-center flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$14,560</div>
                  <div className="fw-semibold text-gray-400">Earnings</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">23</div>
                  <div className="fw-semibold text-gray-400">Tasks</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$236,400</div>
                  <div className="fw-semibold text-gray-400">Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-6">
          <div className="card">
            <div className="card-body d-flex flex-center flex-column pt-12 p-9">
              <div className="symbol symbol-65px symbol-circle mb-5">
                <span className="symbol-label fs-2x fw-semibold text-success bg-light-success">
                  N
                </span>
              </div>
              <a
                href="##"
                className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
              >
                Neil Owen
              </a>
              <div className="fw-semibold text-gray-400 mb-6">
                Accountant at Numbers Co.
              </div>
              <div className="d-flex flex-center flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$14,560</div>
                  <div className="fw-semibold text-gray-400">Earnings</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">23</div>
                  <div className="fw-semibold text-gray-400">Tasks</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$236,400</div>
                  <div className="fw-semibold text-gray-400">Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-6">
          <div className="card">
            <div className="card-body d-flex flex-center flex-column pt-12 p-9">
              <div className="symbol symbol-65px symbol-circle mb-5">
                <img src="assets/media//avatars/300-12.jpg" alt="" />
              </div>
              <a
                href="##"
                className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
              >
                Benjamin Jacob
              </a>
              <div className="fw-semibold text-gray-400 mb-6">
                Art Director at Novica Co.
              </div>
              <div className="d-flex flex-center flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$14,560</div>
                  <div className="fw-semibold text-gray-400">Earnings</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">23</div>
                  <div className="fw-semibold text-gray-400">Tasks</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                  <div className="fs-6 fw-bold text-gray-700">$236,400</div>
                  <div className="fw-semibold text-gray-400">Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feeds;
