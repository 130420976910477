export enum BannerTypeEnum {
  HOME_BIG = "home_big",
  HOME_SMALL = "home_small",
  HOME_RANK = "home_rank",
  SHOPPING = "shopping",
  SHOPPING_BIG = "shopping_big",
  SHOPPING_SMALL = "shopping_small",
  BLACK_FRIDAY = "black_friday",
  TOUR_SLIDER = "tour_slider",
  TOUR_EXP = "tour_experiential",
  GALLERY = "gallery",
  EVENT_CATEGORY_ITEM = "event_category_item",
}

export enum UserRoleEnum {
  CUSTOMER = "customer",
  MANAGER = "manager",
}

export enum OrderStatusEnum {
  CANCEL = "Đã hủy",
  PENDING = "Chờ xử lý",
  PAYMENT_IN_PROCESS = "Chờ thanh toán",
  PAYMENT_FINISH = "Hoàn tất thanh toán",
  SHIPING_IN_PROCESS = "Đang vận chuyển",
  SHIPPING_FINISH = "Đã giao",
  FINISH = "Đã xong",
  REFUND_REQUEST = "REFUND",
  REFUND_IN_PROCESS = "REFUND_IN_PROCESS",
  REFUND_FINISH = "REFUND_FINISH",
}

export enum ContactStatusEnum {
  PENDING = "PENDING",
  IN_PROCESS = "IN_PROCESS",
  DONE = "DONE",
  DECLINE = "DECLINE",
}

export enum BikeServiceEnum {
  PENDING = "Chờ xử lý",
  IN_PROCESS = "Đang xử lý",
  DONE = "Đã xong",
  DECLINE = "Từ chối",
}

export enum BikeSourceEnum {
  OTHER = "Bên ngoài",
  DECLINE = "Từ chối",
}

export enum PointStatusEnum {
  waiting = "waiting",
  reject = "reject",
  approval = "approval",
  used = "used",
}

export enum GiftTypeEnum {
  PRODUCT = "product",
  VOUCHER = "voucher",
  PRODUCT_VARIANT = "productVariant",
  CUSTOM = "custom",
}

export enum GiftKindEnum {
  USER = "user",
  GROUP = "group",
  ALL = "all",
}

export enum PlatformEnum {
  active = "active",
  xedap = "xedap",
  mobile = "mobile",
  all = "all",
}

export enum VoucherConditionEnum {
  custome = "Tùy chọn",
  // first_time = "Áp dụng cho lần đầu mua hàng",
  // next_time = "Áp dụng cho lần mua hàng tiếp theo",
}

export enum BikeRentalPriceEnum {
  hour = "Theo giờ",
  day = "Theo ngày",
  month = "Theo tháng",
  year = "Theo năm",
  "minute-fee" = "Phụ thu trễ giờ",
}

export enum BikeRentalStatusEnum {
  AVAILABLE = "available",
  RENTED = "rented",
  RETURN = "return",
  FIXED = "fixed",
}

export enum BikeRentalBookingStatusEnum {
  CANCEL = "CANCEL",
  BOOKING = "BOOKING",
  IN_PROCCESS = "IN_PROCCESS",
  FINISH = "FINISH",
}

export enum UserSocialTypeEnum {
  strava = "strava",
  facebook = "facebook",
  google = "google",
  apple = "apple",
}

export enum ConfigEnum {
  ORDER_POINT = "order-point",
}

export enum EmailTemplateEnum {
  VERIFY = "VERIFY",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  NOTIFICATION = "NOTIFICATION",
}

export enum TargetModelEnum {
  Newsfeed = "Newsfeed",
  Product = "Product",
  ProductVariant = "ProductVariant",
  Post = "Post",
  Event = "Event",
}

export enum PointTypeEnum {
  PAYMENT = "payment",
  GIFT = "gift",
  ACTIVITY = "activity",
}

export enum PointOwnerEnum {
  GROUP = "Group",
  USER = "User",
}

export enum PointSourceEnum {
  ORDER = "Order",
  GIFT = "Gift",
  ACTIVITY = "Activity",
  BOOKING = "Booking",
}
