import convertCurrency from "_metronic/helpers/convertData/convert-currency";
import moment from "moment";

const Item = ({
  data,
  serviceItem,
  vouchers,
}: {
  vouchers?: any[];
  data?: any;
  serviceItem?: any;
}) => {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          {/*begin::Thumbnail*/}
          <a href="test" className="symbol symbol-50px">
            <img
              src={
                data?.target?.image ||
                data?.target?.thumbnail ||
                data?.target?.productDetail?.thumbnail
              }
              alt=""
              className="symbol-label"
            />
          </a>
          {/*end::Thumbnail*/}
          {/*begin::Title*/}
          <div className="ms-5">
            <p className="fw-bold text-gray-600 text-hover-primary">
              {data?.target?.title ||
                data?.target?.name ||
                data?.target?.productDetail?.title}{" "}
              {serviceItem ? ` - ${serviceItem.value}` : ""}
            </p>

            {data?.target?.startedAt && (
              <div className="fs-7 text-muted">
                Bắt đầu vào:{" "}
                {moment(data?.target?.startedAt).format("DD/MM/YYYY")}
              </div>
            )}

            {data?.target?.attributeItems && (
              <>
                <div className="fs-7 text-muted">
                  Mẫu mã:{" "}
                  {data?.target?.attributeItems?.map(
                    (item: any, index: number) => (
                      <span key={index} style={{ fontWeight: "bold" }}>
                        {index % 2 === 1 && " - "} {item?.title}
                      </span>
                    )
                  )}
                </div>
                <div className="fs-7 text-muted">
                  Voucher sử dụng:{" "}
                  {vouchers?.map((item: any, index: number) => (
                    <span key={index} style={{ fontWeight: "bold" }}>
                      {index % 2 === 1 && " , "} {item?.code}
                    </span>
                  ))}
                </div>
              </>
            )}
          </div>
          {/*end::Title*/}
        </div>
      </td>
      <td className="text-end">{data?.target?.sku || "-"}</td>
      <td className="text-end">{data?.quantity}</td>
      <td className="text-end">
        {convertCurrency(
          serviceItem
            ? data.target?.services?.[0]?.options.find(
                (item: any) => item.name === serviceItem.value
              )?.initValue
            : data?.target?.sellPrice > 0
            ? data?.target?.sellPrice
            : data?.target?.price
        )}
      </td>
      <td className="text-end">
        {convertCurrency(
          data?.quantity *
            (data?.target?.sellPrice > 0
              ? data?.target?.sellPrice
              : data?.target?.price)
        )}
      </td>
    </tr>
  );
};

export default Item;
