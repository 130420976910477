import { useLayout } from "_metronic/layout/core";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { ReactSortable } from "react-sortablejs";

import { toAbsoluteUrl } from "../../../../_metronic/helpers/AssetHelpers";

/**
 * File Manager real time use case sample
 */
interface PropsType {
  autoUpload?: boolean;
  uploaded?: any;
  formik?: any;
  name?: string;
  height?: number;
  required?: boolean;
  className?: string;
  path?: string;
  heightPreview?: string;
  multiple?: boolean;
  values?: any;
  customUpdate?: boolean;
  target?: string;
}

const DEFAULT_IMAGE = toAbsoluteUrl("/media/svg/files/blank-image.svg");
const ImageUploadGlobal = (props: PropsType) => {
  const {
    multiple = false,
    name = "image",
    formik,
    className = "",
    heightPreview,
    height,
    path = "/",
    uploaded,
    values,
    customUpdate = false,
  } = props;

  const [preview, setPreview] = useState<string[]>([]);
  const { openFilemanager, selectedFiles, fileManagerConfig } = useLayout();
  const uuid = `FileManagerWidget${name}`;
  const activeId = useMemo(() => fileManagerConfig?.id, [fileManagerConfig]);
  const btnClick = (): void => {
    openFilemanager({
      path,
      isMultiple: multiple,
      selectedFiles: !multiple ? getSelectItemsFromPreview() : [],
      id: uuid,
    });
  };

  const previewList = useMemo(
    () => preview.map((x, i) => ({ id: i + 1, name: x })),
    [preview]
  );

  const getSelectItemsFromPreview = () => {
    return preview
      ?.map((x: string) => {
        const array = x?.split("/");
        return array?.[array?.length - 1];
      })
      .filter((x: string) => !!x);
  };

  useEffect(() => {
    if (customUpdate) {
      if (multiple) setPreview([...(values || [])]);
      else setPreview([values]);
    } else {
      if (multiple) setPreview([...(formik?.values[name] || [])]);
      else setPreview([formik?.values[name]]);
    }
  }, [values, formik?.values[name]]);

  const updateValue = (images: string[]) => {
    let data = [];
    if (multiple) {
      data = images.filter((x) => !!x && x !== "" && x !== DEFAULT_IMAGE);
    } else {
      data = images.filter((x) => !!x && x !== "" && x !== DEFAULT_IMAGE);
    }

    setPreview(data);
    if (!customUpdate) {
      formik?.setFieldValue(name, multiple ? data : data?.[0] || "");
    }

    uploaded && uploaded(multiple ? data : data?.[0] || "");
  };

  useEffect(() => {
    if (uuid === activeId) {
      updateValue([
        ...(multiple ? preview : []),
        ...selectedFiles.map((x) => x.url.replace(" ", "%20")),
      ]);
    }
  }, [selectedFiles]);

  const removeItem = (url: string) => {
    if (multiple) {
      const newFiles = preview.filter(
        (x: string) => x !== url && x !== DEFAULT_IMAGE
      );

      if (customUpdate) {
        uploaded && uploaded(newFiles);
      } else {
        formik?.setFieldValue(name, newFiles);
      }
      setPreview(newFiles);
    } else {
      const newFiles = "";

      if (customUpdate) {
        uploaded && uploaded(newFiles);
      } else {
        formik?.setFieldValue(name, newFiles);
      }
      setPreview(newFiles !== "" ? [newFiles] : []);
    }
  };

  const ImagePreview = (preview: string, index: number) => (
    <div
      className="dz-preview dz-image-preview d-inline-block position-relative mb-4 p-2"
      key={preview + index}
    >
      <div className={clsx(className || "", "image-input image-input-outlet")}>
        <div
          className={clsx("image-input-wrapper border rounded", heightPreview)}
          style={{
            backgroundImage: `url("${preview.replace(" ", "%20").toString()}")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            height: 120,
            width: 120,
          }}
        ></div>
        <button
          type="button"
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
          data-kt-image-input-action="remove"
          title="Remove image"
          onClick={() => removeItem(preview)}
        >
          <i className="ki-duotone ki-cross fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </button>
      </div>
    </div>
  );

  const singleImageSelector = () => {
    return (
      <>
        <div className="control-section">
          <div
            className={clsx(className || "", "image-input image-input-outlet")}
          >
            <div
              className={clsx(
                "image-input-wrapper w-auto border rounded",
                heightPreview
              )}
              style={{
                backgroundImage: `url("${(preview[0] || DEFAULT_IMAGE).replace(
                  " ",
                  "%20"
                )}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                height: height || 120 + "px",
              }}
            ></div>
            <ButtonComponent
              type="button"
              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
              onClick={btnClick.bind(this)}
              data-kt-image-input-action="change"
            >
              <i className="ki-duotone ki-pencil fs-7">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </ButtonComponent>
            {preview && preview.length > 0 && (
              <button
                type="button"
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="remove"
                title="Remove avatar"
                onClick={() => removeItem(preview?.[0])}
              >
                <i className="ki-duotone ki-cross fs-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  const sortGallery = (newlist: any[]) => {
    updateValue(newlist.map((x) => x.name));
  };

  const multipleImageSelector = () => {
    return (
      <>
        <div className="control-section">
          <div className="position-relative">
            <ReactSortable
              list={previewList}
              setList={(newState) => sortGallery(newState)}
            >
              {previewList.map((img: any) => ImagePreview(img.name, img.id))}
            </ReactSortable>
          </div>
          <div className="dropzone dz-clickable">
            <ButtonComponent
              type="button"
              className="w-100 bg-transparent border-0 dz-message needsclick align-items-center"
              onClick={btnClick.bind(this)}
            >
              <i className="ki-duotone ki-file-up text-primary fs-3x">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <div className="ms-4">
                <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                  Click để chọn hình ảnh ({preview.length})
                </h3>
              </div>
            </ButtonComponent>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {multiple ? multipleImageSelector() : singleImageSelector()}
      {name && formik?.errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{formik?.errors[name]}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageUploadGlobal;
