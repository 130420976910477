import { KTCard, KTCardBody } from "_metronic/helpers";
import convertCurrency from "_metronic/helpers/convertData/convert-currency";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import { OrderStatusEnum } from "constants/enum";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getDetailByAction } from "../core/_requests";

export const Orders = () => {
  const { id } = useParams<string>();
  const [orders, setOrders] = useState<any>();

  useEffect(() => {
    getDetailByAction("orders", id)
      .then((x) => setOrders(x.data))
      .catch((x) => console.log(x));
  }, [id]);

  return (
    <KTCard>
      <div className="card-header">
        <div className="card-title">
          <h4 className="h4">
            Tổng đơn hàng đã đặt:{" "}
            <span className="mx-1 text-primary">{orders?.length}</span> đơn hàng
          </h4>
        </div>
      </div>
      <KTCardBody>
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
              <tr>
                <th className="min-w-200px">Mã đơn hàng</th>
                <th className="min-w-100px">Trạng thái</th>
                <th className="min-w-100px">Tổng cộng</th>
                <th className="min-w-100px">Ngày đặt</th>
                <th className="min-w-100px">Ngày cập nhật</th>
              </tr>
            </thead>
            <tbody className="fw-6 fw-semibold text-gray-600">
              {orders?.map((item: any, index: number) => (
                <tr className="row-member" key={index}>
                  <td>
                    <p className="fw-bolder">{item?.code}</p>
                  </td>
                  <td>
                    <p className="fw-bolder">
                      {
                        OrderStatusEnum[
                          item?.status as keyof typeof OrderStatusEnum
                        ]
                      }
                    </p>
                  </td>
                  <td>
                    <p className="fw-bolder">
                      {convertCurrency(item?.totalPrice)}
                    </p>
                  </td>
                  <td>
                    <p className="fw-bolder">
                      {item?.createdAt ? convertDate(item?.createdAt) : "-"}
                    </p>
                  </td>
                  <td>
                    <p className="fw-bolder">
                      {item?.updatedAt ? convertDate(item?.updatedAt) : "-"}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default Orders;
