import Button from "app/components/Button";
import { Switcher } from "app/components/Form";
import Label from "app/components/Form/Label";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getList as getProductCategory } from "../../../product-category/core/_requests";
import { ItemModel as ProductCategoryInterface } from "../../../type/core/_models";
import { formEditType } from "../../core/_models";
import CategoryCheckbox from "../CategoryCheckbox";

const SideBar = () => {
  const { id } = useParams();

  const formik = useFormikContext<formEditType>();

  const [productCategory, setProductCategory] = useState<
    ProductCategoryInterface[]
  >([]);

  useEffect(() => {
    getProductCategory({
      limit: 1000,
      page: 1,
      hasChildren: true,
      isActive: true,
      platform: process.env.REACT_APP_PLATFORM,
    })
      .then((x?: any) => setProductCategory(x.data || []))
      .catch((x?: any) => console.log(x));
  }, []);

  return (
    <div className="flex-lg-auto card min-w-300px">
      <div className="card-body p-10">
        <ImageUploadGlobal
          name="thumbnail"
          className="mx-auto w-100 border mb-10"
          path="/products"
          formik={formik}
        />
        <div className="fv-row mb-7">
          <Switcher
            label="Trạng thái"
            name="isActive"
            direction="horizontal"
            value={formik.values.isActive}
            onChange={formik.handleChange}
          />
        </div>

        <div className="fv-row mb-7">
          <Switcher
            label="Làm quà tặng"
            name="isGift"
            direction="horizontal"
            value={formik.values.isGift}
            onChange={formik.handleChange}
          />
        </div>

        <div className="fv-row mb-7">
          <Switcher
            label="Sản phẩm cho thuê"
            name="isRental"
            direction="horizontal"
            value={!!formik.values.isRental}
            onChange={formik.handleChange}
          />
        </div>

        <div className="fv-row mb-7">
          <Switcher
            label="Không có biến thể giá"
            name="isPrimary"
            direction="horizontal"
            value={formik.values.isPrimary}
            onChange={formik.handleChange}
          />
        </div>
        <div className="fv-row mb-7">
          <Switcher
            label="Đề xuất"
            name="isFeature"
            direction="horizontal"
            value={formik.values.isFeature}
            onChange={formik.handleChange}
          />
        </div>

        <Label className="mb-5">Danh mục sản phẩm</Label>

        <CategoryCheckbox productCategory={productCategory} formik={formik} />

        <div className="text-end mt-12">
          <Button
            type="button"
            disabled={formik?.isSubmitting}
            onClick={formik.handleSubmit}
            className="btn btn-primary"
          >
            {id ? "Cập nhật" : "Tiếp theo"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
