import { PageLink, PageTitle } from "_metronic/layout/core";
import Report from "app/pages/content-management/report/components";
import { Outlet, Route, Routes } from "react-router-dom";

import { ListWrapper as ListWrapperComment } from "../../../modules/comment/components/List";
import { getList } from "../comment/core/_requests";
import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Tours",
    path: "/tour",
  },

  {
    isSeparator: true,
  },
];

const TourPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Danh sách tour</PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="create"
          element={
            <>
              <EditPage breadcrumbs={breadbrumbs} />
            </>
          }
        />

        <Route
          path=":id/edit"
          element={
            <>
              <EditPage breadcrumbs={breadbrumbs} />
            </>
          }
        />

        <Route
          path=":id/child"
          element={
            <>
              <ListWrapper />
            </>
          }
        />

        <Route
          path=":id/create-child"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Thêm mới tour</PageTitle>
              <EditPage />
            </>
          }
        />
        <Route
          path=":id/child/:child/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Chỉnh sửa tour</PageTitle>
              <EditPage />
            </>
          }
        />

        <Route
          path=":id/report"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Thông tin chi tiết
              </PageTitle>
              <Report />
            </>
          }
        />
      </Route>

      <Route element={<Outlet />}>
        <Route
          path="comment"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách đánh giá tour
              </PageTitle>
              <ListWrapperComment getList={getList} />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default TourPage;
