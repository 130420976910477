/* eslint-disable @typescript-eslint/no-empty-function */
import { QUERIES } from "_metronic/helpers";
import {
  ListViewProvider,
  useListView,
} from "_metronic/helpers/provider/ListViewProvider";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "_metronic/helpers/provider/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "_metronic/helpers/provider/QueryResponseProvider";
import { alertSuccess } from "_metronic/helpers/toastify";
import ListManagement from "app/components/Page";
import { useState } from "react";

import { COLUMNS } from "../core/_columns";
import {
  bulkDelete,
  bulkSync,
  deleteItem,
  getList,
  syncData,
} from "../core/_requests";

const List = ({ noPaginate }: { noPaginate?: boolean }) => {
  const { setItemIdForUpdate } = useListView();
  const [isLoading, setLoading] = useState<boolean>(false);

  return (
    <>
      {!noPaginate && (
        <div className="d-flex flex-end mb-2">
          <button
            className="btn btn-info"
            onClick={() => {
              setLoading(true);
              syncData().then(() => {
                alertSuccess("Yêu cầu thành công, vui lòng chờ trong giây lát");
                setTimeout(() => {
                  setLoading(false);
                }, 5000);
              });
            }}
            disabled={isLoading}
          >
            {isLoading ? "Đang tải..." : "Sync All"}
          </button>
        </div>
      )}
      <ListManagement
        isCreate={false}
        columns={COLUMNS}
        actionAddNew={() => {
          setItemIdForUpdate(null);
        }}
        useQueryResponseLoading={useQueryResponseLoading}
        queryString={[QUERIES.CATEGORY_LIST]}
        useQueryResponsePagination={
          noPaginate ? undefined : useQueryResponsePagination
        }
        useQueryResponseData={useQueryResponseData}
        useListView={useListView}
        useQueryRequest={useQueryRequest}
        useQueryResponse={useQueryResponse}
        bulkDelete={bulkDelete}
        bulkSync={bulkSync}
        deleteItem={deleteItem}
      />
    </>
  );
};

const GroupListWrapper = ({
  noPaginate,
  customGetList,
}: {
  noPaginate?: boolean;
  customGetList?: any;
}) => (
  <QueryRequestProvider>
    <QueryResponseProvider
      queryString={QUERIES.GROUP_LIST}
      getList={customGetList ? customGetList : getList}
    >
      <ListViewProvider>
        <List noPaginate={noPaginate} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { GroupListWrapper };
