import convertCurrency from "_metronic/helpers/convertData/convert-currency";
import Button from "app/components/Button";
import { Switcher } from "app/components/Form";
import ModalCustom from "app/components/ModalCustom";
import moment from "moment";
import { useEffect } from "react";

const ConfirmModal = ({
  formik,
  setIsOpen,
}: {
  formik?: any;
  setIsOpen: any;
}) => {
  const now = moment();
  const startTime = formik?.values?.info?.startTime;
  const endTime = moment(startTime).add(formik?.values?.info?.hoursUse, "h");
  const expiredHours = now.diff(endTime, "m");

  useEffect(() => {
    const feePrice =
      formik.values?.target.prices.find((x: any) => x.type === "minute-fee")
        ?.price || 0;

    formik.setFieldValue("extraFee", feePrice * expiredHours);

    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <ModalCustom
        labelHeader="Xác nhận"
        actionHeader={setIsOpen}
        actionCancel={() => setIsOpen(false)}
        formik={formik}
      >
        <div className="fv-row mb-7">
          <Switcher
            label="Cần sửa xe"
            name="needFixed"
            direction="horizontal"
            onChange={(e) => {
              formik.setFieldValue("needFixed", !formik.values.needFixed);
            }}
            value={formik.values.needFixed}
          />
        </div>

        <div className="text-center pt-15">
          {expiredHours > 0 ? (
            <>
              <Button
                type="submit"
                className="btn btn-primary mx-6"
                onClick={() => {
                  formik.setFieldValue("isFee", false);
                  formik.handleSubmit();
                }}
                disabled={formik?.isSubmitting}
              >
                Không tính phụ thu trễ
              </Button>

              <Button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  formik.setFieldValue("isFee", true);
                  formik.handleSubmit();
                }}
                disabled={formik?.isSubmitting}
              >
                Tính phụ thu trễ ({convertCurrency(formik.values.extraFee)})
              </Button>
            </>
          ) : (
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                formik.setFieldValue("isFee", false);
                formik.handleSubmit();
              }}
              disabled={formik?.isSubmitting}
            >
              Xác nhận
            </Button>
          )}
        </div>
      </ModalCustom>
    </>
  );
};

export default ConfirmModal;
