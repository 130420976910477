import { ID } from "_metronic/helpers";
import { AxiosResponse } from "axios";
import api, { getData, postData } from "services/api";

import { ItemModel } from "./_models";

const URL = `event`;

const getItem = async (id: ID, params?: any): Promise<ItemModel> =>
  getData(URL + "/" + id + "/members", params);

const getDetailEvent = async (id: ID): Promise<ItemModel> =>
  getData(URL + "/" + id);

const syncMemberEvent = async (id: ID, formData: any) =>
  postData(URL + "/" + id + "/members/sync", formData);

const exportCSV = async (id: ID, params?: any): Promise<AxiosResponse<Blob>> =>
  api({ isToken: true }).get(URL + "/" + id + "/members/export", {
    params,
    responseType: "arraybuffer",
  });

export { exportCSV, getDetailEvent, getItem, syncMemberEvent };
