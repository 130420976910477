import { ID, Response } from "_metronic/helpers";
import { UserSocialTypeEnum } from "constants/enum";

export type ItemModel = {
  id?: ID;
  appId?: string;
  appSerect: string;
  platform: UserSocialTypeEnum;
  numUsed?: number;
  isActive?: boolean;
  updatedAt?: string;
  createdAt?: string;
};

export const initialData: ItemModel = {
  id: null,
  appId: "",
  appSerect: "",
  platform: UserSocialTypeEnum.strava,
  isActive: true,
};

export type QueryResponse = Response<Array<ItemModel>>;
