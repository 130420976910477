import { ID } from "_metronic/helpers";
import { deleteDataItem, getData, postData, updateData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `product-tag`;

const getList = (query: any): Promise<QueryResponse> =>
  getData(URL, { ...query, platform: process.env.REACT_APP_PLATFORM });

const createItem = (query: ItemModel): Promise<ItemModel> =>
  postData(URL, query);

const editItem = (query: ItemModel, id: ID): Promise<QueryResponse> =>
  updateData(URL, query, id);

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

export { bulkDelete, createItem, deleteItem, editItem, getList };
