import { ID } from "_metronic/helpers";
import api, { deleteDataItem, getData, updateData } from "services/api";

import { QueryResponse } from "./_models";

const URL = `group`;

const getList = (query: any): Promise<QueryResponse> => getData(URL, query);

const updateItem = (query: any, id: ID): Promise<QueryResponse> =>
  updateData(URL, query, id);

const syncData = async (query?: any): Promise<any> =>
  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/group-ranking`,
    query
  );

const getItem = async (id: string): Promise<QueryResponse> =>
  getData(URL + "/" + id);

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

const bulkSync = async (ids: Array<ID>): Promise<void> => {
  await syncData({ group: ids });
};

export {
  bulkDelete,
  bulkSync,
  deleteItem,
  getItem,
  getList,
  syncData,
  updateItem,
};
