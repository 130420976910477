import { ID, Response } from "_metronic/helpers";
export type ItemModel = {
  id?: ID;
  platform: any[];
  name: string;
  isActive: boolean;
  createdBy?: any;
  createdAt?: any;
  updatedAt?: any;
  updatedBy?: any;
};

export const initialData = {
  name: "",
  isActive: true,
};

export type QueryResponse = Response<Array<ItemModel>>;
