import { FC } from "react";

type Props = {
  value?: number;
  className?: string;
};

const CurrencyCell: FC<Props> = ({ value, className }) => {
  return (
    <span className={className}>
      {value?.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND",
      })}
    </span>
  );
};

export { CurrencyCell };
