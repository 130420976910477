/* eslint-disable @typescript-eslint/no-empty-function */
import { QUERIES } from "_metronic/helpers";
import {
  ListViewProvider,
  useListView,
} from "_metronic/helpers/provider/ListViewProvider";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "_metronic/helpers/provider/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "_metronic/helpers/provider/QueryResponseProvider";
import { alertSuccess } from "_metronic/helpers/toastify";
import ListManagement from "app/components/Page";
import { useState } from "react";

import { COLUMNS } from "../core/_columns";
import { bulkSync, getList, syncAllData, syncData } from "../core/_requests";

const List = () => {
  const [isLoadingStrava, setLoadingStrava] = useState(false);
  const [isLoadingSyncAll, setLoadingSyncAll] = useState(false);

  return (
    <>
      <div className="d-flex flex-end mb-2 gap-3 mb-2">
        <button
          className="btn btn-sm btn-info"
          onClick={() => {
            setLoadingStrava(true);
            syncData().then(() => {
              alertSuccess("Yêu cầu thành công, vui lòng chờ trong giây lát");
              setTimeout(() => {
                setLoadingStrava(false);
              }, 5000);
            });
          }}
          disabled={isLoadingStrava}
        >
          {isLoadingStrava ? "Đang tải..." : "Lấy dữ liệu hoạt động"}
        </button>

        <button
          className="btn btn-sm btn-info"
          onClick={() => {
            setLoadingSyncAll(true);
            syncAllData().then(() => {
              alertSuccess("Yêu cầu thành công, vui lòng chờ trong giây lát");
              setTimeout(() => {
                setLoadingSyncAll(false);
              }, 5000);
            });
          }}
          disabled={isLoadingSyncAll}
        >
          {isLoadingSyncAll ? "Đang tải..." : "Xử lý dữ liệu"}
        </button>
      </div>
      <ListManagement
        columns={COLUMNS}
        actionAddNew={() => {}}
        actionClickItem={() => {}}
        useQueryResponseLoading={useQueryResponseLoading}
        queryString={[QUERIES.USER_STRAVA_LIST]}
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryResponseData={useQueryResponseData}
        useListView={useListView}
        useQueryRequest={useQueryRequest}
        useQueryResponse={useQueryResponse}
        bulkDelete={() => {}}
        bulkSync={bulkSync}
        deleteItem={() => {}}
        isCreate={false}
      />
    </>
  );
};

const ListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider
      queryString={QUERIES.USER_STRAVA_LIST}
      getList={getList}
    >
      <ListViewProvider>
        <List />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { ListWrapper };
