import { ID, Response } from "_metronic/helpers";

import { GroupList } from "../../group/core/_models";
export type ItemModel = {
  id?: ID;
  fullname?: string;
  email?: string;
  isActive?: boolean;
  role?: string;
  phone?: string;
  password?: string;
  createdAt?: string;
  socials?: Socials[];
  updatedAt?: string;
  isVerifiedEmail: boolean;
  isVerifiedPhone: boolean;
  avatar?: string;
};

export type Socials = {
  social_username: string;
  social_platform: string;
};

export const initialData = {
  avatar: "",
  id: "",
  fullname: "",
  email: "",
  isActive: true,
  role: "",
  phone: "",
  password: "",
  isVerifiedEmail: false,
  isVerifiedPhone: false,
};

export interface ActivitiesModel {
  activityId: string;
  owner: {
    fullname: string;
    avatar: string;
    numFriends: number;
    friends: any[];
    id: string;
  };
  platform: string;
  createdAt: Date;
  data: Data;
  distance: number;
  elapsedTime: number;
  isActive: boolean;
  isFeatured: boolean;
  movingTime: number;
  startDate: Date;
  updatedAt: Date;
  visible: boolean;
  group: GroupList;
  event: Event;
}

export interface Data {
  resource_state: number;
  athlete: {
    id: number;
    resource_state: number;
  };
  name: string;
  distance: number;
  moving_time: number;
  elapsed_time: number;
  total_elevation_gain: number;
  type: string;
  sport_type: string;
  workout_type: number;
  id: number;
  start_date: Date;
  start_date_local: Date;
  timezone: string;
  utc_offset: number;
  location_city: null;
  location_state: null;
  location_country: string;
  achievement_count: number;
  kudos_count: number;
  comment_count: number;
  athlete_count: number;
  photo_count: number;
  map: {
    id: string;
    summary_polyline: string;
    resource_state: number;
  };
  trainer: boolean;
  commute: boolean;
  manual: boolean;
  private: boolean;
  visibility: string;
  flagged: boolean;
  gear_id: null;
  start_latlng: any[];
  end_latlng: any[];
  average_speed: number;
  max_speed: number;
  has_heartrate: boolean;
  heartrate_opt_out: boolean;
  display_hide_heartrate_option: boolean;
  upload_id: null;
  external_id: null;
  from_accepted_tag: boolean;
  pr_count: number;
  total_photo_count: number;
  has_kudoed: boolean;
}

export type QueryResponse = Response<Array<ItemModel>>;
