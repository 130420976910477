import { KTCard } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { FormField } from "app/components/Form/Field";
import React, { useEffect, useState } from "react";

import { getAdminList } from "../core/_requests";

const AdvanceSetting = ({ formik }: { formik?: any }) => {
  const [adminList, setAdminList] = useState();

  const BASIC_FIELDS = [
    {
      label: "Quản lý",
      name: "managerId",
      required: false,
      type: "dropdown",
      options: adminList ? mapValue(adminList) : [],
      onSearch: (search: string) =>
        getAdminList({ search }).then((res?: any) => setAdminList(res?.data)),
    },

    {
      label: "Giờ mở cửa",
      name: "open",
      required: false,
      type: "text",
    },

    {
      label: "Giờ đóng cửa",
      name: "close",
      required: false,
      type: "text",
    },

    {
      label: "Ngày tối đa cho phép đặt xe",
      name: "maxDaysBooking",
      required: false,
      type: "number",
    },

    {
      label: "Ngày nghỉ",
      name: "daysOff",
      required: false,
      type: "multiple-date",
    },
  ];

  useEffect(() => {
    getAdminList({ limit: 50, page: 1 }).then((res?: any) => {
      setAdminList(res?.data);
    });
  }, []);

  return (
    <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
      <KTCard className="p-10 mb-7">
        {BASIC_FIELDS.map((field: any, index: number) => {
          const fieldData = { ...field, formik };
          return (
            <React.Fragment key={index}>
              {!field?.hidden && (
                <div key={fieldData.name} className="fv-row mb-7">
                  {FormField(fieldData)}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </KTCard>
    </div>
  );
};

export default AdvanceSetting;
