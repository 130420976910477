import { ID, Response } from "_metronic/helpers";

export type ItemModel = {
  id?: ID;
  image: string;
  title: string;
  alias: string;
  description: string;
  excerpt: string;
  platform?: any;
  isActive?: boolean;
  seoTitle: string;
  seoDescription: string;
  seoKeyword: string;
  seoImage: string;
  parent?: string;
  createdBy?: any;
  createdAt?: any;
  updatedAt?: any;
  updatedBy?: any;
};

export const initialData: ItemModel = {
  id: null,
  image: "",
  title: "",
  alias: "",
  description: "",
  // platform: Object.keys(PlatformEnum)?.map((item) => ({
  //   label: PlatformEnum[item as keyof typeof PlatformEnum],
  //   value: item,
  // })),
  excerpt: "",
  seoTitle: "",
  seoDescription: "",
  seoKeyword: "",
  seoImage: "",
  isActive: true,
};

export type QueryResponse = Response<Array<ItemModel>>;
