import moment from "moment";

export const convertDate = (
  date: any,
  type?: any
  // format?: "MM/DD/YYYY"
): string => {
  if (date == null) date = new Date();

  const newDate = moment(date);

  return newDate.format("MM/DD/YYYY hh:mm:ss");
};
