import { FC } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../../../app/pages/account-management/auth";
import { toAbsoluteUrl } from "../../../helpers";

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl(
                currentUser?.avatar || "/media/avatars/300-1.jpg"
              )}
            />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {currentUser?.fullname}
            </div>
            <span className="fw-bold text-muted text-hover-primary fs-7">
              {currentUser?.email}
            </span>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to={"/profile"} className="menu-link px-5">
          Chỉnh sửa thông tin
        </Link>
      </div>

      <div className="menu-item px-5">
        <Link to={"/profile/change-password"} className="menu-link px-5">
          Thay đổi mật khẩu
        </Link>
      </div>

      <div className="menu-item px-5">
        <a href="/admin" onClick={logout} className="menu-link px-5">
          Đăng xuất
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
