import { convertFileSize } from "_metronic/helpers/convertData/convert-file-size";
import { swallError } from "_metronic/helpers/swal";
import { AUTH_LOCAL_STORAGE_KEY } from "app/modules/auth";
import axios from "axios";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { errorHandle } from "services/error";

type Props = {
  name?: string;
  label?: string;
  setIsUpload?: any;
  uploaded?: any;
  src?: string;
  path?: string;
  upload?: boolean;
  url?: string;
  value?: string;
  autoUpload?: boolean;
  description?: string;
  requireType?: string[];
};

const BASE_URL_UPLOAD: any = process.env.REACT_APP_API_URL;

const submitUpload = async (URL: string, formData: FormData) => {
  const getAuthLocal: Storage = JSON.parse(
    localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || "{}"
  );

  const token: string = getAuthLocal.access_token || null;

  const instance = axios.create({
    baseURL: BASE_URL_UPLOAD + URL,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      errorHandle(error.response);
      throw error;
    }
  );

  return await instance.post("", formData);
};

const FileImport: FC<Props> = ({
  url,
  requireType,
  label,
  setIsUpload,
  uploaded,
  src,
  path,
  upload,
  value,
  description,
  name,
}) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleUpload = async () => {
    if (!selectedFile) {
      setIsUpload(false);
      uploaded(src);
      return;
    }

    if (requireType) {
      const fileName = selectedFile.name.split(".").pop().toLowerCase();
      if (!requireType?.includes(fileName)) {
        swallError(
          "Không đúng định dạng yêu cầu",
          "Vui lòng chọn đúng định dạng file"
        );

        return;
      }
    }

    const formData: any = new FormData();
    formData.append("file", selectedFile);
    if (path) formData.append("path", path);

    try {
      const response = await submitUpload(url as any, formData);
      await uploaded(response);
      setSelectedFile(undefined);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsUpload && setIsUpload(false);
    }
  };

  const onSelectedFile = (e: any) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (upload) {
      handleUpload();
    }
  }, [upload]);

  return (
    <div className="form-group">
      {/*begin::Dropzone*/}
      <label className={clsx("mb-2", "fw-bold fs-6")}>{label}</label>
      {value && (
        <a
          href={value}
          target="_blank"
          className="fs-6 fw-bold"
          style={{ marginLeft: 10, textDecoration: "underline" }}
          rel="noreferrer"
        >
          Xem file tại đây
        </a>
      )}
      <div
        className="dropzone dropzone-queue mb-2"
        id="kt_modal_upload_dropzone"
      >
        {/*begin::Controls*/}
        <div className="dropzone-panel mb-4">
          <label
            htmlFor={name}
            className="dropzone-select btn btn-sm btn-primary me-2 dz-clickable"
          >
            Attach files
          </label>
          <input
            name={name}
            onChange={onSelectedFile}
            id={name}
            style={{
              display: "none",
            }}
            type="file"
          />
        </div>
        {/*end::Controls*/}
        {/*begin::Items*/}
        <div className="dropzone-items wm-200px"></div>
        {/*end::Items*/}
        <div className="dz-default dz-message">
          <button className="dz-button" type="button">
            Drop files here to upload
          </button>
        </div>
      </div>
      {/*end::Dropzone*/}
      {selectedFile && (
        <h3 className="form-text fs-6 text-muted">
          <span>{selectedFile?.name}</span>
          {"  "}
          <span>{convertFileSize(selectedFile?.size)}</span>
        </h3>
      )}
      {/*begin::Hint*/}
      <span className="form-text fs-6 text-muted">{description}</span>
      {/*end::Hint*/}
    </div>
  );
};

export default FileImport;
