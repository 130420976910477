import { ID } from "_metronic/helpers";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, editSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { Input } from "app/components/Form";
import ModalCustom from "app/components/ModalCustom";
import { useFormik } from "formik";
import { FC, SetStateAction, useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem } from "../core/_requests";

type Props = {
  data: SetStateAction<ID>;
};

export const validateSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng điền đủ thông tin"),
});

const ItemModal: FC<Props> = () => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const { refetch, isLoading } = useQueryResponse();
  const data: any = itemIdForUpdate;

  const [item] = useState<ItemModel>({
    ...initialData,
    ...data,
  });

  const formik = useFormik({
    initialValues: item,
    validationSchema: validateSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (!values.id) {
          await createItem({ ...values, isActive: true });
          createSuccess();
        } else {
          await editItem({ ...values, isActive: true }, values.id);
          editSuccess();
        }
        refetch();
        setItemIdForUpdate(undefined);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <ModalCustom
        labelHeader={`${!itemIdForUpdate ? "Tạo mới" : "Chỉnh sửa"}`}
        actionHeader={setItemIdForUpdate}
        actionCancel={() => setItemIdForUpdate(undefined)}
        formik={formik}
        labelCancel="Đóng"
        labelSubmit="Xác nhận"
      >
        <div className="fv-row mb-7">
          <Input
            formik={formik}
            required
            label="Tên"
            placeholder="Tên"
            name="name"
            disabled={formik.isSubmitting || isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.name}
          />
        </div>
        <div className="text-center pt-3">
          <Button
            type="button"
            className="btn btn-light me-3"
            onClick={() => setItemIdForUpdate(undefined)}
          >
            Đóng
          </Button>

          <Button
            type="submit"
            className="btn btn-primary"
            disabled={
              isLoading ||
              formik?.isSubmitting ||
              !formik?.isValid ||
              !formik?.touched
            }
          >
            Xác nhận
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export default ItemModal;
