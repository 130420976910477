import { KTCard } from "_metronic/helpers";
import { updateSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { Input, Switcher } from "app/components/Form";
import { ConfigEnum } from "constants/enum";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

import {
  FormDataConfigSettingPoint,
  initialFormDataConfigSettingPoint,
} from "../core/_models";
import {
  configDisablePoint,
  configOptionsPoint,
  getItem,
} from "../core/_requests";

export const GeneralSetting = () => {
  const [data, setData] = useState<FormDataConfigSettingPoint>(
    initialFormDataConfigSettingPoint
  );

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    const response = await getItem("order-point");

    setData({
      ...response.configOption,
      isDisabled: response.isDisabled,
    });
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      const formConfigOptionsPoint = {
        label: values.label,
        value: values.value,
      };

      try {
        Promise.all([
          await configDisablePoint(ConfigEnum.ORDER_POINT, {
            value: values.isDisabled,
          }),
          await configOptionsPoint(
            ConfigEnum.ORDER_POINT,
            formConfigOptionsPoint
          ),
        ]);

        const response = await getItem("order-point");

        setData({ ...response.configOption, isDisabled: response.isDisabled });
        updateSuccess(null);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form className="mt-7" onSubmit={formik.handleSubmit} noValidate>
      <KTCard>
        <div className="card-body">
          <h2 className="mb-7">Thông tin chung</h2>

          <div className="fv-row mb-7">
            <Switcher
              label="Tắt nhận điểm"
              name="isDisabled"
              direction="horizontal"
              onChange={formik.handleChange}
              value={formik.values.isDisabled}
            />
          </div>

          <div className="row">
            <div className="fv-row mb-7">
              <Input
                formik={formik}
                label="Label"
                placeholder="1 điểm = 10000 đồng"
                name="label"
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={formik.values.label}
              />
            </div>
            <div className="fv-row mb-7">
              <Input
                formik={formik}
                label="Giá trị (Quy ước theo 1 điểm)"
                placeholder="10000 đồng"
                name="value"
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={formik.values.value}
              />
            </div>
          </div>
          <div className="text-end mt-12 mb-12">
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={
                formik?.isSubmitting || !formik?.isValid || !formik?.touched
              }
            >
              Xác nhận
            </Button>
          </div>
        </div>
      </KTCard>
    </form>
  );
};
