import { KTCard, KTCardBody } from "_metronic/helpers";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getDetailByAction } from "../core/_requests";

export const Feeds = () => {
  const { id } = useParams<string>();
  const [feeds, setFeeds] = useState<any>();

  useEffect(() => {
    getDetailByAction("newsfeed", id)
      .then((x) => setFeeds(x.data))
      .catch((x) => console.log(x));
  }, [id]);

  return (
    <KTCard className="bg-light">
      <div className="card-header">
        <div className="card-title">
          <h4 className="h4">
            Tổng số bài viết:{" "}
            <span className="mx-1 text-primary">{feeds?.length}</span> bài viết
          </h4>
        </div>
      </div>
      <KTCardBody>
        <div id="kt_social_feeds_posts">
          {feeds?.map((item: any, index: number) => (
            <div className="card card-flush mb-10" key={index}>
              <div className="card-header pt-9">
                {/*begin::Author*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-50px me-5">
                    <img src={item.owner.avatar} className="" alt="" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Info*/}
                  <div className="flex-grow-1">
                    {/*begin::Name*/}
                    <a
                      href="#sd"
                      className="text-gray-800 text-hover-primary fs-4 fw-bold"
                    >
                      {item.owner.fullname}
                    </a>
                    {/*end::Name*/}
                    {/*begin::Date*/}
                    <span className="text-gray-500 fw-semibold d-block">
                      {convertDate(item.createdAt)}
                    </span>
                    {/*end::Date*/}
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::Author*/}
                {/*begin::Card toolbar*/}
                <div className="card-toolbar">
                  {/*begin::Menu wrapper*/}
                  <div className="m-0">
                    <div className="btn btn-danger">Block</div>
                  </div>
                  {/*end::Menu wrapper*/}
                </div>
                {/*end::Card toolbar*/}
              </div>

              <div className="card-body">
                <div className="fs-6 fw-normal text-gray-700 mb-5">
                  {item?.content}
                </div>
                {item?.gallery?.length > 0 && (
                  <div className="row g-7 h-250px h-md-375px">
                    {item?.gallery?.map((pic: any, index: number) => (
                      <div className="col-6" key={index}>
                        <a
                          className="d-block card-rounded overlay h-100"
                          data-fslightbox="lightbox-projects"
                          href={pic}
                        >
                          <div
                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-100"
                            style={{
                              backgroundImage: `url(${pic})`,
                            }}
                          ></div>
                          <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i className="ki-duotone ki-eye fs-3x text-white">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>{" "}
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/*begin::Card footer*/}
              <div className="card-footer pt-0">
                {/*begin::Info*/}
                <div className="mb-6">
                  {/*begin::Separator*/}
                  <div className="separator separator-solid" />
                  {/*end::Separator*/}
                  {/*begin::Nav*/}
                  <ul className="nav py-3">
                    <li className="nav-item">
                      <a
                        href="#sd"
                        className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold px-4 me-1"
                      >
                        <i className="ki-duotone ki-heart fs-2 me-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        {item.totalLikes} Likes
                      </a>
                    </li>

                    {/*begin::Item*/}
                    <li className="nav-item">
                      <a
                        className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold px-4 me-1"
                        data-bs-toggle="collapse"
                        href="#kt_social_feeds_comments_1"
                      >
                        <i className="ki-duotone ki-message-text-2 fs-2 me-1">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </i>
                        {item.totalComments} Comments
                      </a>
                    </li>
                    {/*end::Item*/}

                    {/*begin::Item*/}
                    <li className="nav-item">
                      <a
                        href="#sd"
                        className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold px-4"
                      >
                        <i className="ki-duotone ki-bookmark fs-2 me-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        {item.totalShares} Shares
                      </a>
                    </li>
                    {/*end::Item*/}
                  </ul>
                  {/*end::Nav*/}
                  {/*begin::Separator*/}
                  <div className="separator separator-solid mb-1" />
                  {/*end::Separator*/}
                  {/*begin::Comments*/}

                  {/*end::Collapse*/}
                </div>
                {/*end::Info*/}
              </div>
              {/*end::Card footer*/}
            </div>
          ))}
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default Feeds;
