import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const swallError = (title?: string, description?: string) => {
  MySwal.fire({
    title,
    html: description,
    showConfirmButton: false,
    icon: "error",
  });
};
