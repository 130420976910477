import { TEXT_REQUIRED } from "constants/text-error";
import * as Yup from "yup";

export const SEO_VALIDATE_RULE = {
  seoTitle: Yup.string().required(TEXT_REQUIRED.TITLE),
  seoDescription: Yup.string().required(TEXT_REQUIRED.DESCRIPTION),
  seoKeyword: Yup.string().required(TEXT_REQUIRED.ALIAS),
};

export const CONTENT_VALIDATE_RULE = {
  title: Yup.string().required(TEXT_REQUIRED.TITLE),
  alias: Yup.string().required(TEXT_REQUIRED.ALIAS),
};

export const PERCENTAGE_VALIDATE = {
  discountValue: Yup.number().min(0, "Giá trị giảm phải lớn hơn 0"),
};

export const CONTENT_VALIDATE_RULE_NAME = {
  name: Yup.string().required(TEXT_REQUIRED.TITLE),
  alias: Yup.string().required(TEXT_REQUIRED.ALIAS),
};

export const PROFILE_VALIDATE_RULE = {
  fullname: Yup.string().required(TEXT_REQUIRED.TITLE),
};

export const CHANGE_PASSWORD_VALIDATE_RULE = {
  password: Yup.string().required(TEXT_REQUIRED.PASSWORD),
  passwordConfirmation: Yup.string().required(TEXT_REQUIRED.CONFIRM_PASSWORD),
};
