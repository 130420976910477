/* eslint-disable jsx-a11y/anchor-is-valid */
import { getPaginations } from "_metronic/helpers/pagination/pagination";
import clsx from "clsx";

interface PropsType {
  useQueryResponsePagination?: any;
  useQueryRequest?: any;
}

const Pagination = (props: PropsType) => {
  const { useQueryResponsePagination, useQueryRequest } = props;
  const pagination: any = useQueryResponsePagination();
  const { updateState, state } = useQueryRequest();
  const updatePage = (page: any) => {
    updateState({ ...state, page, limit: pagination.limit || 10 });
  };

  const pageRange = getPaginations({
    firstPage: 1,
    lastPage: pagination.pageTotal,
    currentPage: state.page,
    offsetPage: 2,
  });

  return (
    <div className="row mt-5">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_list_paginate">
          {pagination.pageTotal > 1 && (
            <ul className="pagination">
              <li
                className={clsx("page-item previous", {
                  disabled: state.page === 1,
                })}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => updatePage(state.page - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>

              {pageRange.map((item, index) => (
                <li
                  key={index}
                  className={clsx("page-item", {
                    active:
                      typeof item.value === "number" &&
                      item.value === state.page,
                    disabled: !item.enable,
                  })}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={(event) => {
                      typeof item.value === "number" && updatePage(item.value);
                      event.currentTarget.blur();
                    }}
                  >
                    {item.value}
                  </a>
                </li>
              ))}

              <li
                className={clsx("page-item next", {
                  disabled: state.page === pagination.pageTotal,
                })}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => updatePage(state.page + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export { Pagination };
