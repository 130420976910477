import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { alertSuccess } from "_metronic/helpers/toastify";
import {
  CreatedByColumn,
  DefaultCell,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import ItemColumn from "app/components/Table/columns/ItemColumn";
import { Header, SelectionHeader } from "app/components/Table/header";
import clsx from "clsx";
import { FC } from "react";
import { Column } from "react-table";

import { ItemModel } from "./_models";
import { approveComment, denyComment } from "./_requests";

type Props = {
  type?: "approve" | "deny";
  commentId: Array<string>;
};

const ActionComment: FC<Props> = ({ type = "approve", commentId }) => {
  const { refetch } = useQueryResponse();

  return (
    <button
      type="button"
      className={clsx(
        "hide-text btn btn-sm d-inline-flex align-items-center",
        type === "approve" ? "btn-success" : "btn-danger"
      )}
      onClick={async () => {
        type === "approve"
          ? await approveComment(commentId).then(() => {
              alertSuccess("Bạn đã chấp nhận điểm thành công !");
            })
          : await denyComment(commentId).then(() => {
              alertSuccess("Bạn đã từ chối điểm thành công !");
            });

        refetch();
      }}
    >
      {type === "approve" ? "Approve" : "Reject"}
    </button>
  );
};

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Người gửi"
        className="min-w-300px"
      />
    ),
    id: "item",
    Cell: ({ ...props }) => (
      <ItemColumn
        customLink={`${props.data[props.row.index]?.id}/detail`}
        value={props.data[props.row.index]?.owner}
        isAlias={false}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Nội dung"
        className="min-w-200px"
      />
    ),
    id: "comment",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.comment} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Đánh giá"
        className="min-w-100px"
      />
    ),
    id: "rating",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.rating} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tình trạng"
        className="min-w-100px"
      />
    ),
    id: "status",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.status} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-200px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <>
        {props.data[props.row.index]?.status === "waiting" ? (
          <div className=" d-flex gap-2 min-w-200px">
            <ActionComment commentId={[props.data[props.row.index]?.id]} />
            <ActionComment
              type="deny"
              commentId={[props.data[props.row.index]?.id]}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    ),
  },
];

export { COLUMNS };
