/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "_metronic/helpers";
import { alertSuccess } from "_metronic/helpers/toastify";
import { FC } from "react";

type Props = {
  onClick: () => any;
  useQueryResponse: any;
  hideText?: boolean;
};

const ActionDuplicateCell: FC<Props> = ({
  hideText,
  onClick,
  useQueryResponse,
  ...props
}) => {
  const { refetch } = useQueryResponse();

  const handleClick = async () => {
    onClick && (await onClick());
    refetch();
    alertSuccess("Sao chép thành công");
  };
  return (
    <button
      {...props}
      type="button"
      className={`${
        hideText ? "hide-text" : ""
      } btn btn-info btn-sm d-inline-flex align-items-center`}
      onClick={handleClick}
    >
      <KTIcon iconName="copy" />
      {!hideText ? "Sao chép" : ""}
    </button>
  );
};

export { ActionDuplicateCell };
