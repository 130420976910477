import { KTIcon } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { InputDropdown } from "app/components/Form";
import {
  CONDITION_TYPE,
  CONNECTOR_ITEM_OPTION,
  initConditionData,
} from "constants/voucher";
import React, { memo, useEffect, useState } from "react";

import { getList as getEvent } from "../../../event-management/events/core/_requests";
import { getList as getProductAttribute } from "../../attribute/core/_requests";
import { getList as getBrand } from "../../brand/core/_requests";
import { getList as getProduct } from "../../product/core/_requests";
import { getListVariant as getProductVariant } from "../../product/core/_requests";
import { getList as getProductCategory } from "../../product-category/core/_requests";
import { useVoucherCondition } from "../context/ConditionProvider";
import { IConditionOption } from "../core/_models";

const ConditionItem = ({ formik }: { formik?: any }) => {
  const { condition, setCondition } = useVoucherCondition();

  const [dataOption, setDataOption] = useState<IConditionOption>();

  // create option by condition type
  const renderOptionByType = (item?: { type: string; value: string }) => {
    switch (item?.type) {
      case "brand":
        return dataOption?.brand;

      case "product-category":
        return dataOption?.category;

      case "product":
        return dataOption?.product;

      case "attribute":
        return dataOption?.attribute;

      case "event":
        return dataOption?.event;

      case "product-variant":
        return dataOption?.variant;

      default:
        return [];
    }
  };

  const onChangeConnector = (e: any, idx: number) => {
    condition[Object.keys(condition)[0]][idx] = {
      [e.value]: [initConditionData],
    };

    setCondition({
      ...condition,
    });
  };

  const searchConditionByType = (input?: string, type?: string) => {
    switch (type) {
      case "brand":
        getBrand({
          limit: 10,
          page: 1,
          search: input,
          platform: process.env.REACT_APP_PLATFORM,
          isActive: true,
        }).then((data?: any) => {
          setDataOption((prev: any) => {
            return {
              ...prev,
              brand: mapValue(data?.data),
            };
          });
        });

        return;

      case "product-category":
        getProductCategory({
          limit: 10,
          page: 1,
          search: input,
          platform: process.env.REACT_APP_PLATFORM,
          isActive: true,
        }).then((data?: any) => {
          setDataOption((prev: any) => {
            return {
              ...prev,
              category: mapValue(data?.data),
            };
          });
        });

        return;

      case "product-variant":
        getProductCategory({
          limit: 10,
          page: 1,
          search: input,
          isActive: true,
          platform: process.env.REACT_APP_PLATFORM,
        }).then((data?: any) => {
          setDataOption((prev: any) => {
            return {
              ...prev,
              variant: mapValue(data?.data),
            };
          });
        });

        return;

      case "product":
        getProduct({
          limit: 10,
          page: 1,
          search: input,
          isActive: true,
          platform: process.env.REACT_APP_PLATFORM,
        }).then((data?: any) => {
          setDataOption((prev: any) => {
            return {
              ...prev,
              product: mapValue(data?.data),
            };
          });
        });
        return;

      case "attribute":
        getProductAttribute({
          limit: 10,
          page: 1,
          search: input,
          isActive: true,
          platform: process.env.REACT_APP_PLATFORM,
        }).then((data?: any) => {
          setDataOption((prev: any) => {
            return {
              ...prev,
              attribute: mapValue(data?.data),
            };
          });
        });

        return;

      case "event":
        getEvent({ limit: 10, page: 1, search: input, isActive: true }).then(
          (data?: any) => {
            setDataOption((prev: any) => {
              return {
                ...prev,
                event: mapValue(data?.data),
              };
            });
          }
        );

        return;

      default:
        return [];
    }
  };

  useEffect(() => {
    Promise.all([
      getProductCategory({
        limit: 10,
        page: 1,
        platform: process.env.REACT_APP_PLATFORM,
        isActive: true,
      }),
      getBrand({
        limit: 10,
        page: 1,
        platform: process.env.REACT_APP_PLATFORM,
        isActive: true,
      }),
      getProductAttribute({
        limit: 10,
        page: 1,
        platform: process.env.REACT_APP_PLATFORM,
        isActive: true,
      }),
      getProduct({
        limit: 10,
        page: 1,
        platform: process.env.REACT_APP_PLATFORM,
        isActive: true,
      }),
      getEvent({ limit: 10, page: 1, isActive: true }),
      getProductVariant({ limit: 10, page: 1, isActive: true }),
    ]).then((data: any) => {
      setDataOption({
        category: mapValue(data[0]?.data),
        brand: mapValue(data[1].data),
        attribute: mapValue(data[2].data),
        product: mapValue(data[3].data),
        event: mapValue(data[4].data),
        variant: mapValue(
          data[5].data?.map((item: any) => {
            return {
              label: `${item?.product.title} - ${item?.attributeItems
                ?.map((child: any) => child.detail.title)
                ?.concat("")}`,
              value: item?.id,
            };
          })
        ),
      });
    });
  }, []);

  return (
    <>
      {((Object.values(condition)?.[0] as []) || [])?.map((parent, idx) => {
        return (
          <React.Fragment key={idx}>
            <div className="row" style={{ marginLeft: 12 }}>
              <div className="col-3">
                <InputDropdown
                  onChange={(e) => onChangeConnector(e, idx)}
                  clearable={false}
                  options={Object.keys(CONNECTOR_ITEM_OPTION)?.map((item) => ({
                    label:
                      CONNECTOR_ITEM_OPTION?.[
                        item as keyof typeof CONNECTOR_ITEM_OPTION
                      ],
                    value: item,
                  }))}
                  classNameLabel="form-label fs-6 fw-bold"
                  classNameDropdown="form-select form-select-solid fw-bolder"
                  // disabled={formik?.isSubmitting}
                  values={
                    Object.keys(parent)?.[0] !== "type"
                      ? Object.keys(parent)?.[0]
                      : "value"
                  }
                />
              </div>
            </div>

            {condition ? (
              <div style={{ marginLeft: 40, marginRight: 20 }}>
                {(
                  ((Object.values(condition)?.[0] as [])?.[idx]?.[
                    Object.keys(parent)?.[0]
                  ] as Array<any>) || []
                )?.map((item, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-md-5">
                        <InputDropdown
                          clearable={false}
                          classNameLabel="form-label fs-6 fw-bold"
                          classNameDropdown="form-select form-select-solid fw-bolder"
                          options={CONDITION_TYPE}
                          allowNoneValue={false}
                          values={item.type}
                          onChange={(event) => {
                            condition[Object.keys(condition)[0]][idx][
                              Object.keys(parent)?.[0]
                            ][index] = {
                              type: event.value,
                              value: "",
                            };

                            setCondition(() => ({
                              ...condition,
                            }));
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <InputDropdown
                          clearable={false}
                          onSearch={(input?: string) => {
                            searchConditionByType(input, item?.type);
                          }}
                          classNameLabel="form-label fs-6 fw-bold"
                          classNameDropdown="form-select form-select-solid fw-bolder"
                          options={renderOptionByType(item)}
                          allowNoneValue={false}
                          values={item?.value}
                          onChange={(event) => {
                            condition[Object.keys(condition)[0]][idx][
                              Object.keys(parent)?.[0]
                            ][index] = {
                              type: item.type,
                              value: event.value,
                            };

                            const checkExist =
                              formik.values?.conditionSelected?.[item.type]
                                ?.map((item: any) => item.value)
                                ?.some((item: any) => item === event.value);
                            if (!checkExist) {
                              formik.setFieldValue(
                                `conditionSelected.${item.type}`,
                                [
                                  // ...formik.values?.conditionSelected?.[
                                  //   item.type
                                  // ],
                                  {
                                    label: event.label,
                                    value: event.value,
                                  },
                                ]
                              );
                            }

                            setCondition(() => ({
                              ...condition,
                            }));
                          }}
                        />
                      </div>
                      <div className="col-md-1 align-items-center justify-content-center d-flex">
                        <div>
                          <label className="form-label fs-6 fw-bold"></label>
                          <button
                            type="button"
                            className="hide-text btn btn-danger btn-sm d-flex align-items-center"
                            onClick={() => {
                              condition[Object.keys(condition)[0]][idx][
                                Object.keys(parent)?.[0]
                              ] = condition[Object.keys(condition)[0]][idx][
                                Object.keys(parent)?.[0]
                              ]?.filter((x: any, id?: number) => id !== index);

                              setCondition((e: any) => {
                                return {
                                  ...condition,
                                };
                              });
                            }}
                          >
                            <KTIcon iconName="basket" />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="w-200px mt-7">
                  <button
                    className="btn btn-sm btn-light-primary"
                    type="button"
                    onClick={() => {
                      condition[Object.keys(condition)[0]][idx][
                        Object.keys(parent)?.[0]
                      ].push({
                        type: "",
                        value: "",
                      });

                      setCondition(() => {
                        return {
                          ...condition,
                        };
                      });
                    }}
                  >
                    <i className="ki-duotone ki-plus fs-3"></i>Thêm giá trị khác
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      })}

      {Object.keys(
        (Object.values(condition)?.[0] as Array<any>)?.[0] || []
      )[0] !== "value" ? (
        <div className="w-200px mt-7" style={{ marginLeft: 16 }}>
          <button
            className="btn btn-sm btn-light-primary"
            type="button"
            onClick={() => {
              condition?.[Object.keys(condition)[0]].push({
                type: "",
                value: "",
              });

              setCondition(() => {
                return {
                  ...condition,
                };
              });
            }}
          >
            <i className="ki-duotone ki-plus fs-3"></i>Thêm giá trị khác
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(ConditionItem);
