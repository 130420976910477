/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "_metronic/helpers";
import { FC } from "react";

type Props = {
  data?: any;
  useListView: any;
  isModal?: boolean;
  hideText?: boolean;
  path?: any;
};

const ActionEditCell: FC<Props> = ({
  data,
  hideText,
  useListView,
  isModal = true,
  path,
  ...props
}) => {
  const PUBLIC_URL = process.env.PUBLIC_URL;
  const { setItemIdForUpdate } = useListView();
  // const navigate = useNavigate();

  const handleEditUser = () => {
    if (isModal) {
      setItemIdForUpdate(data);
    } else {
      // navigate(path);
      window.open(PUBLIC_URL + path, "_blank");
    }
  };

  return (
    <button
      {...props}
      type="button"
      className={`${
        hideText ? "hide-text" : ""
      } btn btn-secondary btn-sm d-inline-flex align-items-center`}
      onClick={handleEditUser}
    >
      <KTIcon iconName="pencil" />
      {!hideText ? "Chỉnh sửa" : ""}
    </button>
  );
};

export { ActionEditCell };
