import { toAbsoluteUrl } from "_metronic/helpers";
import React from "react";
import { Link } from "react-router-dom";

interface PropsType {
  customLink?: string;
  value: any;
  isAlias?: boolean;
  isEdit?: boolean;
  disabled?: boolean;
  isRole?: boolean;
  isModal?: boolean;
  isSquare?: boolean;
  classNameContent?: string;
  useListView?: any;
  children?: React.ReactNode;
}

const DEFAULT_IMAGE = "/media/svg/files/blank-image.svg";
export const ItemColumn = (props: PropsType) => {
  const {
    customLink,
    value,
    children,
    disabled,
    isAlias = true,
    isEdit = true,
    isRole = false,
    isModal = false,
    isSquare = false,
    classNameContent,
    useListView = () => {
      return {
        setItemIdForUpdate: null,
      };
    },
  } = props;

  const { setItemIdForUpdate } = useListView();

  const openModal = () => {
    setItemIdForUpdate(value);
  };

  return (
    <div className="d-flex align-items-center">
      <div
        className="bg-light rounded overflow-hidden"
        style={{
          height: 80,
          flex: isSquare ? "0 0 80px" : "0 0 150px",
          overflow: "hidden",
        }}
      >
        <img
          src={
            value?.image ||
            value?.logo ||
            value?.avatar ||
            value?.thumbnail ||
            toAbsoluteUrl(DEFAULT_IMAGE)
          }
          alt=""
          className="h-100 w-100 border"
          style={{ objectFit: "contain" }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = toAbsoluteUrl(DEFAULT_IMAGE);
          }}
        />
      </div>
      <div
        className={`d-flex justify-content-start flex-column text-truncate ${classNameContent}`}
        style={{ marginLeft: 16 }}
      >
        {!isModal ? (
          disabled ? (
            <div className="text-truncate">
              {value?.title || value?.name || value?.fullname || "-"}
            </div>
          ) : (
            <Link
              to={
                customLink ||
                (isEdit ? `${value?.id}/edit` : `${value?.id}/view-detail`)
              }
              target="_blank"
              className="text-truncate"
            >
              {value?.title || value?.name || value?.fullname || "-"}
            </Link>
          )
        ) : (
          <div
            className="text-primary text-truncate"
            onClick={() => {
              useListView && openModal();
            }}
          >
            {value?.title || value?.name || value?.fullname || "-"}
          </div>
        )}
        {isAlias && (
          <p className="mb-0 text-truncate">Alias: {value?.alias || "-"}</p>
        )}
        {isRole && <p className="mb-0">Vai trò: {value?.role?.name || "-"}</p>}
        {children}
      </div>
    </div>
  );
};

export default ItemColumn;
