import { ID } from "_metronic/helpers";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { InputDropdown } from "app/components/Form";
import ModalCustom from "app/components/ModalCustom";
import FileImport from "app/components/Upload/FileImport/FileImport";
import { useFormik } from "formik";
import { FC, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import { createItem } from "../core/_requests";

type Props = {
  data: SetStateAction<ID>;
};

export const validateSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng điền đủ thông tin"),
});

const addingMethodOptions = [
  {
    label: "Nhập liệu",
    value: "write",
  },
  {
    label: "Import File",
    value: "import",
  },
];

const ItemModal: FC<Props> = () => {
  const [uploadFile, setUploadFile] = useState(false);
  const [addingMethod, setAddingMethod] = useState("write");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const { refetch, isLoading } = useQueryResponse();
  const data: any = itemIdForUpdate;

  const [item] = useState<ItemModel>({
    ...initialData,
    ...data,
  });

  const formik = useFormik({
    initialValues: item,
    validationSchema: validateSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await createItem({
          campaign: id,
        }).then(() => {
          setTimeout(() => {
            createSuccess();
            setItemIdForUpdate(undefined);
            refetch();
          }, 500);
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <ModalCustom
        labelHeader={`${!itemIdForUpdate ? "Tạo mới" : "Chỉnh sửa"}`}
        actionHeader={setItemIdForUpdate}
        actionCancel={() => setItemIdForUpdate(undefined)}
        formik={formik}
        labelCancel="Đóng"
        labelSubmit="Xác nhận"
      >
        <div className="fv-row mb-7">
          <InputDropdown
            onChange={(e) => setAddingMethod(e.value)}
            label={"Phương thức thêm"}
            options={addingMethodOptions}
            classNameLabel="form-label fs-6 fw-bold"
            classNameDropdown="form-select form-select-solid fw-bolder"
            disabled={formik?.isSubmitting}
            values={addingMethod}
          />
        </div>

        {addingMethod === "import" ? (
          <div className="fv-row mb-7">
            <FileImport
              url={`/vouchers/import`}
              setIsUpload={setUploadFile}
              upload={uploadFile}
              name="voucherImport"
              requireType={["csv", "xlsx"]}
              description="Định dạng của file phải là định dạng .csv, .xlsx"
              label="Voucher Import"
              uploaded={() => {
                setTimeout(() => {
                  createSuccess();
                  setItemIdForUpdate(undefined);
                  refetch();
                }, 2500);
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="text-center pt-15">
          <Button
            type="button"
            className="btn btn-light me-3"
            onClick={() => setItemIdForUpdate(undefined)}
          >
            Đóng
          </Button>

          <Button
            type="submit"
            className="btn btn-primary"
            onClick={async () => {
              if (addingMethod === "import") {
                setUploadFile(true);
              } else {
                navigate("/vouchers/create");
              }
            }}
            disabled={isLoading || formik?.isSubmitting}
          >
            {addingMethod === "import" ? "Xác nhận" : "Đến trang nhập liệu"}
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export default ItemModal;
