import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  CreatedByColumn,
  DefaultCell,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { CurrencyCell } from "app/components/Table/columns/CurrencyCell";
import { Header, SelectionHeader } from "app/components/Table/header";
import { OrderStatusEnum } from "constants/enum";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Mã đơn hàng"
        className="min-w-150px"
      />
    ),
    accessor: "code",
    Cell: ({ ...props }) => (
      <DefaultCell
        link={`/orders/${props.data[props.row.index].id}/view`}
        value={props.data[props.row.index].code}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Khách hàng"
        className="min-w-300px"
      />
    ),
    id: "item",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={
          <div className="d-flex justify-content-start flex-column">
            <p className="text-primary">
              {props.data[props.row.index]?.customerInformation?.fullname ||
                "-"}
            </p>
            <p className="mb-0">
              {props.data[props.row.index]?.customerInformation?.email || "-"}
            </p>
          </div>
        }
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-250px"
      />
    ),
    accessor: "status",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={
          OrderStatusEnum[
            props.data[props.row.index].status as keyof typeof OrderStatusEnum
          ]
        }
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tổng cộng"
        className="min-w-150px"
      />
    ),
    accessor: "totalPrice",
    Cell: ({ ...props }) => (
      <CurrencyCell value={props.data[props.row.index]?.totalPrice} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  // {
  //   Header: "Hành động",
  //   id: "action",
  //   Cell: (props: any) => (
  //     <div className="d-flex gap-2 min-w-200px justify-content-between">
  //       <ActionEditCell
  //         data={props.row.original}
  //         useListView={useListView}
  //         isModal={false}
  //         path={`/orders/${props.data[props.row.index].id}/view`}
  //       />
  //       {/* <ActionDeleteCell
  //         data={props.row.original}
  //         useListView={useListView}
  //         hideText={true}
  //       /> */}
  //     </div>
  //   ),
  // },
];

export { COLUMNS };
