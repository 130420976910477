type Props = {
  children: any;
  isSticky?: boolean;
};

export const StickyCard = (props: Props) => {
  const { children, isSticky = true } = props;
  return (
    <div
      className="card"
      data-kt-sticky={isSticky}
      data-kt-sticky-name="invoice"
      data-kt-sticky-offset="{default: false, lg: '100px'}"
      data-kt-sticky-width="{lg: '250px', lg: '300px'}"
      data-kt-sticky-left="auto"
      data-kt-sticky-top="50px"
      data-kt-sticky-animation="false"
      data-kt-sticky-zindex="95"
      data-kt-sticky-enabled="true"
    >
      <div className="card-body p-10">{children}</div>
    </div>
  );
};
