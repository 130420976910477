const Item = ({ data }: { data?: any }) => {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          {/*begin::Thumbnail*/}
          <a href="test" className="symbol symbol-50px">
            <img
              src={data?.target?.product?.thumbnail}
              alt=""
              className="symbol-label"
            />
          </a>
          {/*end::Thumbnail*/}
          {/*begin::Title*/}
          <div className="ms-5">
            <p className="fw-bold text-gray-600 text-hover-primary">
              {data?.target?.product?.title}
            </p>
          </div>
          {/*end::Title*/}
        </div>
      </td>
      <td className="text-end">{data?.target?.code}</td>
      <td className="text-end">{data?.store?.name}</td>
    </tr>
  );
};

export default Item;
