import { ID, Response } from "_metronic/helpers";

export type ItemModel = {
  id?: ID;
  platform?: any;
  image: string;
  title: string;
  alias: string;
  isActive?: boolean;
  seoTitle: string;
  seoDescription: string;
  seoKeyword: string;
  seoImage: string;
  parent?: {
    alias: string;
    id: string;
    title: string;
  };
  createdBy?: any;
  createdAt?: any;
  types?: any[];
  updatedAt?: any;
  updatedBy?: any;
  category: {
    alias: string;
    id: string;
    title: string;
  };
};

export const initialData: ItemModel = {
  title: "",
  seoTitle: "",
  seoDescription: "",
  seoKeyword: "",
  seoImage: "",
  image: "",
  alias: "",
  // platform: Object.keys(PlatformEnum)?.map((item) => ({
  //   label: PlatformEnum[item as keyof typeof PlatformEnum],
  //   value: item,
  // })),
  isActive: true,
  category: {
    alias: "",
    id: "",
    title: "",
  },
  platform: [
    {
      label: process.env.REACT_APP_PLATFORM,
      value: process.env.REACT_APP_PLATFORM,
    },
  ],
};

export type QueryResponse = Response<Array<ItemModel>>;
