import clsx from "clsx";

type SwitcherProps = {
  onChange?: (x: any) => any;
  size?: "sm" | "md" | "lg";
  name?: string;
  value?: any;
  label: string;
  direction: "vertical" | "horizontal";
};
export const Switcher = (props: SwitcherProps) => {
  const {
    onChange,
    name,
    value,
    label,
    direction = "horizontal",
    size = "sm",
  } = props;

  return (
    <div
      className={clsx("d-flex ", {
        "align-items-center justify-content-between":
          direction === "horizontal",
        "flex-column": direction === "vertical",
      })}
    >
      <label className="fw-bold fs-6">{label}</label>
      <div
        className={`form-check form-switch form-switch-${size} form-check-custom form-check-solid`}
      >
        <input
          className={clsx("form-check-input", {
            "mt-3": direction === "vertical",
          })}
          value={"checked"}
          type="checkbox"
          name={name}
          onChange={(x) => {
            return onChange ? onChange(x) : () => null;
          }}
          checked={!!value}
        />
      </div>
    </div>
  );
};
