import api, { getData } from "services/api";

import {
  ConfigTypeOrderPoint,
  DisableFormPoint,
  OptionsFormPoint,
} from "./_models";

const API_URL = `${process.env.REACT_APP_API_URL}/config`;

export const getItem = async (type: ConfigTypeOrderPoint) =>
  getData(`${API_URL}/${type}`);

export const configDisablePoint = (
  type: ConfigTypeOrderPoint,
  formData: DisableFormPoint
) => api({ isToken: true }).put(`${API_URL}/${type}/disable`, formData);

export const configOptionsPoint = (
  type: ConfigTypeOrderPoint,
  formData: OptionsFormPoint
) => api({ isToken: true }).put(`${API_URL}/${type}/options`, formData);
