import { QUERIES } from "_metronic/helpers";
import { QueryResponseProvider } from "_metronic/helpers/provider/QueryResponseProvider";
import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";
import { getList } from "./core/_requests";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Loại sản phẩm",
    path: "/product-type",
  },
  {
    isSeparator: true,
  },
];

const ProductTypePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách loại sản phẩm
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="/create"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Thêm mới loại sản phẩm
              </PageTitle>
              <QueryResponseProvider
                queryString={QUERIES.PRODUCT_CATEGORY_LIST}
                getList={getList}
              >
                <EditPage />
              </QueryResponseProvider>
            </>
          }
        />
        <Route
          path=":id/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Chỉnh sửa loại sản phẩm
              </PageTitle>
              <QueryResponseProvider
                queryString={QUERIES.PRODUCT_CATEGORY_LIST}
                getList={getList}
              >
                <EditPage />
              </QueryResponseProvider>
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ProductTypePage;
