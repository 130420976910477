const Item = ({ data }: { data?: any }) => {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          {/*begin::Title*/}
          <div className="">
            <p className="fw-bold text-gray-600 text-hover-primary">
              {data?.name}
            </p>
          </div>
          {/*end::Title*/}
        </div>
      </td>
      {/* <td className="text-end">02844008 </td> */}
      <td className="text-end">{data?.description}</td>
    </tr>
  );
};

export default Item;
