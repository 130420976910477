import { ID, Response } from "_metronic/helpers";

export interface CategoryType {
  alias: string;
  id: string;
  title: string;
}

export interface ProductTypeType {
  alias: string;
  id: string;
  title: string;
}

export type ItemModel = {
  id?: ID;
  image: string;
  title: string;
  alias: string;
  platform?: any;
  description: string;
  categoryIds?: string[];
  productTypeIds?: any[];
  excerpt: string;
  isActive?: boolean;
  seoTitle: string;
  seoDescription: string;
  seoKeyword: string;
  seoImage: string;
  createdBy?: any;
  createdAt?: any;
  updatedAt?: any;
  updatedBy?: any;
  categories: CategoryType[];
  types: ProductTypeType[];
};

export const initialData: ItemModel = {
  id: null,
  image: "",
  title: "",
  alias: "",
  description: "",
  excerpt: "",
  seoTitle: "",
  seoDescription: "",
  // platform: Object.keys(PlatformEnum)?.map((item) => ({
  //   label: PlatformEnum[item as keyof typeof PlatformEnum],
  //   value: item,
  // })),
  // platform: [
  //   {
  //     label: process.env.REACT_APP_PLATFORM,
  //     value: process.env.REACT_APP_PLATFORM,
  //   },
  // ],
  seoKeyword: "",
  seoImage: "",
  isActive: true,
  categories: [],
  productTypeIds: [],
  types: [],
};

export type QueryResponse = Response<Array<ItemModel>>;
