import { ModalForm } from "./ModalForm";
import { ModalHeader } from "./ModalHeader";

interface PropsType {
  actionHeader?: any;
  labelHeader?: string;
  children?: any;
  isDelete?: boolean;
  actionCancel?: () => void;
  formik?: any;
  isLoading?: any;
  labelSubmit?: string;
  labelCancel?: string;
  //   [restProp: string]: any;
}

export const ModalCustom = (props: PropsType) => {
  const { actionHeader, labelHeader, children, formik, ...restProp } = props;

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <ModalHeader
              actionCancel={actionHeader}
              lableHeader={labelHeader}
            ></ModalHeader>
            <div className="modal-body scroll-y mx-5 mx-xl-15">
              <ModalForm formik={formik} {...restProp}>
                {children}
              </ModalForm>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default ModalCustom;
