import { KTCardBody } from "_metronic/helpers";
import { NOT_FOUND_DATA } from "constants/text-error";
import { useMemo } from "react";
import { ColumnInstance, Row, useTable } from "react-table";

import { RowCell } from "./columns";
import { HeaderColumn } from "./header";
import { ListLoading } from "./loading/ListLoading";
import { Pagination } from "./pagination/Pagination";

type Props = {
  columnsSettings: any;
  useQueryResponsePagination: any;
  useQueryRequest: any;
  data: any;
  isLoading: boolean;
  actionClickItem?: (data?: any) => void;
};

const Table = (props: Props) => {
  const {
    data,
    columnsSettings,
    useQueryResponsePagination,
    useQueryRequest,
    isLoading,
    actionClickItem,
  } = props;

  const columns = useMemo(() => columnsSettings, []);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <KTCardBody className="py-4">
      <div className="table-responsive">
        <table
          id="kt_table_list"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              {headers.map((column: ColumnInstance<any>, index: number) => (
                <HeaderColumn key={index} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row);
                return (
                  <RowCell
                    onClick={actionClickItem}
                    row={row}
                    key={`row-${i}-${row.id}`}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={headers.length}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    {NOT_FOUND_DATA}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {useQueryResponsePagination && (
        <Pagination
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryRequest={useQueryRequest}
        />
      )}
      {isLoading && <ListLoading />}
    </KTCardBody>
  );
};

export default Table;
