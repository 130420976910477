import { InputDropdown } from "app/components/Form";
import { CONNECTOR_OPTION, initConditionData } from "constants/voucher";
import { useEffect } from "react";

import { useVoucherCondition } from "../context/ConditionProvider";
import ConditionItem from "./ConditionItem";

const ConditionConnector = ({ formik, data }: { formik?: any; data: any }) => {
  const { condition, setCondition } = useVoucherCondition();

  useEffect(() => {
    if (data) {
      let parseCondition = Array.isArray(data) ? [] : JSON.parse(data);

      if ((Object.values(parseCondition)?.[0] as Array<any>)?.length > 0) {
        if (
          Object.keys(
            (Object.values(parseCondition)?.[0] as Array<any>)?.[0]
          )?.[0] === "type"
        ) {
          parseCondition = {
            [Object.keys(parseCondition)?.[0]]: [
              {
                value: Object.values(parseCondition)?.[0] as Array<any>,
              },
            ],
          };
        }
      }

      setCondition({ ...parseCondition });
    }
  }, [data]);

  return (
    <>
      <div className="row">
        <div className="col-3">
          <InputDropdown
            onChange={(e) =>
              setCondition({ [e.value]: [{ value: [initConditionData] }] })
            }
            clearable={false}
            options={Object.keys(CONNECTOR_OPTION)?.map((item) => ({
              label: CONNECTOR_OPTION?.[item as keyof typeof CONNECTOR_OPTION],
              value: item,
            }))}
            classNameLabel="form-label fs-6 fw-bold"
            classNameDropdown="form-select form-select-solid fw-bolder"
            // disabled={formik?.isSubmitting}
            values={condition ? Object.keys(condition)[0] : ""}
          />
        </div>
      </div>

      {condition && Object.keys(condition).length !== 0 ? (
        <ConditionItem formik={formik} />
      ) : (
        <></>
      )}
    </>
  );
};

export default ConditionConnector;
