import { QUERIES } from "_metronic/helpers";
import { QueryResponseProvider } from "_metronic/helpers/provider/QueryResponseProvider";
import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import Detail from "./components/Edit";
import { ListWrapper } from "./components/List";
import { getList } from "./core/_requests";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Collection",
    path: "/collection",
  },
  {
    isSeparator: true,
  },
];

const CollectionPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách collection
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="/create"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Thêm mới collection
              </PageTitle>
              <QueryResponseProvider
                queryString={QUERIES.COLLECTION_LIST}
                getList={getList}
              >
                <Detail />
              </QueryResponseProvider>
            </>
          }
        />
        <Route
          path=":id/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Chỉnh sửa collection
              </PageTitle>
              <QueryResponseProvider
                queryString={QUERIES.COLLECTION_LIST}
                getList={getList}
              >
                <Detail />
              </QueryResponseProvider>
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default CollectionPage;
