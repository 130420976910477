import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  ActionDeleteCell,
  CreatedByColumn,
  DefaultCell,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { Column } from "react-table";

import { ItemDealModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<ItemDealModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="SKU"
        className="min-w-150px"
      />
    ),
    accessor: "sku",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].sku} />
    ),
  },

  // {
  //   Header: (props) => (
  //     <Header
  //       useQueryRequest={useQueryRequest}
  //       tableProps={props}
  //       title="Giá trị giảm"
  //       className="min-w-150px"
  //     />
  //   ),
  //   id: "discountValue",
  //   Cell: ({ ...props }) => (
  //     <DefaultCell
  //       value={formatDiscountVoucher(
  //         props.data[props.row.index].discountValue,
  //         props.data[props.row.index].discountType
  //       )}
  //     />
  //   ),
  // },

  // {
  //   Header: (props) => (
  //     <Header
  //       useQueryRequest={useQueryRequest}
  //       tableProps={props}
  //       title="Trạng thái"
  //       className="min-w-150px"
  //     />
  //   ),
  //   accessor: "isActive",
  //   Cell: ({ ...props }) => (
  //     <IsActiveCell value={props.data[props.row.index].isActive} />
  //   ),
  // },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-250px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className=" d-flex gap-2  justify-content-around">
        {/* <ActionEditCell
          data={props.row.original}
          useListView={useListView}
          isModal={false}
          path={`/shipping/${props.data[props.row.index].id}/edit`}
        /> */}
        <ActionDeleteCell data={props.row.original} useListView={useListView} />
      </div>
    ),
  },
];

export { COLUMNS };
