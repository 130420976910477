import { toAbsoluteUrl } from "_metronic/helpers";
import clsx from "clsx";
import { BikeSourceEnum } from "constants/enum";

const GeneralData = ({ data }: { data?: any }) => {
  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-5">
        <div className="d-flex flex-wrap flex-sm-nowrap">
          <div className="me-7 mb-4">
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                src={
                  //   data.image ||
                  toAbsoluteUrl("/media/svg/files/blank-image.svg")
                }
                alt="avatar"
              />
              <div
                className={clsx(
                  "position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-body h-20px w-20px",
                  {
                    // "bg-success": !data.isBlocked,
                    // "bg-secondary": data.isBlocked,
                  }
                )}
              ></div>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a
                    href="##"
                    className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                  >
                    Thông tin khách hàng
                  </a>
                  <a href="##">
                    {/* <i className="ki-duotone ki-verify fs-1 text-primary">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i> */}
                    <div
                      className={clsx({
                        // "badge badge-light-success me-auto": !data.isBlocked,
                        // "badge badge-light-danger me-auto": data.isBlocked,
                      })}
                    >
                      {/* {!data.isBlocked ? "Đang hoạt động" : "Ngưng hoạt động"} */}
                    </div>
                  </a>
                </div>

                <div className="fw-semibold fs-6 mb-4 mt-4 pe-2">
                  <a
                    href="##"
                    className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                  >
                    Tên khách hàng:
                    <span className="fw-bold" style={{ marginLeft: 4 }}>
                      {data?.owner?.fullname || "-"}
                    </span>
                  </a>
                </div>

                <div className="fw-semibold fs-6 mb-4 pe-2">
                  <a
                    href="##"
                    className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                  >
                    Tên xe:
                    <span className="fw-bold" style={{ marginLeft: 4 }}>
                      {data?.bikeName || "-"}
                    </span>
                  </a>
                </div>

                <div className="fw-semibold fs-6 mb-4 pe-2">
                  <a
                    href="##"
                    className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                  >
                    Hãng xe:{" "}
                    <span className="fw-bold" style={{ marginLeft: 4 }}>
                      {data?.brand?.title || "-"}
                    </span>
                  </a>
                </div>

                <div className="fw-semibold fs-6 mb-4 pe-2">
                  <a
                    href="##"
                    className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                  >
                    Nguồn gốc xe:
                    <span className="fw-bold" style={{ marginLeft: 4 }}>
                      {BikeSourceEnum[
                        data?.bikeSource as keyof typeof BikeSourceEnum
                      ] || "-"}
                    </span>
                  </a>
                </div>

                <div className="fw-semibold fs-6 mb-4 pe-2">
                  <a
                    href="##"
                    className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                  >
                    Thời gian sử dụng:
                    <span className="fw-bold" style={{ marginLeft: 4 }}>
                      {data?.renewType?.description || "-"}
                    </span>
                  </a>
                </div>

                <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                  <a
                    href="##"
                    className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                  >
                    <i className="bi bi-telephone-fill me-1"></i>
                    <span className="fw-bold">{data?.owner?.phone || "-"}</span>
                  </a>
                  <a
                    href="##"
                    className="d-flex align-items-center text-dark text-hover-primary mb-2"
                  >
                    <i className="ki-duotone ki-sms fs-4 me-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="fw-bold">{data.owner?.email || "-"}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralData;
