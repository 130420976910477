import { KTIcon } from "_metronic/helpers";
import { InputDropdown } from "app/components/Form";
import { useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ItemModel as AttributeInterface } from "../../../attribute/core/_models";
import { getItem } from "../../../attribute/core/_requests";
import { formEditType } from "../../core/_models";

interface AttributeItemProps {
  id: string;
}

const AttributeItemProduct = (props: AttributeItemProps) => {
  const { id } = props;
  const [attributes, setAttributes] = useState<AttributeInterface[]>([]);

  const formik = useFormikContext<formEditType>();

  const variantOptions = useMemo(() => {
    return attributes.map((a) => ({
      label: a.title,
      value: a.id,
    }));
  }, [attributes]);

  useEffect(() => {
    id &&
      getItem(id)
        .then((x) => setAttributes(x.items || []))
        .catch((x) => console.log(x));
  }, [id]);

  const variantOptionsRemain = useCallback(
    (itemId: string) => {
      const optionTaken = formik.values.attributeItemsProduct[id]?.filter(
        (v) => v !== itemId
      );
      return variantOptions.filter(
        (v) => !optionTaken.includes(v.value as string)
      );
    },
    [variantOptions, formik.values.attributeItemsProduct, id]
  );

  return (
    <div className="mx-8 row">
      {Object.values(formik.values.attributeItemsProduct)?.length > 0 &&
        Object?.values(formik?.values?.attributeItemsProduct[id]).map(
          (val: string, index: number) => (
            <div className="row" key={val}>
              <div className="col-md-11">
                <InputDropdown
                  clearable={false}
                  classNameLabel="form-label fs-6 fw-bold"
                  classNameDropdown="form-select form-select-solid fw-bolder"
                  options={variantOptionsRemain(val)}
                  allowNoneValue={false}
                  values={val}
                  onChange={(event) => {
                    const attributeItems = [
                      ...formik.values.attributeItemsProduct[id],
                    ];
                    attributeItems[index] = event.value;

                    formik.setFieldValue(
                      `attributeItemsProduct.${id}`,
                      attributeItems
                    );
                  }}
                />
              </div>
              <div className="col-md-1 align-items-center justify-content-center d-flex">
                <div>
                  <label className="form-label fs-6 fw-bold"></label>
                  <button
                    type="button"
                    className="hide-text btn btn-danger btn-sm d-flex align-items-center"
                    onClick={() => {
                      formik.setFieldValue(
                        `attributeItemsProduct.${id}`,
                        formik.values.attributeItemsProduct[id].filter(
                          (v) => v !== val
                        )
                      );
                    }}
                  >
                    <KTIcon iconName="basket" />
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      {variantOptionsRemain("").length > 0 && (
        <div className="row mt-7">
          <div className="w-200px">
            <button
              className="btn btn-sm btn-light-primary"
              type="button"
              onClick={() => {
                const attributeItems = [
                  ...formik.values.attributeItemsProduct[id],
                ];
                attributeItems.push(
                  variantOptionsRemain("")[0].value as string
                );
                formik.setFieldValue(
                  `attributeItemsProduct.${id}`,
                  attributeItems
                );
              }}
            >
              <i className="ki-duotone ki-plus fs-3"></i>Thêm giá trị khác
            </button>
          </div>
        </div>
      )}
    </div>
  );

  // return <></>;
};

export default AttributeItemProduct;
