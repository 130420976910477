import { ReactNode } from "react";

export interface PropsType {
  children?: ReactNode;
  isDelete?: boolean;
  actionCancel?: () => void;
  formik?: any;
  isLoading?: any;
  labelSubmit?: string;
  labelCancel?: string;
}

export const ModalForm = (props: PropsType) => {
  const { children, formik } = props;

  return (
    <form
      id="kt_modal_add_user_form"
      className="form"
      onSubmit={formik?.handleSubmit}
      noValidate
    >
      <div
        className="d-flex flex-column scroll-y me-n7 pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        {children}
      </div>
    </form>
  );
};
