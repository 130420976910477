import { KTCard } from "_metronic/helpers/components/KTCard";

import Dropdown from "../Dropdown";
import { Input } from "../Input";
import TextArea from "../TextArea";

interface PropsType {
  formik: any;
  [props: string]: any;
  onChange?: (e: any) => void;
}

export const SEO = (props: PropsType) => {
  const { formik } = props;

  return (
    <KTCard {...props}>
      <div className="card-header">
        <div className="card-title">
          <h2>SEO</h2>
        </div>
      </div>
      <div className="card-body">
        <div className="fv-row mb-7">
          <Input
            label="Title"
            placeholder="Tiêu đề"
            name="seoTitle"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.seoTitle}
          />
        </div>
        <div className="fv-row mb-7">
          <Input
            label="Keyword"
            placeholder="Keyword"
            name="seoKeyword"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.seoKeyword}
          />
        </div>
        <div className="row">
          <div className="col-lg-6 mb-7">
            <Input
              label="Canonical"
              placeholder="Canonical"
              name="canonical"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              values={formik.values.canonical}
            />
          </div>
          <div className="col-lg-6 mb-7">
            <Dropdown
              formik={formik}
              label="Robot"
              name="metaRobot"
              classNameLabel="mb-2 fw-bold fs-6"
              options={[
                { label: "index", value: "index" },
                { label: "follow", value: "follow" },
                { label: "noindex", value: "noindex" },
                { label: "nofollow", value: "nofollow" },
              ]}
            />
          </div>
        </div>
        <div className="fv-row mb-7">
          <TextArea
            rows={5}
            label="Description"
            placeholder="Description"
            name="seoDescription"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.seoDescription}
          />
        </div>
      </div>
    </KTCard>
  );
};

export default SEO;
