import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import { ListWrapper } from "./components/List";
import ViewDetail from "./components/ViewDetail";

const Breadcrumbs: Array<PageLink> = [
  {
    title: "Danh sách người dùng",
    path: "/user",
  },
  {
    isSeparator: true,
  },
];

const UsersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>
                Danh sách người dùng
              </PageTitle>
              <ListWrapper />
            </>
          }
        />

        <Route
          path=":id/view-detail"
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>
                Chi tiết người dùng
              </PageTitle>
              <ViewDetail />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default UsersPage;
