import { getData } from "services/api";

import { QueryResponse } from "./_models";

const URL = `revenue-booking`;

const getInfoChart = async (query: any): Promise<QueryResponse> =>
  getData(URL + "/chart", query);

const getInfoOverview = async (query: any): Promise<QueryResponse> =>
  getData(URL + "/overview", query);

export { getInfoChart, getInfoOverview };
