import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  ActionDeleteCell,
  ActionEditCell,
  CreatedByColumn,
  DefaultCell,
  IsActiveCell,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  // {
  //   Header: (props) => (
  //     <Header
  //       useQueryRequest={useQueryRequest}
  //       tableProps={props}
  //       title="Tiêu đề"
  //       className="min-w-350px"
  //     />
  //   ),
  //   accessor: "name",
  //   Cell: ({ ...props }) => (
  //     <ItemColumn isAlias={false} value={props.data[props.row.index]} />
  //   ),
  // },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Name"
        className="min-w-350px"
      />
    ),
    id: "name",
    Cell: ({ ...props }) => (
      <DefaultCell
        link={`${props.data[props.row.index]?.id}/edit`}
        value={
          props.data[props.row.index]?.title ||
          `${props.data[props.row.index]?.productVariant?.product?.title}, ${
            props.data[props.row.index]?.productVariant?.attributeItems?.[0]
              ?.title
          }`
        }
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Type"
        className="min-w-100px"
      />
    ),
    id: "type",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.type} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    accessor: "isActive",
    Cell: ({ ...props }) => (
      <IsActiveCell value={props.data[props.row.index].isActive} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-250px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className=" d-flex gap-2 min-w-200px justify-content-start">
        <ActionEditCell
          data={props.row.original}
          useListView={useListView}
          isModal={false}
          path={`/gift/${props.data[props.row.index].id}/edit`}
        />
        <ActionDeleteCell
          data={props.row.original}
          useListView={useListView}
          hideText={true}
        />
      </div>
    ),
  },
];

export { COLUMNS };
