import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  CreatedByColumn,
  DefaultCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header } from "app/components/Table/header";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

const TargetLink = ({
  targetModel,
  dataTarget,
}: {
  targetModel?: string;
  dataTarget?: any;
}) => {
  // Generate link based on target model
  const generateLink = () => {
    switch (targetModel) {
      case "Newsfeed":
        return `${WEBSITE_URL}/newsfeed/${dataTarget?.id}`;

      case "Group":
        return `${WEBSITE_URL}/groups/${dataTarget?.id}`;

      case "Product":
        return `${WEBSITE_URL}/products/${dataTarget?.alias}`;

      case "Event":
        return `${WEBSITE_URL}/event-detail/${dataTarget?.alias}`;

      case "Post":
        return `${WEBSITE_URL}/blogs/${dataTarget?.alias}`;

      default:
        return "-";
    }
  };
  return (
    <a href={(() => generateLink())()} target="_blank" rel="noreferrer">
      {(() => generateLink())()}
    </a>
  );
};

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Người dùng"
        className="min-w-200px"
      />
    ),
    id: "user",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.user?.fullname} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Đường dẫn bài"
        className="min-w-250px"
      />
    ),
    id: "target",
    Cell: ({ ...props }) => (
      <TargetLink
        dataTarget={props.data[props.row.index].target}
        targetModel={props.data[props.row.index].targetModel}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-250px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
];

export { COLUMNS };
