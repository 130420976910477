import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Điều kiện thu cũ đổi mới",
    path: "/bike-renew-condition",
  },
  {
    isSeparator: true,
  },
];

const BikeRenewPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách điều kiện thu cũ đổi mới
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="create"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Thêm mới điều kiện thu cũ đổi mới
              </PageTitle>
              <EditPage />
            </>
          }
        />

        <Route
          path=":id/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Chỉnh sửa điều kiện thu cũ đổi mới
              </PageTitle>
              <EditPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default BikeRenewPage;
