const QUERIES = {
  USERS_LIST: "users-list",
  ADMINS_LIST: "admin-list",
  ROLE_LIST: "role-list",
  PERMISSION_LIST: "permission-list",
  CATEGORY_LIST: "category_list",
  TAG_LIST: "tag-list",
  POST_LIST: "post-list",
  TAG_LIST_POST: "tag_list_post",
  CATEGORY_LIST_POST: "category_list_post",
  VIEW: "view",
  PAGES_LIST: "page-list",
  VOUCHER_LIST: "voucher-list",
  ACTIVITY_LOG: "activity-log",
  DEAL_LIST: "deal-list",
  SETTING_PAGE: "setting_page",
  MENU_LIST: "menu",
  EVENTS_CATEGORIES_LIST: "event-category",
  BRAND_LIST: "brand-list",
  COLLECTION_LIST: "collection-list",
  PRODUCT_CATEGORY_LIST: "product-categories",
  PRODUCT_ATTRIBUTES: "product-attributes",
  PRODUCT_LIST: "product-list",
  ORDER_LIST: "order-list",
  SPORT_LIST: "sport-list",
  GROUP_LIST: "group-list",
  EVENT_LIST: "event-list",
  BANNER_LIST: "banner-list",
  TOUR_LIST: "tour-list",
  TOUR_CHILD_LIST: "tour-child-list",
  TESTIMONIAL_LIST: "testimonial-list",
  BIKE_SERVICES_LIST: "bike-services-list",
  USER_STRAVA_LIST: "user-strava-list",
  APP_KEYS_LIST: "app-keys-list",
  EMAIL_TEMPLATE_LIST: "email-template-list",
  BIKE_RENTAL_LIST: "bike-rental-list",
  BIKE_RENEW_REQUEST: "bike-renew-request",
  BIKE_RENEW_CONDITIONAL: "bike-renew-conditional",
  BIKE_FIXING: "bike-fixing",
  COMMENT_LIST: "comment-list",
};

export { QUERIES };
