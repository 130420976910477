import { PageLink, PageTitle } from "_metronic/layout/core";
import Report from "app/pages/content-management/report/components";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Sự kiện",
    path: "/event",
    isSeparator: false,
    isActive: false,
  },
  {
    isSeparator: true,
  },
];

const EventPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Danh sách sự kiện</PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="create"
          element={
            <>
              <EditPage breadcrumbs={breadbrumbs} />
            </>
          }
        />
        <Route
          path=":id/edit"
          element={
            <>
              <EditPage breadcrumbs={breadbrumbs} />
            </>
          }
        />

        <Route
          path=":id/report"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Thông tin chi tiết
              </PageTitle>
              <Report />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default EventPage;
