import { ID } from "_metronic/helpers";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import {
  alertSuccess,
  createSuccess,
  editSuccess,
} from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { Input } from "app/components/Form";
import InputDropdown from "app/components/Form/Dropdown";
import { Switcher } from "app/components/Form/Switcher";
import ModalCustom from "app/components/ModalCustom";
import { UserRoleEnum } from "constants/enum";
import { TEXT_REQUIRED } from "constants/text-error";
import { useFormik } from "formik";
import { FC, SetStateAction, useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import {
  createItem as createUser,
  editItem as editUser,
  syncData,
} from "../core/_requests";

type Props = {
  data: SetStateAction<ID>;
};

export const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email(TEXT_REQUIRED.VALIDATE_EMAIL)
    .min(3, TEXT_REQUIRED.MIN_EMAIL)
    .max(50, TEXT_REQUIRED.MAX_EMAIL)
    .required(TEXT_REQUIRED.REQUIRED_EMAIL),
  fullname: Yup.string()
    .min(3, TEXT_REQUIRED.MIN_NAME)
    .max(50, TEXT_REQUIRED.MAX_NAME)
    .required(TEXT_REQUIRED.REQUIRED_NAME),
  role: Yup.string().required(TEXT_REQUIRED.TITLE_ROLE),
});

export const createUserSchema = Yup.object().shape({
  email: Yup.string()
    .email(TEXT_REQUIRED.VALIDATE_EMAIL)
    .min(3, TEXT_REQUIRED.MIN_EMAIL)
    .max(50, TEXT_REQUIRED.MAX_EMAIL)
    .required(TEXT_REQUIRED.REQUIRED_EMAIL),
  fullname: Yup.string()
    .min(3, TEXT_REQUIRED.MIN_NAME)
    .max(50, TEXT_REQUIRED.MAX_NAME)
    .required(TEXT_REQUIRED.REQUIRED_NAME),
  role: Yup.string().required(TEXT_REQUIRED.TITLE_ROLE),
  password: Yup.string().required(TEXT_REQUIRED.REQUIRED_PASSWORD),
});

const ItemModal: FC<Props> = () => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const [error, setError] = useState(false);

  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const isLoading = false;
  const data: any = itemIdForUpdate;

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const [createItem] = useState<ItemModel>({
    ...initialData,
    ...data,
  });

  const [editItem] = useState<ItemModel>({
    ...data,
    fullname: data?.fullname,
    email: data?.email,
    phone: data?.phone,
    isActive:
      typeof data?.isActive === "boolean"
        ? data?.isActive
        : initialData.isActive,
    role: data?.role || initialData.role,
  });

  const formik = useFormik({
    initialValues: itemIdForUpdate ? editItem : createItem,
    validationSchema: itemIdForUpdate ? editUserSchema : createUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (!values.id) {
          await createUser({
            ...values,
            isVerifiedEmail: false,
            isVerifiedPhone: false,
          });
          createSuccess();
          cancel(true);
        } else {
          await editUser(
            {
              fullname: values?.fullname,
              email: values?.email,
              phone: values?.phone,
              isActive: values?.isActive,
              role: values?.role,
            },
            values.id
          );
          editSuccess();
          cancel(true);
        }
      } catch (err) {
        console.error(err);
        setError(true);
      } finally {
        setSubmitting(true);
      }
    },
  });

  return (
    <>
      <ModalCustom
        labelHeader={`${!itemIdForUpdate ? "Tạo mới" : "Chỉnh sửa"}`}
        actionHeader={setItemIdForUpdate}
        actionCancel={() => setItemIdForUpdate(undefined)}
        formik={formik}
        labelCancel="Đóng"
        labelSubmit="Xác nhận"
      >
        {itemIdForUpdate ? (
          <div className="text-right fv-row mb-7">
            <Button
              type="button"
              className="btn btn-sm btn-info"
              onClick={() => {
                syncData({
                  user: [data.id],
                }).then(() => {
                  alertSuccess(
                    "Yêu cầu thành công, vui lòng chờ trong giây lát"
                  );
                });
              }}
            >
              Sync
            </Button>
          </div>
        ) : null}
        <div className="fv-row mb-7">
          <Input
            formik={formik}
            label="Tên"
            placeholder="Tên"
            name="fullname"
            disabled={formik.isSubmitting || isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.fullname}
          ></Input>
        </div>
        <div className="fv-row mb-7">
          <Input
            formik={formik}
            label="Email"
            placeholder="Email"
            name="email"
            type="email"
            disabled={formik.isSubmitting || isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.email}
          ></Input>
        </div>
        <div className="fv-row mb-7">
          <Input
            formik={formik}
            label="Số điện thoại"
            placeholder="Số điện thoại"
            name="phone"
            type="phone"
            disabled={formik.isSubmitting || isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.phone}
          ></Input>
        </div>
        <div className="fv-row mb-7">
          <Switcher
            label="Trạng thái"
            name="isActive"
            onChange={formik.handleChange}
            value={formik.values.isActive}
            direction="vertical"
          ></Switcher>
        </div>
        <div className="fv-row mb-7">
          <div className="fv-row mb-7">
            <InputDropdown
              formik={formik}
              label="Vai trò"
              placeholder="Vai trò"
              name="role"
              options={[
                { label: "Người dùng", value: UserRoleEnum.CUSTOMER },
                { label: "Quản lý cửa hàng", value: UserRoleEnum.MANAGER },
              ]}
              classNameLabel="required form-label fs-6 fw-bold"
              classNameDropdown="form-select form-select-solid fw-bolder"
              disabled={formik.isSubmitting || isLoading}
              values={formik.values.role}
            />
          </div>
        </div>
        {!itemIdForUpdate && (
          <div>
            <div className="fv-row mb-7">
              <Input
                formik={formik}
                label="Mật khẩu"
                placeholder="Mật khẩu"
                name="password"
                type="password"
                classNameInput="form-control form-control-solid mb-3 mb-lg-0"
                classNameLabel="required fw-bold fs-6 mb-2"
                disabled={formik.isSubmitting || isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={formik.values.password}
              ></Input>
            </div>
          </div>
        )}
        {error && (
          <div className="fv-plugins-message-container mt-6">
            <div className="fv-help-block">
              <span role="alert">Có lỗi xảy ra</span>
            </div>
          </div>
        )}
        <div className="text-center pt-3">
          <Button
            type="button"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={() => setItemIdForUpdate(undefined)}
          >
            Đóng
          </Button>

          <Button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={
              isLoading ||
              formik?.isSubmitting ||
              !formik?.isValid ||
              !formik?.touched
            }
          >
            Xác nhận
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export { ItemModal };
