import { Response } from "_metronic/helpers";

export interface ItemModel {
  type?: string;
  title?: string;
  isGift?: boolean;
  image: string;
  id: string;
  conditionSelected: any;
  owners?: Array<any>;
  usedNumber?: number;
  ownerIds?: Array<{
    label: string;
    value: string;
  }>;
  isActive: boolean;
  createdBy: null;
  code: string;
  discountType: string;
  condition?: string;
  discountValue: number;
  usedNumberPerUser: number;
  totalLimit: number;
  conditionBuyTime?: {
    isNextApplied: boolean;
    isFirstApplied: boolean;
  };
  targetType: string[];
  startedDate?: string;
  expiredDate?: string;
  createdAt: Date;
  updatedAt: Date;
  conditionType: string;
  avaiableForGuess: boolean;
  platform: any[];
}

export interface IConditionOption {
  category: Option[];
  brand: Option[];
  attribute: Option[];
  product: Option[];
  event: Option[];
  variant: Option[];
}

export interface Option {
  label: string;
  value: string;
}

export const initialData: any = {
  conditionType: "custome",
  isActive: true,
  createdBy: null,
  code: "",
  conditionSelected: {
    product: [],
    category: [],
    attribute: [],
    event: [],
    brand: [],
    variant: [],
  },
  discountType: "",
  discountValue: 0,
  limitPerUser: 0,
  totalLimit: 0,
  specificUsers: [],
  specificProducts: [],
  isGift: false,
  startedDate: Date.now(),
  createdAt: Date.now(),
  updatedAt: Date.now(),
  platform: [
    {
      label: process.env.REACT_APP_PLATFORM,
      value: process.env.REACT_APP_PLATFORM,
    },
  ],
  avaiableForGuess: false,
};

export type QueryResponse = Response<Array<ItemModel>>;
