import { getItem, syncMemberEvent } from "./_requests";

export const handleSyncDataWeekly = async (
  id: string,
  startOfWeek: string,
  endOfWeek: string
) => {
  await syncMemberEvent(id, {
    startOfWeek,
    endOfWeek,
  });

  const response = await getItem(id, {
    isFilterWeekly: true,
    startOfWeek,
    endOfWeek,
  });

  return response;
};
