import // CONTENT_VALIDATE_RULE,
// SEO_VALIDATE_RULE,
"constants/validate-rule";

import { KTCard } from "_metronic/helpers";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { Input } from "app/components/Form";
import { FormField } from "app/components/Form/Field";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { BannerTypeEnum } from "constants/enum";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem, getItem } from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();

  const validation = Yup.object().shape({
    // ...SEO_VALIDATE_RULE,
    // ...CONTENT_VALIDATE_RULE,
  });

  const submit = async (query: any) => {
    try {
      if (id) {
        await editItem(query, id).then(updateSuccess);
      } else {
        await createItem(query).then((data) => {
          if (data.statusCode && data.statusCode > 300) {
            return;
          }

          createSuccess();
          navigate("/banner");
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) =>
        setData({
          ...x,
        })
      )
      .catch((e) => {
        console.log(e);
        navigate("/banner");
      });
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await submit({
          ...values,
          isActive: !!values?.isActive,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const values = Object.values(BannerTypeEnum);

  const FIELDS = [
    {
      label: "Tên",
      name: "title",
      required: false,
      type: "text",
    },
    {
      label: "URL",
      name: "url",
      required: false,
      type: "url",
    },

    {
      label: "Mô tả",
      name: "description",
      required: false,
      type: "text",
    },

    {
      label: "Tiêu đề nút bấm",
      name: "buttonText",
      required: false,
      type: "text",
    },

    {
      label: "URL nút bấm",
      name: "buttonLink",
      required: false,
      type: "url",
    },
    {
      label: "Type",
      name: "type",
      required: true,
      type: "dropdown",
      options: values.map((x) => ({
        value: x,
        label: x,
      })),
    },
  ];

  return (
    <>
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 border mb-10"
                path="/banners"
                height={300}
              />
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };
                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}
            </KTCard>
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={() => formik.handleSubmit()}
            >
              <div className="my-4">
                <Input
                  label="Ngày đăng"
                  placeholder="Ngày đăng"
                  type="datetime-local"
                  name="publishedDate"
                  disabled={formik.isSubmitting}
                  onChange={(val) => formik.setFieldValue("publishedDate", val)}
                  onBlur={formik.handleBlur}
                  values={formik.values.publishedDate}
                />
              </div>
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
