import { ID } from "_metronic/helpers";
import { AUTH_LOCAL_STORAGE_KEY } from "app/modules/auth";
import axios, { AxiosInstance, AxiosResponse } from "axios";

import { errorHandle } from "./error";
const BASE_URL = process.env.REACT_APP_API_URL;

const api = ({ isToken = false }: { isToken?: boolean }) => {
  const getAuthLocal: Storage = JSON.parse(
    localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || "{}"
  );

  const token: string = getAuthLocal.access_token || null;
  const apiRequest = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
      Authorization: isToken ? `Bearer ${token}` : `Bearer`,
    },
  });

  apiRequest.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      errorHandle(error.response);
      throw error;
    }
  );

  return apiRequest;
};

export const getData = async (URL: string, query?: any) => {
  return api({ isToken: true })
    .get(`${URL}`, {
      params: query,
    })
    .then((d: AxiosResponse<any>) => d?.data || d);
};

export const getDataDetail = async (URL: string, id: ID) => {
  return api({ isToken: true })
    .get(`${URL}/${id}`)
    .then((d: AxiosResponse<any>) => d?.data);
};

export const postData = async (URL: string, query: any) => {
  return api({ isToken: true })
    .post(`${URL}`, query)
    .then((d: AxiosResponse<any>) => {
      return d?.data || d;
    });
};

export const updateData = async (URL: string, query: any, id: ID) => {
  return api({ isToken: true })
    .patch(`${URL}/${id || ""}`, query)
    .then((d: AxiosResponse<any>) => d?.data);
};

export const deleteDataItem = async (URL: string, id: ID) => {
  return api({ isToken: true })
    .delete(`${URL}/${id}`)
    .then((d: AxiosResponse<any>) => d?.data);
};

export const bulkAction = async (
  URL: string,
  ids: ID[],
  action: "delete" | "update"
) => {
  return api({ isToken: true })
    .post(`${URL}/bulk-action`, { action: action, ids })
    .then((d: AxiosResponse<any>) => d?.data);
};

export const createFormDataInstance = (URL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: URL,
    headers: { "Content-Type": "multipart/form-data" },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      errorHandle(error.response);
      throw error;
    }
  );

  return instance;
};

export default api;
