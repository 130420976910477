import { ID, Response } from "_metronic/helpers";
import { BannerTypeEnum } from "constants/enum";

export type ItemModel = {
  id?: ID;
  title?: string;
  image: string;
  type: BannerTypeEnum;
  isActive?: boolean;
  createdBy?: any;
  updatedBy?: any;
  createdAt?: string;
  buttonLink?: string;
  buttonText?: string;
  updatedAt?: string;
  isShowButton?: boolean;
  publishedDate?: string;
};

export const initialData: ItemModel = {
  id: null,
  isShowButton: false,
  image: "",
  title: "",
  type: BannerTypeEnum.HOME_BIG,
  isActive: true,
};

export type QueryResponse = Response<Array<ItemModel>>;
