import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import {
  ActionDeleteCell,
  ActionDuplicateCell,
  ActionEditCell,
  CreatedByColumn,
  IsActiveCell,
  ItemColumn,
  SelectionCell,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Column } from "react-table";

import { EventList } from "./_models";
import { duplicate } from "./_requests";

type Props = {
  parentId?: string;
  childId?: string;
};

const AccessTourChildButton: FC<Props> = ({ parentId }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      {!id ? (
        <button
          type="button"
          className="hide-text btn btn-primary btn-sm d-inline-flex align-items-center"
          onClick={() => {
            navigate(`/tour/${parentId}/child`);
          }}
        >
          <i className="bi bi-list-ul"></i>
        </button>
      ) : null}
    </>
  );
};

const AccessDetailTourButton: FC<Props> = ({ childId }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      {id ? (
        <button
          type="button"
          className="hide-text btn btn-primary btn-sm d-inline-flex align-items-center"
          onClick={() => {
            navigate(`/tour/${childId}/report`);
          }}
        >
          <i className="bi bi-list-ul"></i>
        </button>
      ) : null}
    </>
  );
};

const COLUMNS: ReadonlyArray<Column<EventList>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Sự kiện"
        className="min-w-350px"
      />
    ),
    accessor: "title",
    Cell: ({ ...props }) => <ItemColumn value={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    accessor: "isActive",
    Cell: ({ ...props }) => (
      <IsActiveCell value={props.data[props.row.index].isActive} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => {
      const { id } = useParams();

      return (
        <div className="text-end d-flex gap-2 min-w-200px justify-content-end">
          <ActionEditCell
            hideText
            data={props.row.original}
            useListView={useListView}
            isModal={false}
            path={
              id
                ? `/tour/${id}/child/${props.data[props.row.index].id}/edit`
                : `/tour/${props.data[props.row.index].id}/edit`
            }
          />
          <ActionDeleteCell
            hideText
            data={props.row.original}
            useListView={useListView}
          />
          <ActionDuplicateCell
            hideText
            onClick={() => duplicate(props.data[props.row.index].id)}
            useQueryResponse={useQueryResponse}
          />
          <AccessTourChildButton parentId={props.data[props.row.index].id} />
          <AccessDetailTourButton childId={props.data[props.row.index].id} />
        </div>
      );
    },
  },
];

export { COLUMNS };
