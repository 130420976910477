import { ID } from "_metronic/helpers";
import { AxiosResponse } from "axios";
import api, { deleteDataItem, getData, postData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `renew-bookings`;

const getList = async (query?: any): Promise<QueryResponse> =>
  getData(URL, query);

const getItem = async (id: ID): Promise<ItemModel> => getData(URL + "/" + id);

const createItem = async (query: any): Promise<QueryResponse> =>
  postData(URL, query);

const editItem = (query: any, id: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .patch(`${URL}/${id}`, query)
    .then((d: AxiosResponse<any>) => {
      return d?.data || false;
    });

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

export { bulkDelete, createItem, deleteItem, editItem, getItem, getList };
