import { ID } from "_metronic/helpers";
import api, { getData } from "services/api";

import { QueryResponse } from "./_models";

const URL = `users`;

const getList = async (query: any): Promise<QueryResponse> => {
  return await getData(`${URL}/strava`, query);
};

const syncData = async (id?: ID): Promise<QueryResponse> => {
  return await api({ isToken: true }).get(
    `${process.env.REACT_APP_API_PUBLIC}strava/sync${id ? "/" + id : ""}`
  );
};

const syncAllData = async (): Promise<any> => {
  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/user-ranking`,
    { user: [] }
  );

  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/group-ranking`,
    { group: [] }
  );

  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/event-member-ranking`,
    { event: [] }
  );

  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/event-group-ranking`,
    { event: [] }
  );

  await api({ isToken: true }).post(
    `${process.env.REACT_APP_API_URL}/activity/sync/event-ranking`,
    { event: [] }
  );
};

const bulkSync = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await syncData(id)));
};

export { bulkSync, getList, syncAllData, syncData };
