import { Response } from "_metronic/helpers";
export type AdminModel = {
  id?: string;
  avatar?: string;
  fullname?: string;
  email?: string;
  role?: Role;
  roleId?: string;
  isActive?: boolean;
  password?: string;
  passwordConfirmation?: string;
};

export type Role = {
  id?: string;
  name?: string;
};

export const initialData = {
  id: null,
  avatar: "",
  fullname: "",
  email: "",
  isActive: true,
  role: "",
  roleID: "",
  password: "",
  passwordConfirmation: "",
};

export type QueryResponse = Response<Array<AdminModel>>;
