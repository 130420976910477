import { createContext, useContext, useState } from "react";

type VoucherConditionType = {
  condition: any;
  setCondition?: any;
};

const VoucherConditionContext = createContext<VoucherConditionType>({
  condition: {},
  setCondition: (condition?: any) => {
    console.log("update condition");
  },
});

const VoucherConditionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [condition, setCondition] = useState<any>(null);

  return (
    <VoucherConditionContext.Provider value={{ condition, setCondition }}>
      {children}
    </VoucherConditionContext.Provider>
  );
};

const useVoucherCondition = () => useContext(VoucherConditionContext);

export { useVoucherCondition, VoucherConditionProvider };
