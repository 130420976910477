import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import Datetime from "react-datetime";

import AddableInputTrip from "../Repeater/AddableInputTrip";
import AddableInput from "../Repeater/RepeaterAmentities/AddableInputAmentities";
import RepeaterService from "../Repeater/RepeaterAmentities/RepeaterService";
import { Slug } from "../Slug";
import TextArea from "../TextArea";

export interface InputProps {
  label?: string;
  name?: any;
  style?: React.CSSProperties;
  classNameInput?: string;
  classNameLabel?: string;
  disabled?: boolean;
  required?: boolean;
  values?: any;
  formik?: any;
  type?: string;
  className?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  [restProp: string]: any;
}
export const Input = (props: InputProps) => {
  const {
    label,
    formik,
    name,
    values,
    classNameInput = "form-control form-control-solid",
    classNameLabel = "fw-bold fs-6",
    onChange,
    onBlur,
    required,
    type,
    disabled,
    ...restProp
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  switch (type) {
    case "datetime-local":
      return (
        <div className="form-input">
          <label
            className={clsx("mb-2", { required: !!required }, classNameLabel)}
          >
            {label}
          </label>

          <Datetime
            isValidDate={restProp.valid}
            value={moment(values)}
            // initialValue={props?.initialValues}
            onChange={(val: any) => {
              if (typeof val === "object") {
                onChange && onChange(val);
              } else {
                onChange && onChange(moment(val));
              }
            }}
          />

          {formik?.errors[name] && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik?.errors[name]}</span>
              </div>
            </div>
          )}
        </div>
      );

    case "textarea":
      return (
        <TextArea
          maxLength={restProp?.max}
          required={required}
          label={label}
          placeholder={label}
          name={name}
          formik={formik}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          values={formik.values[name]}
        />
      );

    case "tripPlans":
      return <AddableInputTrip {...props} />;

    case "amentities":
      return <AddableInput {...props} />;

    case "services":
      return <RepeaterService {...props} />;

    case "alias":
      return <Slug formik={formik} />;

    case "password":
      return (
        <div className="form-input" {...restProp}>
          <label
            className={clsx("mb-2", { required: !!required }, classNameLabel)}
          >
            {label}
          </label>
          <input
            {...restProp}
            // {...formik?.getFieldProps(name)}
            type={showPassword ? "text" : "password"}
            name={name}
            className={clsx(
              classNameInput,
              { "is-invalid": formik?.touched[name] && formik?.errors[name] },
              {
                "is-valid": formik?.touched[name] && !formik?.errors[name],
              }
            )}
            autoComplete="off"
            disabled={disabled || formik?.isSubmitting}
            defaultValue={values || ""}
            onChange={onChange}
            onBlur={onBlur}
          />
          <i
            className={`bi ${showPassword ? "bi bi-eye" : "bi-eye-slash"}`}
            onClick={() => {
              setShowPassword((prev) => !prev);
            }}
          ></i>
          {formik?.touched[name] && formik?.errors[name] && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik?.errors[name]}</span>
              </div>
            </div>
          )}
        </div>
      );

    case "number":
    case "text":
      return (
        <div className="form-input" {...restProp}>
          <label
            className={clsx("mb-2", { required: !!required }, classNameLabel)}
          >
            {label}
          </label>
          <input
            {...restProp}
            type={type}
            name={name}
            className={clsx(
              classNameInput,
              { "is-invalid": formik?.touched[name] && formik?.errors[name] },
              {
                "is-valid": formik?.touched[name] && !formik?.errors[name],
              }
            )}
            autoComplete="off"
            disabled={disabled || formik?.isSubmitting}
            defaultValue={values || ""}
            onChange={onChange}
            onBlur={onBlur}
          />
          {formik?.touched[name] && formik?.errors[name] && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik?.errors[name]}</span>
              </div>
            </div>
          )}
        </div>
      );

    default:
      return (
        <div className="form-input" {...restProp}>
          <label
            className={clsx("mb-2", { required: !!required }, classNameLabel)}
          >
            {label}
          </label>
          <input
            {...restProp}
            type={type}
            name={name}
            className={clsx(
              classNameInput,
              { "is-invalid": formik?.touched[name] && formik?.errors[name] },
              {
                "is-valid": formik?.touched[name] && !formik?.errors[name],
              }
            )}
            autoComplete="off"
            disabled={disabled || formik?.isSubmitting}
            defaultValue={values || ""}
            onChange={onChange}
            onBlur={onBlur}
          />
          {formik?.touched[name] && formik?.errors[name] && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik?.errors[name]}</span>
              </div>
            </div>
          )}
        </div>
      );
  }
};
