import { Response } from "_metronic/helpers";
export type ItemModel = {
  name: string;
  code: string;
  description: string;
  isActive: boolean;
  fee: number;
  id: string;
  image: string;
  createdBy?: any;
  updatedBy?: any;
  createdAt?: string;
  updatedAt?: string;
};

export const initialData: any = {
  name: "",
  code: "",
  description: "",
  isActive: true,
  fee: 0,
  id: "",
};

export type QueryResponse = Response<Array<ItemModel>>;
