export function generateCombination(...args: any): Array<string[]> {
  const r: any = [];
  const max = args.length - 1;
  function helper(arr: any, i: any) {
    for (let j = 0, l = args[i].length; j < l; j++) {
      const a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i === max) r.push(a);
      else helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
}

export function findVariant(variants: any[], combination: string[]) {
  const variant = variants?.find((variant: any) => {
    return (
      variant?.attributeItems &&
      variant?.attributeItems.length === combination.length &&
      variant?.attributeItems.every((value: string) =>
        combination.includes(value)
      )
    );
  });

  return variant;
}

export function removeDuplicates(array: string[]) {
  return array?.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
}
