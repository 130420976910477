import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import ChangePassword from "./components/ChangePassword";
import Edit from "./components/Edit";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Thông tin cá nhân",
    path: "/profile",
  },
  {
    isSeparator: true,
  },
];

const ProfilePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Chỉnh sửa thông tin cá nhân
              </PageTitle>
              <Edit />
            </>
          }
        />

        <Route
          path="/change-password"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Thay đổi mật khẩu</PageTitle>
              <ChangePassword />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ProfilePage;
