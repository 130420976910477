import Button from "../Button";
import { StickyCard } from "../Card/Index";
import { Switcher } from "../Form";

type StickyEditSidebarProps = {
  formik: any;
  children?: any;
  isLoading: boolean;
  isShowActive?: boolean;
  onSubmit: () => any;
  hideSubmitButton?: boolean;
  isEdit: boolean;
};
export const StickyEditSidebar = ({
  formik,
  children,
  isLoading,
  hideSubmitButton,
  isShowActive = true,
  isEdit,
  onSubmit,
}: StickyEditSidebarProps) => {
  return (
    <StickyCard>
      {children}
      {isShowActive ? (
        <div className="fv-row mb-7">
          <Switcher
            label="Trạng thái"
            name="isActive"
            direction="horizontal"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            value={!!formik.values.isActive}
          />
        </div>
      ) : (
        <></>
      )}
      {!hideSubmitButton && (
        <div className="text-end mt-12">
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => onSubmit()}
            disabled={isLoading || formik?.isSubmitting || !formik?.touched}
          >
            {isEdit ? "Cập nhật" : "Thêm mới"}
          </Button>
        </div>
      )}
    </StickyCard>
  );
};
