import { PageLink, PageTitle } from "_metronic/layout/core";
import CommentsWrapper from "app/modules/comment/components/CommentWrapper";
import { ListWrapper } from "app/modules/comment/components/List";
import { Outlet, Route, Routes } from "react-router-dom";

import { getList } from "./core/_requests";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Đánh giá sản phẩm",
    path: "/product-comment",
  },
  {
    isSeparator: true,
  },
];

const ProductCommentPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách đánh giá sản phẩm
              </PageTitle>
              <ListWrapper getList={getList} />
            </>
          }
        />
        <Route
          path=":id/detail"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Chi tiết</PageTitle>
              <CommentsWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ProductCommentPage;
