import { toast } from "react-toastify";

export const createSuccess = () => {
  toast.success("Bạn đã tạo mới thành công !", {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const editSuccess = () => {
  toast.success("Bạn đã chỉnh sửa thành công !", {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const deleteSuccess = () => {
  toast.success("Bạn đã xóa thành công !", {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const updateSuccess = (res: any, noReload = false) => {
  if (!res || (res.statusCode && res.statusCode > 300)) {
    return;
  }

  toast.success("Bạn cập nhập thành công !", {
    position: toast.POSITION.TOP_CENTER,
  });

  // if (!noReload) setTimeout(() => window.location.reload(), 500);
};

export const alertSuccess = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};
