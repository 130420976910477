import { ID, Response } from "_metronic/helpers";
export type ItemModel = {
  id?: ID;
  image?: string;
  title: string;
  alias: string;
  excerpt?: string;
  description?: string;
  tags?: [];
  categories?: [];
  isActive?: boolean;
  isTour?: boolean;
  publishedDate?: any;
  createdBy?: any;
  createdAt?: any;
  updatedAt?: any;
  updatedBy?: any;
  isPreview?: boolean;
};

export const initialData = {
  id: null,
  image: "",
  title: "",
  alias: "",
  description: "",
  excerpt: "",
  seoTitle: "",
  seoDescription: "",
  seoKeyword: "",
  tags: [],
  categories: [],
  isActive: true,
  isTour: false,
  isPreview: false,
};

export type QueryResponse = Response<Array<ItemModel>>;
