import { KTCard, toAbsoluteUrl } from "_metronic/helpers";
import { alertSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import CustomTabs from "app/components/Tabs";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { getItem, syncData, updateItem } from "../core/_requests";
import { Feeds } from "./Feeds";
import Memmbers from "./Members";

const fetchDataDetail: any = async (id: string, setData: any) => {
  try {
    const response = await getItem(id);
    setData(response);
  } catch (error) {
    console.log(error);
  }
};

export const ViewDetail = () => {
  const { id } = useParams<string>();
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const { refetch } = useQuery("data", () => fetchDataDetail(id, setData));

  useEffect(() => {
    if (id) {
      fetchDataDetail(id, setData);
    }
  }, [id]);

  const tabs = [
    {
      id: "members",
      name: "Thành viên",
      content: () => (
        <KTCard>
          <div className="card-body">
            <Memmbers></Memmbers>
          </div>
        </KTCard>
      ),
    },
    {
      id: "feeds",
      name: "Bài viết",
      content: () => (
        <>
          <KTCard>
            <div className="card-body">
              <Feeds></Feeds>
            </div>
          </KTCard>
        </>
      ),
    },
    {
      id: "rank",
      name: "Bảng xếp hạng",
      content: () => (
        <>
          <KTCard>
            <div className="card-body">
              <h3>Bảng xếp hạng</h3>
            </div>
          </KTCard>
        </>
      ),
    },
    {
      id: "logs",
      name: "Logs",
      content: () => (
        <KTCard>
          <div className="card-body">
            <h1>Logs</h1>
          </div>
        </KTCard>
      ),
    },
  ];

  const handleBtn = async () => {
    setIsLoading(true);
    if (data.isBlocked && id) {
      try {
        await updateItem(
          {
            isBlocked: !data.isBlocked,
          },
          id
        );
        refetch();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error:", error);
      }
    } else if (!data.isBlocked && id) {
      try {
        await updateItem(
          {
            isBlocked: !data.isBlocked,
          },
          id
        );
        refetch();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error:", error);
      }
    }
  };

  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-5">
          <div className="d-flex flex-wrap flex-sm-nowrap">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img
                  src={
                    data.image ||
                    toAbsoluteUrl("/media/svg/files/blank-image.svg")
                  }
                  alt="avatar"
                />
                <div
                  className={clsx(
                    "position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-body h-20px w-20px",
                    {
                      "bg-success": !data.isBlocked,
                      "bg-secondary": data.isBlocked,
                    }
                  )}
                ></div>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a
                      href="##"
                      className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                    >
                      {data.title || "-"}
                    </a>
                    <a href="##">
                      {/* <i className="ki-duotone ki-verify fs-1 text-primary">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i> */}
                      <div
                        className={clsx({
                          "badge badge-light-success me-auto": !data.isBlocked,
                          "badge badge-light-danger me-auto": data.isBlocked,
                        })}
                      >
                        {!data.isBlocked ? "Đang hoạt động" : "Ngưng hoạt động"}
                      </div>
                    </a>
                  </div>
                  <div className="mb-4 text-info">
                    <i className="bi bi-dot"></i>
                    Thứ hạng của đội nhóm: 50/200 nhóm
                  </div>
                  <div className="mb-4 text-info">
                    <i className="bi bi-dot"></i>
                    Điểm tích lũy của đội nhóm: 500 điểm
                  </div>
                  <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                    <a
                      href="##"
                      className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                    >
                      <i className="ki-duotone ki-profile-circle fs-4 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      {data.owner?.fullname || "-"}
                    </a>
                    <a
                      href="##"
                      className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                    >
                      <i className="bi bi-telephone-fill me-1"></i>
                      {data.owner?.phone || "-"}
                    </a>
                    <a
                      href="##"
                      className="d-flex align-items-center text-dark text-hover-primary mb-2"
                    >
                      <i className="ki-duotone ki-sms fs-4 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      {data.owner?.email || "-"}
                    </a>
                  </div>
                </div>
                <div className="d-flex my-4">
                  <a href="##" id="kt_user_follow_button">
                    <i className="ki-duotone ki-check fs-3 d-none"></i>
                    {id ? (
                      <Button
                        className="btn btn-sm me-2 btn-info"
                        disabled={data.isBlocked}
                        onClick={() => {
                          syncData({ group: [id] }).then(() => {
                            alertSuccess(
                              "Yêu cầu thành công, vui lòng chờ trong giây lát"
                            );
                          });
                        }}
                      >
                        Sync
                        {isLoading && (
                          <span className="mx-2 spinner-border spinner-border-sm"></span>
                        )}
                      </Button>
                    ) : null}

                    <Button
                      className="btn btn-sm me-2 btn-danger"
                      disabled={data.isBlocked}
                      onClick={handleBtn}
                    >
                      Khóa
                      {isLoading && (
                        <span className="mx-2 spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                  </a>
                  <Button
                    className="btn btn-sm me-2 btn-primary"
                    disabled={!data.isBlocked}
                    onClick={handleBtn}
                  >
                    Bật
                    {isLoading && (
                      <span className="mx-2 spinner-border spinner-border-sm"></span>
                    )}
                  </Button>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-arrow-up fs-3 text-success me-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <div
                          className="fs-2 fw-bold"
                          data-kt-countup="true"
                          data-kt-countup-value="4500"
                          data-kt-countup-prefix="$"
                        >
                          +50
                        </div>
                      </div>
                      <div className="fw-semibold fs-6 text-gray-400">
                        Số điểm cộng
                      </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-arrow-down fs-3 text-danger me-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <div
                          className="fs-2 fw-bold"
                          data-kt-countup="true"
                          data-kt-countup-value="80"
                        >
                          -10
                        </div>
                      </div>
                      <div className="fw-semibold fs-6 text-gray-400">
                        Số điểm trừ
                      </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-arrow-up fs-3 text-success me-2">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <div
                          className="fs-2 fw-bold"
                          data-kt-countup="true"
                          data-kt-countup-value="60"
                          data-kt-countup-prefix="%"
                        >
                          100
                        </div>
                      </div>
                      <div className="fw-semibold fs-6 text-gray-400">
                        Mức điểm tối đa
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                  {/* <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                    <span className="fw-semibold fs-6 text-gray-400">
                      Profile Compleation
                    </span>
                    <span className="fw-bold fs-6">50%</span>
                  </div>
                  <div className="h-5px mx-3 w-100 bg-light mb-3">
                    <div
                      className="bg-success rounded h-5px"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            {/* <li className="nav-item mt-2">
              <a
                className="nav-link text-active-primary ms-0 me-10 py-5"
                href="##"
              >
                Thành viên
              </a>
            </li>
            <li className="nav-item mt-2">
              <a
                className="nav-link text-active-primary ms-0 me-10 py-5"
                href="##"
              >
                Bài viết
              </a>
            </li>
            <li className="nav-item mt-2">
              <a
                className="nav-link text-active-primary ms-0 me-10 py-5 active"
                href="##"
              >
                Logs
              </a>
            </li> */}
            {/* <li className="nav-item mt-2">
              <a
                className="nav-link text-active-primary ms-0 me-10 py-5"
                href="../../demo1/dist/account/billing.html"
              >
                Billing
              </a>
            </li>
            <li className="nav-item mt-2">
              <a
                className="nav-link text-active-primary ms-0 me-10 py-5"
                href="../../demo1/dist/account/statements.html"
              >
                Statements
              </a>
            </li>
            <li className="nav-item mt-2">
              <a
                className="nav-link text-active-primary ms-0 me-10 py-5"
                href="../../demo1/dist/account/referrals.html"
              >
                Referrals
              </a>
            </li>
            <li className="nav-item mt-2">
              <a
                className="nav-link text-active-primary ms-0 me-10 py-5"
                href="../../demo1/dist/account/api-keys.html"
              >
                API Keys
              </a>
            </li>
            <li className="nav-item mt-2">
              <a
                className="nav-link text-active-primary ms-0 me-10 py-5"
                href="../../demo1/dist/account/logs.html"
              >
                Logs
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="flex-lg-row-fluid mb-10">
        <CustomTabs
          className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
          items={tabs}
        />
      </div>
    </>
  );
};

export default ViewDetail;
