import { KTIcon } from "_metronic/helpers";
import { InputDropdown } from "app/components/Form";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

import { formEditType } from "../../core/_models";
import AttributeItem from "./AttributeItem";
import AttributeItemProduct from "./AttributeItemProduct";

interface AttributeProps {
  options: any;
  value: string;
  onChange: (value: string) => void;
  onDelete: () => void;
  isAttributeProduct?: boolean;
}

const Attribute = (props: AttributeProps) => {
  const { options, value, isAttributeProduct = false } = props;

  const formik = useFormikContext<formEditType>();

  const [isShow, setShow] = useState(true);

  useEffect(() => {
    if (!formik.values?.attributeItems || !formik.values?.attributeItemsProduct)
      return;

    setShow(true);
  }, [formik]);

  const toggleShow = () => {
    setShow(!isShow);
  };

  return (
    <div className="mx-6 row justify-content-between w-100">
      <div className="col-md-10">
        <InputDropdown
          clearable={false}
          classNameLabel="form-label fs-6 fw-bold d-none"
          classNameDropdown="form-select form-select-solid fw-bolder"
          options={options}
          allowNoneValue={false}
          values={value}
          onChange={(event) => {
            props.onChange(event.value);
          }}
        />
      </div>
      <div className="col-md-2 align-items-center justify-content-center d-flex gap-6">
        <button
          type="button"
          className={`btn btn-secondary btn-sm d-inline-flex align-items-center`}
          onClick={toggleShow}
        >
          <KTIcon iconName="pencil" />
        </button>
        <button
          type="button"
          className="hide-text btn btn-danger btn-sm d-flex align-items-center"
          onClick={props.onDelete}
        >
          <KTIcon iconName="basket" />
        </button>
      </div>
      <div
        style={{
          display: isShow ? "block" : "none",
        }}
      >
        {isAttributeProduct ? (
          <AttributeItemProduct id={value} />
        ) : (
          <AttributeItem id={value} />
        )}
      </div>
    </div>
  );
};

export default Attribute;
