/* eslint-disable */
import qs from "qs";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { ID, QueryResponseContextProps, QueryState } from "./models";

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState);
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== "";
}

// Example: page=1&limit=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {
    filter: ["page", "limit"],
    skipNulls: true,
  });
  const sort = qs.stringify(state, {
    filter: ["sortBy", "orderBy"],
    skipNulls: true,
  });
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ["search"], skipNulls: true })
    : "";

  const filter = qs.stringify(state, {
    filter: ["role", "isActive", "fromDate", "toDate", "status", "platform"],
    skipNulls: true,
  });

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join("&")
    .toLowerCase();
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query);
  return cache as QueryState;
}

function calculatedGroupingIsDisabled<T>(
  isLoading: boolean,
  response: Array<T> | undefined
): boolean {
  if (isLoading) {
    return true;
  }

  return !response || !response.length;
}

function calculateIsAllDataSelected<T>(
  response: { data?: Array<T> | undefined },
  selected: Array<ID>
): boolean {
  if (!response.data || !response.data.length) {
    return false;
  }

  return response.data.length > 0 && response.data.length === selected.length;
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  response: { data?: Array<any> },
  shiftHeld?: boolean
) {
  if (!id) {
    return;
  }

  const { data } = response;

  if (shiftHeld && selected?.length > 0) {
    const startIndex = data?.findIndex((item) => item.id === selected[0]) || 0;
    const endIndex = data?.findIndex((item) => item.id === id) || 0;

    const selectList = data
      ?.filter((item, index) => index >= startIndex && index <= endIndex)
      ?.map((item) => item.id);

    setSelected([...(selectList as [])]);
  } else {
    if (selected.includes(id)) {
      setSelected(selected.filter((itemId) => itemId !== id));
    } else {
      setSelected([...selected, id]);
    }
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  response: { data?: Array<T & { id?: ID }> }
) {
  if (isAllSelected) {
    setSelected([]);
    return;
  }

  if (!response.data || !response.data.length) {
    return;
  }

  setSelected(
    response.data.filter((item?: any) => item.id).map((item) => item.id)
  );
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const filterObject = (obj: any) => {
  const filteredObj: any = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value !== "" && value !== undefined) {
      filteredObj[key] = value;
    }
  });

  return filteredObj;
};

// Transform YYYY-MM-DD
const transformDate = (date: any) => {
  return new Date(date).toISOString().split("T")[0];
};

// Compare 2 arrays
function compareArrays(arr1: Array<any>, arr2: Array<any>) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export {
  compareArrays,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  createResponseContext,
  groupingOnSelect,
  groupingOnSelectAll,
  isNotEmpty,
  parseRequestQuery,
  stringifyRequestQuery,
  useDebounce,
  filterObject,
  transformDate,
};
