import { ID } from "_metronic/helpers";
import { getData, postData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `notification`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, query);

const createItem = async (query: any): Promise<QueryResponse> =>
  postData(URL, query);

const getItem = async (id: ID): Promise<ItemModel> => getData(URL + "/" + id);

export { createItem, getItem, getList };
