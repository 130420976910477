/* eslint-disable react/jsx-no-target-blank */
import { SIDEBAR_MENU } from "constants/sidebar-menu";

import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = (): any => {
  return (
    <>
      {SIDEBAR_MENU.map((x) => {
        if (x.children && x.children.length > 0) {
          return (
            <SidebarMenuItemWithSub
              key={x.path}
              to={x.path}
              fontIcon={x.fontIcon}
              title={x.title}
            >
              {x.children.map((child) => (
                <SidebarMenuItem
                  requireRole={child.requireRole}
                  key={child.path}
                  to={child.path}
                  title={child.title}
                />
              ))}
            </SidebarMenuItemWithSub>
          );
        } else {
          return (
            <SidebarMenuItem
              key={x.path}
              to={x.path}
              fontIcon={x.fontIcon}
              title={x.title}
            />
          );
        }
      })}
    </>
  );
};

export { SidebarMenuMain };
