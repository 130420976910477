import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  CreatedByColumn,
  DefaultCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header } from "app/components/Table/header";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

const TargetLink = ({
  targetModel,
  id,
}: {
  targetModel?: string;
  id?: string;
}) => {
  // Generate link based on target model
  const generateLink = () => {
    switch (targetModel) {
      case "Newsfeed":
        return `${WEBSITE_URL}/newsfeed/${id}`;

      case "Group":
        return `${WEBSITE_URL}/groups/${id}`;

      default:
        return "-";
    }
  };
  return (
    <a href={(() => generateLink())()} target="_blank" rel="noreferrer">
      {(() => generateLink())()}
    </a>
  );
};

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Nguyên nhân"
        className="min-w-200px"
      />
    ),
    accessor: "content",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].content} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Người báo cáo"
        className="min-w-150px"
      />
    ),
    accessor: "user",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].user?.fullname} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Target"
        className="min-w-150px"
      />
    ),
    accessor: "target",
    Cell: ({ ...props }) => (
      <TargetLink
        id={props.data[props.row.index].target?.id}
        targetModel={props.data[props.row.index].targetModel}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-250px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  // {
  //   Header: "Hành động",
  //   id: "action",
  //   Cell: (props: any) => (
  //     <div className=" d-flex gap-2 min-w-200px justify-content-between">
  //       <ActionEditCell
  //         data={props.row.original}
  //         useListView={useListView}
  //         isModal={false}
  //         path={`/payment/${props.data[props.row.index].id}/edit`}
  //       />
  //       {/* <ActionDeleteCell
  //         data={props.row.original}
  //         useListView={useListView}
  //         hideText={true}
  //       /> */}
  //     </div>
  //   ),
  // },
];

export { COLUMNS };
