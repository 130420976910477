import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  CreatedByColumn,
  DefaultCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header } from "app/components/Table/header";
import { PointStatusEnum } from "constants/enum";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Email"
        className="min-w-250px"
      />
    ),
    accessor: "owner",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={
          props.data[props.row.index]?.owner?.email ||
          props.data[props.row.index]?.group?.title
        }
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Số điểm"
        className="min-w-100px"
      />
    ),
    id: "point",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.point} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Loại"
        className="min-w-100px"
      />
    ),
    id: "type",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.type} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    id: "status",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={
          PointStatusEnum[
            props.data[props.row.index]?.status as keyof typeof PointStatusEnum
          ]
        }
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-200px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
];

export { COLUMNS };
