import { ID, Response } from "_metronic/helpers";
export type ItemModel = {
  id?: ID;
  fullname: string;
  status?: string;
  logs?: any;
  isActive: boolean;
  createdBy?: any;
  createdAt?: any;
  updatedAt?: any;
  updatedBy?: any;
  email?: string;
  description?: string;
};

export const initialData: ItemModel = {
  fullname: "",
  isActive: true,
};

export type QueryResponse = Response<Array<ItemModel>>;
