import { ID } from "_metronic/helpers";
import { deleteDataItem, getData, postData, updateData } from "services/api";

import { QueryResponse } from "./_models";

const URL = `admins`;

const getList = async (query: any): Promise<QueryResponse> => {
  return getData(URL, query);
};

const getRoleList = async (query: any): Promise<QueryResponse> => {
  return getData(`roles`, query);
};

const createItem = async (query: any): Promise<QueryResponse> =>
  postData(URL, query);

const editItem = (query: any, id: ID): Promise<QueryResponse> =>
  updateData(URL, query, id);

const deleteItem = async (id: ID): Promise<QueryResponse> =>
  deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

export { bulkDelete, createItem, deleteItem, editItem, getList, getRoleList };
