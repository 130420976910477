/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  dataDetail: any;
  isModal?: boolean;
  path?: any;
};

const ActionViewDetail: FC<Props> = ({
  dataDetail,
  isModal = true,
  path,
  ...props
}) => {
  const navigate = useNavigate();
  const handleViewDetail = () => {
    navigate(path);
    // if (isModal) {
    //   setItemIdForUpdate(data);
    // } else {
    //   navigate(path, { state: data });
    // }
  };

  return (
    <button
      {...props}
      type="button"
      className={` btn btn-secondary btn-sm d-inline-flex align-items-center`}
      onClick={handleViewDetail}
    >
      Xem chi tiết
      <i className="ki-duotone ki-black-right fs-2 text-gray-500 px-2"></i>
    </button>
  );
};

export { ActionViewDetail };
