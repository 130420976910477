import { KTCard, KTCardBody } from "_metronic/helpers";

import { Socials } from "../core/_models";

export const Connects = ({ data }: { data?: Socials[] }) => {
  return (
    <KTCard>
      <div className="card-header">
        <div className="card-title">
          <h4 className="h4">
            Tổng số tài khoản kết nối:{" "}
            <span className="mx-1 text-primary">{data?.length}</span> Tài khoản
          </h4>
        </div>
      </div>
      <KTCardBody>
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
              <tr>
                <th className="min-w-200px">Username</th>
                <th className="min-w-100px">Nền tảng</th>
              </tr>
            </thead>
            <tbody className="fw-6 fw-semibold text-gray-600">
              {data?.map((item, index: number) => (
                <tr className="row-member" key={index}>
                  <td>
                    <p className="fw-bolder">{item.social_username}</p>
                  </td>
                  <td>
                    <p className="fw-bolder">{item.social_platform}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default Connects;
