import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Chiến dịch thông báo",
    path: "/notification",
  },
  {
    isSeparator: true,
  },
];

const NotificationManagement = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách chiến dịch thông báo
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path=":id/view-detail"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Chi tiết chiến dịch thông báo
              </PageTitle>
              <EditPage />
            </>
          }
        />
        <Route
          path="/create"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Thêm mới chiến dịch thông báo
              </PageTitle>
              <EditPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default NotificationManagement;
