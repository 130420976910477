/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "_metronic/helpers";
import { FC } from "react";

type Props = {
  data: any;
  useListView: any;
  hideText?: boolean;
};

const ActionDeleteCell: FC<Props> = ({
  hideText,
  data,
  useListView,
  ...props
}) => {
  const { setItemDelete } = useListView();

  const handleDeleteUser = () => {
    setItemDelete(data);
  };

  return (
    <button
      {...props}
      type="button"
      className={`${
        hideText ? "hide-text" : ""
      } btn btn-danger btn-sm d-inline-flex align-items-center`}
      onClick={handleDeleteUser}
    >
      <KTIcon iconName="basket" />
      {!hideText ? "Xoá" : ""}
    </button>
  );
};

export { ActionDeleteCell };
