import { Response } from "_metronic/helpers";
export type ItemModel = {
  status?: string;
  logs?: Array<any>;
  createdAt?: any;
  payment?: { name?: string };
  shippingInformation?: any;
  customerInformation?: any;
  transactions?: any;
  platform?: string[];
  shipping?: any;
  updatedAt?: any;
  totalPrice?: any;
  id?: any;
  code?: any;
};

export const initialData: ItemModel = {};

export type QueryResponse = Response<Array<ItemModel>>;
