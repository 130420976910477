import convertCurrency from "_metronic/helpers/convertData/convert-currency";

import Item from "./Item";

const PriceTable = ({ data }: { data?: any }) => {
  return (
    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
      {/*begin::Card header*/}
      <div className="card-header">
        <div className="card-title">
          <h2>Thông tin đơn hàng</h2>
        </div>
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body pt-0">
        <div className="table-responsive">
          {/*begin::Table*/}
          <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
            <thead>
              <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th className="min-w-175px max-w-175px">Dịch vụ</th>
                <th className="min-w-175px text-end">Tình trạng</th>
              </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">
              {data?.renewConditions?.map((item: any, index: number) => (
                <Item key={index} data={item} />
              ))}

              <tr>
                <td colSpan={2} className="fs-3 text-dark text-end text-start">
                  Giá tạm tính:{" "}
                  <span
                    className="text-dark fs-3 fw-bolder text-end"
                    style={{ marginLeft: 10 }}
                  >
                    {convertCurrency(data?.price * (data?.rate / 100))}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="">
                  <span className="fw-bolder fs-3">Ghi chú:</span>{" "}
                  {data?.description || "Không có"}
                </td>
              </tr>
            </tbody>
          </table>
          {/*end::Table*/}
        </div>
      </div>
      {/*end::Card body*/}
    </div>
  );
};

export default PriceTable;
