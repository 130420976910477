/* eslint-disable react-hooks/exhaustive-deps */
import {
  createResponseContext,
  filterObject,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  stringifyRequestQuery,
} from "_metronic/helpers";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

type Props = {
  children: any;
  queryString: string;
  getList: any;
  isGetDetail?: boolean;
};
const QueryResponseProvider = ({
  isGetDetail,
  children,
  queryString,
  getList,
}: Props) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${queryString}-${query}`,
    async () => {
      if (isGetDetail) {
        return await getList();
      }

      return await getList(filterObject(state));
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const QueryResponseContext = createResponseContext<any>(initialQueryResponse);

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData: any = (extraProperty?: string) => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return (
    {
      // data:
      //   response.data || (extraProperty ? response?.data?.[extraProperty] : []),
      data: extraProperty ? response?.[extraProperty as any] : response.data,
      pageTotal: response?.pageTotal,
    } || []
  );
};

const useQueryResponsePagination = () => {
  const { pageTotal } = useListView();

  const defaultPaginationState: PaginationState = {
    ...initialQueryState,
    pageTotal: pageTotal,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
};
