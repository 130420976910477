import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getItem } from "../core/_requests";
import CommentItem from "./CommentItem";

export const CommentsWrapper = () => {
  const { id } = useParams<string>();
  const [data, setData] = useState<any>([]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x?: any) => setData({ ...x }))
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  if (!id) return <></>;

  return (
    <div className="mb-10">
      <CommentItem refresh={() => initPage(id)} data={data} />
      {data?.childrens?.map((child: any, index: string) => (
        <div style={{ marginLeft: 30, marginTop: 20 }} key={index}>
          <CommentItem refresh={() => initPage(id)} data={child} key={index} />
        </div>
      ))}
    </div>
  );
};

export default CommentsWrapper;
