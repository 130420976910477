import { ID, KTCard } from "_metronic/helpers";
import { updateSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { InputDropdown } from "app/components/Form";
import CustomTabs from "app/components/Tabs";
import { BikeServiceEnum } from "constants/enum";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { initialData, ItemModel } from "../core/_models";
import { editItem, getItem } from "../core/_requests";
import Logs from "./Logs";

const EditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<ItemModel>(initialData);

  const submit = async (query?: any) => {
    await editItem(query, id).then(updateSuccess);
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const query = {
          status: values.status,
          logs: values.logs || [],
        };

        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const tabs = [
    {
      id: "general",
      name: "Thông tin chung",
      content: () => (
        <>
          <KTCard className="p-10 mb-7">
            <div className="fv-row mb-7">
              <InputDropdown
                formik={formik}
                label={"Trạng thái đơn hàng"}
                options={
                  Object.keys(BikeServiceEnum)?.map((item: string) => {
                    return {
                      label:
                        BikeServiceEnum[item as keyof typeof BikeServiceEnum],
                      value: item,
                    };
                  }) || []
                }
                name="status"
                classNameLabel="form-label fs-6 fw-bold"
                classNameDropdown="form-select form-select-solid fw-bolder"
                values={formik.values.status}
              />
            </div>

            <div className="text-end">
              <Button
                type="button"
                className="btn btn-primary "
                onClick={() => formik.handleSubmit()}
                // disabled={status === formik?.values?.status}
              >
                Cập nhật
              </Button>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a
                      href="##"
                      className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                    >
                      Thông tin liên hệ
                    </a>
                  </div>

                  <div className="fw-semibold fs-6 mb-4 mt-4 pe-2">
                    <a
                      href="##"
                      className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                    >
                      <i className="ki-duotone ki-profile-circle fs-4 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      Tên khách hàng:
                      <span
                        style={{ display: "inline-block", marginLeft: 6 }}
                        className="fw-bold"
                      >
                        {data?.fullname || "-"}
                      </span>
                    </a>
                  </div>

                  <div className="fw-semibold fs-6 mb-4 pe-2">
                    <a
                      href="##"
                      className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                    >
                      <i className="ki-duotone ki-sms fs-4 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      Email:
                      <span
                        style={{ display: "inline-block", marginLeft: 6 }}
                        className="fw-bold"
                      >
                        {data?.email || "-"}
                      </span>
                    </a>
                  </div>

                  <div className="fw-semibold fs-6 mb-4 pe-2">
                    <a
                      href="##"
                      className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                    >
                      <i className="ki-duotone ki-profile-circle fs-4 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      Nội dung:
                      <span
                        style={{ display: "inline-block", marginLeft: 6 }}
                        className="fw-bold"
                      >
                        {data?.description || "-"}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </KTCard>
        </>
      ),
    },

    {
      id: "logs",
      name: "Logs",
      content: () => (
        <>
          <Logs data={data?.logs} formik={formik} />
        </>
      ),
    },
  ];

  useEffect(() => {
    initialPage(id);
  }, [id]);

  const initialPage = async (id?: ID) => {
    getItem(id)
      .then((data) => {
        setData({ ...data } as ItemModel);
      })
      .catch((e) => {
        console.log(e);
        navigate("/orders");
      });
  };

  return (
    <>
      <CustomTabs
        className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
        items={tabs}
      />
    </>
  );
};

export default EditPage;
