import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadcrumbs: Array<PageLink> = [
  {
    title: "Danh mục sự kiện",
    path: "/event-category",
  },
  {
    isSeparator: true,
  },
];

const EventCategoryPage = ({ isTour }: { isTour: boolean }) => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="create"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Tạo danh mục</PageTitle>
              <EditPage isTour={isTour} />
            </>
          }
        />
        <Route
          path=":id/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Chỉnh sửa</PageTitle>
              <EditPage isTour={isTour} />
            </>
          }
        />
        <Route
          path="/"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>
                Danh sách danh mục
              </PageTitle>
              <ListWrapper isTour={isTour} />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default EventCategoryPage;
