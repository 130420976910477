import { FC } from "react";

type Props = {
  tableProps: any;
  useListView: any;
};

const SelectionHeader: FC<Props> = ({ tableProps, useListView }) => {
  const { isAllSelected, onSelectAll } = useListView();
  return (
    <th {...tableProps.column.getHeaderProps()} className="w-10px pe-2">
      <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
        <input
          className="form-check-input"
          type="checkbox"
          checked={isAllSelected}
          value={isAllSelected}
          onChange={onSelectAll}
        />
      </div>
    </th>
  );
};

export { SelectionHeader };
