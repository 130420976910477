import { KTCard, toAbsoluteUrl } from "_metronic/helpers";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import { alertSuccess } from "_metronic/helpers/toastify";
import { TextArea } from "app/components/Form";
import { useState } from "react";

import { approveComment, denyComment, replyComment } from "../core/_requests";
const DEFAULT_IMAGE = "/media/svg/files/blank-image.svg";

const CommentItem = ({ data, refresh }: { data?: any; refresh: () => any }) => {
  const [isShowReply, setShowReply] = useState(false);
  const [replyContent, setReplyContent] = useState("");

  return (
    <>
      <KTCard className="card card-flush">
        <div className="card-header pt-9">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50px me-5">
              <img
                src={data?.owner?.avatar || toAbsoluteUrl(DEFAULT_IMAGE)}
                className=""
                alt=""
              />
            </div>
            <div className="flex-grow-1">
              <div className="text-gray-800 text-hover-primary fs-4 fw-bold">
                {data?.owner?.fullname}
              </div>
              <span className="text-gray-500 fw-semibold d-block">
                {data?.rating ? (
                  [...Array(5)]?.map((number, index) => {
                    if (index < data?.rating) {
                      return (
                        <i
                          key={index}
                          className="bi bi-star-fill"
                          style={{ color: "yellow" }}
                        ></i>
                      );
                    }

                    return <i className="bi bi-star" key={index}></i>;
                  })
                ) : (
                  <></>
                )}
              </span>
              <span className="text-gray-500 fw-semibold d-block">
                {convertDate(data?.createdAt)}
              </span>
            </div>
          </div>
          {data.ownerModel !== "Admin" && (
            <div className="card-toolbar">
              {data?.status === "waiting" || data?.status === "rejected" ? (
                <div className="m-0">
                  <button
                    type="button"
                    onClick={async () => {
                      await approveComment([data?.id]).then(() => {
                        alertSuccess("Chấp nhận thành công");
                        refresh && refresh();
                      });
                    }}
                    className="btn btn-success btn-sm"
                    style={{ marginRight: 10 }}
                  >
                    Chấp nhận
                  </button>
                </div>
              ) : (
                <></>
              )}

              {data?.status === "waiting" || data?.status === "approved" ? (
                <div className="m-0">
                  <button
                    type="button"
                    onClick={async () => {
                      await denyComment([data?.id]).then(() => {
                        alertSuccess("Từ chối thành công");
                        refresh && refresh();
                      });
                    }}
                    className="btn btn-danger btn-sm"
                    style={{ marginRight: 10 }}
                  >
                    Từ chối
                  </button>
                </div>
              ) : (
                <></>
              )}

              {data?.status === "approved" ? (
                <div className="m-0">
                  <button
                    type="button"
                    className="btn btn-info btn-sm"
                    onClick={() => setShowReply((prev) => !prev)}
                  >
                    Phản hồi
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>

        <div className="card-body">
          <div className="fs-6 fw-normal text-gray-700 mb-5">
            {data?.comment}
          </div>
          {data?.gallery?.length > 0 && (
            <div className="row g-7">
              {data?.gallery?.map((pic: any, index: number) => (
                <img
                  src={pic}
                  alt=""
                  style={{
                    width: 120,
                    height: 120,
                  }}
                  key={index}
                />
              ))}
            </div>
          )}
          {isShowReply ? (
            <div className="d-flex align-items-start">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-50px border me-5">
                  <img src={toAbsoluteUrl(DEFAULT_IMAGE)} className="" alt="" />
                </div>
              </div>
              <div className="flex-grow-1">
                <span
                  className="text-gray-500 fw-semibold d-block"
                  style={{ position: "relative" }}
                >
                  <TextArea
                    cols={120}
                    rows={5}
                    placeholder="Site Description"
                    name="siteDescription"
                    onChange={(e) => setReplyContent(e.target.value)}
                  />

                  <i
                    className="bi bi-send-fill"
                    onClick={async () => {
                      await replyComment({
                        commentId: data.id,
                        comment: replyContent,
                      }).then(() => {
                        refresh && refresh();
                        alertSuccess("Phản hồi thành công");
                      });
                    }}
                    style={{
                      position: "absolute",
                      right: 20,
                      bottom: 20,
                      fontSize: 20,
                      color: "#009ef7",
                      cursor: "pointer",
                    }}
                  ></i>
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </KTCard>
    </>
  );
};

export default CommentItem;
