import { ID, Response } from "_metronic/helpers";

export interface CategoryType {
  alias: string;
  id: string;
  title: string;
}

export type ItemModel = {
  id?: ID;
  owner?: any;
  renewType?: any;
  renewConditions?: any;

  brand?: any;
  status?: string;
  name?: string;
  phone?: string;
  logs?: any;
  address?: string;
  createdAt?: string;
  updatedAt?: string;
};

export const initialData: ItemModel = {};

export type QueryResponse = Response<Array<ItemModel>>;
