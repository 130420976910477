import { Response } from "_metronic/helpers";

export type ItemCampaignModel = {
  discountValue?: string;
  discountType?: string;
  isActive: boolean;
  id: string;
  startDate?: Date | number;
  endDate?: Date | number;
  createdBy?: any;
  updatedBy?: any;
  createdAt?: string;
  updatedAt?: string;
  name?: string;
  platform?: any[];
};

export type ItemDealModel = {
  sku?: string;
  discountValue?: string;
  discountType?: string;
  isActive: boolean;
  id: string;
  startDate?: Date | number;
  endDate?: Date | number;
  createdBy?: any;
  platform?: any[];
  updatedBy?: any;
  createdAt?: string;
  updatedAt?: string;
  name?: string;
};

export const initialData: ItemCampaignModel = {
  isActive: true,
  id: "",
  startDate: Date.now(),
  endDate: Date.now(),

  platform: [
    {
      label: process.env.REACT_APP_PLATFORM,
      value: process.env.REACT_APP_PLATFORM,
    },
  ],
};

export type QueryResponse = Response<Array<ItemCampaignModel>>;
