import { KTIcon } from "_metronic/helpers";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  ActionDeleteCell,
  CreatedByColumn,
  DefaultCell,
  IsActiveCell,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { useNavigate, useParams } from "react-router-dom";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const ActionEditBtn = ({ child }: { child: string }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className={`btn btn-secondary btn-sm d-inline-flex align-items-center`}
      onClick={() => {
        navigate(`/bike-rental/${id}/${child}/edit`);
      }}
    >
      <KTIcon iconName="pencil" />
      Chỉnh sửa
    </button>
  );
};

const COLUMNS_DETAIL: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Code"
        className="min-w-100px"
      />
    ),
    accessor: "code",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].code} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cửa hàng"
        className="min-w-200px"
      />
    ),
    accessor: "store",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.store?.[0].name} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tình trạng"
        className="min-w-100px"
      />
    ),
    accessor: "status",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].status} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    accessor: "isActive",
    Cell: ({ ...props }) => (
      <IsActiveCell value={props.data[props.row.index].isActive} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className="text-end d-flex gap-2 min-w-200px justify-content-center">
        <ActionEditBtn child={props.data[props.row.index].id} />
        <ActionDeleteCell
          data={props.row.original}
          useListView={useListView}
          hideText={true}
        />
      </div>
    ),
  },
];

export { COLUMNS_DETAIL };
