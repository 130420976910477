import clsx from "clsx";
import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

type props = {
  items: Array<item>;
  navClass?: string;
  className?: string;
  id?: string;
  variant?: string;
};

type item = {
  id: string;
  name: string;
  hide?: boolean;
  content: () => any;
};

export const CustomTabs = (props: props) => {
  const { items, className, id, variant = "underline" } = props;
  const [activeItem, setActiveItem] = useState<any>(items[0]?.id || null);
  const [list, setList] = useState<item[]>([]);

  useEffect(() => {
    setList(items.filter((x) => !x.hide));
  }, [items]);

  if (!list || list.length === 0) return <></>;

  return (
    <>
      <Tab.Container
        id={id}
        activeKey={activeItem}
        onSelect={(key) => setActiveItem(key)}
      >
        <Nav variant={variant} className={clsx(className)}>
          {list.map((item) => (
            <Nav.Item key={item.id}>
              <Nav.Link
                eventKey={item.id}
                active={activeItem === item.id}
                className={clsx({ "text-primary": activeItem === item.id })}
              >
                {item.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <Tab.Content className="my-4">
          {list.map((item) => (
            <Tab.Pane key={item.id} eventKey={item.id}>
              {item.content()}
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default CustomTabs;
