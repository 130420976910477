import { ID, toAbsoluteUrl } from "_metronic/helpers";
import CustomTabs from "app/components/Tabs";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { initialData, ItemModel } from "../core/_models";
import { getDetail } from "../core/_requests";
import { Activities } from "./Activities";
import Connects from "./Connects";
import Events from "./Events";
import { Feeds } from "./Feeds";
import Gifts from "./Gifts";
import Groups from "./Groups";
import Orders from "./Orders";
import Points from "./Points";

export const ViewDetail = () => {
  const { id } = useParams<string>();
  const [data, setData] = useState<ItemModel>(initialData);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      initialPage(id);
    }
  }, [id]);

  const initialPage = async (id?: ID) => {
    getDetail(id)
      .then((data?: any) => {
        setData({
          ...data,
        });
      })
      .catch((e) => {
        console.log(e);
        navigate("/users");
      });
  };

  const tabs = [
    {
      id: "feeds",
      name: "Feeds",
      content: () => <Feeds />,
    },
    {
      id: "activities",
      name: "Hoạt động",
      content: () => <Activities />,
    },

    {
      id: "events",
      name: "Sự kiện tham gia",
      content: () => <Events />,
    },

    {
      id: "groups",
      name: "Nhóm đã tham gia",
      content: () => <Groups />,
    },

    {
      id: "orders",
      name: "Đơn hàng đã đặt",
      content: () => <Orders />,
    },

    {
      id: "points",
      name: "Điểm số",
      content: () => <Points />,
    },

    {
      id: "gifts",
      name: "Quà tặng",
      content: () => <Gifts />,
    },

    {
      id: "connects",
      name: "Tài khoản kết nối",
      content: () => <Connects data={data?.socials} />,
    },
  ];

  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-5">
          <div className="d-flex flex-wrap flex-sm-nowrap">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img
                  src={
                    data.avatar ||
                    toAbsoluteUrl("/media/svg/files/blank-image.svg")
                  }
                  className="border rounded"
                  alt="avatar"
                />
                <div
                  className={clsx(
                    "position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-body h-20px w-20px",
                    {
                      "bg-success": data.isActive,
                      "bg-secondary": !data.isActive,
                    }
                  )}
                ></div>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a
                      href="##"
                      className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                    >
                      {data.fullname || "-"}
                    </a>
                    <a href="##">
                      {/* <i className="ki-duotone ki-verify fs-1 text-primary">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i> */}
                      <div
                        className={clsx({
                          "badge badge-light-success me-auto": data.isActive,
                          "badge badge-light-danger me-auto": !data.isActive,
                        })}
                      >
                        {data.isActive ? "Đang hoạt động" : "Ngưng hoạt động"}
                      </div>
                    </a>
                  </div>
                  <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                    <a
                      href="##"
                      className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2"
                    >
                      <i className="ki-duotone ki-profile-circle fs-4 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      {data.role || "-"}
                    </a>
                    <span className="d-flex align-items-center text-dark text-hover-primary me-5 mb-2">
                      <i className="bi bi-telephone-fill me-1"></i>
                      {data.phone || "-"}
                      {data.isVerifiedPhone && (
                        <i className="ki-duotone ki-verify fs-1 text-primary">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      )}
                    </span>
                    <a
                      href="##"
                      className="d-flex align-items-center text-dark text-hover-primary mb-2"
                    >
                      <i className="ki-duotone ki-sms fs-4 me-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      {data.email || "-"}
                      {data.isVerifiedEmail && (
                        <i className="ki-duotone ki-verify fs-1 text-primary">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-lg-row-fluid mb-10">
        <CustomTabs
          className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
          items={tabs}
        />
      </div>
    </>
  );
};

export default ViewDetail;
