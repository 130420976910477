import { KTIcon } from "_metronic/helpers";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import React, { ReactNode, useEffect, useState } from "react";

import InputDropdown from "../Dropdown";
import TextArea from "../TextArea";
import Modal from "./Modal";

interface Props {
  label?: string;
  // placeholder?: string;
  name?: any;
  initItem?: any;
  values?: any;
  formik?: any;
  headerItem?: string[];
  inputItem?: any[];
  isModal?: boolean;
  deletable?: boolean;
  customFieldLabel?: ReactNode;
  addable?: boolean;
  [restProp: string]: any;
}

const Repeater = (props: Props) => {
  const {
    name,
    formik,
    values,
    label,
    initItem,
    headerItem,
    inputItem,
    isModal,
    onAddNew,
    deletable = true,
    maxLength,
    onDelete,
    // addable = true,
    customFieldLabel,
  } = props;
  const [list, setList] = useState<Array<any>>(
    values?.length > 0 ? values : initItem
  );

  const [dataModal, setDataModal] = useState<any>(null);

  const convertValueInput = (item?: any, type?: string, name?: string) => {
    if (name) {
      switch (type) {
        case "datetime":
          return convertDate(item[name]);

        default:
          return item?.[name];
      }
    } else {
      return item;
    }
  };

  const renderInput = (input: any, item: any, idxList: number) => {
    switch (input.type) {
      case "dropdown":
        return (
          <InputDropdown
            style={{
              marginTop: -20,
            }}
            name={input?.name}
            onSearch={input?.onSearch}
            options={input.options || []}
            classNameLabel="form-label fs-6 fw-bold"
            classNameDropdown="form-select form-select-solid fw-bolder"
            disabled={input?.disabled}
            onChange={(e) => {
              setList((prev) => {
                if (input?.name) {
                  prev[idxList] = {
                    ...prev[idxList],
                    [input?.name]: e.value,
                  };
                } else {
                  prev[idxList] = e.value;
                }

                return [...prev];
              });

              formik.setFieldValue(name, list);
            }}
            values={item?.[input?.name] || []}
          />
        );

      case "textarea":
        return (
          <TextArea
            // classNameLabel="mb-6"
            maxLength={input?.max}
            required={input?.required}
            label={input?.label}
            placeholder={input?.label}
            name={input?.name}
            onChange={(e) => {
              setList((prev) => {
                if (input?.name) {
                  prev[idxList] = {
                    ...prev[idxList],
                    [input?.name]: e.target.value,
                  };
                } else {
                  prev[idxList] = e.target.value;
                }

                return [...prev];
              });
              formik.setFieldValue(name, list);
            }}
            values={item?.[input?.name]}
          />
        );

      default:
        return (
          <input
            disabled={!!isModal || input.disabled}
            type={input?.type}
            className="form-control form-control-solid"
            value={convertValueInput(item, input.type, input.name)}
            onChange={(e) => {
              setList((prev) => {
                if (input?.name) {
                  prev[idxList] = {
                    ...prev[idxList],
                    [input?.name]: e.target.value,
                  };
                } else {
                  prev[idxList] = e.target.value;
                }

                return [...prev];
              });
              formik.setFieldValue(name, list);
            }}
            min={input?.min}
          />
        );
    }
  };

  useEffect(() => {
    if (values?.length > 0) {
      setList(values);
    }
  }, [values]);

  return (
    <div className="d-flex flex-column mb-15 fv-row">
      {customFieldLabel ? (
        customFieldLabel
      ) : (
        <div className="fs-5 fw-bold form-label mb-3">{label}</div>
      )}

      <div className="">
        {/*begin::Table*/}
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="">
            <table className="table align-middle table-row-dashed fw-semibold fs-6 gy-5 dataTable no-footer">
              {/*begin::Table head*/}
              <thead>
                <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                  {headerItem?.map((item, index) => (
                    <th
                      className="pt-0 sorting_disabled"
                      rowSpan={1}
                      colSpan={1}
                      key={index}
                    >
                      {item}
                    </th>
                  ))}

                  <th
                    className="pt-0 text-end sorting_disabled"
                    rowSpan={1}
                    colSpan={1}
                    style={{ width: "80.7px" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody>
                {list?.length > 0 ? (
                  list?.map((item, idxList) => {
                    return (
                      <tr className="odd" key={idxList}>
                        {inputItem?.map((input, index) => {
                          return (
                            <React.Fragment key={index}>
                              {input.displayOutside !== false ? (
                                <td style={input?.style}>
                                  {renderInput(input, item, idxList)}
                                </td>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          );
                        })}

                        <td className="text-end d-flex gap-3">
                          {isModal ? (
                            <div
                              className="hide-text btn btn-secondary btn-xl d-inline-flex align-items-center"
                              onClick={() => {
                                setDataModal({ ...item, index: idxList });
                              }}
                            >
                              <KTIcon iconName="pencil" />
                            </div>
                          ) : (
                            <></>
                          )}

                          {deletable ? (
                            <div
                              className="hide-text btn btn-danger btn-xl d-inline-flex align-items-center"
                              onClick={() => {
                                onDelete && onDelete(item.id);
                                setList((prev) => {
                                  prev.splice(idxList, 1);
                                  return [...prev];
                                });

                                formik.setFieldValue(name, list);
                              }}
                            >
                              <KTIcon iconName="basket" />
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>

              {/*end::Table body*/}
            </table>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" />
          </div>
        </div>
        {/*end:Table*/}
      </div>

      {(!maxLength || list?.length < maxLength) && (
        <button
          type="button"
          className="btn btn-light-primary me-auto"
          onClick={() => {
            if (isModal) {
              setDataModal({
                ...initItem[0],
                index: list.length,
              });
            } else {
              setList((prev) => {
                return [...prev, initItem[0]];
              });
            }
          }}
        >
          Thêm
        </button>
      )}
      {/*end::Add custom field*/}

      {dataModal ? (
        <Modal
          formik={formik}
          data={dataModal}
          setData={setDataModal}
          setList={setList}
          onAddNew={onAddNew}
          inputItem={inputItem}
          onChange={() => {
            formik.setFieldValue(name, list);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Repeater;
