import { MenuComponent } from "_metronic/assets/ts/components";
import { initialQueryState } from "_metronic/helpers";
import Button from "app/components/Button";
import { Input, InputDropdown } from "app/components/Form";
import { FilterList } from "app/components/ToolbarTable/FilterList";
import { activeOption } from "constants/active-option";
import { PlatformEnum } from "constants/enum";
import { FC, useEffect, useState } from "react";

type Props = {
  filterContent?: any;
  useQueryRequest: any;
  platformFilter?: boolean;
  statusFilterOption?: any;
  useQueryResponse: any;
};

const HeaderFilter: FC<Props> = ({
  filterContent,
  useQueryRequest,
  useQueryResponse,
  platformFilter,
  statusFilterOption,
}) => {
  const { updateState, state } = useQueryRequest();
  const [filterData, setFilter] = useState({ ...state });
  const { isLoading } = useQueryResponse();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const resetData = () => {
    updateState({ ...initialQueryState });
    setFilter(initialQueryState);
  };

  const handleChange = (key: string, value: any) => {
    setFilter({
      ...filterData,
      [key]: value,
    });
  };

  const option = statusFilterOption
    ? Object.keys(statusFilterOption).map((item) => ({
        value: item,
        label: statusFilterOption[item],
      }))
    : activeOption;

  return (
    <FilterList>
      <div
        className="menu menu-sub menu-sub-dropdown w-md-400px"
        data-kt-menu="true"
      >
        <div className="px-7 py-5">
          <div className="fs-5 mb-2 text-dark fw-bolder">Lọc theo</div>
          <div className="separator border-gray-200 mb-4"></div>
          <div className="mb-10">
            <InputDropdown
              label="Trạng Thái"
              classNameLabel="form-label fs-6 fw-bold"
              classNameDropdown="form-select form-select-solid fw-bolder"
              options={option}
              closeMenuOnSelect={false}
              values={
                statusFilterOption ? filterData.status : filterData.isActive
              }
              onChange={(e) => {
                handleChange(
                  statusFilterOption ? "status" : "isActive",
                  e.value
                );
              }}
            />
          </div>
          {platformFilter ? (
            <div className="mb-10">
              <InputDropdown
                label="Nền tảng"
                classNameLabel="form-label fs-6 fw-bold"
                classNameDropdown="form-select form-select-solid fw-bolder"
                options={Object.keys(PlatformEnum).map((item) => ({
                  label: PlatformEnum[item as keyof typeof PlatformEnum],
                  value: item,
                }))}
                closeMenuOnSelect={false}
                values={filterData.platform || PlatformEnum.all}
                onChange={(e) => {
                  handleChange("platform", e.value);
                }}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="mb-10">
            <div className="d-flex align-items-end justify-content-between">
              <Input
                label="Theo ngày"
                classNameLabel="fs-6 fw-bold"
                classNameInput="form-control w-bolder w-30 date"
                type="date"
                values={filterData.fromDate}
                onChange={(e) => handleChange("fromDate", e.target.value)}
              />
              <Input
                classNameLabel="fs-6 fw-bold"
                classNameInput="form-control w-bolder w-30 date"
                type="date"
                values={filterData.toDate}
                onChange={(e) => handleChange("toDate", e.target.value)}
              />
            </div>
          </div>
          {filterContent}
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={resetData}
              disabled={isLoading}
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="reset"
            >
              Đặt lại
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter"
              onClick={() => updateState(filterData)}
            >
              Lọc
            </Button>
          </div>
        </div>
      </div>
    </FilterList>
  );
};

export { HeaderFilter };
