import { ID, Response } from "_metronic/helpers";

export type ItemModel = {
  id?: ID;
  title?: string;
  image?: string;
  isActive?: boolean;

  strava?: string;
  point?: number;

  alias?: string;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
};

export const initialData = {
  id: "",
  title: "",
  image: "",
  isActive: true,
  alias: "",
};

export type QueryResponse = Response<Array<ItemModel>>;
