import { QUERIES } from "_metronic/helpers";
import {
  ListViewProvider,
  useListView,
} from "_metronic/helpers/provider/ListViewProvider";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "_metronic/helpers/provider/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "_metronic/helpers/provider/QueryResponseProvider";
import { PageLink, PageTitle } from "_metronic/layout/core";
import ListManagement from "app/components/Page";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandle } from "services/error";

import { COLUMNS } from "../core/_columns";
import {
  bulkDelete,
  bulkSync,
  deleteItem,
  getItem,
  getList,
} from "../core/_requests";

type Props = {
  isQueryChild?: boolean;
};

const List = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [childBreadCrumb, setChildBreadCrumb] = useState<Array<PageLink>>();

  useEffect(() => {
    // Initialize dynamic breadcrumb if is child page
    if (id) {
      // breadcrumbs default
      const breadcrumbsDefault: Array<PageLink> = [
        {
          title: "Tours",
          path: "/tour",
        },
      ];

      // dynamic breadcrumb related to parent title
      getItem(id as string).then((result) => {
        breadcrumbsDefault?.push(
          { isSeparator: true },
          {
            title: result?.title,
            path: `/tour/${result.id}/edit`,
          },
          { isSeparator: true }
        );

        setChildBreadCrumb(breadcrumbsDefault);
      });
    }
  }, [id]);

  return (
    <>
      {id ? (
        <PageTitle breadcrumbs={childBreadCrumb}>Danh sách tour con</PageTitle>
      ) : null}

      <ListManagement
        columns={COLUMNS}
        actionAddNew={() => {
          if (id) {
            navigate(`/tour/${id}/create-child`);
          } else {
            navigate("/tour/create");
          }
        }}
        useQueryResponseLoading={useQueryResponseLoading}
        queryString={[QUERIES.TOUR_LIST + id]}
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryResponseData={useQueryResponseData}
        useListView={useListView}
        useQueryRequest={useQueryRequest}
        useQueryResponse={useQueryResponse}
        bulkDelete={bulkDelete}
        bulkSync={bulkSync}
        // deleteItem={deleteItem}
        deleteItem={(id) => {
          getList({ parent: id }).then((x) => {
            if (x?.data?.length > 0) {
              errorHandle({
                data: {
                  error: "Tồn tại tour con",
                  message: "Cần phải xóa hết tour con mới được xóa tour cha",
                },
              });
            } else {
              deleteItem(id);
            }
          });
        }}
      />
    </>
  );
};
export const ListWrapper: FC<Props> = () => {
  const { id } = useParams();

  return (
    <QueryRequestProvider>
      <QueryResponseProvider
        getList={async (state: any) => await getList({ ...state, parent: id })}
        queryString={QUERIES.TOUR_LIST}
      >
        <ListViewProvider>
          <List />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};
