import { PageLink, PageTitle } from "_metronic/layout/core";
import CommentsWrapper from "app/modules/comment/components/CommentWrapper";
import { Outlet, Route, Routes } from "react-router-dom";

import { ListWrapper } from "../../../modules/comment/components/List";
import { getList } from "./core/_requests";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Đánh giá sự kiện",
    path: "/event-comment",
  },
  {
    isSeparator: true,
  },
];

const EventCommentPage = () => {
  return (
    <Routes>
      <Route
        path=":id/detail"
        element={
          <>
            <PageTitle breadcrumbs={breadbrumbs}>Bình luận</PageTitle>
            <CommentsWrapper />
          </>
        }
      />
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách đánh giá sự kiện
              </PageTitle>
              <ListWrapper getList={getList} />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default EventCommentPage;
