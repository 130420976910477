import { ReactElement, ReactNode } from "react";

interface propsType {
  label?: string | ReactElement;
  icon?: ReactElement;
  type?: "submit" | "reset" | "button" | undefined;
  style?: React.CSSProperties;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: any;
  [restProps: string]: any;
}

export const Button = (props: propsType) => {
  const { type, className, disabled, style, children, ...restProps } = props;
  const { onClick } = props;

  return (
    <button
      {...restProps}
      type={type}
      onClick={onClick}
      className={className}
      disabled={disabled}
      style={style}
      // disabled={formik.isSubmitting || isUserLoading}
    >
      {children}
    </button>
  );
};

export default Button;
