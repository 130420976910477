import "react-datetime/css/react-datetime.css";

// import { I18nProvider } from "_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "_metronic/layout/core";
import { MasterInit } from "_metronic/layout/MasterInit";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { AuthInit } from "./pages/account-management/auth";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <AuthInit>
          <Outlet />
          <MasterInit />
        </AuthInit>
      </LayoutProvider>
    </Suspense>
  );
};

export { App };
