import { ID } from "_metronic/helpers";
import React, { FC, useEffect, useMemo, useState } from "react";

type Props = {
  id: ID;
  useListView: any;
};

const SelectionCell: FC<Props> = React.memo(({ id, useListView }) => {
  const { selected, onSelect } = useListView();

  const [shiftHeld, setShiftHeld] = useState(false);
  const isSelected = useMemo(() => selected.includes(id), [id, selected]);

  function downHandler({ key }: any) {
    if (key === "Shift") {
      setShiftHeld(true);
    }
  }

  function upHandler({ key }: any) {
    if (key === "Shift") {
      setShiftHeld(false);
    }
  }

  useEffect(() => {
    window?.addEventListener("keydown", downHandler);
    window?.addEventListener("keyup", upHandler);

    return () => {
      window?.removeEventListener("keydown", downHandler);
      window?.removeEventListener("keyup", upHandler);
    };
  }, []);

  return (
    <div className="form-check form-check-custom form-check-solid">
      <input
        className="form-check-input"
        type="checkbox"
        data-kt-check={isSelected}
        data-kt-check-target="#kt_table_list .form-check-input"
        checked={isSelected}
        onChange={() => onSelect(id, shiftHeld)}
      />
    </div>
  );
});

export { SelectionCell };
