import { KTCard, KTCardBody } from "_metronic/helpers";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, editSuccess } from "_metronic/helpers/toastify";
import { OptionsType } from "_metronic/helpers/Type";
import { PageTitle } from "_metronic/layout/core";
import { FormField } from "app/components/Form/Field";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { CONTENT_VALIDATE_RULE } from "constants/validate-rule";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { CategoryList, initialData } from "../core/_models";
import { createItem, editItem, getItem, getList } from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>(initialData);
  const [optionCategories, setOptionCategories] = useState([]);
  const { isLoading } = useQueryResponse();

  useEffect(() => {
    initPage();
  }, [id]);

  const initPage = async () => {
    getList({ limit: 10, page: 1 }).then((results) => {
      if (results?.data) {
        const formatOption = results.data?.map((item: CategoryList) => ({
          label: item.title,
          value: item.id,
        }));

        const options = formatOption.filter(
          (item: OptionsType) => item.value !== data.id
        );

        setOptionCategories(options);
      }
    });

    if (id) {
      getItem(id)
        .then((x: any) => setData({ ...x }))
        .catch((e) => {
          navigate("/category");
        });
    }
  };

  const validation = Yup.object().shape({
    ...CONTENT_VALIDATE_RULE,
  });

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        if (id) {
          await editItem(values, id).then((data) => setData(data));
          editSuccess();
          setTimeout(() => window.location.reload(), 1000);
        } else {
          await createItem(values);
          createSuccess();
          navigate("/category");
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "title",
      required: true,
      type: "text",
    },
    {
      label: "Alias",
      name: "alias",
      required: true,
      type: "alias",
    },
    {
      label: "Danh mục cha",
      name: "parent",
      required: false,
      type: "dropdown",
      options: optionCategories,
    },
  ];

  return (
    <>
      <PageTitle>{`${data.title || "Thêm mới"}`}</PageTitle>
      <form className="form" noValidate onSubmit={formik?.handleSubmit}>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="mb-7">
              <KTCardBody>
                {FIELDS.map((data) => {
                  const field = { ...data, formik: formik };
                  return (
                    <div key={data.name} className="fv-row mb-7">
                      {FormField(field)}
                    </div>
                  );
                })}
              </KTCardBody>
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              onSubmit={formik.handleSubmit}
              isEdit={!!id}
              isShowActive={true}
            >
              <ImageUploadGlobal
                name="image"
                formik={formik}
                className="mx-auto w-100 border mb-10"
                path="/blog-category"
              />
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
