import { KTIcon } from "_metronic/helpers";
import Button from "app/components/Button";
import { FC } from "react";

import { HeaderFilter } from "./HeaderFilter";
import { HeaderSort } from "./HeaderSort";

type Props = {
  useQueryRequest: any;
  useQueryResponse: any;
  actionAddNew: () => void;
  sortContent?: any;
  filterContent?: any;
  platformFilter?: boolean;
  isCreate?: boolean;
  statusFilterOption?: any;
};

const HeaderToolbar: FC<Props> = ({
  actionAddNew,
  isCreate = true,
  ...props
}) => {
  return (
    <div className="d-flex justify-content-end">
      <div className="sort">
        <HeaderSort {...props} />
      </div>
      <div className="filter">
        <HeaderFilter {...props} />
      </div>
      {isCreate && (
        <Button
          className="btn btn-primary"
          onClick={actionAddNew}
          type="button"
        >
          <KTIcon iconName="plus" className="fs-2" />
          Thêm mới
        </Button>
      )}
    </div>
  );
};

export { HeaderToolbar };
