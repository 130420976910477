import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import { EditPage } from "./components/Edit";
import ListWrapper from "./components/List";

const Breadcrumbs: Array<PageLink> = [
  {
    title: "Danh sách bài viết",
    path: "/post",
  },
  {
    isSeparator: true,
  },
];

const PostsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>
                Danh sách bài viết
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="create"
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Thêm mới bài viết</PageTitle>
              <EditPage />
            </>
          }
        />
        <Route
          path=":id/edit"
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>
                Chỉnh sửa bài viết
              </PageTitle>
              <EditPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default PostsPage;
