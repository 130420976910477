import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import ListWrapper from "./components/List";

const Breadcrumbs: Array<PageLink> = [
  {
    title: "Danh sách thẻ",
    path: "/tag",
  },
  {
    isSeparator: true,
  },
];

const ProductTagPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sách thẻ</PageTitle>
              <ListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ProductTagPage;
