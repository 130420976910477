import { FC } from "react";

type Props = {
  value?: boolean;
};

const IsActiveCell: FC<Props> = ({ value }) => {
  if (value) {
    return <span className="badge badge-light-success">Kích hoạt</span>;
  } else {
    return <span className="badge badge-light-danger">Ngừng kích hoạt</span>;
  }
};

export { IsActiveCell };
