import { autoTransformSlug } from "_metronic/helpers/formatData";
import Button from "app/components/Button";
import ModalCustom from "app/components/ModalCustom";
import { EventTripPlans } from "app/pages/event-management/tours/core/_models";
import clsx from "clsx";
import { FC } from "react";

import { Input } from "../Input/index";
import { Slug } from "../Slug";
import { Switcher } from "../Switcher";

type Props = {
  formik?: any;
  data?: any;
  setList?: React.Dispatch<React.SetStateAction<EventTripPlans[]>>;
  setData?: React.Dispatch<
    React.SetStateAction<
      | (EventTripPlans & {
          id: number;
        })
      | null
    >
  >;
  onAddNew?: any;
  onChange?: () => void;
  inputItem?: Array<any>;
};

const Modal: FC<Props> = ({
  onAddNew,
  formik,
  data,
  setData,
  setList,
  onChange,
  inputItem,
}) => {
  return (
    <ModalCustom
      labelHeader={`${!true ? "Tạo mới" : "Chỉnh sửa"}`}
      actionHeader={() => {
        setData && setData(null);
      }}
      actionCancel={() => {
        setData && setData(null);
      }}
      labelCancel="Đóng"
      labelSubmit="Xác nhận"
    >
      {inputItem?.map((item, index) => {
        switch (item.type) {
          case "switcher":
            return (
              <div className="fv-row mb-7" key={index}>
                <Switcher
                  label={item.label}
                  name="include"
                  direction="horizontal"
                  onChange={(e) => {
                    setData &&
                      setData((prev?: any) => {
                        if (data) {
                          prev = {
                            ...prev,
                            [item?.name]: e.target.checked,
                          };
                        }

                        return {
                          ...prev,
                        };
                      });
                  }}
                  value={data?.[item.name]}
                />
              </div>
            );

          case "alias":
            return (
              <div className="fv-row mb-7" key={index}>
                <Slug
                  bindingValue={
                    item?.bindingField ? data?.[item?.bindingField] : data?.name
                  }
                  value={data?.[item.name]}
                  onChange={(value) => {
                    setData &&
                      setData((prev?: any) => {
                        if (data) {
                          prev = {
                            ...prev,
                            [item?.name]: autoTransformSlug(value),
                          };
                        }

                        return {
                          ...prev,
                        };
                      });
                  }}
                />
              </div>
            );

          default:
            return (
              <div className="fv-row mb-7" key={index}>
                <Input
                  label={item.label}
                  placeholder={item.label}
                  name={item.name}
                  type={item.type}
                  classNameInput="form-control form-control-solid mb-3 mb-lg-0"
                  classNameLabel={clsx(
                    "fw-bold fs-6 mb-2 ",
                    item?.required && "required"
                  )}
                  onChange={(e) => {
                    setData &&
                      setData((prev?: any) => {
                        if (data) {
                          prev = {
                            ...prev,
                            [item?.name]: e.target.value,
                          };
                        }

                        return {
                          ...prev,
                        };
                      });
                  }}
                  values={data?.[item.name]}
                ></Input>
              </div>
            );
        }
      })}

      {false && (
        <div className="fv-plugins-message-container mt-6">
          <div className="fv-help-block">
            <span role="alert">Có lỗi xảy ra</span>
          </div>
        </div>
      )}
      <div className="text-center pt-10">
        <Button
          type="button"
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
          onClick={() => {
            setData && setData(null);
          }}
        >
          Đóng
        </Button>

        <Button
          type="button"
          onClick={() => {
            const { index, ...query } = data;
            onAddNew && onAddNew(query, data?.id);
            setList &&
              setList((prev) => {
                if (data) {
                  prev[data?.index] = data;
                }

                return [...prev];
              });

            setData && setData(null);

            onChange && onChange();
          }}
          className="btn btn-primary"
          data-kt-users-modal-action="submit"
          //   disabled={
          //     isLoading ||
          //     formik?.isSubmitting ||
          //     !formik?.isValid ||
          //     !formik?.touched
          //   }
        >
          Xác nhận
        </Button>
      </div>
    </ModalCustom>
  );
};

export default Modal;
