import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Đặt dịch vụ sửa xe",
    path: "/bike-fixing",
  },
  {
    isSeparator: true,
  },
];

const BikeFixingPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách đặt sửa xe
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="/:id/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Chi tiết đặt sửa xe
              </PageTitle>
              <EditPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default BikeFixingPage;
