export type ConfigTypeOrderPoint = "order-point";

export interface DisableFormPoint {
  value: boolean;
}

export interface OptionsFormPoint {
  value: number;
  label: string;
}

export interface FormDataConfigSettingPoint {
  label: string;
  value: number;
  isDisabled: boolean;
}

export const initialFormDataConfigSettingPoint = {
  label: "",
  value: 0,
  isDisabled: false,
};
