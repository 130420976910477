import { KTCard, KTCardBody } from "_metronic/helpers";
import convertCurrency from "_metronic/helpers/convertData/convert-currency";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getDetailByAction } from "../core/_requests";

export const Points = () => {
  const { id } = useParams<string>();
  const [points, setPoints] = useState<any>();

  useEffect(() => {
    getDetailByAction("points", id, { limit: 1000 })
      .then((x) => setPoints(x))
      .catch((x) => console.log(x));
  }, [id]);

  return (
    <KTCard>
      <div className="card-header">
        <div className="card-title">
          <h4 className="h4">
            Tổng điểm số đã có:{" "}
            <span className="mx-1 text-primary">{points?.total}</span>
          </h4>
        </div>
      </div>
      <KTCardBody>
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
              <tr>
                <th className="min-w-200px">Số điểm</th>
                <th className="min-w-100px">Hoạt động</th>
                <th className="min-w-100px">Trạng thái</th>
                <th className="min-w-100px">Ngày đặt</th>
                <th className="min-w-100px">Ngày cập nhật</th>
              </tr>
            </thead>
            <tbody className="fw-6 fw-semibold text-gray-600">
              {points?.data?.map((item: any, index: number) => (
                <tr className="row-member" key={index}>
                  <td>
                    <p className="fw-bolder">{item?.point}</p>
                  </td>
                  <td>
                    <p className="fw-bolder">{item?.activity?.name || "-"}</p>
                  </td>
                  <td>
                    <p className="fw-bolder">{convertCurrency(item?.status)}</p>
                  </td>
                  <td>
                    <p className="fw-bolder">
                      {item?.createdAt ? convertDate(item?.createdAt) : "-"}
                    </p>
                  </td>
                  <td>
                    <p className="fw-bolder">
                      {item?.updatedAt ? convertDate(item?.updatedAt) : "-"}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default Points;
