import { ID } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, editSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { Input, Switcher } from "app/components/Form";
import InputDropdown from "app/components/Form/Dropdown";
import ModalCustom from "app/components/ModalCustom";
import { TEXT_REQUIRED } from "constants/text-error";
import { useFormik } from "formik";
import { FC, SetStateAction, useEffect, useState } from "react";
import * as Yup from "yup";

import { useAuth } from "../../auth";
import { initialData } from "../core/_models";
import { createItem, editItem, getRoleList } from "../core/_requests";

type Props = {
  data: SetStateAction<ID>;
};

const VALIDATE_RULE = {
  email: Yup.string()
    .email(TEXT_REQUIRED.VALIDATE_EMAIL)
    .min(3, TEXT_REQUIRED.MAX_EMAIL)
    .max(50, TEXT_REQUIRED.MAX_EMAIL)
    .required(TEXT_REQUIRED.REQUIRED_EMAIL),
  fullname: Yup.string()
    .min(3, TEXT_REQUIRED.MIN_NAME)
    .max(50, TEXT_REQUIRED.MAX_NAME)
    .required(TEXT_REQUIRED.REQUIRED_NAME),
  isActive: Yup.string().required(TEXT_REQUIRED.TEXT_REQUIRED),
  role: Yup.string().required(TEXT_REQUIRED.TEXT_REQUIRED),
};

export const editUserSchema = Yup.object().shape({ ...VALIDATE_RULE });

export const createUserSchema = Yup.object().shape({
  ...VALIDATE_RULE,
  password: Yup.string().required(TEXT_REQUIRED.TEXT_REQUIRED),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password")],
    TEXT_REQUIRED.VALIDATE_PASSWORD_CONFIRM
  ),
});

const ItemModal: FC<Props> = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    document.body.classList.add("modal-open");
    getRoleList({ noPaginate: true }).then((data) => setRoles(mapValue(data)));
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const { refetch, isLoading } = useQueryResponse();
  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const [roles, setRoles] = useState<any>([]);

  const [data] = useState<any>(itemIdForUpdate ? itemIdForUpdate : initialData);

  const formik = useFormik({
    initialValues: { ...data, role: data?.role?.id || data?.role },
    validationSchema: itemIdForUpdate ? editUserSchema : createUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const query = {
        ...values,
        isActive: !!values.isActive,
      };
      setSubmitting(true);
      try {
        if (!values.id) {
          await createItem(query);
          createSuccess();
        } else {
          if (values.password) {
            await editItem(
              { ...query, passwordConfirmation: values.password },
              values.id
            );
          } else {
            await editItem(query, values.id);
          }
          editSuccess();
        }
        refetch();
        setItemIdForUpdate(undefined);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <ModalCustom
        labelHeader={`${!itemIdForUpdate ? "Tạo mới" : "Chỉnh sửa"}`}
        actionHeader={setItemIdForUpdate}
        actionCancel={() => setItemIdForUpdate(undefined)}
        formik={formik}
        labelCancel="Đóng"
        labelSubmit="Xác nhận"
      >
        <div className="fv-row mb-7">
          <Input
            formik={formik}
            label="Tên"
            placeholder="Tên"
            name="fullname"
            required
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.fullname}
          />
        </div>
        <div className="fv-row mb-7">
          <Input
            formik={formik}
            label="Email"
            placeholder="Email"
            name="email"
            type="email"
            required
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.email}
          />
        </div>
        <div className="fv-row mb-7">
          <Switcher
            label="Trạng thái"
            name="isActive"
            direction="horizontal"
            onChange={formik.handleChange}
            value={formik.values.isActive}
          />
        </div>
        <div className="fv-row mb-7">
          <InputDropdown
            formik={formik}
            label="Vai trò"
            placeholder="Vai trò"
            name="role"
            options={roles}
            required
            classNameLabel="form-label fs-6 fw-bold"
            classNameDropdown="form-select form-select-solid fw-bolder"
            disabled={formik.isSubmitting}
            values={formik.values.role}
          />
        </div>
        {currentUser?.role === "superadmin" && itemIdForUpdate ? (
          <div className="fv-row mb-7">
            <Input
              formik={formik}
              label="Mật khẩu"
              placeholder="Mật khẩu"
              name="password"
              type="password"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              values={formik.values.password}
            />
          </div>
        ) : (
          <></>
        )}
        {!itemIdForUpdate && (
          <>
            <div className="fv-row mb-7">
              <Input
                formik={formik}
                label="Mật khẩu"
                placeholder="Mật khẩu"
                name="password"
                type="password"
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={formik.values.password}
              />
            </div>
            <div className="fv-row mb-7">
              <Input
                formik={formik}
                label="Nhập lại Mật khẩu"
                placeholder="Nhập lại Mật khẩu"
                name="passwordConfirmation"
                type="password"
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={formik.values.passwordConfirmation}
              />
            </div>
          </>
        )}
        <div className="text-center pt-3">
          <Button
            type="button"
            className="btn btn-light me-3"
            onClick={() => setItemIdForUpdate(undefined)}
          >
            Đóng
          </Button>

          <Button
            type="submit"
            className="btn btn-primary"
            disabled={
              formik?.isSubmitting ||
              !formik?.isValid ||
              !formik?.touched ||
              isLoading
            }
          >
            Xác nhận
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export default ItemModal;
