import { ID, KTCard } from "_metronic/helpers";
import { formatData } from "_metronic/helpers/formatData";
import { alertSuccess, deleteSuccess } from "_metronic/helpers/toastify";
import { useEffect, useMemo, useState } from "react";

import DeleteConfirmModal from "../Modal/DeleteModal";
import Table from "../Table";
import { ListHeader } from "../Table/header/ListHeader";

type Props = {
  useListView: any;
  useQueryResponse: () => any;
  actionAddNew?: () => any;
  useQueryRequest: () => any;
  queryString: Array<string>;
  useQueryResponseLoading: () => any;
  useQueryResponseData: (extraProps?: string) => any;
  statusFilterOption?: any;
  deletable?: boolean;
  useQueryResponsePagination?: () => any;
  columns: ReadonlyArray<any>;
  bulkDelete?: (ids: Array<ID>) => any;
  bulkSync?: (ids: Array<ID>) => any;
  bulkApprove?: (ids: Array<ID>) => any;
  bulkReject?: (ids: Array<ID>) => any;
  deleteItem?: (id: string) => any;
  searchPlaceholder?: string;
  actionClickItem?: (data?: any) => void;
  isCreate?: boolean;
  listProperty?: string | number;
  isSearch?: boolean;
  platformFilter?: boolean;
};

const ListManagement = (props: Props) => {
  const {
    actionAddNew,
    useListView,
    platformFilter,
    listProperty,
    useQueryResponse,
    useQueryRequest,
    queryString,
    useQueryResponseData,
    statusFilterOption,
    useQueryResponseLoading,
    useQueryResponsePagination,
    columns,
    bulkApprove,
    bulkReject,
    deletable = true,
    bulkSync,
    bulkDelete,
    deleteItem,
    actionClickItem,
    isCreate = true,
    searchPlaceholder = "Nhập từ khoá để tìm khiếm",
    isSearch = true,
  } = props;

  const { itemDelete, setItemDelete, selected, clearSelected } = useListView();
  const [isDelete, setIsDelete] = useState(false);
  const results = useQueryResponseData(listProperty as any);
  const isLoading = useQueryResponseLoading();
  const { setPageTotal } = useListView();

  const data = useMemo(
    () => (results.data ? formatData(results.data) : []),
    [results]
  );

  useEffect(() => {
    setPageTotal(results.pageTotal);
  }, [results]);

  const { refetch } = useQueryResponse();

  const handleDelete = async () => {
    const id = itemDelete?.id;
    try {
      await deleteItem?.(id);
      refetch();
      setItemDelete(undefined);
      deleteSuccess();
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleSelectedBulkAction = async (
    actions?: (ids: Array<ID>) => any,
    successMsg?: string
  ) => {
    if (!selected || !Array.isArray(selected) || selected.length < 1) {
      return;
    }

    try {
      await actions?.(selected);
      successMsg && alertSuccess(successMsg);
      refetch();
    } catch (error) {
      console.log("error:", error);
    } finally {
      clearSelected();
      setIsDelete(false);
    }
  };

  return (
    <div>
      <KTCard>
        <ListHeader
          platformFilter={platformFilter}
          statusFilterOption={statusFilterOption}
          useQueryRequest={useQueryRequest}
          useQueryResponse={useQueryResponse}
          queryString={queryString}
          useListView={useListView}
          deletable={deletable}
          showSyncBtn={!!bulkSync}
          showApproveBtn={!!bulkApprove}
          showRejectBtn={!!bulkReject}
          bulkSync={() =>
            handleSelectedBulkAction(bulkSync, "Bạn đã sync thành công !")
          }
          bulkApprove={() =>
            handleSelectedBulkAction(bulkApprove, "Bạn đã approve thành công !")
          }
          bulkReject={() =>
            handleSelectedBulkAction(bulkReject, "Bạn đã reject thành công !")
          }
          bulkDelete={() => setIsDelete(true)}
          actionAddNew={() => actionAddNew && actionAddNew()}
          placeholderSearch={searchPlaceholder}
          isCreate={isCreate}
          isSearch={isSearch}
        />
        <Table
          actionClickItem={actionClickItem}
          columnsSettings={columns}
          data={data}
          isLoading={isLoading}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryRequest={useQueryRequest}
        />
      </KTCard>

      {itemDelete !== undefined && (
        <DeleteConfirmModal
          onCancel={() => setItemDelete(undefined)}
          onConfirm={handleDelete}
        />
      )}

      {isDelete && (
        <DeleteConfirmModal
          onCancel={() => {
            clearSelected();
            setIsDelete(false);
          }}
          onConfirm={() =>
            setTimeout(
              () =>
                handleSelectedBulkAction(bulkDelete, "Bạn đã xóa thành công !"),
              300
            )
          }
        />
      )}
    </div>
  );
};

export default ListManagement;
