import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const errorHandle = (response: any) => {
  if (!response) return;
  const MySwal = withReactContent(Swal);
  const status = response?.status || 500;
  switch (status) {
    case 400:
      MySwal.fire({
        title: response.data.error,
        html: `<div style="text-align: left; color: #e74c3c; line-height: 2">${renderMessage(
          response.data.message
        )}</div>`,
        showConfirmButton: false,
        icon: "error",
      });
      break;

    case 500:
      MySwal.fire({
        title: response.data.error,
        html: "Có lỗi xảy ra",
        showConfirmButton: false,
        icon: "error",
      });
      break;

    default:
      MySwal.fire({
        title: response.data.error,
        html: response.data.message,
        showConfirmButton: false,
        icon: "error",
      });
      break;
  }
};

function renderMessage(message: any): string {
  let newMessage = "";
  if (Array.isArray(message)) {
    newMessage = "<ul><li>" + message.join("</li><li>") + "</li></ul>";
  } else {
    newMessage = message;
  }
  return newMessage;
}
