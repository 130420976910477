const ORDER_CARD = [
  {
    label: "Ngày tạo",
    icon: (
      <i className="ki-duotone ki-calendar fs-2 me-2">
        <span className="path1" />
        <span className="path2" />
      </i>
    ),
    type: "datetime",
    property: "createdAt",
  },

  {
    label: "Payment Method",
    icon: (
      <i className="ki-duotone ki-wallet fs-2 me-2">
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="path4" />
      </i>
    ),
    type: "text",
    property: "payment.name",
  },

  {
    label: "Shipping Method",
    icon: (
      <i className="ki-duotone ki-truck fs-2 me-2">
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="path4" />
        <span className="path5" />
      </i>
    ),
    type: "text",
    property: "shipping.name",
  },
];

const CUSTOMER_CARD = [
  {
    label: "Khách hàng",
    icon: (
      <i className="ki-duotone ki-profile-circle fs-2 me-2">
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
      </i>
    ),
    type: "text",
    property: "fullname",
  },

  {
    label: "Email",
    icon: (
      <i className="ki-duotone ki-sms fs-2 me-2">
        <span className="path1" />
        <span className="path2" />
      </i>
    ),
    type: "text",
    property: "email",
  },

  {
    label: "Điện thoại",
    icon: (
      <i className="ki-duotone ki-phone fs-2 me-2">
        <span className="path1" />
        <span className="path2" />
      </i>
    ),
    type: "text",
    property: "phone",
  },
];

const SHIPPING_CARD = [
  {
    label: "Khách hàng",
    icon: (
      <i className="ki-duotone ki-profile-circle fs-2 me-2">
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
      </i>
    ),
    type: "text",
    property: "fullname",
  },

  {
    label: "Điện thoại",
    icon: (
      <i className="ki-duotone ki-phone fs-2 me-2">
        <span className="path1" />
        <span className="path2" />
      </i>
    ),
    type: "text",
    property: "phone",
  },
];

export { CUSTOMER_CARD, ORDER_CARD, SHIPPING_CARD };
