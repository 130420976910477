import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  CreatedByColumn,
  DefaultCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header } from "app/components/Table/header";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tên"
        className="min-w-200px"
      />
    ),
    id: "name",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={props.data[props.row.index].name}
        link={`${props.data[props.row.index].id}/view-detail`}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Nội dung"
        className="min-w-250px"
      />
    ),
    id: "content",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].content} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-250px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
];

export { COLUMNS };
