import { KTCard } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { FormField } from "app/components/Form/Field";
import { StickyEditSidebar } from "app/components/Sidebar";
import { GiftKindEnum, GiftTypeEnum } from "constants/enum";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import {
  createItem,
  editItem,
  getItem,
  getProductDetail,
  getProductList,
  getVoucherList,
} from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const [giftList, setGiftList] = useState([]);
  const [productVariants, setProductVariants] = useState([]);

  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();

  const validation = Yup.object().shape({
    // ...CONTENT_VALIDATE_RULE,
  });

  const submit = async (query: any) => {
    try {
      if (id) {
        await editItem(
          {
            ...query,
            // title: query?.type === GiftTypeEnum.CUSTOM ? query?.title : "",
            target:
              query?.type === GiftTypeEnum.CUSTOM
                ? undefined
                : query.targetProductVariant
                ? query.targetProductVariant
                : query.target,
          },
          id
        ).then(updateSuccess);
      } else {
        await createItem({
          ...query,
          // title: query?.type === GiftTypeEnum.CUSTOM ? query?.title : "",
          target:
            query?.type === GiftTypeEnum.CUSTOM
              ? undefined
              : query.targetProductVariant
              ? query.targetProductVariant
              : query.target,
        }).then(() => {
          createSuccess();
          navigate("/gift");
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id, giftList]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) => {
        if (x.type === GiftTypeEnum.PRODUCT_VARIANT) {
          const findProduct: any = giftList.find((gift: any) => {
            const findVariant = gift.variants.find(
              (item: any) => item.id === x.target
            );

            return findVariant;
          });

          if (findProduct) {
            formik.setFieldValue("target", findProduct.id);
            formik.setFieldValue("targetProductVariant", x.target);
          }
        }
        setData(x);
      })
      .catch((e) => {
        console.log(e);
        navigate("/gift");
      });
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const query = {
          ...values,
          startDate: values?.startDate ? values?.startDate : Date.now(),
          expired: values?.expired ? values?.expired : Date.now(),
          isActive: !!values?.isActive,
          platform: [process.env.REACT_APP_PLATFORM],
        };
        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
        // navigate("/gift");
      }
    },
  });

  const FIELDS = [
    {
      label: "Loại quà",
      name: "type",
      required: true,
      type: "dropdown",
      options: Object.values(GiftTypeEnum)?.map((item) => ({
        label: item,
        value: item,
      })),
    },
    {
      label: "Target",
      name: "target",
      required: true,
      type: "dropdown",
      options: mapValue(giftList),
      onChange: (e: any) => {
        if (formik.values.type !== GiftTypeEnum.PRODUCT_VARIANT) {
          formik?.setFieldValue("title", e?.label);
        }
      },
      hidden: formik.values.type === GiftTypeEnum.CUSTOM,
    },
    {
      label: "Tiêu đề",
      name: "title",
      required: true,
      type: "text",
      // hidden: formik.values.type !== GiftTypeEnum.CUSTOM,
    },
    {
      label: "Target Product Variant",
      name: "targetProductVariant",
      // required: true,
      type: "dropdown",
      options: productVariants,
      hidden: formik.values.type !== GiftTypeEnum.PRODUCT_VARIANT,
      onChange: (e: any) => {
        formik?.setFieldValue("title", e?.label);
      },
    },
    {
      label: "Bên đổi quà",
      name: "kind",
      required: true,
      type: "dropdown",
      options: Object.values(GiftKindEnum)?.map((item) => ({
        label: item,
        value: item,
      })),
    },
    {
      label: "Điểm số cần để đổi quà",
      name: "value",
      required: true,
      type: "number",
    },
    {
      label: "Số lượng",
      name: "limitUser",
      required: true,
      type: "number",
    },
    {
      label: "Giới hạn lượt đổi của mỗi người",
      name: "limitExchangeUser",
      required: true,
      type: "number",
    },
    {
      label: "Ngày bắt đầu",
      name: "startDate",
      required: false,
      type: "datetime-local",
      onChange: (val?: any) => {
        formik.setFieldValue("startDate", val);
      },
    },
    {
      label: "Ngày hết hạn",
      name: "expired",
      required: false,
      type: "datetime-local",
      onChange: (val?: any) => {
        formik.setFieldValue("expired", val);
      },
    },
  ];

  useEffect(() => {
    switch (formik.values.type) {
      case GiftTypeEnum.PRODUCT:
        getProductList().then((data?: any) => {
          const filterProduct = data.data?.filter(
            (item: any) => item.variants.length === 0
          );
          setGiftList(filterProduct);
        });
        formik.setFieldValue("targetProductVariant", "");
        break;
      case GiftTypeEnum.PRODUCT_VARIANT:
        getProductList().then((data?: any) => {
          const filterProductVariant = data.data?.filter(
            (item: any) => item.variants.length > 0
          );
          setGiftList(filterProductVariant);
        });
        break;
      case GiftTypeEnum.VOUCHER:
        getVoucherList().then((data?: any) => {
          setGiftList(data?.data);
        });
        formik.setFieldValue("targetProductVariant", "");
        break;
    }
  }, [formik.values.type]);

  useEffect(() => {
    if (formik.values.type !== GiftTypeEnum.PRODUCT_VARIANT) return;

    if (
      (id && formik.values?.target && formik.values.targetProductVariant) ||
      (!id && formik.values?.target)
    ) {
      getProductDetail(formik.values.target).then((findProduct) => {
        if (!findProduct || findProduct.variants.length === 0) {
          setProductVariants([]);
          return;
        }

        const listOption = findProduct.variants.map((variant: any) => {
          return {
            label: `${findProduct.title} - ${variant.items
              ?.filter((item: any) => item !== null)
              ?.map((item: { title: string }) => item.title)
              ?.concat("")}`,
            value: variant.id,
          };
        });

        setProductVariants(listOption);
      });
    }
  }, [formik.values.target, formik.values.type, giftList]);

  return (
    <>
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any, index: number) => {
                const fieldData = { ...field, formik };

                return (
                  <React.Fragment key={index}>
                    {!field?.hidden && (
                      <div key={fieldData.name} className="fv-row mb-7">
                        {FormField(fieldData)}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </KTCard>
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={() => {
                formik.handleSubmit();
              }}
            ></StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
