import { ID, KTCard } from "_metronic/helpers";
import { updateSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { InputDropdown } from "app/components/Form";
import CustomTabs from "app/components/Tabs";
import { OrderStatusEnum } from "constants/enum";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDetailDistrict,
  getDetailProvinces,
  getDetailWards,
} from "services/actions";

import { initialData, ItemModel } from "../core/_models";
import { editItem, getItem } from "../core/_requests";
import { CUSTOMER_CARD, SHIPPING_CARD } from "../core/const";
import Logs from "./Logs";
import PriceTable from "./PriceTable/index";
import Transaction from "./Transaction";

const EditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<ItemModel>(initialData);
  const [status, setStatus] = useState(data?.status);
  const [detailLocation, setDetailLocation] = useState<string>("");

  const submit = async (query?: any) => {
    await editItem(query, id).then(updateSuccess);
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const query = {
          status: status,
          logs: values.logs,
        };

        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const tabs = [
    {
      id: "general",
      name: "Thông tin chung",
      content: () => (
        <>
          <KTCard className="p-10 mb-7">
            <div className="mb-4">
              <h2>Mã đơn hàng: {formik.values.code}</h2>
            </div>
            <div className="mb-4">
              <h4>Nền tảng: {formik.values.platform?.join(", ")}</h4>
            </div>
            <div className="fv-row mb-7">
              <InputDropdown
                formik={formik}
                label={"Trạng thái đơn hàng"}
                options={(() => {
                  let selectIndex = 0;

                  Object.keys(OrderStatusEnum)?.forEach(
                    (item: string, index: number) => {
                      if (item === formik.values.status) {
                        selectIndex = index;
                      }
                    }
                  );

                  const options = Object.keys(OrderStatusEnum)
                    .map((item) => ({
                      label:
                        OrderStatusEnum[item as keyof typeof OrderStatusEnum],
                      value: item,
                    }))
                    .slice(selectIndex);

                  return options;
                })()}
                onChange={(e) => {
                  setStatus(e.value);
                }}
                classNameLabel="form-label fs-6 fw-bold"
                classNameDropdown="form-select form-select-solid fw-bolder"
                values={status}
              />
            </div>

            <div className="text-end">
              <Button
                type="button"
                className="btn btn-primary "
                onClick={() => formik.handleSubmit()}
                disabled={status === formik?.values?.status}
              >
                Cập nhật
              </Button>
            </div>
          </KTCard>

          <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 mb-10">
            {/*begin::Order details*/}
            <div
              className="card card-flush py-4 flex-row-fluid"
              style={{ width: "calc(100%/3)" }}
            >
              {/*begin::Card header*/}
              <div className="card-header">
                <div className="card-title">
                  <h2>Chi tiết đơn hàng</h2>
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-0">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-semibold text-gray-600">
                      <tr>
                        <td
                          className="text-muted"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-calendar fs-2 me-2">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                            Ngày tạo
                          </div>
                        </td>
                        <td className="fw-bold text-end">
                          {moment(data?.createdAt)?.format(
                            "DD/MM/YYYY hh:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="text-muted"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-wallet fs-2 me-2">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>{" "}
                            Payment Method
                          </div>
                        </td>
                        <td className="fw-bold text-end">
                          {data?.payment?.name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="text-muted"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-truck fs-2 me-2">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                              <span className="path5" />
                            </i>
                            Shipping Method
                          </div>
                        </td>
                        <td className="fw-bold text-end">
                          {data?.shipping?.name || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Order details*/}
            {/*begin::Customer details*/}
            <div
              className="card card-flush py-4  flex-row-fluid"
              style={{ width: "calc(100%/3)" }}
            >
              {/*begin::Card header*/}
              <div className="card-header">
                <div className="card-title">
                  <h2>Chi tiết khách hàng</h2>
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-0">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-semibold text-gray-600">
                      {CUSTOMER_CARD?.map((item, index) => (
                        <tr key={index}>
                          <td
                            className="text-muted"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <div className="d-flex align-items-center">
                              {item.icon} {item.label}
                            </div>
                          </td>
                          <td className="fw-bold text-end">
                            {item.type === "datetime"
                              ? moment(
                                  data?.customerInformation?.[item.property]
                                )?.format("DD/MM/YYYY hh:mm:ss")
                              : data?.customerInformation?.[item.property] ||
                                "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Customer details*/}
            {/*begin::Documents*/}
            <div
              className="card card-flush py-4  flex-row-fluid"
              style={{ width: "calc(100%/3)" }}
            >
              {/*begin::Card header*/}
              <div className="card-header">
                <div className="card-title">
                  <h2>Chi tiết giao hàng</h2>
                </div>
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-0">
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-semibold text-gray-600">
                      {SHIPPING_CARD?.map((item, index) => (
                        <tr key={index}>
                          <td
                            className="text-muted"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <div className="d-flex align-items-center">
                              {item.icon} {item.label}
                            </div>
                          </td>
                          <td className="fw-bold text-end">
                            {item.type === "datetime"
                              ? moment(
                                  data?.customerInformation?.[item.property]
                                )?.format("DD/MM/YYYY hh:mm:ss")
                              : data?.customerInformation?.[item.property] ||
                                "-"}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td
                          className="text-muted"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-sms fs-2 me-2">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                            Địa chỉ
                          </div>
                        </td>
                        <td className="fw-bold text-end">{`${data?.shippingInformation?.address}, ${detailLocation}`}</td>
                      </tr>
                    </tbody>
                  </table>
                  {/*end::Table*/}
                </div>
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Documents*/}
          </div>

          <PriceTable data={data} />
        </>
      ),
    },

    {
      id: "transactions",
      name: "Lịch sử thanh toán",
      content: () => (
        <>
          <Transaction data={data?.transactions} />
        </>
      ),
    },

    {
      id: "logs",
      name: "Logs",
      content: () => (
        <>
          <Logs data={data?.transactions} formik={formik} />
        </>
      ),
    },
  ];

  useEffect(() => {
    initialPage(id);
  }, [id]);

  const initialPage = async (id?: ID) => {
    getItem(id)
      .then((data) => {
        setStatus(data?.status);
        setData({ ...data });

        Promise.all([
          getDetailWards(data?.shippingInformation?.ward),
          getDetailDistrict(data?.shippingInformation?.district),
          getDetailProvinces(data?.shippingInformation?.province),
        ]).then((values) => {
          setDetailLocation(
            `${values[0]?.name || ""}, ${values[1]?.name || ""}, ${
              values[2]?.name || ""
            }`
          );
        });
      })
      .catch((e) => {
        console.log(e);
        navigate("/orders");
      });
  };

  return (
    <>
      <CustomTabs
        className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
        items={tabs}
      />
    </>
  );
};

export default EditPage;
