import { KTCard, KTCardBody } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { Input, InputDropdown, MultiSelectDropdown } from "app/components/Form";
import Repeater from "app/components/Form/Repeater";
import TextEditor from "app/components/Form/TextEditor";
import { getList as getListTag } from "app/pages/sale-management/product-tag/core/_requests";
import { BikeRentalPriceEnum, PlatformEnum } from "constants/enum";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { formEditType } from "../../core/_models";
import { initialSpecifications } from "./contants";

const AdvanceSetting = () => {
  const { id } = useParams();
  const formik = useFormikContext<formEditType>();

  const [dataSpecitification, setDataSpecitification] = useState<string>("");
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    if (id) {
      setDataSpecitification(formik.values.specifications || "");
      return;
    }

    formik.setFieldValue("selectSpecifications", initialSpecifications[0].id);

    const listPlatForm = Object.keys(PlatformEnum).map((item) => ({
      label: PlatformEnum[item as keyof typeof PlatformEnum],
      value: item,
    }));
    formik.setFieldValue("platform", listPlatForm);
  }, [id]);

  // useEffect(() => {
  //   getListTag().then((data) => setTags(data?.data));
  // }, []);

  useEffect(() => {
    if (id) {
      handleSelectSpecificationEdit();
    } else {
      handleSelectSpecificationsCreate();
    }
  }, [formik?.values?.selectSpecifications, id, dataSpecitification]);

  const handleSelectSpecificationEdit = () => {
    if (!dataSpecitification) return;

    const specifications = JSON.parse(dataSpecitification || "");

    if (formik?.values?.selectSpecifications === specifications.id) {
      formik.setFieldValue("specifications", specifications.data);
      formik.setFieldValue("selectSpecifications", specifications.id);
      return;
    }

    handleSelectSpecificationsCreate();
  };

  const handleSelectSpecificationsCreate = () => {
    if (!formik?.values?.selectSpecifications) return;

    const findSpecitications = initialSpecifications.find(
      (item) => item.id === formik?.values?.selectSpecifications
    );

    if (!findSpecitications) return;

    formik.setFieldValue("specifications", findSpecitications.data);
  };

  return (
    <KTCard>
      <KTCardBody>
        <div className="fv-row mb-7">
          <label className={"mb-5 fw-bold fs-6 "}>Thông tin hàng</label>
          <div className="mx-6">
            <div className="col-md-12 mb-7">
              <MultiSelectDropdown
                formik={formik}
                options={Object.keys(PlatformEnum).map((item) => ({
                  label: PlatformEnum[item as keyof typeof PlatformEnum],
                  value: item,
                }))}
                label="Nền tảng đăng sản phẩm"
                name="platform"
                onSelect={(values) => {
                  formik.setFieldValue("platform", values);
                }}
                onRemove={(values) => {
                  formik.setFieldValue("platform", values);
                }}
                values={formik.values.platform}
              />
            </div>

            {formik.values.isRental && (
              <div className="fv-row mb-7">
                <Repeater
                  maxLength={5}
                  // deletable={true}
                  label="Giá thuê theo thời gian"
                  name="rentPrices"
                  formik={formik}
                  //   type="feature"
                  //   disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  values={formik.values.rentPrices}
                  headerItem={["Loại thời gian", "Giá tiền"]}
                  initItem={formik.values.rentPrices}
                  inputItem={[
                    {
                      type: "dropdown",
                      name: "type",
                      options: Object.keys(BikeRentalPriceEnum).map((item) => ({
                        label:
                          BikeRentalPriceEnum[
                            item as keyof typeof BikeRentalPriceEnum
                          ],
                        value: item,
                      })),
                    },
                    {
                      type: "number",
                      name: "price",
                      min: 0,
                    },
                  ]}
                />
              </div>
            )}

            {formik.values.isPrimary && (
              <>
                <div className="fv-row mb-7">
                  <Input
                    formik={formik}
                    label="SKU (Đơn vị lưu kho)"
                    placeholder=""
                    name="sku"
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    values={formik.values.sku}
                  />
                </div>

                <div className="fv-row mb-7">
                  <div className="row">
                    <div className="col-md-6 mb-7">
                      <Input
                        label="Giá gốc"
                        placeholder="Giá"
                        name="price"
                        type="number"
                        min="0"
                        disabled={formik.isSubmitting}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        values={formik.values.price}
                      />
                    </div>
                    <div className="col-md-6 mb-7">
                      <Input
                        label="Giá khuyến mãi"
                        placeholder="Giá"
                        name="sellPrice"
                        type="number"
                        min="0"
                        disabled={formik.isSubmitting}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        values={formik.values.sellPrice}
                      />
                    </div>

                    <div className="col-md-6 mb-7">
                      <Input
                        label="Stock"
                        placeholder="stock"
                        name="stock"
                        type="number"
                        min="0"
                        disabled={formik.isSubmitting}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        values={formik.values.stock}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="fv-row mb-7">
              <div className="fv-row mb-7">
                <TextEditor
                  label="Quà tặng kèm"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  name="accompanyingGifts"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.informationDetail?.accompanyingGifts}
                />
              </div>
            </div>

            <div className="fv-row mb-7">
              <div className="fv-row mb-7">
                <TextEditor
                  label="Chương trình khuyến mãi"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  name="dealInformation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.dealInformation}
                />
              </div>
            </div>

            <div className="col-md-12 mb-7">
              <MultiSelectDropdown
                formik={formik}
                options={mapValue(tags)}
                label="Tags"
                name="tags"
                onSearch={(search) => {
                  getListTag(search).then((data) => setTags(data?.data));
                }}
                onSelect={(values) => {
                  formik.setFieldValue("tags", values);
                }}
                onRemove={(values) => {
                  formik.setFieldValue("tags", values);
                }}
                values={formik.values.tags}
              />
            </div>

            <div className="fv-row mb-7">
              <InputDropdown
                label="Lựa chọn thông số kĩ thuật"
                classNameLabel="form-label fs-6 fw-bold"
                classNameDropdown="form-select form-select-solid fw-bolder"
                options={mapValue(initialSpecifications)}
                formik={formik}
                name="selectSpecifications"
                values={formik.values.selectSpecifications}
              />
            </div>
            <div className="fv-row mb-7">
              <Repeater
                customFieldLabel={
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="fs-5 fw-bold form-label  d-flex align-items-center">
                      Chi tiết thông số kỹ thuật
                    </div>

                    <div
                      className="hide-text btn btn-danger btn-xl d-inline-flex align-items-center"
                      onClick={() => {
                        formik.setFieldValue("specifications", [
                          {
                            title: "",
                            value: "",
                          },
                        ]);
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        Xoá tất cả
                      </p>
                    </div>
                  </div>
                }
                name="specifications"
                formik={formik}
                disabled={formik.isSubmitting}
                values={formik.values?.specifications}
                // values={formik.values.informationDetail?.specifications}
                headerItem={["Nội dung", "Giá trị"]}
                initItem={[
                  {
                    title: "",
                    value: "",
                  },
                ]}
                inputItem={[
                  {
                    type: "text",
                    name: "title",
                    label: "Tiêu đề",
                  },

                  {
                    type: "textarea",
                    name: "value",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default AdvanceSetting;
