export const MENU_NAME: any = {
  ADMIN: "Quản trị viên",
  ROLE: "Phân quyền",
  USER: "Khách hàng",
  EVENT: "Sự kiện",
  PAGE: "Trang",
  POST: "Bài viết",
  TAG: "Thẻ",
  CATEGORY: "Danh mục Bài viết",
  COLLECTION: "Bộ sưu tập",
  PRODUCT_CATEGORY: "Danh mục sản phẩm",
  PRODUCT: "Sản phẩm",
  SETTING: "Thiết lập",
  BRAND: "Thương hiệu",
  EVENT_CATEGORY: "Danh mục sự kiện",
  SPORT: "Môn thể thao",
  MENU: "Menu",
  BANNER: "Banners",
};
