import { EventServiceOptionType } from "app/pages/event-management/events/core/_models";
import { useEffect, useState } from "react";

import { InputProps } from "../../Input/index";

const RepeaterService = ({ name, formik, values, label }: InputProps) => {
  const [list, setList] = useState<Array<EventServiceOptionType>>(
    values && values.length > 0
      ? values
      : [
          {
            name: "",
            value: 0,
            initValue: 0,
          },
        ]
  );

  useEffect(() => {
    if (values) {
      setList(values);
    }
  }, [values]);

  return (
    <div className="d-flex flex-column mb-15 fv-row">
      <div className="fs-5 fw-bold form-label mb-3">{label}</div>
      <div className="table-responsive">
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fw-semibold fs-6 gy-5 dataTable no-footer">
              {/*begin::Table head*/}
              <thead>
                <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                  <th className="pt-0 sorting_disabled" rowSpan={1} colSpan={1}>
                    Mục tiêu
                  </th>
                  <th className="pt-0 sorting_disabled" rowSpan={1} colSpan={1}>
                    Mức giá
                  </th>
                  <th
                    className="pt-0 text-end sorting_disabled"
                    rowSpan={1}
                    colSpan={1}
                    style={{ width: "80.7px" }}
                  >
                    Xóa
                  </th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody>
                {list?.length > 0 ? (
                  list.map((item, index) => {
                    return (
                      <tr className="odd" key={index}>
                        <td>
                          <input
                            className="form-control form-control-solid"
                            value={item?.name || ""}
                            onChange={(e) => {
                              setList((prev) => {
                                prev[index].name = e.target.value;
                                return [...prev];
                              });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control form-control-solid"
                            value={item?.initValue}
                            onChange={async (e) => {
                              setList((prev) => {
                                prev[index].initValue = Number(e.target.value);
                                return [...prev];
                              });
                            }}
                          />
                        </td>
                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3"
                            data-kt-action="field_remove"
                            onClick={() => {
                              setList((prev) => {
                                prev.splice(index, 1);
                                return [...prev];
                              });
                            }}
                          >
                            <i className="ki-duotone ki-trash fs-3">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                              <span className="path5" />
                            </i>{" "}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
              {/*end::Table body*/}
            </table>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" />
          </div>
        </div>
        {/*end:Table*/}
      </div>

      <div className="d-flex justify-content-end gap-2">
        <button
          type="button"
          className="btn btn-light-success w-auto"
          onClick={() =>
            formik.setFieldValue(name, [
              {
                key: "distance",
                type: "select",
                options: list,
              },
            ])
          }
        >
          Lưu
        </button>
        <button
          type="button"
          className="btn btn-light-primary w-auto"
          onClick={() => {
            setList((prev) => {
              return [
                ...prev,
                {
                  name: "",
                  value: 0,
                  initValue: 0,
                },
              ];
            });
          }}
        >
          Thêm
        </button>
      </div>
    </div>
  );
};

export default RepeaterService;
