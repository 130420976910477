export const VOUCHER_OPTION = {
  discountType: [
    { label: "Giá trị cứng", value: "fixed" },
    { label: "Phần trăm", value: "percentage" },
    // { label: "One time", value: "one-time" },
  ],

  voucherType: [
    { label: "Free ship", value: "free-ship" },
    { label: "Nền tảng", value: "internal" },
    { label: "Nhãn hàng", value: "branding" },
  ],

  voucherTargetType: [
    { label: "Tất cả", value: "All" },
    { label: "Đặt dịch vụ", value: "Booking" },
    { label: "Sản phẩm", value: "Product" },
    // { label: "Sản phẩm biến thể", value: "ProductVariant" },
    { label: "Sự kiện", value: "Event" },
  ],
};

export const CONNECTOR_OPTION = {
  $and: "And",
  $or: "Or",
};

export const CONNECTOR_ITEM_OPTION = {
  $and: "And",
  $or: "Or",
  value: "Giá trị",
};

export const CONDITION_TYPE = [
  {
    label: "Thương hiệu",
    value: "brand",
  },
  {
    label: "Danh mục sản phẩm",
    value: "product-category",
  },
  {
    label: "Thuộc tính sản phẩm",
    value: "attribute",
  },
  {
    label: "Sản phẩm",
    value: "product",
  },
  {
    label: "Sự kiện",
    value: "event",
  },
  {
    label: "Sản phẩm biến thể",
    value: "product-variant",
  },
];

export const initConditionData = { type: "", value: "" };
