import { ID, Response } from "_metronic/helpers";

export type RoleList = {
  id?: ID;
  name?: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Role = {
  id?: string;
  name?: string;
  description?: string;
  permissions: Array<string>;
  createdAt?: string;
  updatedAt?: string;
};

export const initialData = {
  name: "",
  description: "",
  permissions: [],
  createdAt: "",
};

export type QueryResponse = Response<Array<RoleList>>;
