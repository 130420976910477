import { useQueryResponseLoading } from "_metronic/helpers/provider/QueryResponseProvider";
import { updateSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { FormField } from "app/components/Form/Field";
import { CHANGE_PASSWORD_VALIDATE_RULE } from "constants/validate-rule";
import { FormikValues, useFormik } from "formik";
import { errorHandle } from "services/error";
import * as Yup from "yup";

import { useAuth } from "../../auth";
import { updateProfile } from "../core/_requests";

const ChangePassword = () => {
  const isLoading = useQueryResponseLoading();

  const { currentUser } = useAuth();

  const validation = Yup.object().shape({
    ...CHANGE_PASSWORD_VALIDATE_RULE,
  });

  const formik = useFormik({
    initialValues: currentUser as FormikValues,
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await updateProfile({
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
          isActive: Boolean(values?.isActive) || true,
        }).then(updateSuccess);
      } catch (err: any) {
        errorHandle(err.response);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Mật khẩu mới",
      name: "password",
      required: true,
      type: "text",
    },
    {
      label: "Nhập lại mật khẩu",
      name: "passwordConfirmation",
      required: true,
      type: "text",
    },
  ];

  return (
    <>
      <div className="card-body border-top p-9">
        {FIELDS.map((field: any) => {
          const fieldData = { ...field, formik };
          return (
            <div key={fieldData.name} className="fv-row mb-7">
              <div className="col-6">{FormField(fieldData)}</div>
            </div>
          );
        })}

        <div className="text-end mt-12">
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading || formik?.isSubmitting || !formik?.touched}
          >
            Cập nhật
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
