import { ID } from "_metronic/helpers";
import { getData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `view`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, query);

const getItem = async (id: ID): Promise<ItemModel> => getData(URL + "/" + id);

export { getItem, getList };
