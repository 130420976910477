/* eslint-disable jsx-a11y/anchor-is-valid */
import { getCSSVariableValue } from "_metronic/assets/ts/_utils";
import convertCurrency from "_metronic/helpers/convertData/convert-currency";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect, useMemo, useRef } from "react";
// import { KTIcon, toAbsoluteUrl } from "../../../helpers";
// import { getCSSVariableValue } from "../../../assets/ts/_utils";
// import { Dropdown1 } from "../../content/dropdown/Dropdown1";

type Props = {
  data?: any;
  className: string;
  chartColor: string;
  chartHeight: string;
};

const MixedWidget8: React.FC<Props> = ({
  data,
  className,
  chartColor,
  chartHeight,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const chart1 = new ApexCharts(
      chartRef.current,
      chart1Options(data, chartColor, chartHeight)
    );
    if (chart1) {
      chart1.render();
    }

    return chart1;
  };

  const totalRevenue = useMemo(() => {
    return data?.data?.reduce((acc: number, curr: any) => {
      return acc + curr.total;
    }, 0);
  }, [data]);

  useEffect(() => {
    const chart1 = refreshChart();

    return () => {
      if (chart1) {
        chart1.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, data]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5 ">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {data?.store?.name}
          </span>
        </h3>

        <div>
          <span className="text-muted fw-semibold fs-7">
            Tổng doanh thu của cửa hàng:
          </span>
          <div
            style={{ fontSize: 18 }}
            className="text-end fw-bold card-label mt-1"
          >
            {convertCurrency(totalRevenue) || 0}
          </div>
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body d-flex flex-column">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className="mixed-widget-5-chart card-rounded-top"
        ></div>
        {/* end::Chart */}

        {/* begin::Items */}
        <div className="mt-5">
          {data?.bookings?.map((item: any, indx: any) => {
            return (
              <div key={indx} className="d-flex flex-stack mb-5">
                {/* begin::Section */}
                <div className="d-flex align-items-center me-2">
                  <div>
                    <a
                      href="#"
                      className="fs-6 text-gray-800 text-hover-primary fw-bold"
                    >
                      {item?.user?.fullname || "-"}
                    </a>
                    <div className="fs-7 text-muted fw-semibold mt-1">
                      {item?.orderNumber || "-"}
                    </div>
                  </div>
                </div>
                {/* end::Section */}

                {/* begin::Label */}
                <div className="badge badge-light fw-semibold py-4 px-3">
                  {convertCurrency(item?.totalPrice) || "-"}
                </div>
                {/* end::Label */}
              </div>
            );
          })}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  );
};

const chart1Options = (
  data: any,
  chartColor: string,
  chartHeight: string
): ApexOptions => {
  const labelColor = getCSSVariableValue("--bs-gray-800");
  const strokeColor = getCSSVariableValue("--bs-gray-300");
  const baseColor = getCSSVariableValue("--bs-" + chartColor) as string;
  const lightColor = getCSSVariableValue("--bs-" + chartColor + "-light");

  return {
    series: [
      {
        name: "Revenue",
        data: data?.data?.map((item: any) => item.total),
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    fill1: {
      type: "gradient",
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.375,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: data?.data?.map((item: any) => convertDate(item?.date)),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: Math.max(...data?.data?.map((item: any) => item.total)) + 100000,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val + " VNĐ";
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      strokeColor: [baseColor],
      strokeWidth: 3,
    },
  };
};

export { MixedWidget8 };
