import { ID } from "_metronic/helpers";
import { dateStartEndWeek } from "_metronic/helpers/formatData";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { handleSyncDataWeekly } from "../core/_helper";
import { ItemModel } from "../core/_models";
import { getDetailEvent, getItem } from "../core/_requests";
import Table from "./List";
import OverviewCard from "./OverviewCard";

const Report = () => {
  const [data, setData] = useState<ItemModel | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      setData(null);
      return;
    }
    initialPage(id);
  }, [id]);

  const initialPage = async (id: ID) => {
    const response = await getDetailEvent(id);

    if (!response.isFilterWeekly) {
      handleEvent();
      return;
    }

    handleEventIsFilterWeekly();
  };

  const handleEvent = () => {
    getItem(id)
      .then((data: any) => {
        setIsLoading(true);
        setData(data);
      })
      .catch((e) => {
        console.log(e);
        navigate("/event");
      })
      .finally(() => {
        setIsLoading(false);
      });
    return;
  };

  const handleEventIsFilterWeekly = async () => {
    try {
      setIsLoading(true);
      const { startOfWeek, endOfWeek } = dateStartEndWeek();

      const response = await handleSyncDataWeekly(
        id || "",
        startOfWeek,
        endOfWeek
      );

      setData(response);
      setIsLoading(false);
    } catch (error) {
      navigate("/event");
    }
  };

  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <OverviewCard data={data} />
      <Table
        eventName={data?.title}
        dataEventDetail={data}
        data={data?.members}
        setDataEventDetail={setData}
      />
    </>
  );
};

export default Report;
