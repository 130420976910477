/* eslint-disable jsx-a11y/anchor-is-valid */
import { Input } from "app/components/Form";
import clsx from "clsx";
import { useFormik } from "formik";
import { useState } from "react";
// import { Link } from "react-router-dom";
import * as Yup from "yup";

import { getUserByToken, login } from "../core/_requests";
import { useAuth } from "../core/Auth";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password);
        // localStorage.setItem("token", auth.access_token);
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.access_token);
        setCurrentUser(user);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setErrors({ email: "Email hoặc mật khẩu không chính xác" });
      }
      setSubmitting(false);
      setLoading(false);
    },
  });

  return (
    <form className="form w-100" onSubmit={formik.handleSubmit} noValidate>
      {/* begin::Heading */}
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">Đăng nhập</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <Input
          formik={formik}
          label="Mật khẩu"
          placeholder="Mật khẩu"
          name="password"
          type="password"
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          values={formik.values.password}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />

        {/* begin::Link */}
        {/* <Link to="/auth/forgot-password" className="link-primary">
          Quên mật khẩu ?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Chờ chút...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}
