import { KTCard } from "_metronic/helpers";
import { removeDuplicates } from "_metronic/helpers/convertData/remove-duplicate";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import { FormField } from "app/components/Form/Field";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getList as getProductCategory } from "../../product-category/core/_requests";
import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem, getItem, getList } from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>(initialData);
  const [categories, setCategories] = useState<ItemModel[]>([]);
  const [types, setTypes] = useState<ItemModel[]>([]);
  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) => {
        setData({
          ...x,
          categoryId: x.category.id,
          productTypeParentId: x.parent?.id,
        });
      })
      .catch((e) => {
        console.log(e);
        navigate("/product-type");
      });
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (id) {
          await editItem(
            {
              ...values,
              isActive: !!values?.isActive,
              platform: [process.env.REACT_APP_PLATFORM],
            },
            id
          ).then(updateSuccess);
        } else {
          await createItem({
            ...values,
            isActive: !!values?.isActive,
            platform: [process.env.REACT_APP_PLATFORM],
          }).then(() => {
            createSuccess();
            navigate("/product-type");
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "title",
      required: true,
      type: "text",
    },
    {
      label: "Alias",
      name: "alias",
      required: true,
      type: "alias",
    },
    {
      label: "Danh mục",
      name: "categoryId",
      // required: true,
      type: "dropdown",
      options: removeDuplicates(
        Object.keys(formik.values.category || {})?.length
          ? mapValue([formik.values.category, ...categories])
          : mapValue([...categories]),
        "value"
      ),
      visible: !formik.values.productTypeParentId,
      onSearch: (e: string) => {
        getProductCategory({
          search: e,
          limit: 10,
          page: 1,
          platform: process.env.REACT_APP_PLATFORM,
        }).then((data: any) => {
          setCategories(data?.data);
        });
      },
    },
    {
      label: "Loại sản phẩm cha",
      name: "productTypeParentId",
      // required: true,
      type: "dropdown",
      options: types?.map((item: any) => ({
        label: `${item?.title} (${item?.alias})`,
        value: item?.id || item?.value,
      })),
      visible: !formik.values.categoryId,
      onSearch: (e: string) => {
        getList({
          search: e,
          limit: 10,
          noPaginate: true,
          isActive: true,
          platform: process.env.REACT_APP_PLATFORM,
        }).then((data) => {
          setTypes(data?.data || []);
        });
      },
    },
  ];

  return (
    <>
      {id && <PageTitle>{data.title}</PageTitle>}
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };

                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={() => formik.handleSubmit()}
            >
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 border mb-10"
                path="/product-type"
              />
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
