import { ID } from "_metronic/helpers";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { Input, InputDropdown } from "app/components/Form";
import ModalCustom from "app/components/ModalCustom";
import { useFormik } from "formik";
import { FC, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import FileImport from "../../../../components/Upload/FileImport/FileImport";
import { initialData, ItemDealModel } from "../core/_models";
import { createDeal } from "../core/_requests";

type Props = {
  data: SetStateAction<ID>;
};

export const validateSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng điền đủ thông tin"),
});

const addingMethodOptions = [
  {
    label: "Nhập liệu",
    value: "write",
  },
  {
    label: "Import File",
    value: "import",
  },
];

const ItemModal: FC<Props> = () => {
  const [uploadFile, setUploadFile] = useState(false);
  const [addingMethod, setAddingMethod] = useState("write");
  const { id } = useParams();

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const { refetch, isLoading } = useQueryResponse();
  const data: any = itemIdForUpdate;

  const [item] = useState<ItemDealModel>({
    ...initialData,
    ...data,
  });

  const formik = useFormik({
    initialValues: item,
    validationSchema: validateSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await createDeal({
          sku: values.sku,
          campaign: id,
          platform: [process.env.PLATFORM],
        });
        refetch();
        setItemIdForUpdate(undefined);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <ModalCustom
        labelHeader={`${!itemIdForUpdate ? "Tạo mới" : "Chỉnh sửa"}`}
        actionHeader={setItemIdForUpdate}
        actionCancel={() => setItemIdForUpdate(undefined)}
        formik={formik}
        labelCancel="Đóng"
        labelSubmit="Xác nhận"
      >
        <div className="fv-row mb-7">
          <InputDropdown
            onChange={(e) => setAddingMethod(e.value)}
            label={"Phương thức thêm"}
            options={addingMethodOptions}
            classNameLabel="form-label fs-6 fw-bold"
            classNameDropdown="form-select form-select-solid fw-bolder"
            disabled={formik?.isSubmitting}
            values={addingMethod}
          />
        </div>
        {addingMethod === "write" ? (
          <>
            <div className="fv-row mb-7">
              <Input
                // required={!!required}
                formik={formik}
                type="text"
                label={"Nhập SKU"}
                placeholder={"Nhập SKU"}
                name="sku"
                disabled={formik?.isSubmitting}
                onChange={(e) => formik?.handleChange(e)}
                onBlur={formik?.handleBlur}
                values={formik?.values?.sku}
              />
            </div>
            {/* <div className="col-md-12 mb-7">
              <MultiSelectDropdown
                formik={formik}
                options={Object.keys(PlatformEnum).map((item) => ({
                  label: PlatformEnum[item as keyof typeof PlatformEnum],
                  value: item,
                }))}
                label="Nền tảng"
                name="platform"
                onSelect={(values) => {
                  formik.setFieldValue("platform", values);
                }}
                onRemove={(values) => {
                  formik.setFieldValue("platform", values);
                }}
                values={formik.values.platform}
              />
            </div> */}
          </>
        ) : (
          <></>
        )}
        {addingMethod === "import" ? (
          <div className="fv-row mb-7">
            <FileImport
              url={`/campaigns/${id}/import-deals`}
              setIsUpload={setUploadFile}
              upload={uploadFile}
              name="dealImport"
              requireType={["csv"]}
              description="Định dạng của file phải là định dạng .csv"
              label="Deal Import"
              uploaded={() => {
                setTimeout(() => {
                  createSuccess();
                  setItemIdForUpdate(undefined);
                  refetch();
                }, 2000);
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="text-center pt-15">
          <Button
            type="button"
            className="btn btn-light me-3"
            onClick={() => setItemIdForUpdate(undefined)}
          >
            Đóng
          </Button>

          <Button
            type="submit"
            className="btn btn-primary"
            onClick={async () => {
              if (addingMethod === "import") {
                setUploadFile(true);
              } else {
                await createDeal({
                  sku: formik.values.sku,
                  campaign: id,
                  platform: [process.env.PLATFORM],
                }).then(() => {
                  setTimeout(() => {
                    createSuccess();
                    setItemIdForUpdate(undefined);
                    refetch();
                  }, 500);
                });
              }
            }}
            disabled={isLoading || formik?.isSubmitting}
          >
            Xác nhận
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export default ItemModal;
