import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Sản phẩm",
    path: "/product",
  },
  {
    isSeparator: true,
  },
];

const ProductPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách sản phẩm
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="/create"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Thêm mới sản phẩm</PageTitle>

              <EditPage />
            </>
          }
        />
        <Route
          path=":id/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Chỉnh sửa sản phẩm
              </PageTitle>

              <EditPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ProductPage;
