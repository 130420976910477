/* eslint-disable */import clsx from "clsx";
import { FC } from "react";
import { ColumnInstance } from "react-table";

type Props = {
  column: ColumnInstance<any>;
};

const HeaderColumn: FC<Props> = ({ column }) => {
  return (
    <>
      {column.Header && typeof column.Header === "string" ? (
        <th {...column.getHeaderProps()} className={clsx({"text-end": column.Header === "Hành động"})} style={{ paddingRight: "100px" }}>{column.render("Header")}</th>
      ) : (
        column.render("Header")
      )}
    </>
  );
};

export { HeaderColumn };
