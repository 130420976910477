import { alertSuccess } from "_metronic/helpers/toastify";
import {
  DetailsView,
  FileManagerComponent,
  Inject,
  NavigationPane,
  Toolbar,
  ToolbarItemDirective,
  ToolbarItemsDirective,
} from "@syncfusion/ej2-react-filemanager";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";
import { useRef, useState } from "react";

import { ajaxConfig } from "./config";

const FileManager = () => {
  const [isDirectoryUpload, setIsDirectoryUpload] = useState<boolean>(false);
  const fmObj = useRef<FileManagerComponent>(null);

  const items: ItemModel[] = [{ text: "Folder" }, { text: "Files" }];
  const contextmenuItems: string[] = [
    "Open",
    "|",
    "Cut",
    "Copy",
    "Delete",
    "Rename",
    "|",
    "Details",
    "Select",
  ];

  const menuOpen = (args: any) => {
    if (fmObj.current)
      for (const i in args.items) {
        if (args.items[i].id === fmObj.current.element.id + "_cm_select") {
          args.items[i].iconCss = "e-icons e-fe-tick";
        }
      }
  };

  const onSelect = (args: any) => {
    if (args.item.text === "Folder") {
      setIsDirectoryUpload(true);
    } else {
      setIsDirectoryUpload(false);
    }

    setTimeout(() => {
      const uploadBtn: any = document.querySelector(
        ".e-file-select-wrap button"
      );
      uploadBtn.click();
    }, 100);
  };

  const uploadClick = (e: any) => {
    e.stopPropagation();
  };

  const uploadTemplate = () => {
    return (
      <DropDownButtonComponent
        id="dropButton"
        cssClass="e-tbar-btn e-tbtn-txt"
        onClick={uploadClick}
        items={items}
        iconCss="e-icons e-fe-upload"
        select={onSelect}
      >
        <span className="e-tbar-btn-text">Tải lên</span>
      </DropDownButtonComponent>
    );
  };

  const menuClick = (args: any) => {
    if (args.item.text === "Select") {
      if (fmObj.current) {
        const selectedFiles = fmObj.current.getSelectedFiles();
        for (let index = 0; index < selectedFiles.length; index++) {
          const file: any = selectedFiles[index];
          if (file.isFile) {
            navigator.clipboard.writeText(file.publicUrl);
            alertSuccess("Đã copy thành công đường dẫn của ảnh");
          }
        }
      }
    }
  };

  return (
    <div className="control-section">
      <FileManagerComponent
        id="fileManager"
        ref={fmObj}
        uploadSettings={{ directoryUpload: isDirectoryUpload }}
        ajaxSettings={ajaxConfig}
        sortBy="dateModified"
        sortOrder="Descending"
        path="/"
        enablePersistence={true}
        contextMenuSettings={{
          file: contextmenuItems,
          folder: contextmenuItems.filter((x) => x !== "Select"),
        }}
        menuOpen={menuOpen}
        menuClick={menuClick}
      >
        <ToolbarItemsDirective>
          <ToolbarItemDirective name="NewFolder" />
          <ToolbarItemDirective template={uploadTemplate} name="Upload" />
          <ToolbarItemDirective name="SortBy" />
          <ToolbarItemDirective name="Refresh" />
          <ToolbarItemDirective name="Cut" />
          <ToolbarItemDirective name="Copy" />
          <ToolbarItemDirective name="Paste" />
          <ToolbarItemDirective name="Delete" />
          <ToolbarItemDirective name="Download" />
          <ToolbarItemDirective name="Rename" />
          <ToolbarItemDirective name="Selection" />
          <ToolbarItemDirective name="View" />
          <ToolbarItemDirective name="Details" />
        </ToolbarItemsDirective>
        <Inject services={[NavigationPane, DetailsView, Toolbar]} />
      </FileManagerComponent>
    </div>
  );
};

export default FileManager;
