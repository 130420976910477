export const SIDEBAR_MENU = [
  {
    path: "/dashboard",
    fontIcon: "bi-speedometer2",
    title: "Bản điều khiển",
    children: [],
  },
  {
    path: "admin",
    fontIcon: "bi-person-lock",
    title: "Quản trị viên",
    children: [
      {
        path: "/admin",
        title: "Quản trị viên",
        requireRole: "GET_ADMIN",
      },
      {
        path: "/role",
        title: "Phân quyền",
        requireRole: "GET_ROLE",
      },
    ],
  },
  {
    path: "/post",
    fontIcon: "bi-pin-angle",
    title: "Bài viết",
    children: [
      {
        path: "/post/create",
        title: "Thêm bài viết",
        requireRole: "CREATE_POST",
      },
      {
        path: "/post",
        title: "Danh sách bài viết",
        requireRole: "GET_POST",
      },
      {
        path: "/tag",
        title: "Tag",
        requireRole: "GET_TAG",
      },
      {
        path: "/category",
        title: "Danh mục",
        requireRole: "GET_CATEGORY",
      },
    ],
  },
  {
    path: "event",
    fontIcon: "bi-calendar2-event",
    title: "Sự kiện",
    children: [
      {
        path: "/event/create",
        title: "Tạo sự kiện",
        requireRole: "CREATE_EVENT",
      },
      {
        path: "/event",
        title: "Danh sách",
        requireRole: "GET_EVENT",
      },
      {
        path: "/event-category",
        title: "Danh mục",
        requireRole: "GET_EVENT_CATEGORY",
      },
      {
        path: "/event-comment",
        title: "Đánh giá sự kiện",
        requireRole: "GET_EVENT",
      },
    ],
  },
  {
    path: "tour",
    fontIcon: "bi-calendar2-event",
    title: "Tour",
    children: [
      {
        path: "/tour/create",
        title: "Tạo tour",
        requireRole: "CREATE_EVENT",
      },
      {
        path: "/tour",
        title: "Danh sách",
        requireRole: "GET_EVENT",
      },
      {
        path: "/tour-category",
        title: "Danh mục",
        requireRole: "GET_EVENT_CATEGORY",
      },
      {
        path: "/tour/comment",
        title: "Đánh giá tour",
        requireRole: "GET_EVENT",
      },
    ],
  },
  {
    path: "catalog",
    fontIcon: "bi bi-cart",
    title: "Sản phẩm",
    children: [
      {
        path: "/product/create",
        title: "Thêm sản phẩm",
        requireRole: "CREATE_PRODUCT",
      },
      {
        path: "/product",
        title: "Danh sách sản phẩm",
        requireRole: "GET_PRODUCT",
      },
      {
        path: "/product-category",
        title: "Danh mục sản phẩm",
        requireRole: "GET_PRODUCT_CATEGORY",
      },
      {
        path: "/product-tag/",
        title: "Tags",
        requireRole: "CREATE_PRODUCT",
      },
      {
        path: "/brand",
        title: "Thương hiệu",
        requireRole: "GET_BRAND",
      },
      {
        path: "/collection",
        title: "Bộ sưu tập",
        requireRole: "GET_COLLECTION",
      },
      {
        path: "/product-attribute",
        title: "Attribute",
        requireRole: "GET_ATTRIBUTE",
      },
      {
        path: "/product-type",
        title: "Loại sản phẩm",
        requireRole: "GET_PRODUCT_TYPE",
      },
      {
        path: "/product-comment",
        title: "Đánh giá sản phẩm",
        requireRole: "GET_COMMENT",
      },
    ],
  },
  {
    path: "bike-service",
    fontIcon: "bi bi-bicycle",
    title: "Dịch vụ xe",
    children: [
      {
        path: "/bike-rental",
        title: "Danh sách xe cho thuê",
        requireRole: "GET_PRODUCT",
      },
      {
        path: "/bike-rental-booking",
        title: "Yêu cầu thuê xe",
        requireRole: "GET_RENTAL_BOOKING",
      },
      {
        path: "/bike-services",
        title: "Dịch vụ sửa xe",
        requireRole: "GET_SERVICE_TYPE",
      },
      {
        path: "/bike-fixing",
        title: "Yêu cầu sửa xe",
        requireRole: "GET_FIX_BIKE_BOOKING",
      },
      {
        path: "/bike-renew-condition",
        title: "Tiêu chuẩn thu cũ",
        requireRole: "GET_RENEW_CONDITION",
      },
      {
        path: "/bike-renew-request",
        title: "Yêu cầu thu cũ",
        requireRole: "GET_RENEW_BOOKING",
      },
    ],
  },
  {
    path: "sale",
    fontIcon: "bi bi-shop",
    title: "Bán hàng",
    children: [
      {
        path: "/orders",
        title: "Đơn hàng",
        requireRole: "GET_ORDER",
      },
      {
        path: "/campaigns",
        title: "Chương trình khuyến mãi",
        requireRole: "GET_CAMPAIGN",
      },
      {
        path: "/vouchers",
        title: "Vouchers",
        requireRole: "GET_VOUCHER",
      },
      {
        path: "/gift",
        title: "Quà tặng",
        requireRole: "GET_GIFT",
      },
    ],
  },
  {
    path: "content",
    fontIcon: "bi bi-activity",
    title: "Nội dung",
    children: [
      {
        path: "/sport",
        title: "Môn thể thao",
        requireRole: "GET_SPORT",
      },
      {
        path: "/page",
        title: "Trang",
        requireRole: "GET_PAGE",
      },
      {
        path: "/testimonial",
        title: "Testimonial",
        requireRole: "GET_TESTIMONIAL",
      },
      {
        path: "/banner",
        title: "Banners",
        requireRole: "GET_BANNER",
      },
      {
        path: "/store",
        title: "Chi nhánh",
        requireRole: "GET_STORE",
      },
      {
        path: "/contact",
        title: "Người dùng liên hệ",
        requireRole: "GET_CONTACT",
      },
      {
        path: "/notification",
        title: "Chiến dịch thông báo",
        requireRole: "GET_NOTIFICATION",
      },
    ],
  },
  {
    path: "community",
    fontIcon: "bi-people",
    title: "Cộng đồng",
    children: [
      {
        path: "/user",
        title: "Người dùng",
        requireRole: "GET_USER",
      },
      {
        path: "/strava",
        title: "Người dùng Strava",
        requireRole: "GET_USER",
      },
      {
        path: "/group",
        title: "Nhóm",
        requireRole: "GET_GROUP",
      },
      {
        path: "/points",
        title: "Điểm hoạt động",
        requireRole: "GET_POINT",
      },
    ],
  },
  {
    path: "report",
    fontIcon: "bi-file-bar-graph",
    title: "Báo cáo",
    children: [
      {
        path: "/point-history",
        title: "Lịch sử dùng điểm",
        requireRole: "GET_POINT",
      },
      {
        path: "/report",
        title: "Báo cáo",
        requireRole: "GET_REPORT",
      },
      {
        path: "/store-report",
        title: "Thống kê chi nhánh",
        requireRole: "GET_REPORT",
      },
      {
        path: "/views",
        title: "Lịch sử xem",
        requireRole: "GET_VIEW",
      },
      {
        path: "/log",
        title: "Lịch sử hoạt động",
        requireRole: "GET_LOG",
      },
    ],
  },
  {
    path: "theme",
    fontIcon: "bi-layers",
    title: "Giao diện",
    children: [
      {
        path: "/theme/header",
        title: "Header Menu",
        requireRole: "GET_MENU",
      },
      {
        path: "/theme/service",
        title: "Service Menu",
        requireRole: "GET_MENU",
      },
      {
        path: "/theme/feature",
        title: "Features Menu",
        requireRole: "GET_MENU",
      },
      {
        path: "/theme/topbar",
        title: "Topbar Menu",
        requireRole: "GET_MENU",
      },
    ],
  },
  {
    path: "/media",
    fontIcon: "bi-file-image",
    title: "Thư viện",
    children: [],
  },
  {
    path: "setting",
    fontIcon: "bi-gear",
    title: "Thiết lập",
    children: [
      {
        path: "/setting/general",
        title: "Thiết lập chung",
        requireRole: "GET_SETTING",
      },
      {
        path: "/setting/point",
        title: "Thiết lập điểm",
        requireRole: "GET_CONFIG",
      },
      {
        path: "/payment",
        title: "Phương thức thanh toán",
        requireRole: "GET_PAYMENT",
      },
      {
        path: "/shipping",
        title: "Phương thức giao hàng",
        requireRole: "GET_SHIPPING",
      },
      {
        path: "/app-key",
        title: "App Keys",
        requireRole: "GET_APP_KEY",
      },
      {
        path: "/email-template",
        title: "Email Templates",
        requireRole: "GET_EMAIL_TEMPLATE",
      },
    ],
  },
];
