import { ID } from "_metronic/helpers";
import { AxiosResponse } from "axios";
import api, { deleteDataItem, getData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `points`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, { ...query });

const getItem = async (id: ID): Promise<ItemModel> => getData(URL + "/" + id);

const approvePoint = (userId: ID, pointId: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`${URL}/approval/${pointId}`, {})
    .then((d: AxiosResponse<any>) => d?.data);

const approveAll = () =>
  api({ isToken: true })
    .put(`${URL}/approve-all`)
    .then((d: AxiosResponse<any>) => d?.data);

const denyPoint = (userId: ID, pointId: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`${URL}/reject/${pointId}`, {})
    .then((d: AxiosResponse<any>) => d?.data);

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

const bulkApprove = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await approvePoint(URL, id)));
};

const bulkReject = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await denyPoint(URL, id)));
};

export {
  approveAll,
  approvePoint,
  bulkApprove,
  bulkDelete,
  bulkReject,
  deleteItem,
  denyPoint,
  getItem,
  getList,
};
