import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  CreatedByColumn,
  DefaultCell,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { CurrencyCell } from "app/components/Table/columns/CurrencyCell";
import { Header, SelectionHeader } from "app/components/Table/header";
import { BikeRentalBookingStatusEnum } from "constants/enum";
import { Link } from "react-router-dom";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Mã đơn hàng"
        className="min-w-200px"
      />
    ),
    accessor: "orderNumber",
    Cell: ({ ...props }) => (
      <DefaultCell
        link={`/bike-rental-booking/${props.data[props.row.index].id}/view`}
        value={props.data[props.row.index].orderNumber}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Khách hàng"
        className="min-w-200px"
      />
    ),
    id: "user",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={
          <div className="d-flex justify-content-start flex-column">
            <p className="text-primary">
              {props.data[props.row.index]?.user?.fullname || "-"}
            </p>
          </div>
        }
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Mã xe thuê"
        className="min-w-200px"
      />
    ),
    id: "item",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={`/bike-rental/${
                props.data[props.row.index]?.target?.product?.id
              }/${props.data[props.row.index]?.target?.id}/edit`}
              className="text-primary"
            >
              {props.data[props.row.index]?.target?.code || "-"}
            </Link>
          </div>
        }
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Chi nhánh"
        className="min-w-250px"
      />
    ),
    id: "store",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.store?.name} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    accessor: "status",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={
          BikeRentalBookingStatusEnum[
            props.data[props.row.index]
              .status as keyof typeof BikeRentalBookingStatusEnum
          ]
        }
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tổng cộng"
        className="min-w-150px"
      />
    ),
    accessor: "totalPrice",
    Cell: ({ ...props }) => (
      <CurrencyCell value={props.data[props.row.index]?.totalPrice} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
];

export { COLUMNS };
