import { ID, Response } from "_metronic/helpers";

export type EventTripPlans = {
  image?: string;
  title?: string;
  description?: string;
};

export type EventAmentities = {
  name: string;
  include?: boolean;
};

export type EventServiceOptionType = {
  name: string;
  value: string | number;
  initValue: number;
};

export type EventServiceType = {
  key: string;
  type: string;
  options: EventServiceOptionType[];
};

export type ItemModel = {
  id?: ID;
  image?: string;
  title?: string;
  alias?: string;

  description?: string;
  excerpt?: string;
  awardTerm?: string;

  certTemplate?: string;
  bibTemplate?: string;

  ownerModel?: string;
  owner: any;

  hideOwner?: boolean;

  term?: string;
  rule?: string;
  type: string;
  award: string;
  isFeatured?: boolean;
  address: string;
  challenge: string;
  level?: number;
  features?: string[];
  tripPlans?: EventTripPlans[];
  amentities?: EventAmentities[];
  services?: EventServiceType[];
  price: number;
  sellPrice?: number;
  startedAt?: any;
  endedAt?: string;
  registerStartDate?: string;
  registerEndDate?: string;

  sports: string[];
  categories: string[];
  requiredEvents: string[];
  province?: any;
  gallery: string[];

  seoImage?: string;
  seoTitle?: string;
  seoDescription?: string;
  seoKeyword?: string;

  isActive: boolean;
  visible: boolean;
  isChallenge: boolean;
  isTour: boolean;
  isRoad: boolean;
  noLimitTime: boolean;
  disabled: boolean;

  createdAt?: any;
  updatedAt?: any;
  createdBy?: any;
  updatedBy?: any;
  publishedDate?: string;
  isFilterWeekly: boolean;
  isCompletedOnce: boolean;
  limitMember?: number;
  isPreview?: boolean;
};

export const initialData: ItemModel = {
  id: null,
  image: "",
  title: "",
  alias: "",
  description: "",
  term: "",
  awardTerm: "",
  excerpt: "",
  gallery: [],
  type: "",
  price: 0,
  features: [""],
  tripPlans: [],

  isFeatured: false,
  owner: [],
  ownerModel: "Admin",
  sports: [],
  categories: [],
  requiredEvents: [],
  province: null,

  address: "",
  award: "",
  challenge: "",
  rule: "",

  seoTitle: "",
  seoDescription: "",
  seoKeyword: "",
  isActive: true,
  visible: true,
  isChallenge: false,
  isTour: false,
  isRoad: false,
  noLimitTime: false,
  hideOwner: false,
  disabled: false,
  isCompletedOnce: false,
  isFilterWeekly: false,
  isPreview: false,
};

export type QueryResponse = Response<Array<ItemModel>>;
