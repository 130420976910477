import convertCurrency from "_metronic/helpers/convertData/convert-currency";
import { mapValue } from "_metronic/helpers/formatData";
import { alertSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { Input, MultiSelectDropdown } from "app/components/Form";
import { ListLoading } from "app/components/Table/loading/ListLoading";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";

import { getList as getStoreList } from "../../../content-management/store/core/_requests";
import { initialData, initialStore, ItemModel } from "../core/_models";
import { getInfoChart, getInfoOverview } from "../core/_requests";
import { MixedWidget8 } from "./MixedWidget";
import { StatisticsWidget5 } from "./StatisticWidget";

export const ListWrapper = () => {
  const [data, setData] = useState<ItemModel>(initialData);
  const [storeList, setStoreList] = useState([]);
  const [storeSearchList, setStoreSearchList] = useState([]);
  const [infoChart, setInfoChart] = useState([]);
  const [storeSelect, setStoreSelect] = useState(initialStore);
  const [dateSelect, setDateSelect] = useState({
    fromDate: moment().startOf("day").toDate(),
    toDate: moment().toDate(),
  });

  const formik = useFormik({
    initialValues: data as ItemModel,
    // validationSchema: validation,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let resultChart = [] as any;
      let resultOverview = [] as any;
      let totalRevenue = 0;

      try {
        if (
          values?.stores?.length === 0 ||
          values?.stores[0]?.value === "all"
        ) {
          resultChart = await getInfoChart({
            stores: storeList?.map((item: any) => item.value),
            fromDate: values.fromDate,
            toDate: values.toDate,
          });
        } else {
          resultChart = await getInfoChart({
            stores: values?.stores?.map((item: any) => item.value),
            fromDate: values.fromDate,
            toDate: values.toDate,
          });
        }

        resultOverview = await getInfoOverview({
          stores: values?.stores?.map((item: any) => item.value),
        });

        resultChart?.forEach((item: any) => {
          item?.data?.forEach((item2: any) => {
            totalRevenue += item2.total;
          });
        });

        setData((prev) => {
          return {
            ...prev,
            yesterdayRevenue: resultOverview?.totalPriceYesterday,
            lastMonthRevenue: resultOverview?.totalPriceLastMonth,
            totalRevenue,
          };
        });

        setInfoChart(resultChart as any);

        setStoreSelect(formik.values.stores as any);

        setDateSelect({
          toDate: values.toDate,
          fromDate: values.fromDate,
        });

        alertSuccess("Thống kê thành công");
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    getStoreList({ limit: 9999, page: 1 }).then((data) => {
      setStoreList(mapValue(data?.data));
      setStoreSearchList(mapValue(data?.data));
    });
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <MultiSelectDropdown
            formik={formik}
            options={storeSearchList}
            label="Chọn cửa hàng"
            name="store"
            onSearch={async (x: any) => {
              await getStoreList({ search: x }).then((data) => {
                setStoreSearchList(mapValue(data?.data));
              });
            }}
            onSelect={(values: any) => {
              const newValues = values?.filter(
                (item: any) => item.value !== "all"
              );
              formik.setFieldValue("stores", newValues);
              setStoreSelect(newValues);
              setStoreSearchList(storeList);
            }}
            onRemove={(values: any) => {
              if (values?.length === 0) {
                formik.setFieldValue("stores", initialStore);
                setStoreSelect(initialStore);
                setStoreSearchList(storeList);
                return;
              }

              formik.setFieldValue("stores", values);
              setStoreSelect(values);
              setStoreSearchList(storeList);
            }}
            values={storeSelect}
          />
        </div>

        <div className="col-lg-2">
          <Input
            label="Từ ngày"
            placeholder="Từ ngày"
            type="datetime-local"
            name="publishedDate"
            // disabled={formik.isSubmitting}
            onChange={(val) => {
              formik.setFieldValue("fromDate", moment(val).toDate());
              setDateSelect((prev) => ({
                ...prev,
                fromDate: moment(val).toDate(),
              }));
            }}
            // onBlur={formik.handleBlur}
            values={dateSelect.fromDate}
          />
        </div>

        <div className="col-lg-2">
          <Input
            label="Đến ngày"
            placeholder="Đến ngày"
            type="datetime-local"
            name="publishedDate"
            // disabled={formik.isSubmitting}
            onChange={(val) => {
              formik.setFieldValue("toDate", moment(val).toDate());
              setDateSelect((prev) => ({
                ...prev,
                toDate: moment(val).toDate(),
              }));
            }}
            // onBlur={formik.handleBlur}
            values={dateSelect.toDate}
          />
        </div>

        <div className="col-lg-2 mt-8 ">
          <Button
            type="button"
            className="btn btn-primary w-100"
            onClick={() => formik.handleSubmit()}
          >
            Xác nhận
          </Button>
        </div>
      </div>

      <div className="row" style={{ marginTop: 40 }}>
        <div className="col-lg-4">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="chart-simple"
            color="white"
            iconColor="primary"
            title={convertCurrency(data?.totalRevenue || 0)}
            description={`Tổng doanh thu của cửa hàng`}
          />
        </div>

        <div className="col-lg-4">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="cheque"
            color="dark"
            iconColor="white"
            title={convertCurrency(data?.yesterdayRevenue || 0)}
            titleColor="white"
            description="Doanh thu hôm qua của cửa hàng"
            descriptionColor="white"
          />
        </div>

        <div className="col-lg-4">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="briefcase"
            color="warning"
            iconColor="white"
            title={convertCurrency(data?.lastMonthRevenue || 0)}
            titleColor="white"
            description="Doanh thu tháng trước của cửa hàng"
            descriptionColor="white"
          />
        </div>
      </div>

      <div className="row" style={{ marginTop: 20 }}>
        {infoChart &&
          infoChart?.map((item, index) => {
            return (
              <div key={index} className="col-lg-4" style={{ height: "100%" }}>
                <MixedWidget8
                  data={item}
                  className="card-xl-stretch mb-xl-8"
                  chartColor="primary"
                  chartHeight="150px"
                />
              </div>
            );
          })}
      </div>

      {formik.isSubmitting && <ListLoading />}
    </>
  );
};
