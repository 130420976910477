import convertCurrency from "_metronic/helpers/convertData/convert-currency";

import Item from "./Item";

const PriceTable = ({ data }: { data?: any }) => {
  return (
    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
      {/*begin::Card header*/}
      <div className="card-header">
        <div className="card-title">
          <h2>Thông tin đơn hàng</h2>
        </div>
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body pt-0">
        <div className="table-responsive">
          {/*begin::Table*/}
          <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
            <thead>
              <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th className="min-w-175px">Sản phẩm</th>
                <th className="min-w-100px text-end">SKU</th>
                <th className="min-w-70px text-end">Qty</th>
                <th className="min-w-100px text-end">Unit Price</th>
                <th className="min-w-100px text-end">Total</th>
              </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">
              {data?.items?.map((item: any, index: number) => {
                if (item?.services?.length > 0) {
                  return item?.services?.map((serviceItem: any) => (
                    <Item data={item} key={index} serviceItem={serviceItem} />
                  ));
                } else {
                  return (
                    <Item key={index} data={item} vouchers={data?.vouchers} />
                  );
                }
              })}

              <tr>
                <td colSpan={4} className="text-end">
                  Tổng giá trị hàng hóa
                </td>
                <td className="text-end">{convertCurrency(data?.subtotal)}</td>
              </tr>
              {/* <tr>
                <td colSpan={4} className="text-end">
                  VAT (0%)
                </td>
                <td className="text-end">$0.00</td>
              </tr> */}
              <tr>
                <td colSpan={4} className="text-end">
                  Phí thu hộ
                </td>
                <td className="text-end">
                  {convertCurrency(data?.paymentFee)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="text-end">
                  Phí vận chuyển
                </td>
                <td className="text-end">
                  {convertCurrency(data?.shippingFee)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="text-end">
                  Giảm giá
                </td>
                <td className="text-end">
                  <div>- {convertCurrency(data?.discount)}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="fs-3 text-dark text-end">
                  Tổng cộng
                </td>
                <td className="text-dark fs-3 fw-bolder text-end">
                  {convertCurrency(data?.totalPrice as number)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="">
                  <span className="fw-bolder fs-3">Ghi chú:</span>{" "}
                  {data?.note || "Không có"}
                </td>
              </tr>
            </tbody>
          </table>
          {/*end::Table*/}
        </div>
      </div>
      {/*end::Card body*/}
    </div>
  );
};

export default PriceTable;
