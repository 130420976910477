import { useAuth } from "app/pages/account-management/auth";
import clsx from "clsx";
import { FC } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { checkIsActive, KTIcon, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string | undefined;
  hasBullet?: boolean;
  requireRole?: string;
  fontAwesome?: any;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  requireRole,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { currentUser } = useAuth();
  const { app } = config;

  return (
    <>
      {requireRole && !currentUser?.permissions?.includes(requireRole) ? (
        <></>
      ) : (
        <div className="menu-item">
          <Link
            className={clsx("menu-link without-sub gap-2", {
              active: isActive,
            })}
            to={to}
          >
            {hasBullet && (
              <span className="menu-bullet">
                <span className="bullet bullet-dot"></span>
              </span>
            )}
            {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
              <span className="menu-icon">
                {" "}
                <KTIcon iconName={icon} className="fs-2" />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
              <i className={clsx("bi fs-3", fontIcon)}></i>
            )}
            <span className="menu-title">{title}</span>
          </Link>
          {children}
        </div>
      )}
    </>
  );
};

export { SidebarMenuItem };
