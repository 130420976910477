import { KTCard } from "_metronic/helpers";
import { FormField } from "app/components/Form/Field";
import { VoucherConditionEnum } from "constants/enum";
import React from "react";

import { useVoucherCondition } from "../context/ConditionProvider";
import ConditionConnector from "./ConditionConnector";

const AdvanceSetting = ({ formik }: { formik?: any }) => {
  const { setCondition } = useVoucherCondition();

  const BASIC_FIELDS = [
    {
      label: "Loại điều kiện",
      name: "conditionType",
      type: "dropdown",
      options: Object.keys(VoucherConditionEnum).map((item) => ({
        label: VoucherConditionEnum[item as keyof typeof VoucherConditionEnum],
        value: item,
      })),
      values: formik?.values?.conditionType,
      onChange: (e: any) => {
        if (e.value === "first_time" || e.value === "next_time") {
          formik.setFieldValue("usedNumberPerUser", 1);
          setCondition(null);
        }
      },
      clearable: false,
    },

    {
      label: "Giới hạn lượt sử dụng của mỗi người",
      name: "usedNumberPerUser",
      required: false,
      type: "number",
      disabled:
        formik.values.condition?.type === "first_time" ||
        formik.values.condition?.type === "next_time",
    },
  ];

  return (
    <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
      <KTCard className="p-10 mb-7">
        {BASIC_FIELDS.map((field: any, index: number) => {
          const fieldData = { ...field, formik };
          return (
            <React.Fragment key={index}>
              {!field?.hidden && (
                <div key={fieldData.name} className="fv-row mb-7">
                  {FormField(fieldData)}
                </div>
              )}
            </React.Fragment>
          );
        })}

        {formik.values.conditionType === "custome" ? (
          <div className="fv-row mb-7">
            <ConditionConnector
              formik={formik}
              data={formik.values?.condition}
            />
          </div>
        ) : (
          <></>
        )}
      </KTCard>
    </div>
  );
};

export default AdvanceSetting;
