import { getData, postData } from "services/api";

import { QueryResponse, Setting } from "./_models";

const API_URL = `${process.env.REACT_APP_API_URL}/setting`;

const getSetting = async (): Promise<Setting> => getData(API_URL);

const updateSetting = async (query: any): Promise<QueryResponse> =>
  postData(API_URL, query);

export { getSetting, updateSetting };
