import { KTCard, KTCardBody } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, editSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import {
  Input,
  MultiSelectDropdown,
  Slug,
  Switcher,
} from "app/components/Form";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { CONTENT_VALIDATE_RULE } from "constants/validate-rule";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSports } from "services/actions";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem, getItem } from "../core/_requests";

const EditPage = ({ isTour }: { isTour: boolean }) => {
  const { id } = useParams();
  const [data, setData] = useState<any>(initialData);
  const navigate = useNavigate();
  const [sports, setSports] = useState<any>([]);
  const { isLoading } = useQueryResponse();

  useEffect(() => {
    initPage();
  }, [id]);

  const initPage = async () => {
    getSports({ limit: 10, page: 1 })
      .then((x) => setSports(x.data))
      .catch((x) => console.error(x));

    if (id) {
      getItem(id)
        .then((x) => formatData(x))
        .catch((e: any) => {
          console.error(e);
          if (isTour) {
            navigate("/tour-category");
          } else {
            navigate("/event-category");
          }
        });
    }
  };

  const formatData = (x: ItemModel) => {
    setData({
      ...x,
      sport: x.sport
        ? x.sport?.map((item?: any) => ({ label: item.title, value: item.id }))
        : [],
    });
  };

  const validation = Yup.object().shape({
    ...CONTENT_VALIDATE_RULE,
    sport: Yup.array().min(1, "Hãy chọn một môn thể thao"),
  });

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const data = {
          ...values,
          isTour,
          sport: values.sport.map((x: any) => x?.value || null),
        };

        if (id) {
          await editItem(data, id);
          editSuccess();
          setTimeout(() => window.location.reload(), 1000);
        } else {
          await createItem(data);
          createSuccess();
          navigate("/event-category");
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      {id && <PageTitle>{data.title}</PageTitle>}
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="mb-7">
              <KTCardBody>
                <div className="fv-row mb-7">
                  <Input
                    required
                    formik={formik}
                    label="Tên"
                    placeholder="Tên"
                    name="title"
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    values={formik.values.title}
                  />
                </div>

                <div className="fv-row mb-7">
                  <Slug formik={formik} />
                </div>

                <div className="fv-row mb-7">
                  <MultiSelectDropdown
                    // singleSelect
                    formik={formik}
                    options={mapValue(sports)}
                    required
                    label="Môn thể thao"
                    name="sport"
                    classLabel="fw-bold fs-6 mb-2"
                    classNameSelect="form-control-solid mb-3 mb-lg-0 custom-multi-select"
                    onSearch={async (x: any) => {
                      const result = await getSports({ search: x });
                      setSports(result.data || []);
                    }}
                    onSelect={(values) => {
                      formik.setFieldValue("sport", values);
                    }}
                    onRemove={(values) => {
                      formik.setFieldValue("sport", values);
                    }}
                    values={formik.values.sport}
                  />
                </div>
              </KTCardBody>
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              onSubmit={formik.handleSubmit}
              isEdit={!!id}
            >
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 h-120px border mb-10"
                path="/event-category"
              />
              <div className="fv-row mb-7">
                <Switcher
                  label="Ẩn thanh lọc?"
                  name="isHideFilter"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={!!formik.values.isHideFilter}
                />
              </div>
              <div className="fv-row mb-7">
                <Switcher
                  label="Thử thách?"
                  name="isChallenge"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={!!formik.values.isChallenge}
                />
              </div>
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
