import { ID, Response } from "_metronic/helpers";

export type ItemModel = {
  createdAt?: string;
  createdBy?: string;
  id?: ID;
  statusCode?: number;
  isActive?: boolean;
  name?: string;
  orderNumber?: number;
  updatedAt?: string;
};

export const initialData: ItemModel = {
  isActive: true,
  name: "",
  orderNumber: 0,
};

export type QueryResponse = Response<Array<ItemModel>>;
