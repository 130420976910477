import { convertDate } from "_metronic/helpers/convertData/convert-date";
import { FC } from "react";

type Props = {
  value?: string | number;
  className?: string;
};

const DateCell: FC<Props> = ({ value, className }) => {
  return value ? (
    <span className={className}>{convertDate(value)}</span>
  ) : (
    <></>
  );
};

export { DateCell };
