import { getData, postData, updateData } from "services/api";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/profile`;
export const LOGIN_URL = `${API_URL}/auth/login`;
export const REGISTER_URL = `${API_URL}/auth/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`;

const getUserByToken = (token: string) => {
  return postData(GET_USER_BY_ACCESSTOKEN_URL, {
    access_token: token,
  });
};

const getProfile = async () => {
  return getData(`${API_URL}/admins/profile`).then((res) => res);
};

const updateProfile = async (query: any) => {
  return updateData(`${API_URL}/admins/profile`, query, undefined).then(
    (res) => res
  );
};

export { getProfile, getUserByToken, updateProfile };
