import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { alertSuccess } from "_metronic/helpers/toastify";
import {
  ActionDeleteCell,
  ActionDuplicateCell,
  ActionEditCell,
  CreatedByColumn,
  IsActiveCell,
  ItemColumn,
  SelectionCell,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";

import { ItemModel } from "./_models";
import { duplicate, syncData } from "./_requests";

type Props = {
  id: string;
};

const ViewDetailEvent: FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className="hide-text btn btn-primary btn-sm d-inline-flex align-items-center"
      onClick={() => {
        navigate(`/event/${id}/report`);
      }}
    >
      <i className="bi bi-list-ul"></i>
    </button>
  );
};

const SyncEvent: FC<Props> = ({ id }) => {
  return (
    <button
      type="button"
      className="hide-text btn btn-info btn-sm d-inline-flex align-items-center"
      onClick={() => {
        if (id)
          syncData({ event: [id] }).then(() => {
            alertSuccess("Yêu cầu thành công, vui lòng chờ trong giây lát");
          });
      }}
    >
      <i className="bi bi-arrow-repeat"></i>
    </button>
  );
};

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Sự kiện"
        className="min-w-350px"
      />
    ),
    accessor: "title",
    Cell: ({ ...props }) => (
      <ItemColumn
        classNameContent="w-50"
        customLink={`/event/${props.data[props.row.index].id}/edit`}
        value={props.data[props.row.index]}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Danh mục"
        className="min-w-200px"
      />
    ),
    accessor: "categories",
    Cell: ({ ...props }) => (
      <>
        {props.data[props.row.index]?.categories?.map((x: any) => (
          <p key={x.id}>{x.title}</p>
        ))}
      </>
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    accessor: "isActive",
    Cell: ({ ...props }) => (
      <IsActiveCell value={props.data[props.row.index].isActive} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className="text-end d-flex gap-2 min-w-200px justify-content-end">
        <ActionEditCell
          data={props.row.original}
          useListView={useListView}
          hideText={true}
          isModal={false}
          path={`/event/${props.data[props.row.index].id}/edit`}
        />
        <ActionDeleteCell
          data={props.row.original}
          useListView={useListView}
          hideText={true}
        />
        <ActionDuplicateCell
          hideText={true}
          onClick={() => duplicate(props.data[props.row.index].id)}
          useQueryResponse={useQueryResponse}
        />
        <ViewDetailEvent id={props.data[props.row.index].id} />

        <SyncEvent id={props.data[props.row.index].id} />
      </div>
    ),
  },
];

export { COLUMNS };
