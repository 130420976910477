import { ID } from "_metronic/helpers";
import { AxiosResponse } from "axios";
import api, { deleteDataItem, getData, postData } from "services/api";

import { ItemCampaignModel, QueryResponse } from "./_models";

const URL = `campaigns`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, { ...query, platform: process.env.REACT_APP_PLATFORM });

const getListDeal = async (query: any): Promise<QueryResponse> =>
  getData("deals", { ...query, platform: process.env.REACT_APP_PLATFORM });

const getItem = async (id: ID): Promise<ItemCampaignModel> =>
  getData(URL + "/" + id);

const importDeal = async (id: any): Promise<QueryResponse> =>
  postData(`${URL}/${id}/import-deals`, {});

const createItem = async (query: any): Promise<QueryResponse> =>
  postData(URL, query);

const createDeal = async (query: any): Promise<QueryResponse> =>
  postData("deals", query);

const editItem = (query: any, id: ID): Promise<ItemCampaignModel> =>
  api({ isToken: true })
    .put(`${URL}/${id}`, query)
    .then((d: AxiosResponse<any>) => {
      return d?.data || false;
    });

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const deleteDeal = (id: ID): Promise<QueryResponse> =>
  deleteDataItem("deals", id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

const bulkDeleteDeal = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(
    ids.map(async (id: ID) => await deleteDataItem("deals", id))
  );
};

export {
  bulkDelete,
  bulkDeleteDeal,
  createDeal,
  createItem,
  deleteDeal,
  deleteItem,
  editItem,
  getItem,
  getList,
  getListDeal,
  importDeal,
};
