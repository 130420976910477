import { toAbsoluteUrl } from "_metronic/helpers";
import convertTime from "_metronic/helpers/convertData/convert-time";
import {
  autoTransformSlug,
  dateStartEndWeek,
} from "_metronic/helpers/formatData";
import { alertSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import moment from "moment";
import { Dispatch, useState } from "react";
import { useParams } from "react-router-dom";

import { handleSyncDataWeekly } from "../core/_helper";
import { ItemModel } from "../core/_models";
import { exportCSV } from "../core/_requests";

const HEADERS = [
  "Tên",
  "Ngày đăng kí",
  "Số km",
  "Thời gian chạy",
  "Trạng thái",
  "Hoàn thành",
  "Chi tiết",
];

interface Props {
  eventName?: string;
  data: any;
  dataEventDetail: any;
  setDataEventDetail: Dispatch<
    React.SetStateAction<ItemModel | null | undefined>
  >;
}

const Table = (props: Props) => {
  const { eventName, data, dataEventDetail, setDataEventDetail } = props;
  const { id } = useParams();

  const [startOfWeek, setStartOfWeek] = useState<string>(
    dateStartEndWeek().startOfWeek
  );
  const [endOfWeek, setEndOfWeek] = useState<string>(
    dateStartEndWeek().endOfWeek
  );
  const [isLoadingSyncData, setIsLoadingSyncData] = useState<boolean>(false);

  const _exportCSV = async () => {
    let params = null;

    if (dataEventDetail?.isFilterWeekly) {
      params = {
        isFilterWeekly: true,
        startOfWeek,
        endOfWeek,
      };
    }

    const response = await exportCSV(id, params);
    const blob = new Blob([response.data], {
      type: "text/csv",
    });

    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = autoTransformSlug(eventName);
    a.click();

    alertSuccess("Xuất file CSV thành công");
  };

  const handleDataWeekly = async () => {
    try {
      setIsLoadingSyncData(true);
      const response = await handleSyncDataWeekly(
        id || "",
        startOfWeek,
        endOfWeek
      );

      setDataEventDetail(response);

      alertSuccess("Sync dữ liệu thành công");
      setIsLoadingSyncData(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const renderDate = () => {
  //   if (dataEventDetail?.isFilterWeekly)
  //     return (
  //       <>
  //         <div className="row">
  //           <div className="col-md-6 mb-7">
  //             <Input
  //               label="Ngày bắt đầu tuần"
  //               placeholder="Ngày bắt đầu tuần"
  //               type="datetime-local"
  //               name="startOfWeek"
  //               onChange={(val) => {
  //                 setStartOfWeek(moment(val).format("YYYY-MM-DD"));
  //               }}
  //               values={startOfWeek}
  //             />
  //           </div>
  //           <div className="col-md-6 mb-7">
  //             <Input
  //               valid={(date?: any) => {
  //                 return date.isAfter(moment(startOfWeek));
  //               }}
  //               label="Ngày kết thúc tuần"
  //               placeholder="Ngày kết thúc tuần"
  //               type="datetime-local"
  //               name="endOfWeek"
  //               onChange={(val) => {
  //                 setEndOfWeek(moment(val).format("YYYY-MM-DD"));
  //               }}
  //               values={endOfWeek}
  //             />
  //           </div>
  //         </div>
  //       </>
  //     );

  //   return (
  //     <span
  //       className="select2 select2-container select2-container--bootstrap5"
  //       dir="ltr"
  //       data-select2-id="select2-data-33-vxza"
  //       style={{ width: "100%" }}
  //     >
  //       <span className="selection">
  //         <span
  //           className="select2-selection select2-selection--single w-125px form-select form-select-solid form-select-sm"
  //           role="combobox"
  //           aria-haspopup="true"
  //           aria-expanded="false"
  //           tabIndex={0}
  //           aria-disabled="false"
  //           aria-labelledby="select2-kt_filter_year-container"
  //           aria-controls="select2-kt_filter_year-container"
  //         >
  //           <span
  //             className="select2-selection__rendered"
  //             id="select2-kt_filter_year-container"
  //             role="textbox"
  //             aria-readonly="true"
  //             title="All time"
  //           >
  //             All time
  //           </span>
  //           <span className="select2-selection__arrow" role="presentation">
  //             <b role="presentation" />
  //           </span>
  //         </span>
  //       </span>
  //       <span className="dropdown-wrapper" aria-hidden="true" />
  //     </span>
  //   );
  // };

  return (
    <>
      <div className="card card-flush mt-6 mt-xl-9">
        {/*begin::Card header*/}
        <div className="card-header mt-5">
          {/*begin::Card title*/}
          <div className="card-title flex-column">
            <h3 className="fw-bold mb-1">Số lượng tham gia</h3>
            <div className="fs-6 text-gray-400">
              Tổng cộng {data?.length} người
            </div>
          </div>
          {/*begin::Card title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar my-1">
            <div className="me-6 my-1">
              <Button
                type="button"
                onClick={() => {
                  _exportCSV();
                }}
                className="btn btn-primary"
              >
                Xuất file CSV
              </Button>
            </div>

            {dataEventDetail?.isFilterWeekly && (
              <div className="me-6 my-1">
                <Button
                  type="button"
                  onClick={handleDataWeekly}
                  className="btn btn-primary"
                  disabled={isLoadingSyncData}
                >
                  Sync data tuần
                </Button>
              </div>
            )}

            {/*begin::Select*/}
            {/* <div className="me-6 my-1">
              <select
                id="kt_filter_year"
                name="year"
                data-control="select2"
                data-hide-search="true"
                className="w-125px form-select form-select-solid form-select-sm select2-hidden-accessible"
                data-select2-id="select2-data-kt_filter_year"
                tabIndex={-1}
                aria-hidden="true"
                data-kt-initialized={1}
              >
                <option
                  value="All"
                  selected={true}
                  data-select2-id="select2-data-34-gkso"
                >
                  All time
                </option>
                <option value="thisyear">This year</option>
                <option value="thismonth">This month</option>
                <option value="lastmonth">Last month</option>
                <option value="last90days">Last 90 days</option>
              </select>
              {renderDate()}
            </div> */}
            {/*end::Select*/}

            {/*begin::Search*/}
            <div className="d-flex align-items-center position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-3">
                <span className="path1" />
                <span className="path2" />
              </i>{" "}
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-solid form-select-sm w-150px ps-9"
                placeholder="Search people"
              />
            </div>
            {/*end::Search*/}
          </div>
          {/*begin::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body pt-0">
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <div
              id="kt_profile_overview_table_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="table-responsive">
                <table
                  id="kt_profile_overview_table"
                  className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer"
                >
                  <thead className="fs-7 text-gray-400 text-uppercase">
                    <tr>
                      {HEADERS?.map((item, index) => (
                        <th
                          key={index}
                          className="min-w-300px sorting"
                          tabIndex={0}
                          aria-controls="kt_profile_overview_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Manager: activate to sort column ascending"
                          // style={{ width: "411.875px" }}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="fs-6">
                    {data?.map((item?: any, index?: number) => (
                      <tr className="odd" key={index}>
                        <td>
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Wrapper*/}
                            <div className="me-5 position-relative">
                              {/*begin::Avatar*/}
                              <div className="symbol symbol-35px symbol-circle">
                                <img
                                  alt="Pic"
                                  src={
                                    item.user.avatar ||
                                    toAbsoluteUrl(
                                      "/media/svg/files/blank-image.svg"
                                    )
                                  }
                                />
                              </div>
                              {/*end::Avatar*/}
                            </div>
                            {/*end::Wrapper*/}
                            {/*begin::Info*/}
                            <div className="d-flex flex-column justify-content-center">
                              <a
                                href="s"
                                className="fs-6 text-gray-800 text-hover-primary"
                              >
                                {item.user.fullname} (BIB: {item.bib})
                              </a>
                              <div className="fw-semibold text-gray-400">
                                {item.user.email}
                              </div>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::User*/}
                        </td>

                        <td>
                          {moment(item?.createdAt).format(
                            "DD/MM/YYYY hh:mm:ss"
                          )}
                        </td>

                        <td>{(item?.totalDistance / 1000).toFixed(2)} km</td>

                        <td>{convertTime(item?.totalMovingTime, 2)}</td>

                        <td>
                          {
                            <span className="badge badge-light-success fw-bold px-4 py-3">
                              {item?.status}{" "}
                            </span>
                          }
                        </td>

                        <td>
                          {item?.isCompleted ? (
                            <span className="badge badge-light-success fw-bold px-4 py-3">
                              Hoàn thành
                            </span>
                          ) : (
                            <span className="badge badge-light-danger fw-bold px-4 py-3">
                              Chưa hoàn thành
                            </span>
                          )}
                        </td>

                        <td>
                          <a href="#s" className="btn btn-light btn-sm">
                            View
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
        </div>
        {/*end::Card body*/}
      </div>
    </>
  );
};

export default Table;
