import { Response } from "_metronic/helpers";
export type ItemModel = {
  isActive?: boolean;
  createdBy?: string;
  value?: number;
  type?: string;
  target?: string;
  createdAt?: Date;
  limitUser?: number;
  limitExchangeUser?: number;
  updatedAt?: Date;
  product?: any;
  expired?: string;
  kind?: string;
  startDate?: string;
  id?: string;
  title?: string;
  targetProductVariant?: string;
  voucher?: any;
  productVariant?: any;
};

export const initialData: ItemModel = {
  isActive: true,
  value: 0,
  target: "",
  limitUser: 0,
  limitExchangeUser: 0,
  kind: "user",
};

export type QueryResponse = Response<Array<ItemModel>>;
