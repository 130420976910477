import {
  Input,
  InputDropdown,
  MultiSelectDropdown,
  Slug,
  TextArea,
} from "app/components/Form";
import clsx from "clsx";
import moment from "moment";
import Datetime from "react-datetime";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

import Label from "../Label";
import TextEditor from "../TextEditor";

type Props = {
  label?: string;
  name: string;
  required?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  type?: string;
  formik: any;
  displayValue?: string;
  visible?: boolean;
  options?: Array<any>;
  classNameLabel?: string;
  onSearch?: (x?: string) => any;
  onChange?: (x?: any) => any;
  max?: string | number;
  min?: string | number;
  values?: any;
};

export const FormField = (props: Props) => {
  const {
    type,
    required,
    name,
    label,
    formik,
    disabled,
    options,
    onChange,
    onSearch,
    displayValue,
    classNameLabel,
    visible = true,
    ...prop
  } = props;

  switch (type) {
    case "text":
    case "email":
    case "password":
    case "number":
    case "url":
      return (
        <>
          {visible && (
            <Input
              {...prop}
              required={!!required}
              formik={formik}
              type={type}
              label={label}
              placeholder={label}
              name={name}
              disabled={disabled || formik?.isSubmitting}
              onChange={(e) => {
                onChange ? onChange(e) : formik?.handleChange(e);
              }}
              onBlur={formik?.handleBlur}
              values={formik?.values?.[name]}
            />
          )}
        </>
      );

    case "dropdown":
      return (
        <>
          {visible && (
            <InputDropdown
              clearable={props.clearable}
              onChange={onChange}
              formik={formik}
              required={!!required}
              label={label}
              placeholder={label}
              name={name}
              options={options || []}
              classNameLabel="form-label fs-6 fw-bold"
              classNameDropdown="form-select form-select-solid fw-bolder"
              disabled={formik?.isSubmitting}
              values={props?.values || formik?.values[name]}
              onSearch={(x: string) => onSearch && onSearch(x)}
            />
          )}
        </>
      );

    case "datetime-local":
      return (
        <>
          {visible && (
            <div className="form-input">
              <label
                className={clsx(
                  "mb-2",
                  { required: !!required },
                  classNameLabel
                )}
              >
                {label}
              </label>

              <Datetime
                value={moment(formik.values[name])}
                // initialValue={props?.initialValues}
                onChange={(val: any) => {
                  if (typeof val === "object") {
                    onChange && onChange(val);
                  } else {
                    onChange && onChange(moment(val));
                  }
                }}
              />

              {formik?.touched[name] && formik?.errors[name] && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik?.errors[name]}</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      );

    case "alias":
      return <Slug formik={formik} />;

    case "editor":
      return (
        <TextEditor
          required={required}
          label={label}
          formik={formik}
          disabled={formik.isSubmitting}
          onBlur={formik.handleBlur}
          values={formik.values[name]}
          name={name}
        />
      );

    case "textarea":
      return (
        <TextArea
          maxLength={prop?.max}
          required={required}
          label={label}
          placeholder={label}
          name={name}
          formik={formik}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          values={formik.values[name]}
        />
      );

    case "multiple":
      return (
        <>
          {visible && (
            <MultiSelectDropdown
              displayValue={displayValue || "name"}
              formik={formik}
              options={options}
              label={label}
              name={name}
              classNameSelect="mb-3 mb-lg-0"
              customStyles={{
                multiselectContainer: {
                  width: "100%",
                  padding: "3px 6px",
                },
                searchBox: {
                  border: "none",
                  borderRadius: "4px",
                  fontSize: "14px",
                },
                inputField: {
                  width: "30%",
                },
              }}
              onSearch={(x?: string) => onSearch && onSearch(x)}
              onSelect={(selectedList: Event) => {
                formik.setFieldValue(name, selectedList);
              }}
              onRemove={(selectedItem: Event) => {
                formik.setFieldValue(name, selectedItem);
              }}
              values={props?.values || formik.values[name]}
            />
          )}
        </>
      );
    case "multiple-date":
      return (
        <>
          <Label>{label}</Label>
          <DatePicker
            containerStyle={{ display: "block" }}
            inputClass="form-control form-control-solid"
            value={props?.values || formik.values[name]}
            numberOfMonths={2}
            onChange={(values) => {
              formik.setFieldValue(name, values);
            }}
            multiple
            plugins={[<DatePanel />]}
            format={"DD/MM/YYYY"}
          />
        </>
      );
  }
};
