import Button from "app/components/Button";
import { updateItem } from "app/pages/community-management/group/core/_requests";
import { useState } from "react";

interface PropsType {
  dataDetail: any;
  useQueryResponse: any;
  useListView?: any;
}

export const ActionIsActive = (props: PropsType) => {
  const { dataDetail, useQueryResponse } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { refetch } = useQueryResponse();

  const handleBtn = async () => {
    setIsLoading(true);
    if (dataDetail.isBlocked) {
      try {
        await updateItem(
          {
            isBlocked: !dataDetail.isBlocked,
          },
          dataDetail.id
        );
        refetch();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error:", error);
      }
    } else {
      try {
        await updateItem(
          {
            isBlocked: !dataDetail.isBlocked,
          },
          dataDetail.id
        );
        refetch();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error:", error);
      }
    }
  };

  return (
    <div>
      {!dataDetail.isBlocked ? (
        <Button
          className="btn btn-danger w-100px"
          type="button"
          onClick={handleBtn}
          disabled={isLoading}
        >
          {!isLoading && (
            <span className="svg-icon svg-icon-primary svg-icon-1x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <mask fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <g />
                  <path
                    d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z"
                    fill="#ffff"
                  />
                </g>
              </svg>
            </span>
          )}
          Khóa
          {/* <span className="mx-2 spinner-border spinner-border-sm"></span> */}
          {isLoading && (
            <span className="mx-2 spinner-border spinner-border-sm"></span>
          )}
        </Button>
      ) : (
        <Button
          className="btn btn-primary w-100px"
          onClick={handleBtn}
          disabled={isLoading}
        >
          {!isLoading && (
            <span className="svg-icon svg-icon-primary svg-icon-1x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <mask fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <g />
                  <path
                    d="M15.6274517,4.55882251 L14.4693753,6.2959371 C13.9280401,5.51296885 13.0239252,5 12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L14,10 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C13.4280904,3 14.7163444,3.59871093 15.6274517,4.55882251 Z"
                    fill="#ffff"
                  />
                </g>
              </svg>
            </span>
          )}
          Bật
          {isLoading && (
            <span className="mx-2 spinner-border spinner-border-sm"></span>
          )}
        </Button>
      )}
    </div>
  );
};

export default ActionIsActive;
