import { ID } from "_metronic/helpers";
import { AxiosResponse } from "axios";
import api, { deleteDataItem, getData, postData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `comment`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, { ...query });

const getItem = async (id: ID): Promise<ItemModel> => getData(URL + "/" + id);

const createItem = async (query: any): Promise<QueryResponse> =>
  postData(URL, query);

const replyComment = async (query: any): Promise<QueryResponse> =>
  postData(URL + "/reply", query);

const approveComment = (ids: Array<ID>, query?: any): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`${URL}/approval`, { ids: [...ids], ...query })
    .then((d: AxiosResponse<any>) => d?.data);

const denyComment = (ids: Array<ID>, query?: any): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`${URL}/reject`, { ids: [...ids], targetModel: "Event", ...query })
    .then((d: AxiosResponse<any>) => d?.data);

const editItem = (query: any, id: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`${URL}/${id}`, query)
    .then((d: AxiosResponse<any>) => d?.data || false);

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

const bulkApprove = async (ids: Array<ID>): Promise<void> => {
  await approveComment(ids);
};

const bulkDeny = async (ids: Array<ID>): Promise<void> => {
  await denyComment(ids);
};

export {
  approveComment,
  bulkApprove,
  bulkDelete,
  bulkDeny,
  createItem,
  deleteItem,
  denyComment,
  editItem,
  getItem,
  getList,
  replyComment,
};
