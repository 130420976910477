import {
  PointSourceEnum,
  PointStatusEnum,
  PointTypeEnum,
} from "constants/enum";

export const getPointTypeLabel = (type?: PointTypeEnum) => {
  if (type === PointTypeEnum.PAYMENT) {
    return "Mua hàng";
  }

  if (type === PointTypeEnum.ACTIVITY) {
    return "Hoạt động";
  }

  if (type === PointTypeEnum.GIFT) {
    return "Đổi quà";
  }

  return "-";
};

export const getPointSourceLabel = (type?: PointSourceEnum) => {
  if (type === PointSourceEnum.ORDER) {
    return "Mua hàng";
  }

  if (type === PointSourceEnum.BOOKING) {
    return "Đặt lịch";
  }

  if (type === PointSourceEnum.ACTIVITY) {
    return "Hoạt động";
  }

  if (type === PointSourceEnum.GIFT) {
    return "Đổi quà";
  }

  return "-";
};

export const getPointStatusLabel = (str?: PointStatusEnum): any => {
  if (str === PointStatusEnum.approval) {
    return '<span class="text-success">Được duyệt</span>';
  }

  if (str === PointStatusEnum.reject) {
    return '<span class="text-danger">Từ chối</span>';
  }

  if (str === PointStatusEnum.used) {
    return '<strong class="text-bold">Sử dụng</strong>';
  }

  if (str === PointStatusEnum.waiting) {
    return "Chờ duyệt";
  }

  return "-";
};
