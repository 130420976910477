import { KTCard, KTCardBody } from "_metronic/helpers";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getDetailByAction } from "../core/_requests";

export const Events = () => {
  const { id } = useParams<string>();
  const [events, setEvents] = useState<any>();

  useEffect(() => {
    getDetailByAction("events", id)
      .then((x) => setEvents(x.data))
      .catch((x) => console.log(x));
  }, [id]);

  return (
    <KTCard>
      <div className="card-header">
        <div className="card-title">
          <h4 className="h4">
            Tổng số sự kiện tham gia:{" "}
            <span className="mx-1 text-primary">{events?.length}</span> Sự kiện
          </h4>
        </div>
      </div>
      <KTCardBody>
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
              <tr>
                <th className="min-w-200px">Tên sự kiện</th>
                <th className="min-w-100px">BIB</th>
                <th className="min-w-100px">Ngày tham gia</th>
                <th className="min-w-100px">Trạng thái</th>
                <th className="min-w-100px">Ngày hoàn thành</th>
              </tr>
            </thead>
            <tbody className="fw-6 fw-semibold text-gray-600">
              {events?.map((item: any, index: number) => (
                <tr className="row-member" key={index}>
                  <td>
                    <p className="fw-bolder">{item?.event?.title}</p>
                  </td>
                  <td>
                    <p className="fw-bolder">{item?.bib}</p>
                  </td>
                  <td>
                    <p className="fw-bolder">
                      {item?.createdAt ? convertDate(item?.createdAt) : "-"}
                    </p>
                  </td>
                  <td>
                    <p className="fw-bolder">
                      {item?.isCompleted ? (
                        <span className="badge badge-light-success">
                          Đã hoàn thành
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">
                          Chưa hoàn thành
                        </span>
                      )}
                    </p>
                  </td>

                  <td>
                    <p className="fw-bolder">
                      {item?.completedAt ? convertDate(item?.completedAt) : "-"}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default Events;
