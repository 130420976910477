import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import Menu from "./components/Menu";

const breadcrumbs: Array<PageLink> = [
  {
    title: "Giao diện",
    path: "/theme",
  },
  {
    isSeparator: true,
  },
];

const ThemePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/:id"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Menu</PageTitle>
              <Menu />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ThemePage;
