import { QUERIES } from "_metronic/helpers";
import {
  ListViewProvider,
  useListView,
} from "_metronic/helpers/provider/ListViewProvider";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "_metronic/helpers/provider/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "_metronic/helpers/provider/QueryResponseProvider";
import ListManagement from "app/components/Page";
import { useNavigate } from "react-router-dom";

import { COLUMNS } from "../core/_columns";
import { bulkDelete, deleteItem, getList } from "../core/_requests";

const List = () => {
  const navigate = useNavigate();

  return (
    <>
      <ListManagement
        // isCreate
        columns={COLUMNS}
        actionAddNew={() => navigate("/store/create")}
        useQueryResponseLoading={useQueryResponseLoading}
        queryString={[QUERIES.PAGES_LIST]}
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryResponseData={useQueryResponseData}
        useListView={useListView}
        useQueryRequest={useQueryRequest}
        useQueryResponse={useQueryResponse}
        bulkDelete={bulkDelete}
        deleteItem={deleteItem}
      />
    </>
  );
};

export const ListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider queryString={QUERIES.PAGES_LIST} getList={getList}>
      <ListViewProvider>
        <List />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);
