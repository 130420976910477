import clsx from "clsx";
import { FC, useMemo } from "react";
type Props = {
  className?: string;
  title?: string;
  tableProps: any;
  useQueryRequest: any;
};
const Header: FC<Props> = ({
  className,
  title,
  tableProps,
  useQueryRequest,
}) => {
  const id = tableProps.column.id;
  const { state } = useQueryRequest();

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id;
  }, [state, id]);

  const order: "asc" | "desc" | undefined = useMemo(() => state.order, [state]);

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
    >
      {title}
    </th>
  );
};

export { Header };
