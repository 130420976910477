import { ID, Response } from "_metronic/helpers";
export type CategoryList = {
  id?: ID;
  title: string;
  isActive: boolean;
  alias: string;
  parent?: string;
  createdBy?: any;
  createdAt?: string;
  updatedAt?: string;
};

export type Role = {
  id?: string;
  name?: string;
};

export const initialData = {
  id: null,
  title: "",
  alias: "",
  isActive: true,
  parent: "",
};

export type QueryResponse = Response<Array<CategoryList>>;
