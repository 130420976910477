const convertTime = (time: number, decimalLength = 2) => {
  let unit = "s";
  if (time < 60) {
  } else if (time < 3600) {
    time /= 60;
    unit = "m";
  } else {
    time /= 3600;
    unit = "h";
  }

  if (decimalLength) {
    return `${time.toFixed(decimalLength)} ${unit}`;
  }

  return `${time} ${unit}`;
};

export default convertTime;
