import // CONTENT_VALIDATE_RULE,
// SEO_VALIDATE_RULE,
"constants/validate-rule";

import { KTCard } from "_metronic/helpers";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { FormField } from "app/components/Form/Field";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem, getItem } from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();

  const validation = Yup.object().shape({
    // ...SEO_VALIDATE_RULE,
    // ...CONTENT_VALIDATE_RULE,
  });

  const submit = async (query: any) => {
    try {
      if (id) {
        await editItem(query, id).then(updateSuccess);
      } else {
        await createItem(query).then(() => {
          createSuccess();
          navigate("/banner");
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) => setData(x))
      .catch((e) => {
        console.log(e);
        navigate("/banner");
      });
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await submit({
          ...values,
          isActive: !!values?.isActive,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tiêu đề",
      name: "title",
      required: false,
      type: "text",
    },
    {
      label: "Nội dung",
      name: "description",
      required: false,
      type: "editor",
    },
    {
      label: "Họ tên",
      name: "fullname",
      required: false,
      type: "text",
    },
    {
      label: "Bio",
      name: "bio",
      required: false,
      type: "text",
    },
    {
      label: "Rating",
      name: "rating",
      required: false,
      type: "number",
    },
  ];

  return (
    <>
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };
                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}
            </KTCard>
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={() => formik.handleSubmit()}
            >
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 border mb-10"
                path="/banners"
              />
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
