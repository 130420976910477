import { KTCard } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { FormField } from "app/components/Form/Field";
import { VOUCHER_OPTION } from "constants/voucher";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getItem as getUser } from "../../../community-management/user/core/_requests";

const GeneralSetting = ({ formik }: { formik?: any }) => {
  const { id } = useParams();
  const [dataOwner, setDataOwner] = useState([]);

  const searchDataOwner = (search?: string) => {
    getUser({ limit: 10, page: 1, search: search }).then((data?: any) => {
      setDataOwner(data?.data as any);
    });
  };

  const BASIC_FIELDS = [
    {
      label: "Tiêu đề",
      name: "title",
      required: true,
      type: "text",
    },

    {
      label: "Code",
      name: "code",
      required: true,
      type: "text",
      disabled: !!id,
    },

    {
      label: "Chỉ định người dùng",
      name: "ownerIds",
      required: true,
      type: "multiple",
      options: mapValue(dataOwner),
      onSearch: (search?: string) => {
        searchDataOwner(search);
      },
    },

    {
      label: "Loại hình sử dụng",
      name: "targetType",
      required: true,
      type: "dropdown",
      options: VOUCHER_OPTION.voucherTargetType,
    },

    {
      label: "Loại",
      name: "type",
      required: true,
      type: "dropdown",
      options: VOUCHER_OPTION.voucherType,
    },

    {
      label: "Loại giảm giá",
      name: "discountType",
      required: true,
      type: "dropdown",
      options: VOUCHER_OPTION.discountType,
      onSelect: (e: any) => {
        console.log(e);
      },
    },

    {
      label: "Giá trị giảm",
      name: "discountValue",
      required: true,
      type: "number",
      min: 0,
      max: formik.values.discountType === "percentage" ? 100 : null,
    },

    {
      label: "Giá trị giảm tối đa",
      name: "maxDiscount",
      required: false,
      type: "number",
    },

    {
      label: "Số lượng",
      name: "limitNumber",
      required: true,
      type: "number",
    },

    {
      label: "Mô tả",
      name: "description",
      required: true,
      type: "textarea",
    },

    {
      label: "Ngày bắt đầu",
      name: "startedDate",
      required: false,
      type: "datetime-local",
      onChange: (val?: any) => {
        formik.setFieldValue("startedDate", val);
      },
    },

    {
      label: "Ngày hết hạn",
      name: "expiredDate",
      required: false,
      type: "datetime-local",
      onChange: (val?: any) => {
        formik.setFieldValue("expiredDate", val);
      },
    },
  ];

  useEffect(() => {
    searchDataOwner("");
  }, []);

  return (
    <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
      <KTCard className="p-10 mb-7">
        {BASIC_FIELDS.map((field: any, index?: number) => {
          const fieldData = { ...field, formik };
          return (
            <React.Fragment key={index}>
              {!field?.hidden && (
                <div key={fieldData.name} className="fv-row mb-7">
                  {FormField(fieldData)}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </KTCard>
    </div>
  );
};

export default GeneralSetting;
