import { Response } from "_metronic/helpers";
export type ItemModel = {
  id?: string;
  name: string;
  image: string;
  content: string;
  type: string;
  url: string;
  data: any;
  to: string[];
  createdAt?: string;
  updatedAt?: string;
};

export const initialData: any = {
  content: "",
  type: "",
  name: "",
  to: [],
};

export type QueryResponse = Response<Array<ItemModel>>;
