import clsx from "clsx";

interface PropsType {
  formik?: any;
  classNameLabel?: string;
  classNameText?: string;
  label?: string;
  rows?: number;
  htmlFor?: string;
  id?: string;
  name?: any;
  values?: string;
  required?: boolean;
  onChange?: (e: any) => void;
  cols?: number;
  maxLength?: number | string;
  [restProp: string]: any;
}

export const TextArea = (props: PropsType) => {
  const {
    label,
    rows,
    htmlFor,
    id,
    classNameLabel = "fw-bold fs-6 mb-2",
    classNameText = "form-control form-control-solid mb-3 mb-lg-0",
    formik,
    name,
    cols,
    values,
    maxLength,
    required,
    onChange,
  } = props;

  return (
    <div className="form-group">
      <div className="d-flex justify-content-between">
        <label
          className={clsx(classNameLabel, {
            required: required,
          })}
          htmlFor={htmlFor}
        >
          {label}
        </label>

        {maxLength && (
          <span>
            {values?.length}/{maxLength}
          </span>
        )}
      </div>
      <textarea
        name={name}
        className={clsx(
          classNameText,
          { "is-invalid": formik?.touched[name] && formik?.errors[name] },
          {
            "is-valid": formik?.touched[name] && !formik?.errors[name],
          }
        )}
        cols={cols}
        id={id}
        rows={rows}
        defaultValue={values}
        onChange={onChange}
      ></textarea>
      {formik?.touched[name] && formik?.errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{formik?.errors[name]}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextArea;
