import "./style.css";

import { toAbsoluteUrl } from "_metronic/helpers";
import clsx from "clsx";

export const Memmbers = () => {
  const data = [
    {
      name: "Nguyễn Văn A",
      avatar:
        "https://thegioicotich.vn/wp-content/uploads/2019/09/de-men-phieu-luu-ky-chuong-4-cua-nha-van-to-hoai.png",
      role: "Quản trị viên",
      status: true,
      phone: "01234533465",
      accumulatedPoints: "20 điểm",
      achievements: "Giải nhất sự kiện A",
    },
    {
      name: "Nguyễn Văn B",
      avatar: "",
      status: false,
      role: "Người tạo nhóm",
      phone: "01234533465",
      accumulatedPoints: "20 điểm",
      achievements: "Giải nhất sự kiện B",
    },
    {
      name: "Nguyễn Văn C",
      avatar: "",
      role: "Thành viên trong nhóm",
      status: true,
      phone: "01234533465",
      accumulatedPoints: "20 điểm",
      achievements: "Giải nhất sự kiện C",
    },
    {
      name: "Nguyễn Văn D",
      avatar: "",
      role: "Thành viên trong nhóm",
      status: true,
      phone: "01234533465",
      accumulatedPoints: "20 điểm",
      achievements: "Giải nhất sự kiện D",
    },
    {
      name: "Nguyễn Văn E",
      avatar: "",
      role: "Thành viên trong nhóm",
      status: false,
      phone: "01234533465",
      accumulatedPoints: "20 điểm",
      achievements: "Giải nhất sự kiện E",
    },
    {
      name: "Nguyễn Văn F",
      avatar: "",
      role: "Thành viên trong nhóm",
      status: true,
      phone: "01234533465",
      accumulatedPoints: "20 điểm",
      achievements: "Giải nhất sự kiện F",
    },
  ];
  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">
          <h4 className="h4">
            Tổng số thành viên:{" "}
            <span className="mx-1 text-primary">{data.length}</span> Thành viên
          </h4>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
              <tr>
                <th className="min-w-250px">Tên thành viên</th>
                <th className="min-w-100px">Trạng thái</th>
                <th className="min-w-150px">Số điện thoại</th>
                <th className="min-w-150px">Số điểm tích lũy</th>
                <th className="min-w-150px">Thành tích</th>
              </tr>
            </thead>
            <tbody className="fw-6 fw-semibold text-gray-600">
              {data.map((item: any, index: number) => (
                <tr className="row-member" key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-65px symbol-circle mb-5 me-5">
                        <img
                          src={
                            item.avatar ||
                            toAbsoluteUrl("/media/svg/files/blank-image.svg")
                          }
                          alt="avatar"
                        />
                        {/* <div className="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div> */}
                        <div
                          className={clsx(
                            "position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3",
                            {
                              "bg-success": item.status,
                              "bg-secondary": !item.status,
                            }
                          )}
                        ></div>
                      </div>
                      <div className="mb-3">
                        <a
                          href="##"
                          className="text-hover-primary text-primary"
                        >
                          {item.name}
                        </a>
                        <div className="text-dark">{item.role}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      className={clsx("fs-7 fw-bold", {
                        "badge badge-light-success": item.status,
                        "badge badge-light-danger": !item.status,
                      })}
                    >
                      {item.status ? "Đang hoạt động" : "Ngưng hoạt động"}
                    </span>
                  </td>
                  <td>{item.phone}</td>
                  <td>{item.accumulatedPoints}</td>
                  <td>{item.achievements}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Memmbers;
