import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { alertSuccess } from "_metronic/helpers/toastify";
import {
  CreatedByColumn,
  DefaultCell,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import clsx from "clsx";
import {
  PointOwnerEnum,
  PointSourceEnum,
  PointStatusEnum,
} from "constants/enum";
import { FC } from "react";
import { Column } from "react-table";

import { getPointSourceLabel, getPointStatusLabel } from "../contstant";
import { ItemModel } from "./_models";
import { approvePoint, denyPoint } from "./_requests";

type Props = {
  type?: "approve" | "deny";
  userId?: string;
  pointId?: string;
};

const ActionPoint: FC<Props> = ({ type = "approve", userId, pointId }) => {
  const { refetch } = useQueryResponse();

  return (
    <button
      type="button"
      className={clsx(
        "hide-text btn btn-sm d-inline-flex align-items-center",
        type === "approve" ? "btn-success" : "btn-danger"
      )}
      onClick={async () => {
        type === "approve"
          ? await approvePoint(userId, pointId).then(() => {
              alertSuccess("Bạn đã chấp nhận điểm thành công !");
            })
          : await denyPoint(userId, pointId).then(() => {
              alertSuccess("Bạn đã từ chối điểm thành công !");
            });

        refetch();
      }}
    >
      {type === "approve" ? "Approve" : "Reject"}
    </button>
  );
};

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Đối tượng"
        className="min-w-250px"
      />
    ),
    accessor: "owner",
    Cell: ({ ...props }) => (
      <>
        <p className="text-primary">
          {props.data[props.row.index]?.ownerModel}
        </p>
        <p>
          {props.data[props.row.index]?.ownerModel === PointOwnerEnum.USER
            ? `${props.data[props.row.index]?.owner?.fullname} (${
                props.data[props.row.index]?.owner?.email ||
                props.data[props.row.index]?.owner?.phone ||
                "-"
              })`
            : props.data[props.row.index]?.owner?.title}
        </p>
      </>
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Điểm"
        className="min-w-100px"
      />
    ),
    id: "point",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.point} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Từ"
        className="min-w-150px"
      />
    ),
    id: "source",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={getPointSourceLabel(
          props.data[props.row.index].sourceModel || PointSourceEnum.ACTIVITY
        )}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-150px"
      />
    ),
    id: "status",
    Cell: ({ ...props }) => (
      <div
        dangerouslySetInnerHTML={{
          __html: getPointStatusLabel(
            props?.data[props.row.index]?.status || PointStatusEnum.waiting
          ),
        }}
      ></div>
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-200px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },

  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <>
        {props.data[props.row.index]?.status === "waiting" ? (
          <div className=" d-flex gap-2 min-w-200px">
            <ActionPoint
              userId={props.data[props.row.index]?.owner?.id}
              pointId={props.data[props.row.index]?.id}
            />
            <ActionPoint
              type="deny"
              userId={props.data[props.row.index]?.owner?.id}
              pointId={props.data[props.row.index]?.id}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    ),
  },
];

export { COLUMNS };
