import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  ActionDeleteCell,
  ActionEditCell,
  CreatedByColumn,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { Column } from "react-table";

import { RoleList } from "./_models";

const COLUMNS: ReadonlyArray<Column<RoleList>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tên"
        className="min-w-200px"
      />
    ),
    accessor: "name",
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Mô tả"
        className="min-w-200px"
      />
    ),
    accessor: "description",
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-200px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className="text-end d-flex gap-2 justify-content-end">
        <ActionEditCell
          data={props.row.original}
          useListView={useListView}
          isModal={false}
          path={`/role/${props.data[props.row.index].id}/edit`}
        />
        <ActionDeleteCell
          data={props.row.original}
          useListView={useListView}
          hideText={true}
        />
      </div>
    ),
  },
];

export { COLUMNS };
