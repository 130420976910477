import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, editSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { Input } from "app/components/Form";
import TextArea from "app/components/Form/TextArea";
import { MENU_NAME } from "constants/menu-name";
import { TEXT_REQUIRED } from "constants/text-error";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { initialData, Role } from "../core/_models";
import {
  createItem,
  editItem,
  getItem,
  getListPermissions,
} from "../core/_requests";

export const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<Role>(initialData);
  const [permissions, setPermissions] = useState<any>([]);
  const navigate = useNavigate();
  const { isLoading, refetch } = useQueryResponse();

  useEffect(() => {
    initPage();
  }, [id]);

  const initPage = async () => {
    if (id) {
      getListPermissions().then((data: any) => setPermissions(data));

      getItem(id)
        .then((val) => setData(val))
        .catch(() => navigate("/role"));
    }
  };

  const createItemSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, TEXT_REQUIRED.MIN_ROLE)
      .max(50, TEXT_REQUIRED.MAX_ROLE)
      .required(TEXT_REQUIRED.TITLE_ROLE),
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    navigate("/role");
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: createItemSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (!id) {
          await createItem(values);
          createSuccess();
          cancel(true);
        } else {
          await editItem(values, id);
          editSuccess();
          cancel(true);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <form
      className="form card app-container container-xxl"
      onSubmit={formik?.handleSubmit}
      noValidate
    >
      <div className="card-header border-0 m-0">
        <div className="card-title m-0">
          {!data ? (
            <h1 className="fw-bolder fs-3 m-0">Phân quyền</h1>
          ) : (
            <h1 className="fw-bolder fs-3 m-0">Chỉnh sửa {data.name}</h1>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="fv-row mb-7">
          <Input
            formik={formik}
            classNameLabel="required fw-bold fs-6 mb-2"
            classNameInput="form-control form-control-solid mb-3 mb-lg-0"
            label="Tên"
            placeholder="Tên"
            name="name"
            disabled={formik.isSubmitting || isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.name}
          ></Input>
        </div>
        <div className="fv-row mb-7">
          <TextArea
            formik={formik}
            label="Mô tả"
            placeholder="Mô tả"
            name="description"
            classNameInput="form-control form-control-solid mb-3 mb-lg-0"
            classNameLabel="fw-bold fs-6 mb-2"
            disabled={formik.isSubmitting || isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.description}
          />
        </div>
        <label className="fw-bold fs-6 mb-5">Quyền được thực hiện</label>
        <div className="table-responsive">
          {permissions &&
            Object.keys(permissions).map((menuKey) => {
              return (
                <table className="table align-middle table-row-dashed fs-6 gy-5">
                  <tbody className="text-gray-600 fw-semibold">
                    <tr className="">
                      <td className="text-gray-800" width={200}>
                        {MENU_NAME[menuKey] || menuKey}
                      </td>
                      {Object.keys(permissions[menuKey]).map((x) => (
                        <td key={x}>
                          <Input
                            label={x}
                            className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20 gap-3"
                            classNameLabel="form-check-label"
                            classNameInput="form-check-input"
                            type="checkbox"
                            name={permissions[menuKey][x]}
                            checked={formik.values.permissions.includes(
                              permissions[menuKey][x]
                            )}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              if (checked) {
                                formik.setFieldValue("permissions", [
                                  ...formik.values.permissions,
                                  permissions[menuKey][x],
                                ]);
                              } else {
                                formik.setFieldValue(
                                  "permissions",
                                  formik.values.permissions.filter(
                                    (perm: any) =>
                                      perm !== permissions[menuKey][x]
                                  )
                                );
                              }
                            }}
                          ></Input>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              );
            })}
        </div>
        <div className="text-end mt-12 mb-12">
          <Button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={
              isLoading ||
              formik?.isSubmitting ||
              !formik?.isValid ||
              !formik?.touched
            }
          >
            Xác nhận
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EditPage;
