import { getData } from "services/api";

const getLatestUser = async (query: any): Promise<any> => {
  return getData("users", { ...query, limit: 3 });
};

const getLatestEvent = async (query: any): Promise<any> => {
  return getData("event", { ...query, limit: 3 });
};

const getLatestProduct = async (query: any): Promise<any> => {
  return getData("product", { ...query, limit: 3 });
};

const getLatestOrder = async (query: any): Promise<any> => {
  return getData("order", { ...query, limit: 3 });
};

const getLatestPost = async (query: any): Promise<any> => {
  return getData("post", { ...query, limit: 3 });
};

const getLatestGroup = async (query: any): Promise<any> => {
  return getData("group", { ...query, limit: 3 });
};

export {
  getLatestEvent,
  getLatestGroup,
  getLatestOrder,
  getLatestPost,
  getLatestProduct,
  getLatestUser,
};
