import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Quà tặng",
    path: "/gift",
  },
  {
    isSeparator: true,
  },
];

const GiftManagement = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách quà tặng
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="/create"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>Thêm mới quà tặng</PageTitle>
              <EditPage />
            </>
          }
        />
        <Route
          path="/:id/edit"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Chỉnh sửa quà tặng
              </PageTitle>
              <EditPage />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default GiftManagement;
