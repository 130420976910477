import { KTCard } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { FormField } from "app/components/Form/Field";
import Label from "app/components/Form/Label";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import { getDistrict, getProvinces, getWards } from "services/actions";

const GeneralSetting = ({ formik }: { formik?: any }) => {
  const { id } = useParams();
  const [provinces, setProvinces] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [wards, setWards] = useState<any>([]);

  const BASIC_FIELDS = [
    {
      label: "Tên",
      name: "name",
      required: true,
      type: "text",
    },

    {
      label: "Alias",
      name: "alias",
      required: true,
      type: "alias",
    },

    {
      label: "Địa chỉ",
      name: "address",
      required: false,
      type: "text",
    },

    {
      label: "Email",
      name: "email",
      required: false,
      type: "text",
    },

    {
      label: "Store ID",
      name: "locationId",
      required: false,
      type: "text",
    },

    {
      label: "Số điện thoại",
      name: "phone",
      required: false,
      type: "text",
    },

    {
      label: "Fax",
      name: "fax",
      required: false,
      type: "text",
    },

    {
      label: "Lng",
      name: "lng",
      required: false,
      type: "text",
    },

    {
      label: "Lat",
      name: "lat",
      required: false,
      type: "text",
    },

    {
      label: "Tỉnh thành",
      name: "provinceId",
      required: false,
      type: "dropdown",
      options: provinces ? mapValue(provinces) : [],
    },

    {
      label: "Quận huyện",
      name: "districtId",
      required: false,
      type: "dropdown",
      options: districts ? mapValue(districts) : [],
    },

    {
      label: "Phường",
      name: "wardId",
      required: false,
      type: "dropdown",
      options: wards ? mapValue(wards) : [],
    },

    {
      label: "Bản đồ",
      name: "map",
      required: false,
      type: "textarea",
    },
  ];

  useEffect(() => {
    getProvinces()
      .then((x) => setProvinces(x))
      .catch((x) => console.log(x));
  }, [id]);

  useEffect(() => {
    if (formik.values.provinceId && provinces?.length > 0) {
      const provinceCode = provinces.find(
        (item: any) => item.id === formik.values.provinceId
      )?.code;

      getDistrict({ province_code: provinceCode })
        .then((x) => {
          setDistricts(x);
        })
        .catch((x) => console.log(x));

      getWards({ province_code: provinceCode })
        .then((x) => setWards(x))
        .catch((x) => console.log(x));
    }
  }, [provinces, formik.values.provinceId]);

  useEffect(() => {
    if (formik.values.districtId && districts?.length > 0) {
      const districtCode = districts.find(
        (item: any) => item.id === formik.values.districtId
      )?.code;

      getWards({ district_code: districtCode })
        .then((x) => setWards(x))
        .catch((x) => console.log(x));
    }
  }, [districts, formik.values.districtId]);

  return (
    <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
      <KTCard className="p-10 mb-7">
        {BASIC_FIELDS.map((field: any, index?: number) => {
          const fieldData = { ...field, formik };
          return (
            <React.Fragment key={index}>
              {!field?.hidden && (
                <div key={fieldData.name} className="fv-row mb-7">
                  {FormField(fieldData)}
                </div>
              )}
            </React.Fragment>
          );
        })}
        <div className="fv-row mb-7 relative">
          <Label>Phương tiện</Label>
          <ImageUploadGlobal
            formik={formik}
            name="gallery"
            multiple={true}
            path="/products"
          />
        </div>
      </KTCard>
    </div>
  );
};

export default GeneralSetting;
