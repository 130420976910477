import { getData } from "./api";

export const getEventCategories = async (query?: any): Promise<any> =>
  getData(`${process.env.REACT_APP_API_URL}/event-category`, query);

export const getSports = async (query?: any): Promise<any> =>
  getData(`${process.env.REACT_APP_API_URL}/sport`, query);

export const getProvinces = async (query?: any): Promise<any> =>
  getData(`${process.env.REACT_APP_API_PUBLIC}api/province`, {
    limit: 200,
    page: 1,
    ...query,
    noPaginate: true,
  });

export const getDetailProvinces = async (id?: any): Promise<any> => {
  if (id) {
    return await getData(
      `${process.env.REACT_APP_API_PUBLIC}api/province/${id}`
    );
  }
};

export const getWards = async (query?: any): Promise<any> =>
  getData(`${process.env.REACT_APP_API_PUBLIC}api/ward`, {
    limit: 200,
    page: 1,
    ...query,
    noPaginate: true,
  });

export const getDetailWards = async (id?: any): Promise<any> => {
  if (id) {
    return await getData(`${process.env.REACT_APP_API_PUBLIC}api/ward/${id}`);
  }
};

export const getDistrict = async (query?: any): Promise<any> =>
  getData(`${process.env.REACT_APP_API_PUBLIC}api/district`, {
    limit: 200,
    page: 1,
    ...query,
    noPaginate: true,
  });

export const getDetailDistrict = async (id?: any): Promise<any> => {
  if (id) {
    return await getData(
      `${process.env.REACT_APP_API_PUBLIC}api/district/${id}`
    );
  }
};
