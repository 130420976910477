export const TEXT_REQUIRED = {
  TEXT_REQUIRED: "Vui lòng điền đủ thông tin",
  VALIDATE_EMAIL: "Vui lòng điền đúng định dạng email",
  MIN_EMAIL: "Email phải trên 3 ký tự",
  MAX_EMAIL: "Email phải dưới 50 kí tự",
  REQUIRED_EMAIL: "Email không được để trống",
  MIN_NAME: "Tên phải trên 3 ký tự",
  MAX_NAME: "Tên phải dưới 50 kí tự",
  REQUIRED_NAME: "Tên không được để trống",
  REQUIRED_PASSWORD: "Mật khẩu không được để trống",
  VALIDATE_PASSWORD_CONFIRM: "Vui lòng điền đúng mật khẩu ở trên",
  TITLE: "Tiêu để không được để trống",
  EMAIL: "Email không được để trống",
  PASSWORD: "Password không được để trống",
  CONFIRM_PASSWORD: "Confirm password không được để trống",
  MIN_TITLE: "Tiêu đề phải trên 1 kí tự",
  MAX_TITLE: "Tiêu đề phải dưới 50 kí tự",
  TITLE_BRAND: "Tên thương hiệu không để trống",
  MIN_TITLE_BRAND: "Tên thương hiệu phải trên 3 kí tự",
  MAX_TITLE_BRAND: "Tên thương hiệu phải dưới 50 kí tự",
  MIN_SELECT: "Vui lòng chọn ít nhất 1 kí tự",
  ALIAS: "Alias không được để trống",
  MIN_ALIAS: "Alias phải trên 3 ký tự",
  MAX_ALIAS: "Alias phải dưới 50 kí tự",
  DESCRIPTION: "Mô tả không được để trống",
  MIN_DESCRIPTION: "Mô tả phải trên 3 ký tự",
  MAX_DESCRIPTION: "Mô tả phải dưới 50 kí tự",
  TITLE_CATEGORY: "Tên danh mục không được để trống",
  MIN_TITLE_CATEGORY: "Tên danh mục phải trên 3 ký tự",
  MAX_TITLE_CATEGORY: "Tên danh mục phải dưới 50 ký tự",
  TITLE_COLLECTION: "Tên danh mục sản phẩm không được để trống",
  MIN_TITLE_COLLECTION: "Tên danh mục sản phẩm phải trên 3 ký tự",
  MAX_TITLE_COLLECTION: "Tên danh mục sản phâm phải dưới 50 ký tự",
  TITLE_ROLE: "Tên quyền phải trên 5 ký tự",

  MIN_ROLE: "Tên quyền phải trên 5 ký tự",
  MAX_ROLE: "Tên quyền không được để trống",
  MAX_EXCERPT: "Mô tả ngắn không được quá 250 ký tự",

  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD_REGEX: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
};

export const NOT_FOUND_DATA = "Không có dữ liệu";
