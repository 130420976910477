import {
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  ID,
  initialListView,
  ListViewContextProps,
  WithChildren,
} from "_metronic/helpers";
import { createContext, FC, useContext, useMemo, useState } from "react";

import {
  useQueryResponse,
  useQueryResponseData,
} from "./QueryResponseProvider";

const ListViewContext = createContext<ListViewContextProps>(initialListView);

const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected);
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(
    initialListView.itemIdForUpdate
  );
  const [itemDelete, setItemDelete] = useState<ID>(
    initialListView.itemIdForUpdate
  );
  const [pageTotal, setPageTotal] = useState<any>("");
  const { isLoading } = useQueryResponse();
  const response = useQueryResponseData();
  const disabled = useMemo(
    () => calculatedGroupingIsDisabled(isLoading, response),
    [isLoading, response]
  );
  const isAllSelected = useMemo(() => {
    return calculateIsAllDataSelected(response, selected);
  }, [response, selected]);

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        itemDelete,
        setItemDelete,
        disabled,
        pageTotal,
        setPageTotal,
        isAllSelected,
        onSelect: (id: ID, shiftHeld?: boolean) =>
          groupingOnSelect(id, selected, setSelected, response, shiftHeld),
        onSelectAll: () =>
          groupingOnSelectAll(isAllSelected, setSelected, response),
        clearSelected: () => setSelected([]),
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
};

const useListView = () => useContext(ListViewContext);

export { ListViewProvider, useListView };
