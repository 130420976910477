import { ID, KTCard, KTCardBody } from "_metronic/helpers";
import { removeDuplicates } from "_metronic/helpers/convertData/remove-duplicate";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponseLoading } from "_metronic/helpers/provider/QueryResponseProvider";
import {
  createSuccess,
  deleteSuccess,
  updateSuccess,
} from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import { MultiSelectDropdown, Switcher } from "app/components/Form";
import { FormField } from "app/components/Form/Field";
import Repeater from "app/components/Form/Repeater";
import { StickyEditSidebar } from "app/components/Sidebar";
import { PlatformEnum } from "constants/enum";
import { TEditPage } from "constants/type";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { getList as getProductCategory } from "../../product-category/core/_requests";
import { getList as getProductTypes } from "../../type/core/_requests";
import { initialData } from "../core/_models";
import {
  createChild,
  createItem,
  deleteChild,
  editChild,
  editItem,
  getItem,
} from "../core/_requests";

const EditPage: FC<TEditPage> = ({ breadcrumbs }) => {
  const { id } = useParams();
  const [data, setData] = useState<any | null>(initialData);
  const navigate = useNavigate();
  const isLoading = useQueryResponseLoading();

  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) =>
        setData({
          ...x,
          categoryIds: mapValue(x.categories),
          productTypeIds: mapValue(x.types),
          platform: x?.platform?.map((item?: string) => ({
            label: item,
            value: item,
          })),
        })
      )
      .catch((e) => navigate("/product-attribute"));
  };

  const validation = Yup.object().shape({
    // ...CONTENT_VALIDATE_RULE_NAME,
  });

  const submitItem = async (query: any, idChild: ID) => {
    if (idChild) {
      await editChild(
        {
          ...query,
        },
        idChild
      ).then((res) => updateSuccess(res, true));
    } else {
      await createChild({
        ...query,
        attributeId: id,
      }).then(() => {
        createSuccess();
      });
    }
  };

  const submit = async (query: any) => {
    if (id) {
      await editItem({ ...query }, id).then(updateSuccess);
    } else {
      await createItem({
        ...query,
      }).then(() => {
        createSuccess();
        navigate("/product-attribute");
      });
    }
  };

  const deleteItem = async (idChild?: any) => {
    try {
      await deleteChild(idChild);
      deleteSuccess();
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await submit({
          categoryIds: values?.categoryIds?.map((item?: any) => item.value),
          productTypeIds: values?.productTypeIds?.map(
            (item?: any) => item.value
          ),
          title: values.title,
          alias: values.alias,
          image: values.image,
          platform: values?.platform?.map((item?: any) => item.value),
          isVariant: !!values.isVariant,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "title",
      required: true,
      type: "text",
    },
    {
      label: "Alias",
      name: "alias",
      required: true,
      type: "alias",
    },
    {
      label: "Danh mục",
      name: "categoryIds",
      required: true,
      type: "multiple",
      options: removeDuplicates(
        mapValue([...(formik.values.categories || []), ...productCategoryList]),
        "value"
      ),
      onSearch: (e: string) => {
        getProductCategory({ search: e, limit: 10, page: 1 }).then(
          (data?: any) => {
            setProductCategoryList(data?.data);
          }
        );
      },
    },
    {
      label: "Loại sản phẩm",
      name: "productTypeIds",
      required: true,
      type: "multiple",
      options: removeDuplicates(
        mapValue([...(formik.values.types || []), ...productTypes]),
        "value"
      ),
      onSearch: (e: string) => {
        getProductTypes({ search: e, limit: 10, page: 1 }).then(
          (data?: any) => {
            setProductTypes(mapValue(data?.data));
          }
        );
      },
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>
        {data?.title ? data.title : "Thêm mới"}
      </PageTitle>

      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10">
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };
                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}

              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={Object.keys(PlatformEnum).map((item) => ({
                    label: PlatformEnum[item as keyof typeof PlatformEnum],
                    value: item,
                  }))}
                  label="Nền tảng đăng sản phẩm"
                  name="platform"
                  onSelect={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  values={formik.values.platform}
                />
              </div>
            </KTCard>

            {id ? (
              <KTCard className="mt-10">
                <KTCardBody>
                  <div className="fv-row mb-7">
                    <Repeater
                      isModal
                      onAddNew={(data: any, id: ID) => {
                        submitItem(data, id);
                      }}
                      onDelete={(id?: ID) => {
                        deleteItem(id);
                      }}
                      label="Item"
                      name="item"
                      formik={formik}
                      disabled={formik.isSubmitting}
                      values={formik.values.items}
                      headerItem={["Nội dung", "Alias"]}
                      initItem={[
                        {
                          value: "",
                          alias: "",
                          title: "",
                        },
                      ]}
                      inputItem={[
                        {
                          type: "text",
                          name: "title",
                          label: "Nội dung",
                          required: true,
                        },

                        {
                          type: "alias",
                          name: "alias",
                          label: "Alias",
                          bindingField: "title",
                        },

                        {
                          type: "text",
                          name: "value",
                          label: "Giá trị",
                          displayOutside: false,
                        },
                      ]}
                    />
                  </div>
                </KTCardBody>
              </KTCard>
            ) : (
              <></>
            )}
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={() => {
                formik.handleSubmit();
              }}
            >
              <div className="fv-row mb-7">
                <Switcher
                  label="Is variant"
                  name="isVariant"
                  direction="horizontal"
                  value={formik.values.isVariant}
                  onChange={formik.handleChange}
                />
              </div>
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
