import { FC } from "react";

import { HeaderSearch } from "./HeaderSearch";
import { HeaderToolbar } from "./HeaderToolbar";
import { ListGrouping } from "./ListGrouping";
type Props = {
  useListView?: any;
  useQueryResponse?: any;
  useQueryRequest?: any;
  bulkDelete?: any;
  bulkSync?: any;
  bulkApprove?: any;
  bulkReject?: any;
  queryString?: string[];
  statusFilterOption?: any;
  platformFilter?: boolean;
  actionAddNew?: any;
  sortContent?: any;
  deletable?: boolean;
  showApproveBtn?: boolean;
  showRejectBtn?: boolean;
  filterContent?: any;
  showSyncBtn?: boolean;
  isCreate?: boolean;
  placeholderSearch?: string;
  isSearch?: boolean;
};

const ListHeader: FC<Props> = ({
  bulkSync,
  useListView,
  useQueryRequest,
  useQueryResponse,
  bulkDelete,
  queryString,
  bulkApprove,
  platformFilter,
  showApproveBtn,
  showRejectBtn,
  bulkReject,
  statusFilterOption,
  actionAddNew,
  deletable,
  sortContent,
  filterContent,
  isCreate,
  showSyncBtn,
  placeholderSearch,
  isSearch,
}) => {
  const { selected } = useListView();
  const { updateState, state } = useQueryRequest();
  return (
    <div className="card-header border-0 pt-6">
      {isSearch ? (
        <HeaderSearch
          updateState={updateState}
          state={state}
          placeholderSearch={placeholderSearch}
        />
      ) : (
        <div className="cart-title"></div>
      )}
      <div className="card-toolbar">
        {selected.length > 0 ? (
          <ListGrouping
            showSyncBtn={showSyncBtn}
            showApproveBtn={showApproveBtn}
            showRejectBtn={showRejectBtn}
            bulkSync={bulkSync}
            bulkApprove={bulkApprove}
            bulkReject={bulkReject}
            bulkDelete={bulkDelete}
            deletable={deletable}
            useListView={useListView}
          />
        ) : (
          <HeaderToolbar
            platformFilter={platformFilter}
            statusFilterOption={statusFilterOption}
            actionAddNew={actionAddNew}
            useQueryRequest={useQueryRequest}
            useQueryResponse={useQueryResponse}
            sortContent={sortContent}
            filterContent={filterContent}
            isCreate={isCreate}
          />
        )}
      </div>
    </div>
  );
};

export { ListHeader };
