import { KTIcon } from "_metronic/helpers";

import Button from "../Button";

interface PropsType {
  lableHeader?: string;
  actionCancel?: any;
}

export const ModalHeader = (props: PropsType) => {
  const { lableHeader, actionCancel } = props;
  return (
    <div className="modal-header">
      <>
        <h2 className="fw-bolder">{lableHeader}</h2>
      </>
      <Button
        className="btn btn-icon btn-sm btn-active-icon-primary mb-2"
        data-kt-users-modal-action="close"
        onClick={() => actionCancel(undefined)}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </Button>
    </div>
  );
};
