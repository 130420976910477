import { ID, KTCard, KTCardBody } from "_metronic/helpers";
import { convertDate } from "_metronic/helpers/convertData/convert-date";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import {
  Input,
  MultiSelectDropdown,
  Slug,
  Switcher,
} from "app/components/Form";
import Repeater from "app/components/Form/Repeater";
import SEO from "app/components/Form/SEO/Index";
import TextArea from "app/components/Form/TextArea";
import TextEditor from "app/components/Form/TextEditor";
import { StickyEditSidebar } from "app/components/Sidebar";
import CustomTabs from "app/components/Tabs";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { getProfile } from "app/pages/account-management/profile/core/_requests";
import { TEditPage } from "constants/type";
import { CONTENT_VALIDATE_RULE } from "constants/validate-rule";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getEventCategories, getProvinces, getSports } from "services/actions";
import * as Yup from "yup";

import { Event, initialData } from "../core/_models";
import {
  createItem,
  editItem,
  // getAdminList,
  // getGroupList,
  getItem,
  getList,
  // getUserList,
} from "../core/_requests";

// type Props = {
//   breadcrumbs?: Array<PageLink>;
// };

// const OWNER_MODEL_DATA = [
//   {
//     label: "Admin",
//     value: "Admin",
//   },
//   {
//     label: "User",
//     value: "User",
//   },
//   {
//     label: "Group",
//     value: "Group",
//   },
// ];

const EditPage: FC<TEditPage> = ({ breadcrumbs }) => {
  const { id, child } = useParams();
  const { pathname } = useLocation();

  // Check if is create child page
  const isCreateChildPage = pathname?.split("/")[3] === "create-child";
  const [data, setData] = useState<Event>(initialData);
  const [events, setEvents] = useState([]);
  const [sports, setSports] = useState<any>([]);
  const [provinces, setProvinces] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [selectedOwner, setSelectedOwner] = useState<any>();
  // const [ownerList, setOwnerList] = useState<any>([]);
  const { isLoading } = useQueryResponse();

  const navigate = useNavigate();

  const validation = Yup.object().shape({
    // ...SEO_VALIDATE_RULE,
    ...CONTENT_VALIDATE_RULE,
    // excerpt:
    //   isCreateChildPage || child
    //     ? Yup.string().required("Hãy nhập mô tả ngắn")
    //     : Yup.string(),
    address: Yup.string().required("Hãy nhập thông tin địa chỉ"),
    province: Yup.array().min(1, "Hãy chọn tỉnh thành"),
  });

  const submit = async (query: any) => {
    if (id) {
      if (isCreateChildPage) {
        await createItem(query, id).then(() => {
          createSuccess();
          setTimeout(() => navigate(`/tour/${id}/child`), 500);
        });

        return;
      }

      if (child) {
        await editItem(query, child, id).then(updateSuccess);
      } else {
        await editItem(query, id).then(updateSuccess);
      }
    } else {
      await createItem(query);
      createSuccess();
      setTimeout(() => navigate(`/tour`), 500);
    }
  };

  const formatData = (data?: any, dataParent?: any) => {
    setData({
      ...data,
      categories: mapValue(dataParent?.categories || data?.categories),
      sports: mapValue(dataParent?.sports || data.sports),
      requiredEvents: mapValue(
        dataParent?.requiredEvents || data.requiredEvents
      ),
      province:
        dataParent?.province || data.province
          ? [
              {
                label: dataParent?.province?.name || data.province.name,
                value: dataParent?.province?.id || data.province.id,
              },
            ]
          : [],
      address: dataParent?.address || data?.address,
    });
  };

  const initPage = async (id?: ID) => {
    getSports({ limit: 10, page: 1 })
      .then((x) => setSports(x.data))
      .catch((x) => console.log(x));
    getEventCategories({ limit: 10, page: 1, isTour: true })
      .then((x) => setCategories(x.data))
      .catch((x) => console.log(x));

    if (id && !isCreateChildPage) {
      getItem(id)
        .then((data) => {
          setSelectedOwner({ model: data?.ownerModel, id: data?.owner?.id });
          initData(data);
        })
        .catch((e) => {
          console.log(e);
          navigate("/tour");
        });
    } else {
      initData(initialData);
    }
  };

  const initData = (data: Event) => {
    getProfile().then((data) =>
      setData((prev) => {
        return {
          ...prev,
          owner: data.id,
        };
      })
    );

    if (isCreateChildPage) {
      getItem(id).then((dataParent) => {
        formatData(data, dataParent);
      });
    }

    formatData(data);
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let query = {
        ...values,
        id: values?.id || null,
        owner: {
          ...values.owner,
          id: selectedOwner,
        },
        sports: values.sports
          ?.map((x: any) => x?.value || null)
          .filter((x) => x),
        categories: values.categories
          ?.map((x: any) => x?.value || null)
          .filter((x) => x),
        province:
          values.province
            ?.map((x: any) => x?.value || null)
            .filter((x: any) => x)[0] || null,
        noLimitTime: Boolean(values?.noLimitTime || false),
        startedAt: values.startedAt,
        endedAt: !values.noLimitTime
          ? values.endedAt
          : new Date(
              new Date().setFullYear(new Date().getFullYear() + 100)
            ).toDateString(),
        registerStartDate: values.registerStartDate,
        registerEndDate: !values.noLimitTime
          ? values.registerEndDate
          : new Date(
              new Date().setFullYear(new Date().getFullYear() + 100)
            ).toDateString(),
        isTour: true,
      };

      if (!selectedOwner?.id) {
        query = {
          ...query,
          ownerModel: selectedOwner?.model || "Admin",
          owner: values?.owner?.id,
        };
      } else {
        query = {
          ...query,
          owner: selectedOwner?.id,
        };
      }

      await submit(query);
      setSubmitting(false);
    },
  });

  const tabs: Array<any> = [
    {
      id: "general",
      name: "Thông tin cơ bản",
      content: () => (
        <KTCard>
          <KTCardBody>
            <div className="fv-row mb-7">
              <Input
                required
                formik={formik}
                label="Tên"
                type="text"
                placeholder="Tên"
                name="title"
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={formik.values.title}
              />
            </div>
            <div className="fv-row mb-7">
              <Slug formik={formik} />
            </div>

            {/* {!formik.values.hideOwner && (
              <div className="row">
                <label htmlFor="" className="fw-bold fs-6">
                  Đơn vị tổ chức
                </label>
                <div className="col-md-6 mb-7">
                  <MultiSelectDropdown
                    formik={formik}
                    options={mapValue(OWNER_MODEL_DATA)}
                    singleSelect
                    name="ownerModel"
                    classNameSelect="form-control-solid mb-3 mb-lg-0 custom-multi-select"
                    onSelect={(values: any) => {
                      formik.setFieldValue("ownerModel", values[0].value);
                      setSelectedOwner((prev: any) => {
                        return {
                          ...prev,
                          id: null,
                        };
                      });
                    }}
                    onRemove={(values: any) => {
                      formik.setFieldValue("ownerModel", values[0].value);
                    }}
                    values={[
                      {
                        label: formik.values.ownerModel,
                        value: formik.values.ownerModel,
                      },
                    ]}
                  />
                </div>
                <div className="col-md-6 mb-7">
                  <MultiSelectDropdown
                    formik={formik}
                    options={mapValue(ownerList)}
                    singleSelect
                    name="owner"
                    classLabel="fw-bold fs-6 mb-2"
                    classNameSelect="form-control-solid mb-3 mb-lg-0 custom-multi-select"
                    onSelect={(values: any) => {
                      setSelectedOwner((prev: any) => {
                        return {
                          ...prev,
                          id: values?.[0]?.value,
                        };
                      });
                    }}
                    onRemove={(values: any) => {
                      setSelectedOwner((prev: any) => {
                        return {
                          ...prev,
                          id: values?.[0]?.value,
                        };
                      });
                    }}
                    values={mapValue(
                      ownerList.filter(
                        (item?: any) =>
                          item?.id ===
                          (selectedOwner?.id || formik.values?.owner)
                      )
                    )}
                  />
                </div>
              </div>
            )} */}
            <div className="row">
              <div className="col-md-6 mb-7">
                <Input
                  label="Ngày đăng ký"
                  placeholder="Ngày đăng ký"
                  type="datetime-local"
                  name="registerStartDate"
                  disabled={formik.isSubmitting}
                  onChange={(val) => {
                    formik.setFieldValue("registerStartDate", val);
                  }}
                  onBlur={formik.handleBlur}
                  values={formik.values.registerStartDate}
                  min={convertDate(new Date().toDateString())}
                />
              </div>
              {!formik.values.noLimitTime && (
                <div className="col-md-6 mb-7">
                  <Input
                    label="Ngày kết thúc đăng ký"
                    placeholder="Ngày kết thúc đăng ký"
                    type="datetime-local"
                    name="registerEndDate"
                    disabled={formik.isSubmitting}
                    onChange={(val) => {
                      formik.setFieldValue("registerEndDate", val);
                    }}
                    onBlur={formik.handleBlur}
                    values={formik.values.registerEndDate}
                    min={convertDate(new Date().toDateString())}
                  />
                </div>
              )}
              <div className="col-md-6 mb-7">
                <Input
                  label="Ngày bắt đầu"
                  placeholder="Ngày bắt đầu"
                  type="datetime-local"
                  name="startedAt"
                  disabled={formik.isSubmitting}
                  onChange={(val) => {
                    formik.setFieldValue("startedAt", val);
                  }}
                  onBlur={formik.handleBlur}
                  values={formik.values.startedAt}
                  min={convertDate(new Date().toDateString())}
                />
              </div>
              {!formik.values.noLimitTime && (
                <div className="col-md-6 mb-7">
                  <Input
                    label="Ngày kết thúc"
                    placeholder="Ngày kết thúc"
                    type="datetime-local"
                    name="endedAt"
                    disabled={formik.isSubmitting}
                    onChange={(val) => {
                      formik.setFieldValue("endedAt", val);
                    }}
                    onBlur={formik.handleBlur}
                    values={formik.values.endedAt}
                    min={convertDate(formik.values.startedAt)}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6 mb-7">
                <Input
                  label="Giá tối thiểu"
                  placeholder="Giá tối thiểu"
                  name="price"
                  type="number"
                  min="0"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.price}
                />
              </div>
              <div className="col-md-6 mb-7">
                <Input
                  label="Giá khuyến mãi"
                  placeholder="Giá khuyến mãi"
                  name="sellPrice"
                  type="number"
                  min="0"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.sellPrice}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-7">
                <TextEditor
                  label="Phần thưởng"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onBlur={formik.handleBlur}
                  values={formik.values.award}
                  name="award"
                />
              </div>
              <div className="col-md-6 mb-7">
                <Input
                  type="text"
                  label="Hoàn thành"
                  placeholder="VD: Hoàn thành trong một lần"
                  name="rule"
                  min="3"
                  max="255"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.rule}
                />
              </div>
              {formik.values.isChallenge && (
                <div className="col-md-6 mb-7">
                  <Input
                    label="Thử thách"
                    placeholder="Thử thách"
                    name="challenge"
                    min="3"
                    max="255"
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    values={formik.values.challenge}
                  />
                </div>
              )}

              <div className="col-md-6 mb-7">
                <Input
                  label="Level"
                  placeholder="Level"
                  name="level"
                  type="number"
                  min="0"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.level}
                />
              </div>

              <div className="col-md-6 mb-7">
                <Input
                  label="Thời gian"
                  placeholder="Thời gian"
                  name="time"
                  type="text"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.time}
                />
              </div>

              <div className="col-md-6 mb-7">
                <Input
                  label="Giới hạn người tham gia"
                  placeholder="Giới hạn người tham gia"
                  name="limitMember"
                  type="number"
                  min="0"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.limitMember}
                />
              </div>
            </div>
            {/* {child && (
              <div className="fv-row mb-7">
                <Input
                  label="Mức giá theo km"
                  name="services"
                  formik={formik}
                  type="services"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={
                    formik.values.services?.find((x) => x.key === "distance")
                      ?.options || []
                  }
                />
              </div>
            )} */}
          </KTCardBody>
        </KTCard>
      ),
    },
    {
      id: "content",
      name: "Nội dung",
      content: () => (
        <>
          <KTCard>
            <KTCardBody>
              <div className="fv-row mb-7">
                <TextArea
                  label="Mô tả ngắn"
                  rows={6}
                  required
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.excerpt}
                  name="excerpt"
                />
              </div>
              <div className="fv-row mb-7">
                <TextEditor
                  label="Mô tả"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onBlur={formik.handleBlur}
                  values={formik.values.description}
                  name="description"
                />
              </div>
              <div className="fv-row mb-7">
                <TextEditor
                  label="Chi tiết phần thưởng"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.awardTerm}
                  name="awardTerm"
                />
              </div>
              {/* <div className="fv-row mb-7">
                <TextEditor
                  label="Điều khoản"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.term}
                  name="term"
                />
              </div> */}
            </KTCardBody>
          </KTCard>

          <KTCard className="mt-10">
            <KTCardBody>
              <div className="fv-row mb-7">
                <Repeater
                  label="Điểm nổi bật"
                  name="features"
                  formik={formik}
                  type="feature"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.features}
                  headerItem={["Nội dung"]}
                  initItem={[""]}
                  inputItem={[
                    {
                      type: "text",
                      name: "",
                    },
                  ]}
                />
              </div>
              <div className="fv-row mb-7">
                <Input
                  label="Yêu cầu"
                  name="amentities"
                  formik={formik}
                  type="amentities"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.amentities}
                />
              </div>
              <div className="fv-row mb-7">
                <Input
                  label="Kế hoạch tour"
                  name="tripPlans"
                  formik={formik}
                  type="tripPlans"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.tripPlans}
                />
              </div>
            </KTCardBody>
          </KTCard>
          <SEO className="mt-10" formik={formik} isLoading={isLoading} />
        </>
      ),
      hide: !!!child && !isCreateChildPage,
    },
    {
      id: "advance",
      name: "Thông tin nâng cao",
      content: () => (
        <KTCard>
          <KTCardBody>
            <div className="row">
              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={mapValue(sports)}
                  label="Môn thể thao"
                  name="sports"
                  onSearch={async (x: any) => {
                    const result = await getSports({ search: x });
                    setSports(result.data || []);
                  }}
                  onSelect={(values) => {
                    formik.setFieldValue("sports", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("sports", values);
                  }}
                  values={formik.values.sports}
                />
              </div>
              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={mapValue(categories)}
                  label="Danh mục"
                  name="categories"
                  onSearch={async (x: any) => {
                    const result = await getEventCategories({
                      search: x,
                      limit: 10,
                      page: 1,
                      isTour: true,
                    });
                    setCategories(result.data || []);
                  }}
                  onSelect={(values) => {
                    formik.setFieldValue("categories", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("categories", values);
                  }}
                  values={formik.values.categories}
                />
              </div>
              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={mapValue(events)}
                  label="Sự kiện phải hoàn thành"
                  name="requiredEvents"
                  onSearch={async (x: any) => {
                    const result = await getList({ search: x });
                    setEvents(result.data || []);
                  }}
                  onSelect={(values) => {
                    formik.setFieldValue("requiredEvents", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("requiredEvents", values);
                  }}
                  values={formik.values.requiredEvents}
                />
              </div>
              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={mapValue(provinces)}
                  onSearch={async (x: any) => {
                    const result = await getProvinces({ search: x });
                    setProvinces(mapValue(result) || []);
                  }}
                  singleSelect
                  label="Tỉnh thành"
                  required
                  name="province"
                  onSelect={(values: any) => {
                    formik.setFieldValue("province", values);
                  }}
                  onRemove={(values: any) => {
                    formik.setFieldValue("province", values);
                  }}
                  values={formik.values.province}
                />
              </div>
              <div className="col-md-12 mb-7">
                <Input
                  required
                  label="Địa chỉ"
                  placeholder="Địa chỉ"
                  name="address"
                  min="3"
                  max="255"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.address}
                />
              </div>
            </div>
          </KTCardBody>
        </KTCard>
      ),
    },
    {
      id: "gallery",
      name: "Hình ảnh",
      content: () => (
        <KTCard>
          <KTCardBody>
            <ImageUploadGlobal
              formik={formik}
              path="/events"
              name="gallery"
              multiple={true}
            />
          </KTCardBody>
        </KTCard>
      ),
      hide: !!!child && !isCreateChildPage,
    },
  ];

  useEffect(() => {
    initPage(child || id);
  }, [id, child]);

  // useEffect(() => {
  //   if (formik.values.ownerModel) {
  //     switch (formik.values.ownerModel) {
  //       case "Admin":
  //         getAdminList({ limit: 20, page: 1 })
  //           .then((x) => {
  //             setOwnerList(x.data);
  //           })
  //           .catch((x) => console.log(x));
  //         break;

  //       case "User":
  //         getUserList({ limit: 20, page: 1 })
  //           .then((x) => {
  //             setOwnerList(x.data);
  //           })
  //           .catch((x) => console.log(x));
  //         break;

  //       case "Group":
  //         getGroupList({ limit: 20, page: 1 })
  //           .then((x) => {
  //             setOwnerList(x.data);
  //           })
  //           .catch((x) => console.log(x));
  //         break;
  //     }
  //   }
  // }, [formik.values.ownerModel]);

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>
        {data?.title
          ? data.title
          : isCreateChildPage
          ? "Thêm tour con"
          : "Thêm mới"}
      </PageTitle>

      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <CustomTabs
              className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
              items={tabs.filter((x) => x)}
            />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              onSubmit={formik.handleSubmit}
              isEdit={!isCreateChildPage && !!id}
            >
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 border mb-10"
                path="/events"
              />

              <div className="my-4">
                <Input
                  label="Ngày đăng"
                  placeholder="Ngày đăng"
                  type="datetime-local"
                  name="publishedDate"
                  disabled={formik.isSubmitting}
                  onChange={(val) => formik.setFieldValue("publishedDate", val)}
                  onBlur={formik.handleBlur}
                  values={formik.values.publishedDate}
                />
              </div>

              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Hiển thị"
                  name="visible"
                  onChange={formik.handleChange}
                  value={formik.values.visible}
                />
              </div>

              <div className="fv-row mb-7">
                <Switcher
                  direction="horizontal"
                  label="Không hạn chế thời gian"
                  name="noLimitTime"
                  onChange={formik.handleChange}
                  value={formik.values.noLimitTime}
                />
              </div>

              <div className="fv-row mb-7">
                <Switcher
                  label="Nổi bật"
                  name="isFeatured"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={!!formik.values.isFeatured}
                />
              </div>
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
