import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  ActionDeleteCell,
  ActionEditCell,
  IsActiveCell,
  ItemColumn,
  SelectionCell,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { Column } from "react-table";

import { AdminModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<AdminModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tài khoản"
        className="min-w-350px"
      />
    ),
    id: "item",
    Cell: ({ ...props }) => (
      <ItemColumn
        useListView={useListView}
        value={props.data[props.row.index]}
        isAlias={false}
        isRole={true}
        isModal={true}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Email"
      />
    ),
    accessor: "email",
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-100px"
      />
    ),
    accessor: "isActive",
    Cell: ({ ...props }) => (
      <IsActiveCell value={props.data[props.row.index].isActive} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className="text-end d-flex gap-2 justify-content-end">
        <ActionEditCell
          data={props.data[props.row.index]}
          useListView={useListView}
        />
        <ActionDeleteCell
          data={props.row.original}
          useListView={useListView}
          hideText={true}
        />
      </div>
    ),
  },
];

export { COLUMNS };
