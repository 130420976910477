import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";
import { VoucherConditionProvider } from "./context/ConditionProvider";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Vouchers",
    path: "/vouchers",
  },
  {
    isSeparator: true,
  },
];

const VoucherManagement = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách vouchers
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="/create"
          element={
            <VoucherConditionProvider>
              <PageTitle breadcrumbs={breadbrumbs}>Thêm mới vouchers</PageTitle>
              <EditPage />
            </VoucherConditionProvider>
          }
        />
        <Route
          path="/:id/edit"
          element={
            <VoucherConditionProvider>
              <EditPage breadcrumbs={breadbrumbs} />
            </VoucherConditionProvider>
          }
        />
      </Route>
    </Routes>
  );
};

export default VoucherManagement;
