import { ID, Response } from "_metronic/helpers";

export type ItemModel = {
  id?: ID;
  title?: string;
  description?: string;
  bio?: string;
  fullname?: string;
  image: string;
  isActive?: boolean;
  createdBy?: any;
  updatedBy?: any;
  createdAt?: string;
  updatedAt?: string;
};

export const initialData: ItemModel = {
  id: null,
  image: "",
  title: "",
  description: "",
  bio: "",
  fullname: "",
  isActive: true,
};

export type QueryResponse = Response<Array<ItemModel>>;
