import { QUERIES } from "_metronic/helpers";
import {
  ListViewProvider,
  useListView,
} from "_metronic/helpers/provider/ListViewProvider";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "_metronic/helpers/provider/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "_metronic/helpers/provider/QueryResponseProvider";
import ListManagement from "app/components/Page";
import { useNavigate } from "react-router-dom";

import { COLUMNS } from "../core/_columns";
import { bulkDelete, deleteItem, getList } from "../core/_requests";

const List = ({ noPaginate }: { noPaginate?: boolean }) => {
  const navigate = useNavigate();

  return (
    <>
      <ListManagement
        platformFilter={true}
        columns={COLUMNS}
        actionAddNew={() => navigate("/product/create")}
        useQueryResponseLoading={useQueryResponseLoading}
        queryString={[QUERIES.PRODUCT_LIST]}
        useQueryResponsePagination={
          noPaginate ? undefined : useQueryResponsePagination
        }
        useQueryResponseData={useQueryResponseData}
        useListView={useListView}
        useQueryRequest={useQueryRequest}
        useQueryResponse={useQueryResponse}
        bulkDelete={bulkDelete}
        deleteItem={deleteItem}
      />
    </>
  );
};

export const ListWrapper = ({
  noPaginate,
  customGetList,
}: {
  noPaginate?: boolean;
  customGetList?: any;
}) => (
  <QueryRequestProvider>
    <QueryResponseProvider
      queryString={QUERIES.PRODUCT_LIST}
      getList={customGetList ? customGetList : getList}
    >
      <ListViewProvider>
        <List noPaginate={noPaginate} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);
