import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import Detail from "./components/Detail";
import { ListWrapper } from "./components/List";

const Breadcrumbs: Array<PageLink> = [
  {
    title: "Danh sách quyền",
    path: "/role",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "/role",
    isSeparator: true,
    isActive: false,
  },
];

const RolesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sách quyền</PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="create"
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Thêm mới quyền</PageTitle>
              <Detail />
            </>
          }
        />
        <Route
          path=":id/edit"
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Chỉnh sửa quyền</PageTitle>
              <Detail />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default RolesPage;
