import { ID } from "_metronic/helpers";
import { autoTransformSlug } from "_metronic/helpers/formatData";
import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { Input, InputDropdown, Slug } from "app/components/Form";
import { Switcher } from "app/components/Form/Switcher";
import ModalCustom from "app/components/ModalCustom";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { STRAVA_SPORT_OPTION } from "constants/strava-sports-option";
import { TEXT_REQUIRED } from "constants/text-error";
import { useFormik } from "formik";
import { FC, SetStateAction, useEffect, useState } from "react";
import * as Yup from "yup";

import { initialData, ItemModel } from "../core/_models";
import { createItem as createSport, editItem } from "../core/_requests";

type Props = {
  data: SetStateAction<ID>;
};

export const createRoleSchema = Yup.object().shape({
  title: Yup.string().required(TEXT_REQUIRED.TEXT_REQUIRED),
  alias: Yup.string()
    .min(3, TEXT_REQUIRED.MIN_ALIAS)
    .max(50, TEXT_REQUIRED.MAX_ALIAS)
    .required(TEXT_REQUIRED.ALIAS),
});

const ItemModal: FC<Props> = () => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const [error] = useState(false);
  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const { refetch, isLoading } = useQueryResponse();
  const data: any = itemIdForUpdate;

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };
  const [createItem] = useState<ItemModel>({
    ...initialData,
    ...data,
  });

  const submit = async (query: ItemModel) => {
    if (!query.id) {
      const { id, ...data } = query;
      await createSport(data);
      createSuccess();
    } else {
      await editItem(query, query.id);
      createSuccess();
    }
    cancel(true);
  };

  const formik = useFormik({
    initialValues: createItem,
    validationSchema: createRoleSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const query = {
          ...values,
          isActive: !!values?.isActive,
        };
        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleCancel = () => {
    setItemIdForUpdate(undefined);
  };

  useEffect(() => {
    formik.setFieldValue("alias", autoTransformSlug(formik.values.title));
  }, [formik.values.title]);

  return (
    <>
      <ModalCustom
        labelHeader={`${!itemIdForUpdate ? "Tạo mới" : "Chỉnh sửa"}`}
        actionHeader={setItemIdForUpdate}
        actionCancel={handleCancel}
        formik={formik}
        labelCancel="Đóng"
        labelSubmit="Xác nhận"
      >
        <label className="fw-bold fs-6 mb-2">Icon</label>
        <ImageUploadGlobal
          formik={formik}
          className="w-100px border mb-7"
          path="/sport"
          heightPreview="h-90px"
        />
        <div className="fv-row mb-7">
          <Input
            required
            formik={formik}
            label="Tên"
            placeholder="Tên"
            name="title"
            disabled={formik.isSubmitting || isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.title}
          />
        </div>
        <div className="fv-row mb-7">
          <Slug formik={formik} />
        </div>
        <div className="col-md-12 mb-7">
          <InputDropdown
            label="Strava"
            formik={formik}
            options={Object.values(STRAVA_SPORT_OPTION).map((item) => ({
              label: item,
              value: item,
            }))}
            required
            name="strava"
          />
        </div>

        <div className="col-md-12 mb-7">
          <Input
            label="Điểm số"
            placeholder="Điểm số"
            name="point"
            type="number"
            min="0"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.point}
          />
        </div>
        <div className="fv-row mb-7">
          <Switcher
            label="Trạng thái"
            name="isActive"
            onChange={formik.handleChange}
            value={formik.values.isActive}
            direction="vertical"
          />
        </div>
        {error && (
          <div className="fv-plugins-message-container mt-6">
            <div className="fv-help-block">
              <span role="alert">Có lỗi xảy ra</span>
            </div>
          </div>
        )}
        <div className="text-center pt-3">
          <Button
            type="button"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={() => setItemIdForUpdate(undefined)}
          >
            Đóng
          </Button>

          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
            disabled={
              isLoading ||
              formik?.isSubmitting ||
              !formik?.isValid ||
              !formik?.touched
            }
          >
            Xác nhận
          </Button>
        </div>
      </ModalCustom>
    </>
  );
};

export { ItemModal };
