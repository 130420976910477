import { ID } from "_metronic/helpers";
import { AxiosResponse } from "axios";
import api, { deleteDataItem, getData, postData } from "services/api";

import { ItemModel, QueryResponse } from "./_models";

const URL = `service-types`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, query);

const getItem = async (id: ID): Promise<ItemModel> => getData(URL + "/" + id);

const getService = async (id: ID): Promise<ItemModel> =>
  getData("/services", { type: id });

const createService = async (query: any): Promise<QueryResponse> =>
  postData("/services", query);

const updateService = (query: any, id: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`/services/${id}`, query)
    .then((d: AxiosResponse<any>) => {
      return d?.data || false;
    });

const deleteService = (id: ID): Promise<QueryResponse> =>
  deleteDataItem("/services", id);

const createItem = async (query: any): Promise<QueryResponse> =>
  postData(URL, query);

const editItem = (query: any, id: ID): Promise<ItemModel> =>
  api({ isToken: true })
    .put(`${URL}/${id}`, query)
    .then((d: AxiosResponse<any>) => {
      return d?.data || false;
    });

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

export {
  bulkDelete,
  createItem,
  createService,
  deleteItem,
  deleteService,
  editItem,
  getItem,
  getList,
  getService,
  updateService,
};
