import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  ActionEditCell,
  CreatedByColumn,
  DefaultCell,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { BikeServiceEnum } from "constants/enum";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tên khách hàng"
        className="min-w-150px"
      />
    ),
    accessor: "name",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].owner.fullname} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Số điện thoại"
        className="min-w-150px"
      />
    ),
    accessor: "phone",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].owner.phone} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Loại"
        className="min-w-150px"
      />
    ),
    id: "type",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].renewType.name} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-200px"
      />
    ),
    accessor: "status",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={
          BikeServiceEnum[
            props.data[props.row.index].status as keyof typeof BikeServiceEnum
          ]
        }
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Hãng xe"
        className="min-w-150px"
      />
    ),
    accessor: "brand",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index]?.brand?.title || "-"} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-200px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className=" d-flex gap-2 min-w-200px justify-content-start">
        <ActionEditCell
          data={props.row.original}
          useListView={useListView}
          isModal={false}
          path={`/bike-renew-request/${props.data[props.row.index].id}/edit`}
        />
      </div>
    ),
  },
];

export { COLUMNS };
