import { KTCard, KTCardBody } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { FormikProvider, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

// import { useParams } from "react-router-dom";
import { ItemModel as AttributeInterface } from "../../../attribute/core/_models";
import { getList as getAttributeList } from "../../../attribute/core/_requests";
import { formEditType } from "../../core/_models";
import { removeDuplicates } from "../../utils";
import Attribute from "../attribute/Attribute";

const AttributeStep = React.memo(({ dataProduct }: { dataProduct: any }) => {
  const { id: idProduct } = useParams();
  const formik = useFormikContext<formEditType>();

  const [attributes, setAttributes] = useState<AttributeInterface[]>([]);

  const initPage = async () => {
    if (!dataProduct) return formik.setFieldValue("attributesProduct", []);

    const listAttribute = dataProduct.map((item: any) => {
      return {
        attributeId: item.attributeId,
        attributeItems: [],
      };
    });

    const uniqueListAttribute = listAttribute.filter(
      (obj: any, index: number, self: any) =>
        index ===
        self.findIndex((item: any) => item.attributeId === obj.attributeId)
    );

    const listData = uniqueListAttribute.map((unique: any) => {
      const filter = dataProduct
        .filter((item: any) => item.attributeId === unique.attributeId)
        .map((el: any) => el.id);
      return {
        attributeId: unique.attributeId,
        attributeItems: removeDuplicates(filter),
      };
    });

    listData.forEach((item: any) => {
      formik.setFieldValue(
        `attributeItemsProduct.${item.attributeId}`,
        item.attributeItems
      );
    });

    const listAttributeItem = uniqueListAttribute.map(
      (item: any) => item.attributeId
    );

    formik.setFieldValue("attributesProduct", listAttributeItem);
  };

  const variantOptions = useMemo(() => {
    return mapValue(attributes);
  }, [attributes]);

  const variantOptionsRemain = useCallback(
    (id: string) => {
      const optionTaken = formik.values?.attributesProduct.filter(
        (v) => v !== id
      );

      return variantOptions?.filter(
        (v?: any) => !optionTaken?.includes(v.value as string)
      );
    },
    [variantOptions, formik?.values?.attributesProduct]
  );

  useEffect(() => {
    getAttributeList({ limit: 1000, page: 1 }).then((x) =>
      setAttributes(x.data || [])
    );
  }, []);

  useEffect(() => {
    if (!idProduct) return;
    initPage();
  }, [idProduct, dataProduct]);

  return (
    <>
      <KTCard>
        <KTCardBody>
          <div className="fv-row mb-7">
            <FormikProvider value={formik}>
              <div className="form">
                <div className="d-flex flex-column flex-lg-row">
                  <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                    <div className="fv-row mb-7">
                      <label className="fw-bold fs-6 ">Mẫu mã</label>

                      {formik.values &&
                        formik.values.attributesProduct &&
                        formik?.values?.attributesProduct?.map(
                          (id: string, index: number) => (
                            <div key={id} className="mt-6">
                              <Attribute
                                isAttributeProduct
                                value={id}
                                options={variantOptionsRemain(id)}
                                onChange={(value) => {
                                  const attributes = [
                                    ...formik.values?.attributesProduct,
                                  ];

                                  formik.setFieldValue(
                                    `attributeItemsProduct.${value}`,
                                    []
                                  );
                                  attributes[index] = value;
                                  formik.setFieldValue(
                                    "attributesProduct",
                                    attributes
                                  );
                                }}
                                onDelete={() => {
                                  const attributes = [
                                    ...formik.values?.attributesProduct,
                                  ];
                                  formik.setFieldValue(
                                    `attributeItemsProduct.${id}`,
                                    []
                                  );
                                  formik.setFieldValue(
                                    "attributesProduct",
                                    attributes.filter((v) => v !== id)
                                  );
                                }}
                              />
                            </div>
                          )
                        )}

                      {formik.values &&
                        formik.values.attributesProduct &&
                        variantOptionsRemain("").length > 0 && (
                          <div className="row mx-6 mt-7">
                            <div className="w-200px">
                              <button
                                className="btn btn-sm btn-light-primary"
                                type="button"
                                onClick={() => {
                                  const attributes = [
                                    ...formik.values?.attributesProduct,
                                  ];

                                  const optionId = variantOptionsRemain("")[0]
                                    .value as string;

                                  attributes.push(optionId);

                                  formik.setFieldValue(
                                    `attributeItemsProduct.${optionId}`,
                                    []
                                  );

                                  formik.setFieldValue(
                                    "attributesProduct",
                                    attributes
                                  );
                                }}
                              >
                                <i className="ki-duotone ki-plus fs-3"></i>
                                Thêm tùy chọn khác
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </FormikProvider>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  );
});

export default AttributeStep;
