import { KTIcon } from "_metronic/helpers";
import { EventTripPlans } from "app/pages/event-management/events/core/_models";
import { useEffect, useState } from "react";

import { InputProps } from "../Input/index";
import Modal from "./RepeaterAmentities/Modal";

const AddableInput = ({ name, formik, values, label }: InputProps) => {
  const [list, setList] = useState<Array<EventTripPlans>>(values);
  const [dataModal, setDataModal] = useState<
    (EventTripPlans & { id: number }) | null
  >(null);
  const [upload, setUpload] = useState<boolean>(false);

  useEffect(() => {
    setList(values);
  }, [values]);

  return (
    <div className="d-flex flex-column mb-15 fv-row">
      <div className="fs-5 fw-bold form-label mb-3">{label}</div>

      <div className="table-responsive">
        {/*begin::Table*/}
        <div
          id="kt_create_new_custom_fields_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="table-responsive">
            <table
              id="kt_create_new_custom_fields"
              className="table align-middle table-row-dashed fw-semibold fs-6 gy-5 dataTable no-footer"
            >
              {/*begin::Table head*/}
              <thead>
                <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                  <th
                    className="pt-0 sorting_disabled"
                    rowSpan={1}
                    colSpan={1}
                    // style={{ width: "100%" }}
                  >
                    Nội dung
                  </th>

                  <th className="pt-0 sorting_disabled" rowSpan={1} colSpan={1}>
                    Chỉnh sửa
                  </th>

                  <th
                    className="pt-0 text-end sorting_disabled"
                    rowSpan={1}
                    colSpan={1}
                    // style={{ width: "80.7px" }}
                  >
                    Xóa
                  </th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody>
                {list?.length > 0 ? (
                  list.map((item, index) => {
                    return (
                      <tr className="odd" key={index}>
                        <td>
                          <p>{item?.title}</p>
                        </td>

                        <td
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDataModal({ ...item, id: index });
                          }}
                        >
                          <KTIcon iconName="pencil" />
                        </td>

                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3"
                            data-kt-action="field_remove"
                            onClick={() => {
                              setList((prev) => {
                                prev.splice(index, 1);
                                return [...prev];
                              });

                              formik.setFieldValue(name, list);
                            }}
                          >
                            <i className="ki-duotone ki-trash fs-3">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                              <span className="path5" />
                            </i>{" "}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>

              {/*end::Table body*/}
            </table>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" />
          </div>
        </div>
        {/*end:Table*/}
      </div>

      <button
        type="button"
        className="btn btn-light-primary me-auto"
        id="kt_create_new_custom_fields_add"
        onClick={() => {
          setList((prev) => {
            return [
              ...prev,
              {
                title: "",
                description: "",
                image: "",
              },
            ];
          });

          setDataModal({
            title: "",
            description: "",
            image: "",
            id: list.length,
          });
        }}
      >
        Thêm
      </button>
      {/*end::Add custom field*/}

      {dataModal ? (
        <Modal
          formik={formik}
          data={dataModal}
          setData={setDataModal}
          setList={setList}
          onChange={() => {
            formik.setFieldValue(name, list);
          }}
          upload={upload}
          setUpload={setUpload}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddableInput;
