import Button from "app/components/Button";
import ModalCustom from "app/components/ModalCustom";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { EventTripPlans } from "app/pages/event-management/tours/core/_models";
import { FC } from "react";

import { Input } from "../../Input/index";
import TextEditor from "../../TextEditor";

type Props = {
  formik?: any;
  data?:
    | (EventTripPlans & {
        id: number;
      })
    | null;
  setList?: React.Dispatch<React.SetStateAction<EventTripPlans[]>>;
  setData?: React.Dispatch<
    React.SetStateAction<
      | (EventTripPlans & {
          id: number;
        })
      | null
    >
  >;
  onChange?: () => void;
  upload?: boolean;
  setUpload: React.Dispatch<React.SetStateAction<boolean>>;
};

const Modal: FC<Props> = ({ formik, data, setData, setList, onChange }) => {
  return (
    <ModalCustom
      labelHeader={`${!true ? "Tạo mới" : "Chỉnh sửa"}`}
      actionHeader={() => {
        setData && setData(null);
      }}
      actionCancel={() => {
        setData && setData(null);
      }}
      labelCancel="Đóng"
      labelSubmit="Xác nhận"
    >
      <div className="fv-row mb-7">
        <ImageUploadGlobal
          formik={formik}
          className="mx-auto w-100 border mb-10"
          customUpdate
          path="/tours"
          uploaded={(val: string) => {
            setData &&
              setData((prev?: any) => {
                if (data) {
                  data.image = val;
                }

                return { ...prev };
              });
          }}
        />
        {/* <ImageUploadGlobal
          uploaded={(val: string) => {
            setData &&
              setData((prev?: any) => {
                if (data) {
                  data.image = val;
                }

                return { ...prev };
              });

            // setData && setData(null);
          }}
          name="tripplan"
          formik={formik}
          customUpdate
          height={200}
          className="mx-auto w-100 border my-5"
          path="/events"
        /> */}
      </div>
      <div className="fv-row mb-7">
        <Input
          label="Tiêu đề"
          placeholder="Tiêu đề"
          name="title"
          type="text"
          classNameInput="form-control form-control-solid mb-3 mb-lg-0"
          classNameLabel="required fw-bold fs-6 mb-2"
          onChange={(e) => {
            setData &&
              setData((prev?: any) => {
                if (data) {
                  data.title = e.target.value;
                }

                return {
                  ...prev,
                };
              });
          }}
          values={data?.title}
        />
      </div>
      <div className="fv-row mb-7">
        <TextEditor
          label="Mô tả"
          formik={formik}
          disabled={formik.isSubmitting}
          onChangeEditor={(val) => {
            setData &&
              setData((prev?: any) => {
                if (data) {
                  data.description = val;
                }

                return { ...prev };
              });
          }}
          onBlur={formik.handleBlur}
          values={data?.description}
        />
      </div>

      {false && (
        <div className="fv-plugins-message-container mt-6">
          <div className="fv-help-block">
            <span role="alert">Có lỗi xảy ra</span>
          </div>
        </div>
      )}
      <div className="text-center pt-10">
        <Button
          type="button"
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
          onClick={() => {
            setData && setData(null);
          }}
        >
          Đóng
        </Button>

        <Button
          type="button"
          onClick={() => {
            setList &&
              setList((prev) => {
                if (data) {
                  prev[data?.id] = data;
                }

                return [...prev];
              });

            setData?.(null);

            onChange && onChange();
          }}
          className="btn btn-primary"
          //   disabled={
          //     isLoading ||
          //     formik?.isSubmitting ||
          //     !formik?.isValid ||
          //     !formik?.touched
          //   }
        >
          Xác nhận
        </Button>
      </div>
    </ModalCustom>
  );
};

export default Modal;
