import moment from "moment";

export const formatDateTime = (date: string) => {
  if (!date) {
    return "-";
  }

  // Chuyển đổi ngày tháng về định dạng Date
  const formattedDate = new Date(date);

  const day = formattedDate.toLocaleString("vn-VN", { day: "2-digit" });
  const month = formattedDate.toLocaleString("vn-VN", { month: "2-digit" });
  const year = formattedDate.toLocaleString("vn-VN", { year: "numeric" });

  const result = `${day}/${month}/${year}`;

  return result;
};

export const formatListUser = (list: any) => {
  return list?.map((item: any) => {
    return {
      id: item.id,
      fullname: item.fullname || "-",
      email: item.email || "-",
      phone: item.phone || "-",
      isActive: item.isActive,
      role: item.role || "-",
      createdAt: formatDateTime(item.createdAt) || "-",
      updatedAt: formatDateTime(item.updatedAt) || "-",
    };
  });
};

export const formatListRole = (list: any) =>
  list?.map((item: any) => ({
    id: item.id,
    name: item.name || "-",
    createdAt: formatDateTime(item.createdAt) || "-",
    description: item.description || "-",
    permissions: item.permissions || "-",
  }));

export const formatCategory = (list: any) =>
  list?.map((item: any) => ({
    id: item.id,
    title: item.title || "-",
    isActive: item.isActive,
    parent: item.parent || "-",
    alias: item.alias || "-",
    createdAt: formatDateTime(item.createdAt) || "-",
    updatedAt: formatDateTime(item.updatedAt) || "-",
  }));

export const formatData = (list: Array<any>) => {
  return list?.map((item: any) => ({
    ...item,
    createdAt: formatDateTime(item?.createdAt) || "-",
    updatedAt: formatDateTime(item.updatedAt) || "-",
  }));
};

export const formatListCollection = (list: any) =>
  list?.map((item: any) => ({
    id: item.id,
    title: item.title || "-",
    description: item.description || "-",
    image: item.image || "-",
    seoTitle: item.seoTitle || "-",
    seoDescription: item.seoDescription || "-",
    excerpt: item.excerpt || "-",
    parent: item.parent || "-",
    alias: item.alias || "-",
    isActive: item.isActive,
    createdBy: item.createdBy || "-",
    createdAt: formatDateTime(item.createdAt) || "-",
    updatedAt: formatDateTime(item.updatedAt) || "-",
  }));

export const formatListBrand = (list: any) =>
  list?.map((item: any) => ({
    id: item.id,
    title: item.title || "-",
    description: item.description || "-",
    logo: item.logo || "-",
    alias: item.alias || "-",
    isActive: item.isActive,
    createdBy: item.createdBy || "-",
    createdAt: formatDateTime(item.createdAt) || "-",
    updatedAt: formatDateTime(item.updatedAt) || "-",
  }));

export const formatListSport = (list: any) =>
  list?.map((item: any) => ({
    id: item.id,
    title: item.title || "-",
    image: item.image || "-",
    alias: item.alias || "-",
    isActive: item.isActive,
    createdBy: item.createdBy || "-",
    createdAt: formatDateTime(item.createdAt) || "-",
    updatedAt: formatDateTime(item.updatedAt) || "-",
  }));

export const formatListGroup = (list: any) =>
  list?.map((item: any) => ({
    id: item.id,
    image: item.image || "-",
    description: item.description || "-",
    isBlocked: item.isBlocked,
    isActive: item.isActive,
    isPublic: item.isPublic,
    owner: item.owner || "-",
    title: item.title || "-",
    createdBy: item.createdBy || "-",
    createdAt: formatDateTime(item.createdAt) || "-",
    updatedAt: formatDateTime(item.updatedAt) || "-",
  }));

const removeDuplicate = (str: string, specificLetter?: string) => {
  let result = "";

  for (let i = 0; i < str?.length; i++) {
    result += str?.[i];

    if (str?.[i] === specificLetter) {
      while (i + 1 < str?.length && str?.[i + 1] === specificLetter) {
        i++;
      }
    }
  }

  return result;
};

export const autoTransformSlug = (text: string | undefined) => {
  let slug: any = "";
  //Đổi chữ hoa thành chữ thường
  slug = text?.toLowerCase();

  //Đổi ký tự có dấu thành không dấu
  slug = slug?.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
  slug = slug?.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
  slug = slug?.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
  slug = slug?.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
  slug = slug?.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
  slug = slug?.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
  slug = slug?.replace(/đ/gi, "d");
  slug = slug?.replace(
    // eslint-disable-next-line
    /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi,
    ""
  );
  //Đổi khoảng trắng thành ký tự gạch ngang
  slug = slug?.replace(/ /gi, "-");
  //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
  //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
  // eslint-disable-next-line
  slug = slug?.replace(/\-\-\-\-\-/gi, "-");
  // eslint-disable-next-line
  slug = slug?.replace(/\-\-\-\-/gi, "-");
  // eslint-disable-next-line
  slug = slug?.replace(/\-\-\-/gi, "-");
  // eslint-disable-next-line
  slug = slug?.replace(/\-\-/gi, "-");
  //Xóa các ký tự gạch ngang ở đầu và cuối
  slug = "@" + slug + "@";
  // eslint-disable-next-line
  slug = slug?.replace(/\@\-|\-\@|\@/gi, "");
  //Xóa các ký tự đặc biệt unicode
  slug = slug?.replace(/[\u007F-\uFFFF]/g, "");

  return removeDuplicate(slug, "-");
};

export const mapValue = (data: any) =>
  data?.map((item: any) => ({
    label: item?.name || item?.title || item?.fullname || item?.label || "-",
    value: item?.id || item?.value,
  }));

export const dateStartEndWeek = () => {
  const today = moment();
  const startOfWeek = today.clone().startOf("isoWeek").format("YYYY-MM-DD");
  const endOfWeek = today.clone().endOf("isoWeek").format("YYYY-MM-DD");

  return { startOfWeek, endOfWeek };
};
