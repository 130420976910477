import { autoTransformSlug } from "_metronic/helpers/formatData";
import Button from "app/components/Button";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Input } from "../Input";

type Props = {
  formik?: any;
  bindingValue?: string;
  onChange?: (e?: any) => void;
  value?: string;
  disabled?: boolean;
};

export const Slug = (props: Props) => {
  const { formik, bindingValue, onChange, value } = props;
  const { id } = useParams();

  const generateAlias = () => {
    formik?.setFieldValue(
      "alias",
      autoTransformSlug(formik?.values.title || formik?.values.name)
    );
  };

  useEffect(() => {
    if (!formik?.values.title && !formik?.values.name && !bindingValue) {
      formik?.setFieldValue("alias", autoTransformSlug(""));
      return;
    }

    const generateSlug = setTimeout(() => {
      if (bindingValue) {
        onChange && onChange(bindingValue);
      } else {
        if (!id) {
          generateAlias();
        }
      }
    }, 300);

    return () => clearTimeout(generateSlug);
  }, [formik?.values.title, formik?.values.name, bindingValue]);

  useEffect(() => {
    if (formik?.values?.alias) {
      formik?.setFieldValue("alias", formik?.values?.alias);
    }
  }, []);

  return (
    <div
      className="d-flex"
      style={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Input
        required
        style={{ width: "100%", marginRight: 20 }}
        label="Alias"
        placeholder="Alias"
        name="alias"
        type="text"
        disabled={formik?.isSubmitting || props.disabled}
        onChange={(e) => {
          autoTransformSlug(e.target.value);
          formik?.handleChange(e);
        }}
        onBlur={formik?.handleBlur}
        values={value || formik?.values.alias}
      />

      <div className="text-end mt-8">
        <Button
          type="button"
          disabled={formik?.isSubmitting}
          onClick={() => generateAlias()}
          style={{ whiteSpace: "nowrap" }}
          className="btn btn-primary"
        >
          Theo tiêu đề
        </Button>
      </div>
    </div>
  );
};
