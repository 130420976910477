import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import EditPage from "./components/Edit";
import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Thuộc tính sản phẩm",
    path: "/product-attribute",
  },
  {
    isSeparator: true,
  },
];

const AttributePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách thuộc tính sản phẩm
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
        <Route
          path="/create"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Thêm mới thuộc tính sản phẩm
              </PageTitle>
              <EditPage />
            </>
          }
        />
        <Route
          path=":id/edit"
          element={
            <>
              <EditPage breadcrumbs={breadbrumbs} />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default AttributePage;
