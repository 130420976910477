import { updateSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import SEO from "app/components/Form/SEO/Index";
import CustomTabs from "app/components/Tabs";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  defaultInformationProduct,
  formEditType,
  initialValue,
  initRentalPrice,
} from "../core/_models";
import {
  createItem,
  createVariant,
  deleteVariant,
  editItem,
  editVariant,
  getItem,
} from "../core/_requests";
import { removeDuplicates } from "../utils";
import AdvanceSetting from "./tabs/AdvanceSetting";
import AttributeStep from "./tabs/AttributeStep";
import GeneralSetting from "./tabs/GeneralSetting";
import ProductUpsell from "./tabs/ProductUpsell";
import SideBar from "./tabs/SideBar";
import VariantStep from "./tabs/VariantStep";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<formEditType>(initialValue);
  const [variantOptions, setVariantOptions] = useState<any[]>([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x: any) => {
        // setData(x);
        setDefaultValues(x);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        navigate("/product");
      });
  };

  const setDefaultValues = (x?: any) => {
    const attributeItems: any = {};

    x?.attributes &&
      x.attributes.forEach((e: any) => {
        if (e?.attribute) {
          attributeItems[e?.attribute?.id] = e?.items?.map(
            (item: any) => item?.id
          );
        }
      });

    setData({
      ...x,

      dataAttribute: x?.attributeItems || [],

      collectionIds: x?.collections
        ? x.collections.map((e: any) => ({
            label: e.title,
            value: e?.id,
          }))
        : [],

      // collectionIds: x?.collections?.map((item?: any) => item.id),

      attributes: x?.attributes
        ? x.attributes.map((e: any) => e?.attribute?.id)
        : [],

      brandId: x?.brand?.id,

      rentPrices: x?.rentPrices || initRentalPrice,

      isRental: !!x?.isRental,

      selectSpecifications: x?.informationDetail?.specifications
        ? JSON.parse(x?.informationDetail?.specifications)?.id
        : "",

      specifications: x?.informationDetail?.specifications
        ? JSON.parse(x?.informationDetail?.specifications).data
        : [],

      productTypeIds: x?.types?.map((item?: any) => item?.id),

      categoryIds: x?.categories?.map((item?: any) => item?.id),

      attributeItems: attributeItems || {},

      variantTables: x?.variants || [],

      platform: x?.platform?.map((item?: string) => ({
        label: item,
        value: item,
      })),
      tags: x?.tags
        ? x.tags.map((e: any) => ({
            label: e?.name,
            value: e?.id,
          }))
        : [],

      informationDetail: {
        information:
          x?.informationDetail?.information || defaultInformationProduct,
        description: x?.informationDetail?.description || "",
        shortDescription: x?.informationDetail?.shortDescription || "",
        accompanyingGifts: x?.informationDetail?.accompanyingGifts || "",
      },

      productBuyWith: x?.productBuyWith.map((item?: any) => {
        return {
          product: item.product.id,
          productVariant: item.productVariant?.id,
        };
      }),
    });

    if (variantOptions.length <= 0) {
      setVariantOptions(
        x?.productBuyWith?.map((item: any) => {
          return {
            label: item.product.title,
            value: item.product.id,
            isPrimary: item.product.isPrimary,
            variants: item.product.variants?.map((variant: any) => ({
              label: "SKU: " + variant.sku,
              value: variant.id,
            })),
          };
        }) ?? []
      );
    }
  };

  const handleSubmitCreateProduct = async (values: formEditType) => {
    const {
      seoTitle,
      seoDescription,
      seoImage,
      productBuyWith,
      variantTables,
      seoKeyword,
      title,
      alias,
      description,
      thumbnail,
      shortDescription,
      rentPrices,
      sku,
      brandId,
      isRental,
      gallery,
      productTypeIds,
      isActive,
      price,
      sellPrice,
      isPrimary,
      platform,
      stock,
      attributeItemsProduct,
      selectSpecifications,
      specifications,
      accompanyingGifts,
      isGift,
      dealInformation,
      information,
      tags,
    } = values;

    const customSpecifications = {
      id: selectSpecifications,
      data: specifications,
    };

    // const specifications = (values.specifications as any).filter(
    //   (item: any) => item.value
    // );

    const filterData = variantTables?.filter((item) => item.sku);

    const arrayAttributeItemsProduct = Object.values(attributeItemsProduct);
    const convertAttributeItemsProduct: string[] = [];

    arrayAttributeItemsProduct.forEach((item) => {
      convertAttributeItemsProduct.push(...item);
    });
    const listIdTags = tags.map((item: any) => item.value);

    try {
      if (isPrimary) {
        // tạo sản phẩm không có biến thể giá
        await createItem({
          seoTitle,
          seoDescription,
          seoImage,
          seoKeyword,
          isRental,
          dealInformation,
          categoryIds: removeDuplicates(values.categoryIds),
          specifications: JSON.stringify(customSpecifications),
          accompanyingGifts,
          collectionIds: values.collectionIds?.map((c) => c.value),
          platform: platform?.map((item?: any) => item.value),
          thumbnail,
          gallery,
          title,
          alias,
          platformDetail: process.env.REACT_APP_PLATFORM,
          description,
          rentPrices: !!isRental ? rentPrices : initRentalPrice,
          shortDescription,
          sku,
          productBuyWith,
          brandId,
          productTypeIds,
          isPrimary,
          price,
          sellPrice,
          stock,
          attributeItemIds: convertAttributeItemsProduct,
          isGift,
          isActive,
          tags: listIdTags,
          information,
        }).then((res) => {
          if (res) {
            navigate("/product");
          }
        });

        return;
      }

      const result: any = await createItem({
        seoTitle,
        isActive,
        seoDescription,
        seoImage,
        isRental,
        seoKeyword,
        categoryIds: removeDuplicates(values.categoryIds),
        specifications: JSON.stringify(customSpecifications),
        accompanyingGifts,
        collectionIds: values.collectionIds?.map((c) => c.value),
        platform: platform?.map((item?: any) => item.value),
        thumbnail,
        gallery,
        title,
        rentPrices: !!isRental ? rentPrices : [],
        alias,
        dealInformation,
        description,
        productBuyWith,
        platformDetail: process.env.REACT_APP_PLATFORM,
        shortDescription,
        sku,
        brandId,
        productTypeIds,
        isPrimary,
        attributeItemIds: convertAttributeItemsProduct,
        isGift,
        information,
        tags: listIdTags,
      }).then((res) => {
        if (res) {
          navigate("/product");
          return res;
        }
      });

      const processItems = async () => {
        for (const item of filterData) {
          const variantData = {
            productId: result?.data?.id,
            sku: item.sku,
            attributeItemIds: item.attributeItems,
            price: item.price || 0,
            sellPrice: item.sellPrice || 0,
            stock: (item as any).stock || 0,
            images: item.image ? [item.image] : [],
          };

          await createVariant(variantData);
        }
      };

      processItems();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitEditProduct = async (values: formEditType) => {
    const {
      seoTitle,
      seoDescription,
      seoImage,
      variantTables,
      seoKeyword,
      dealInformation,
      title,
      alias,
      description,
      thumbnail,
      shortDescription,
      sku,
      isActive,
      brandId,
      gallery,
      productTypeIds,
      rentPrices,
      platform,
      price,
      isRental,
      sellPrice,
      isPrimary,
      stock,
      attributeItemsProduct,
      selectSpecifications,
      specifications,
      isGift,
      information,
      accompanyingGifts,
      tags,
      productBuyWith,
    } = values;

    const customSpecifications = {
      id: selectSpecifications,
      data: specifications,
    };

    const arrayAttributeItemsProduct = Object.values(
      attributeItemsProduct || {}
    );
    const convertAttributeItemsProduct: string[] = [];

    arrayAttributeItemsProduct.forEach((item) => {
      convertAttributeItemsProduct.push(...item);
    });

    const listIdTags = tags.map((item: any) => item.value);

    try {
      if (isPrimary) {
        await editItem(
          {
            seoTitle,
            seoDescription,
            seoImage,
            seoKeyword,
            dealInformation,
            categoryIds: removeDuplicates(values.categoryIds),
            specifications: JSON.stringify(customSpecifications),
            accompanyingGifts,
            collectionIds: values.collectionIds?.map((c) => c.value),
            platform: platform?.map((item?: any) => item.value),
            // title: title !== data?.title ? title : undefined,
            // alias: alias !== data?.alias ? alias : undefined,
            title,
            alias,
            isGift,
            isActive,
            isRental,
            rentPrices,
            gallery,
            productBuyWith,
            description,
            platformDetail: process.env.REACT_APP_PLATFORM,
            shortDescription,
            sku,
            thumbnail,
            brandId,
            productTypeIds,
            isPrimary,
            price,
            sellPrice,
            stock,
            attributeItemIds: convertAttributeItemsProduct,
            information,
            tags: listIdTags,
          },
          id
        ).then(updateSuccess);
        return;
      }

      const response = await editItem(
        {
          seoTitle,
          seoDescription,
          seoImage,
          seoKeyword,
          productBuyWith,
          dealInformation,
          categoryIds: removeDuplicates(values.categoryIds),
          specifications: JSON.stringify(customSpecifications),
          accompanyingGifts,
          collectionIds: values.collectionIds?.map((c) => c.value),
          platform: platform?.map((item?: any) => item.value),
          title,
          isGift,
          // alias,
          gallery,
          description,
          shortDescription,
          isActive,
          rentPrices,
          platformDetail: process.env.REACT_APP_PLATFORM,
          sku,
          thumbnail,
          isRental,
          brandId,
          productTypeIds,
          isPrimary,
          attributeItemIds: convertAttributeItemsProduct,
          information,
          tags: listIdTags,
        },
        id
      ).then((res) => updateSuccess(res, true));

      const variantsIds = variantTables.map((item) => item?.id);

      const filterVariantRedundant = data.variantTables.filter(
        (item) => !variantsIds.includes(item?.id)
      );

      const deleteItems = async () => {
        for (const item of filterVariantRedundant) {
          await deleteVariant(item?.id);
        }
      };

      await deleteItems();

      // remove list variant rồi khởi tạo lại
      const processItems = async () => {
        for (const item of variantTables) {
          const variantData = {
            productId: id,
            sku: item.sku,
            attributeItemIds: item.attributeItems,
            price: item.price || 0,
            sellPrice: item.sellPrice || 0,
            stock: (item as any).stock || 0,
            images: item.image ? [item.image] : [],
          };

          if (item?.id) {
            await editVariant(variantData, item?.id);
          } else {
            await createVariant(variantData);
          }
        }
      };

      await processItems();

      if (Object.keys((response as any) || {}).length > 0) {
        formik.setFieldValue("variantCombination", []);
        setDefaultValues(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik<formEditType>({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        if (id) {
          handleSubmitEditProduct(values);
        } else {
          handleSubmitCreateProduct(values);
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  // const variantOptions = useMemo(() => {
  //   console.log(">>>> go here");

  //   return formik?.values?.productBuyWith?.map((item: any) => {
  //     return {
  //       label: item.product.title,
  //       value: item.product.id,
  //       isPrimary: item.product.isPrimary,
  //       variants: item.product.variants?.map((variant: any) => ({
  //         label: "SKU: " + variant.sku,
  //         value: variant.id,
  //       })),
  //     };
  //   });
  // }, []);

  const tabs = [
    {
      id: "general",
      name: "Thông tin cơ bản",
      content: () => <GeneralSetting />,
    },
    {
      id: "advance",
      name: "Thông tin nâng cao",
      content: () => !isLoading && <AdvanceSetting />,
    },
    {
      id: "productUpsell",
      name: "Sản phẩm đi kèm",
      content: () =>
        !isLoading && <ProductUpsell selectedOptions={variantOptions} />,
    },
    {
      id: "attribute",
      name: "Thuộc tính",
      content: () =>
        !isLoading && <AttributeStep dataProduct={data.dataAttribute} />,
    },
    {
      id: "variants",
      name: "Mẫu mã",
      content: () => !isLoading && <VariantStep dataProduct={data.variants} />,
      hide: formik.values.isPrimary,
    },
    {
      id: "SEO",
      name: "SEO",
      content: () => <SEO formik={formik} isLoading={isLoading} />,
    },
  ];

  useEffect(() => {
    if (id) {
      initPage(id);
    } else {
      setData(initialValue);
      setIsLoading(false);
    }
  }, [id]);

  return (
    <>
      {id && <PageTitle>{data.title}</PageTitle>}
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik?.handleSubmit} noValidate>
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
              <CustomTabs
                className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
                items={tabs}
              />
            </div>
            <SideBar />
          </div>
        </form>
      </FormikProvider>
    </>
  );
};

export default EditPage;
