import { useDebounce } from "_metronic/helpers";
import clsx from "clsx";
import MultiSelect from "multiselect-react-dropdown";
import { useCallback, useEffect, useState } from "react";

interface Option {
  key?: string;
  value: string;
  label?: string;
}

interface PropsType {
  formik?: any;
  label?: string;
  classLabel?: string;
  classNameSelect?: string;
  customStyles?: any;
  singleSelect?: boolean;
  options?: Option[];
  selectedOptions?: any;
  onSelect?: (e: Event) => void | undefined;
  onRemove?: (e: Event) => void | undefined;
  onSearch?: (value?: string) => void;
  name?: any;
  values?: any;
  emptyRecordMsg?: string;
  required?: boolean;
  displayValue?: string;
}

export const MultiSelectDropdown = (props: PropsType) => {
  const {
    label,
    classLabel = "fw-bold fs-6 mb-2",
    classNameSelect,
    customStyles,
    options,
    formik,
    onSelect,
    onRemove,
    onSearch,
    values,
    name,
    singleSelect = false,
    emptyRecordMsg = "Không có giá trị",
    required,
  } = props;

  const [searchInput, setSearchInput] = useState("");

  const debouncedSearchTerm = useDebounce(searchInput, 700);

  const searchDebounce = useCallback((input: any) => {
    setSearchInput(input);
    return true; // if not search, then all match
  }, []);

  useEffect(() => {
    if (!!onSearch) {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <div>
      <label className={clsx("mb-2", { required: required }, classLabel)}>
        {label}
      </label>
      <MultiSelect
        {...formik?.getFieldProps(name)}
        closeOnSelect={true}
        singleSelect={!!singleSelect}
        className={clsx("form-control p-0", classNameSelect)}
        options={options}
        selectedValues={values}
        onSelect={onSelect}
        onRemove={onRemove}
        placeholder="Chọn giá trị"
        hidePlaceholder={true}
        displayValue="label"
        style={customStyles}
        avoidHighlightFirstOption={true}
        emptyRecordMsg={emptyRecordMsg}
        onSearch={!!onSearch ? searchDebounce : {}}
      />
      {formik?.touched[name] && formik?.errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{formik?.errors[name]}</span>
          </div>
        </div>
      )}
    </div>
  );
};
