import { QUERIES } from "_metronic/helpers";
import {
  ListViewProvider,
  useListView,
} from "_metronic/helpers/provider/ListViewProvider";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "_metronic/helpers/provider/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "_metronic/helpers/provider/QueryResponseProvider";
import ListManagement from "app/components/Page";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { PageTitle } from "../../../../../_metronic/layout/core";
import { COLUMNS } from "../core/_columnsDeal";
import { ItemCampaignModel } from "../core/_models";
import {
  bulkDeleteDeal,
  deleteDeal,
  getItem,
  getListDeal,
} from "../core/_requests";
import ItemModal from "./Modal";

const ListDeal = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const { id } = useParams();
  const [campaign, setCampaign] = useState<ItemCampaignModel>();

  useEffect(() => {
    getItem(id).then((res) => setCampaign(res));
  }, [id]);

  return (
    <>
      <PageTitle>{campaign?.name}</PageTitle>
      <ListManagement
        isCreate
        columns={COLUMNS}
        actionAddNew={() => setItemIdForUpdate(null)}
        useQueryResponseLoading={useQueryResponseLoading}
        queryString={[QUERIES.DEAL_LIST]}
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryResponseData={useQueryResponseData}
        useListView={useListView}
        useQueryRequest={useQueryRequest}
        useQueryResponse={useQueryResponse}
        bulkDelete={bulkDeleteDeal}
        deleteItem={deleteDeal}
      />
      {itemIdForUpdate !== undefined && <ItemModal data={itemIdForUpdate} />}
    </>
  );
};

export const ListDealWrapper = () => {
  const { id } = useParams();
  return (
    <QueryRequestProvider>
      <QueryResponseProvider
        queryString={QUERIES.DEAL_LIST}
        getList={(query?: any) => getListDeal({ ...query, campaign: id })}
      >
        <ListViewProvider>
          <ListDeal />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};
