import { ID, KTCard } from "_metronic/helpers";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import {
  createSuccess,
  deleteSuccess,
  updateSuccess,
} from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import { Input, Switcher } from "app/components/Form";
import { FormField } from "app/components/Form/Field";
import Repeater from "app/components/Form/Repeater";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { initialData, ItemModel } from "../core/_models";
import {
  createItem,
  createService,
  deleteService,
  editItem,
  getItem,
  getService,
  updateService,
} from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();
  const [services, setServices] = useState(null);

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const submitItem = async (query: any, idChild: ID) => {
    if (idChild) {
      await updateService(
        {
          ...query,
          type: id,
        },
        idChild
      ).then((res) => updateSuccess(res, true));
    } else {
      await createService({
        ...query,
        orderNumber: 0,
        isActive: true,
        type: id,
      });
      createSuccess();
    }
  };

  const deleteItem = async (idChild?: any) => {
    try {
      await deleteService(idChild);
      deleteSuccess();
    } catch (error) {
      console.log(error);
    }
  };

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) => {
        setData({ ...x });
      })
      .catch((e) => {
        console.log(e);
        navigate("/bike-renew");
      });

    getService(id).then((d?: any) => setServices(d.data));
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (id) {
          editItem({ ...values }, id).then(updateSuccess);
        } else {
          await createItem({
            ...values,
          }).then((res) => {
            if (res.statusCode && res.statusCode > 300) {
              return;
            }
            createSuccess();
            navigate(`/bike-renew`);
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "name",
      required: true,
      type: "text",
    },
    {
      label: "Điều kiện thời gian",
      name: "description",
      required: true,
      type: "text",
    },
    {
      label: "Tỷ lệ",
      name: "rate",
      required: true,
      type: "number",
    },
  ];

  return (
    <>
      {id ? (
        <PageTitle>{formik.values.name}</PageTitle>
      ) : (
        <PageTitle>Tạo mới</PageTitle>
      )}
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };
                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}

              {id ? (
                <div className="fv-row mb-7">
                  <Repeater
                    isModal
                    onAddNew={(data: any, id: ID) => {
                      submitItem(data, id);
                    }}
                    onDelete={(id?: ID) => {
                      deleteItem(id);
                    }}
                    label="Item"
                    name="item"
                    formik={formik}
                    disabled={formik.isSubmitting}
                    values={services}
                    headerItem={["Nội dung", "Điều kiện", "Tỷ lệ (%)"]}
                    initItem={[]}
                    inputItem={[
                      {
                        type: "text",
                        name: "name",
                        label: "Nội dung",
                        required: true,
                      },

                      {
                        type: "text",
                        name: "description",
                        label: "Điều kiện",
                        required: true,
                      },

                      {
                        type: "number",
                        name: "rate",
                        label: "Tỷ lệ",
                        required: true,
                      },

                      {
                        type: "number",
                        name: "orderNumber",
                        label: "Thứ tự sắp xếp",
                        required: true,
                        displayOutside: false,
                      },
                    ]}
                  />
                </div>
              ) : (
                <></>
              )}
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              isShowActive={false}
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={() => {
                formik.handleSubmit();
              }}
            >
              <div className="fv-row mb-7">
                <Switcher
                  label="Trạng thái"
                  name="isActive"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={formik.values.isActive}
                />
              </div>

              <Input
                formik={formik}
                type="number"
                label="Thứ tự sắp xếp"
                placeholder="Số thứ tự"
                name="orderNumber"
                disabled={formik?.isSubmitting}
                onChange={(e) => {
                  formik?.handleChange(e);
                }}
                onBlur={formik?.handleBlur}
                values={formik?.values?.orderNumber}
              />
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
