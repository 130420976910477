import { EventAmentities } from "app/pages/event-management/events/core/_models";
import { useEffect, useState } from "react";

import { InputProps } from "../../Input/index";
import { Switcher } from "../../Switcher";

const AddableInput = ({ name, formik, values, label }: InputProps) => {
  const [list, setList] = useState<Array<EventAmentities>>(
    values?.length > 0
      ? values
      : [
          {
            name: "",
            include: false,
          },
        ]
  );

  useEffect(() => {
    if (values?.length > 0) {
      setList(values);
    }
  }, [values]);

  return (
    <div className="d-flex flex-column mb-15 fv-row">
      <div className="fs-5 fw-bold form-label mb-3">{label}</div>

      <div className="table-responsive">
        {/*begin::Table*/}
        <div
          id="kt_create_new_custom_fields_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="table-responsive">
            <table
              id="kt_create_new_custom_fields"
              className="table align-middle table-row-dashed fw-semibold fs-6 gy-5 dataTable no-footer"
            >
              {/*begin::Table head*/}
              <thead>
                <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                  <th
                    className="pt-0 sorting_disabled"
                    rowSpan={1}
                    colSpan={1}
                    style={{ width: "100%" }}
                  >
                    Nội dung
                  </th>
                  <th className="pt-0 sorting_disabled" rowSpan={1} colSpan={1}>
                    Bao gồm
                  </th>
                  <th
                    className="pt-0 text-end sorting_disabled"
                    rowSpan={1}
                    colSpan={1}
                    style={{ width: "80.7px" }}
                  >
                    Xóa
                  </th>
                </tr>
              </thead>
              {/*end::Table head*/}
              {/*begin::Table body*/}
              <tbody>
                {list?.length > 0 ? (
                  list.map((item, index) => {
                    return (
                      <tr className="odd" key={index}>
                        <td>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            value={item?.name}
                            onChange={(e) => {
                              setList((prev) => {
                                if (typeof prev[index] === "string") {
                                  prev[index] = {
                                    name: "",
                                    include: false,
                                  };
                                }

                                prev[index].name = e.target.value;

                                return [...prev];
                              });
                              formik.setFieldValue(name, list);
                            }}
                          />
                        </td>
                        <td>
                          <Switcher
                            label=""
                            name="include"
                            direction="horizontal"
                            onChange={(e) => {
                              setList((prev) => {
                                if (typeof prev[index] === "string") {
                                  prev[index] = {
                                    name: "",
                                    include: false,
                                  };
                                }

                                prev[index].include = e.target.checked;

                                return [...prev];
                              });
                              formik.setFieldValue(name, list);
                            }}
                            value={item?.include}
                          />
                        </td>
                        <td className="text-end">
                          <button
                            type="button"
                            className="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3"
                            data-kt-action="field_remove"
                            onClick={() => {
                              setList((prev) => {
                                prev.splice(index, 1);
                                return [...prev];
                              });

                              formik.setFieldValue(name, list);
                            }}
                          >
                            <i className="ki-duotone ki-trash fs-3">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                              <span className="path5" />
                            </i>{" "}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
              {/*end::Table body*/}
            </table>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" />
          </div>
        </div>
        {/*end:Table*/}
      </div>

      <button
        type="button"
        className="btn btn-light-primary me-auto"
        id="kt_create_new_custom_fields_add"
        onClick={() => {
          setList((prev) => {
            return [
              ...prev,
              {
                name: "",
                include: false,
              },
            ];
          });
        }}
      >
        Thêm
      </button>
      {/*end::Add custom field*/}
    </div>
  );
};

export default AddableInput;
