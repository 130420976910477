import { ID, Response } from "_metronic/helpers";
import { EmailTemplateEnum } from "constants/enum";

export type ItemModel = {
  id?: ID;
  subject: string;
  content: string;
  cc?: string;
  type: EmailTemplateEnum;
  isActive?: boolean;
  updatedAt?: string;
  createdAt?: string;
};

export const initialData: ItemModel = {
  id: null,
  subject: "",
  cc: "",
  content: "",
  type: EmailTemplateEnum.NOTIFICATION,
  isActive: true,
};

export type QueryResponse = Response<Array<ItemModel>>;
