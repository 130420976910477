import { Input } from "app/components/Form";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { formEditType, VariantItemData } from "../../core/_models";
import { findVariant, generateCombination } from "../../utils";

interface VariantItemsTableProps {
  findItemNameById: any;
}

const VariantItemsTable = ({ findItemNameById }: VariantItemsTableProps) => {
  const { id } = useParams();
  const formik = useFormikContext<formEditType>();

  const variantItems = useMemo(() => {
    return Object.values(formik.values.attributeItems).filter(
      (e) => e.length > 0
    );
  }, [formik.values.attributeItems]);

  const variantCombination = useMemo(() => {
    return variantItems.length > 0 ? generateCombination(...variantItems) : [];
  }, [variantItems]);

  useEffect(() => {
    const listContanst: VariantItemData[] = [];
    const newly: VariantItemData[] = [];

    variantCombination.forEach((combination, index) => {
      // const newVariantTables = formik.values.variantTables.map(
      //   (item: any, index: number) => {
      //     return {
      //       ...item,
      //       attributeItems: combination,
      //     };
      //   }
      // );

      // console.log("newVariantTables", formik.values.variantTables);

      const variant = formik.values.variantTables.find((item: any) => {
        return item?.attributeItems?.every((value: string) =>
          combination.includes(value)
        );
      });

      if (variant) {
        listContanst.push(variant);
        return;
      }

      newly.push({
        attributeItems: combination,
        productVariantId: "",
        price: "",
        sellPrice: "",
        sku: "",
        barcode: "",
        image: "",
        id: "",
      });
    });

    formik.setFieldValue("variantCombination", variantCombination);
    formik.setFieldValue("variantTables", [...listContanst, ...newly]);
  }, [variantCombination]);

  return (
    <table className="mt-5">
      <thead>
        <tr
          style={{
            paddingTop: "12px",
          }}
        >
          {/* <th
            style={{
              width: 30,

              position: "sticky",
              left: 0,
            }}
            className="px-2"
          >
            <input
              className="form-check-input"
              type="checkbox"
              checked={
                selectedItemId.length === formik.values.variantTables.length &&
                selectedItemId.length > 0
              }
              onChange={() => {
                if (
                  selectedItemId.length === formik.values.variantTables.length
                ) {
                  setSelectedItemsId([]);
                } else {
                  setSelectedItemsId(
                    Array.from(
                      Array(formik.values.variantTables.length).keys()
                    ).map(String)
                  );
                }
              }}
            />
          </th> */}

          <th
            style={{
              width: 30,
              whiteSpace: "nowrap",
            }}
            className="px-6"
          >
            Hình ảnh
          </th>
          {id && (
            <th
              style={{
                width: 30,
                whiteSpace: "nowrap",
              }}
              className="px-2"
            >
              Product Variant ID
            </th>
          )}
          <th
            style={{
              width: 30,
              left: 0,
              whiteSpace: "nowrap",
              position: "sticky",
              background: "#fff",
              zIndex: 99,
            }}
            className="px-2 text-center"
          >
            Mẫu mã
          </th>
          <th className="px-2" style={{ minWidth: 150 }}>
            Giá gốc
          </th>
          <th className="px-2" style={{ minWidth: 150 }}>
            Giá bán
          </th>
          <th className="px-2" style={{ minWidth: 150 }}>
            SKU
          </th>
          <th className="px-2" style={{ minWidth: 150 }}>
            Stock
          </th>
          {/* <th className="px-2" style={{ minWidth: 150 }}>
            Mã vạch
          </th> */}
        </tr>
      </thead>
      <tbody>
        {variantCombination.map((pair, index) => {
          const variant = findVariant(formik.values.variantTables, pair);
          // console.log("findVariant", pair, variant);
          return (
            <tr key={pair.toString()}>
              {/* <td
                className="px-2 pt-5"
                style={{
                  position: "sticky",
                  left: 0,
                }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={selectedItemId.includes(`${index}`)}
                  onChange={() => {
                    if (selectedItemId.includes(`${index}`)) {
                      setSelectedItemsId(
                        selectedItemId.filter((s) => s !== `${index}`)
                      );
                    } else {
                      setSelectedItemsId(selectedItemId.concat(`${index}`));
                    }
                  }}
                />
              </td> */}

              <td className="px-2 pt-5">
                <ImageUploadGlobal
                  uploaded={(val: string) => {
                    if (variant?.id) {
                      variant.image = val;
                    }
                  }}
                  name={`variantTables-${index}`}
                  height={40}
                  className="w-50px"
                  path="/product-variants"
                  values={variant?.image}
                  autoUpload
                  customUpdate
                />
              </td>

              {id && (
                <td className="px-2 pt-5 text-center">
                  {variant?.productVariantId}
                </td>
              )}
              <td
                style={{
                  left: 0,
                  whiteSpace: "nowrap",
                  position: "sticky",
                  background: "#fff",
                  zIndex: 99,
                }}
                className="px-3 pt-5 text-center"
              >
                {pair?.map(findItemNameById).join(" / ")}
              </td>
              <td className="px-2 pt-5">
                <Input
                  classNameLabel="d-none"
                  values={findVariant(formik.values.variantTables, pair)?.price}
                  onChange={(event) => {
                    const value = event.target.value;
                    const variant = findVariant(
                      formik.values.variantTables,
                      pair
                    );

                    variant.price = value;
                    formik.setFieldValue(
                      "variantTables",
                      formik.values.variantTables
                    );
                  }}
                />
              </td>
              <td className="px-2 pt-5">
                <Input
                  classNameLabel="d-none"
                  values={
                    findVariant(formik.values.variantTables, pair)?.sellPrice
                  }
                  onChange={(event) => {
                    const value = event.target.value;
                    const variant = findVariant(
                      formik.values.variantTables,
                      pair
                    );
                    variant.sellPrice = value;
                    formik.setFieldValue(
                      "variantTables",
                      formik.values.variantTables
                    );
                  }}
                />
              </td>
              <td className="px-2 pt-5">
                <Input
                  classNameLabel="d-none"
                  values={findVariant(formik.values.variantTables, pair)?.sku}
                  onChange={(event) => {
                    const value = event.target.value;
                    const variant = findVariant(
                      formik.values.variantTables,
                      pair
                    );
                    variant.sku = value;
                    formik.setFieldValue(
                      "variantTables",
                      formik.values.variantTables
                    );
                  }}
                />
              </td>

              <td className="px-2 pt-5">
                <Input
                  classNameLabel="d-none"
                  values={findVariant(formik.values.variantTables, pair)?.stock}
                  onChange={(event) => {
                    const value = event.target.value;
                    const variant = findVariant(
                      formik.values.variantTables,
                      pair
                    );
                    variant.stock = value;
                    formik.setFieldValue(
                      "variantTables",
                      formik.values.variantTables
                    );
                  }}
                />
              </td>
              {/* <td className="px-2 pt-5">
                <Input
                  classNameLabel="d-none"
                  values={findVariant(formik.values.variantTables, pair)?.barcode}
                  onChange={(event) => {
                    const value = event.target.value;
                    const variant = findVariant(
                      formik.values.variantTables,
                      pair
                    );
                    variant.barcode = value;
                    formik.setFieldValue(
                      "variantTables",
                      formik.values.variantTables
                    );
                  }}
                />
              </td> */}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default VariantItemsTable;
