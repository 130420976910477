import { ID, Response } from "_metronic/helpers";

import { EventServiceType } from "../../events/core/_models";

export type EventTripPlans = {
  image?: string;
  title?: string;
  description?: string;
};

export type EventAmentities = {
  name: string;
  include?: boolean;
};

export type EventList = {
  id?: ID;
  image?: string;
  title?: string;
  alias?: string;
  type: string;
  price: number;
  sellPrice?: number;
  startedAt: Date;
  endedAt: Date;
  registerStartedAt: Date;
  registerEndedAt: Date;
  sports: [string];
  categories: [string];
  isActive: boolean;
  visible: boolean;
  noLimitTime: boolean;
  createdBy?: any;
  updatedBy?: any;
  createdAt?: string;
  updatedAt?: string;
};

export type Event = {
  id?: string;
  image?: string;
  title?: string;
  alias?: string;

  certTemplate?: string;
  bibTemplate?: string;

  description?: string;
  excerpt?: string;
  awardTerm?: string;

  services?: EventServiceType[];

  hideOwner?: boolean;
  term?: string;
  rule?: string;
  type: string;
  award: string;
  address: string;
  challenge: string;
  level?: number;
  time?: string;
  limitMember?: number;
  features?: string[];
  tripPlans?: EventTripPlans[];
  amentities?: EventAmentities[];

  ownerModel?: string;
  owner: any;

  price: number;
  sellPrice?: number;
  startedAt?: any;
  endedAt?: string;
  registerStartDate?: string;
  registerEndDate?: string;

  sports: string[];
  categories: string[];
  requiredEvents: string[];
  province?: any;
  publishedDate?: string;
  gallery: string[];

  seoImage?: string;
  seoTitle?: string;
  seoDescription?: string;
  seoKeyword?: string;

  isActive: boolean;
  visible: boolean;
  isFeatured: boolean;
  isChallenge: boolean;
  isTour: boolean;
  isRoad: boolean;
  noLimitTime: boolean;
};

export const initialData: any = {
  isFeatured: false,
  id: null,
  image: "",
  title: "",
  alias: "",
  description: "",
  term: "",
  awardTerm: "",
  excerpt: "",
  gallery: [],
  type: "",
  price: 0,
  sellPrice: "",
  features: [""],
  tripPlans: [],

  owner: [],
  ownerModel: "Admin",
  sports: [],
  categories: [],
  requiredEvents: [],
  province: null,

  address: "",
  award: "",
  challenge: "",
  rule: "",

  // startedAt: newDate.toISOString().split(".")[0],
  // endedAt: new Date().toISOString().split(".")[0],
  // registerStartDate: newDate.toISOString().split(".")[0],
  // registerEndDate: new Date(newDate.setMonth(newDate.getMonth() + 8))
  //   .toISOString()
  //   .split(".")[0],

  seoTitle: "",
  seoDescription: "",
  seoKeyword: "",
  isActive: true,
  visible: true,
  isChallenge: false,
  isTour: false,
  isRoad: false,
  noLimitTime: false,
  hideOwner: false,
  disabled: false,
};

export type QueryResponse = Response<Array<EventList>>;
