import { useQueryResponseLoading } from "_metronic/helpers/provider/QueryResponseProvider";
import { updateSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { FormField } from "app/components/Form/Field";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { PROFILE_VALIDATE_RULE } from "constants/validate-rule";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import { ProfileModel } from "../core/_models";
import { getProfile, updateProfile } from "../core/_requests";

const EditPage = () => {
  const isLoading = useQueryResponseLoading();
  const [profile, setProfile] = useState<ProfileModel>();

  useEffect(() => {
    getProfile().then((data) => setProfile(data));
  }, []);

  const validation = Yup.object().shape({
    ...PROFILE_VALIDATE_RULE,
  });

  const submit = async (query: any) => {
    await updateProfile({ ...query })
      .then(updateSuccess)
      .catch();
  };

  const formik = useFormik({
    initialValues: profile || ({} as ProfileModel),
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await submit({
          fullname: values.fullname,
          bio: values.bio,
          avatar: values.avatar,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "fullname",
      required: true,
      type: "text",
    },
    {
      label: "Email",
      name: "email",
      required: true,
      readOnly: true,
      type: "text",
    },
    {
      label: "Bio",
      name: "bio",
      required: false,
      type: "text",
    },
  ];

  return (
    <>
      <div className="card-body border-top p-9">
        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            Avatar
          </label>
          <div className="col-lg-8">
            <ImageUploadGlobal
              name="avatar"
              formik={formik}
              className="mx-auto w-25 border mb-10"
              path="/profile"
            />
          </div>
        </div>

        {FIELDS.map((field: any) => {
          const fieldData = { ...field, formik };
          return (
            <div key={fieldData.name} className="fv-row mb-7">
              <div className="col-6">{FormField(fieldData)}</div>
            </div>
          );
        })}

        <div className="text-end mt-12">
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading || formik?.isSubmitting || !formik?.touched}
          >
            Cập nhật
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditPage;
