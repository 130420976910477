import moment from "moment";

const HEADERS = ["Nhà cung cấp", "Ngày tạo", "Trạng thái", "Token"];

const Transaction = ({ data }: { data: any }) => {
  return (
    <>
      <div className="card card-flush mt-6 mt-xl-9">
        {/*begin::Card header*/}
        <div className="card-header mt-5">
          {/*begin::Card title*/}
          <div className="card-title flex-column">
            <h3 className="fw-bold mb-1">Lịch sử thanh toán</h3>
          </div>
          {/*begin::Card title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar my-1">
            {/*begin::Select*/}
            <div className="me-6 my-1">
              <select
                id="kt_filter_year"
                name="year"
                data-control="select2"
                data-hide-search="true"
                className="w-125px form-select form-select-solid form-select-sm select2-hidden-accessible"
                data-select2-id="select2-data-kt_filter_year"
                tabIndex={-1}
                aria-hidden="true"
                data-kt-initialized={1}
              >
                <option
                  value="All"
                  selected={true}
                  data-select2-id="select2-data-34-gkso"
                >
                  All time
                </option>
                <option value="thisyear">This year</option>
                <option value="thismonth">This month</option>
                <option value="lastmonth">Last month</option>
                <option value="last90days">Last 90 days</option>
              </select>
              <span
                className="select2 select2-container select2-container--bootstrap5"
                dir="ltr"
                data-select2-id="select2-data-33-vxza"
                style={{ width: "100%" }}
              >
                <span className="selection">
                  <span
                    className="select2-selection select2-selection--single w-125px form-select form-select-solid form-select-sm"
                    role="combobox"
                    aria-haspopup="true"
                    aria-expanded="false"
                    tabIndex={0}
                    aria-disabled="false"
                    aria-labelledby="select2-kt_filter_year-container"
                    aria-controls="select2-kt_filter_year-container"
                  >
                    <span
                      className="select2-selection__rendered"
                      id="select2-kt_filter_year-container"
                      role="textbox"
                      aria-readonly="true"
                      title="All time"
                    >
                      All time
                    </span>
                    <span
                      className="select2-selection__arrow"
                      role="presentation"
                    >
                      <b role="presentation" />
                    </span>
                  </span>
                </span>
                <span className="dropdown-wrapper" aria-hidden="true" />
              </span>
            </div>
            {/*end::Select*/}

            {/*begin::Search*/}
            <div className="d-flex align-items-center position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-3">
                <span className="path1" />
                <span className="path2" />
              </i>{" "}
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-solid form-select-sm w-150px ps-9"
                placeholder="Search transaction"
              />
            </div>
            {/*end::Search*/}
          </div>
          {/*begin::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body pt-0">
          {/*begin::Table container*/}
          <div className="table-responsive">
            {/*begin::Table*/}
            <div
              id="kt_profile_overview_table_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="table-responsive">
                <table
                  id="kt_profile_overview_table"
                  className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer"
                >
                  <thead className="fs-7 text-gray-400 text-uppercase">
                    <tr>
                      {HEADERS?.map((item, index) => (
                        <th
                          key={index}
                          className="min-w-300px sorting"
                          tabIndex={0}
                          aria-controls="kt_profile_overview_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Manager: activate to sort column ascending"
                          // style={{ width: "411.875px" }}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="fs-6">
                    {data?.map((item?: any, index?: number) => (
                      <tr className="odd" key={index}>
                        <td>{item.provider}</td>

                        <td>
                          {moment(item?.createdAt).format(
                            "DD/MM/YYYY hh:mm:ss"
                          )}
                        </td>

                        <td>
                          {
                            <span className="badge badge-light-success fw-bold px-4 py-3">
                              {item?.status}{" "}
                            </span>
                          }
                        </td>

                        <td>{item.token}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/*end::Table*/}
          </div>
          {/*end::Table container*/}
        </div>
        {/*end::Card body*/}
      </div>
    </>
  );
};

export default Transaction;
