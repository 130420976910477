import { ID } from "_metronic/helpers";
import { deleteDataItem, getData, postData, updateData } from "services/api";

import { QueryResponse, Role } from "./_models";

const URL = `/roles`;
const GET_LIST_PERMISSIONS = `roles/permissions/list`;

const getList = (query: any): Promise<QueryResponse> => getData(URL, query);

const getItem = (id: string): Promise<Role> => getData(URL + "/" + id);

const getListPermissions = (): Promise<any> => getData(GET_LIST_PERMISSIONS);

const createItem = (query: Role): Promise<QueryResponse> =>
  postData(URL, query);

const editItem = (query: Role, id: string): Promise<QueryResponse> =>
  updateData(URL, query, id);

const deleteItem = (id: ID): Promise<QueryResponse> => deleteDataItem(URL, id);

const bulkDelete = async (ids: Array<ID>): Promise<void> => {
  await Promise.all(ids.map(async (id: ID) => await deleteDataItem(URL, id)));
};

export {
  bulkDelete,
  createItem,
  deleteItem,
  editItem,
  getItem,
  getList,
  getListPermissions,
};
