import { FC } from "react";

type Props = {
  useListView: any;
  bulkDelete: any;
  bulkSync?: any;
  bulkApprove?: any;
  deletable?: any;
  bulkReject?: any;
  showSyncBtn?: boolean;
  showApproveBtn?: boolean;
  showRejectBtn?: boolean;
};
const ListGrouping: FC<Props> = ({
  useListView,
  bulkDelete,
  bulkSync,
  deletable,
  bulkApprove,
  showApproveBtn,
  showRejectBtn,
  bulkReject,
  showSyncBtn,
}) => {
  const { selected } = useListView();

  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <div className="fw-bolder me-5">
          Đã chọn <span className="me-2">{selected.length}</span>
        </div>

        {deletable ? (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => bulkDelete()}
          >
            Xoá tất cả ({selected.length})
          </button>
        ) : (
          <></>
        )}

        {showSyncBtn ? (
          <button
            type="button"
            style={{ marginLeft: 10 }}
            className="btn btn-info"
            onClick={() => bulkSync?.()}
          >
            Lấy dữ liệu ({selected.length})
          </button>
        ) : (
          <></>
        )}

        {showApproveBtn ? (
          <button
            type="button"
            style={{ marginLeft: 10 }}
            className="btn btn-info"
            onClick={() => bulkApprove?.()}
          >
            Approve ({selected.length})
          </button>
        ) : (
          <></>
        )}

        {showRejectBtn ? (
          <button
            type="button"
            style={{ marginLeft: 10 }}
            className="btn btn-info"
            onClick={() => bulkReject?.()}
          >
            Reject ({selected.length})
          </button>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export { ListGrouping };
