export const getPaginations = ({
  firstPage,
  lastPage,
  currentPage,
  offsetPage,
}: any) => {
  const paginators = [];
  const isNearFirstPage = currentPage - firstPage < offsetPage + 2;
  const isNearLastPage = lastPage - currentPage < offsetPage + 2;

  if (lastPage <= offsetPage * 2 + 1 || (isNearFirstPage && isNearLastPage)) {
    for (let i = firstPage; i < lastPage + 1; i += 1) {
      paginators.push({
        value: i,
        enable: true,
      });
    }
  } else if (isNearFirstPage) {
    for (
      let i = firstPage;
      i < Math.max(offsetPage * 3, currentPage + offsetPage);
      i += 1
    ) {
      paginators.push({
        value: i,
        enable: true,
      });
    }

    paginators.push({
      value: "...",
      enable: false,
    });

    paginators.push({
      value: lastPage,
      enable: true,
    });
  } else if (isNearLastPage) {
    paginators.push({
      value: firstPage,
      enable: true,
    });

    paginators.push({
      value: "...",
      enable: false,
    });

    for (
      let i = Math.min(lastPage - offsetPage * 2 + 2, currentPage - offsetPage);
      i < lastPage + 1;
      i += 1
    ) {
      paginators.push({
        value: i,
        enable: true,
      });
    }
  } else {
    paginators.push({
      value: firstPage,
      enable: true,
    });

    paginators.push({
      value: "...",
      enable: false,
    });

    for (
      let i = currentPage - offsetPage;
      i < currentPage + offsetPage + 1;
      i += 1
    ) {
      paginators.push({
        value: i,
        enable: true,
      });
    }

    paginators.push({
      value: "...",
    });

    paginators.push({
      value: lastPage,
      enable: true,
    });
  }
  return paginators;
};
