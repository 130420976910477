import { PageTitle } from "_metronic/layout/core";
import Button from "app/components/Button";
import clsx from "clsx";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import ListPost from "../blog-management/post/components/List";
import { GroupListWrapper as ListGroup } from "../community-management/group/components/GroupList";
import { ListWrapper as ListUser } from "../community-management/user/components/List";
import { ListWrapper as ListEvent } from "../event-management/events/components/List";
import { ListWrapper as ListOrder } from "../sale-management/order/components/List";
import { ListWrapper as ListProduct } from "../sale-management/product/components/List";
import {
  getLatestEvent,
  getLatestGroup,
  getLatestOrder,
  getLatestPost,
  getLatestProduct,
  getLatestUser,
} from "./core/request";

const DashboardTitle = ({
  children,
  navigate,
}: {
  children: React.ReactNode;
  navigate: string;
}) => {
  const navigateTo = useNavigate();

  return (
    <div className="d-flex justify-content-between align-items-center mb-8">
      <h1
        className={clsx("page-heading d-flex text-dark fw-bold fs-3 my-0 mb-2")}
      >
        {children}
      </h1>
      <Button
        type="button"
        className="btn btn-primary"
        onClick={() => navigateTo(navigate)}
      >
        Xem tất cả
      </Button>
    </div>
  );
};

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle>Dashboard</PageTitle>

      <DashboardTitle navigate="/user">Người dùng mới nhất</DashboardTitle>
      <ListUser noPaginate={true} customGetList={getLatestUser} />

      <div className="mt-15">
        <DashboardTitle navigate="/event">Sự kiện mới nhất</DashboardTitle>
        <ListEvent noPaginate={true} customGetList={getLatestEvent} />
      </div>

      <div className="mt-15">
        <DashboardTitle navigate="/product">Sản phẩm mới nhất</DashboardTitle>
        <ListProduct noPaginate={true} customGetList={getLatestProduct} />
      </div>

      <div className="mt-15">
        <DashboardTitle navigate="/orders">Đơn hàng mới nhất</DashboardTitle>
        <ListOrder noPaginate={true} customGetList={getLatestOrder} />
      </div>

      <div className="mt-15">
        <DashboardTitle navigate="/post">Bài viết mới nhất</DashboardTitle>
        <ListPost noPaginate={true} customGetList={getLatestPost} />
      </div>

      <div className="mt-15">
        <DashboardTitle navigate="/group">Nhóm mới nhất</DashboardTitle>
        <ListGroup noPaginate={true} customGetList={getLatestGroup} />
      </div>
    </>
  );
};

export { DashboardWrapper };
