import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import { alertSuccess } from "_metronic/helpers/toastify";
import {
  ActionDeleteCell,
  ActionEditCell,
  CreatedByColumn,
  IsActiveCell,
  ItemColumn,
  SelectionCell,
} from "app/components/Table/columns";
import { Header, SelectionHeader } from "app/components/Table/header";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";

import { ItemModel } from "./_models";
import { syncData } from "./_requests";

type Props = {
  data: any;
  useListView?: any;
};

type PropsSync = {
  id?: string;
};

const ViewDetailBtn: FC<Props> = ({ data, useListView }) => {
  const navigate = useNavigate();
  const { setItemIdForUpdate } = useListView();

  return (
    <button
      type="button"
      className="hide-text btn btn-primary btn-sm d-inline-flex align-items-center"
      onClick={() => {
        setItemIdForUpdate(data);
        navigate(`/user/${data.id}/view-detail`);
      }}
    >
      <i className="bi bi-list-ul"></i>
    </button>
  );
};

const SyncUser: FC<PropsSync> = ({ id }) => {
  return (
    <button
      type="button"
      className="hide-text btn btn-info btn-sm d-inline-flex align-items-center"
      onClick={() => {
        if (id)
          syncData({ user: [id] }).then(() => {
            alertSuccess("Yêu cầu thành công, vui lòng chờ trong giây lát");
          });
      }}
    >
      <i className="bi bi-arrow-repeat"></i>
    </button>
  );
};

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Khách hàng"
        className="min-w-350px"
      />
    ),
    id: "item",
    Cell: ({ ...props }) => (
      <ItemColumn
        useListView={useListView}
        value={props.data[props.row.index]}
        isSquare={true}
        isAlias={false}
        isRole={false}
        isModal={true}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Email"
        className="min-w-250px"
      />
    ),
    accessor: "email",
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-200px"
      />
    ),
    accessor: "isActive",
    Cell: ({ ...props }) => (
      <IsActiveCell value={props.data[props.row.index].isActive} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Số điện thoại"
        className="min-w-200px"
      />
    ),
    accessor: "phone",
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày đăng ký"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className="text-end d-flex gap-2 min-w-200px justify-content-end">
        <ActionEditCell
          data={props.row.original}
          useListView={useListView}
          hideText={true}
        />
        <ActionDeleteCell
          data={props.row.original}
          useListView={useListView}
          hideText={true}
        />
        <ViewDetailBtn
          useListView={useListView}
          data={props.data[props.row.index]}
        />

        <SyncUser id={props.data[props.row.index].id} />
      </div>
    ),
  },
];

export { COLUMNS };
