import { useListView } from "_metronic/helpers/provider/ListViewProvider";
import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { alertSuccess } from "_metronic/helpers/toastify";
import {
  CreatedByColumn,
  IsActiveCell,
  SelectionCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import ActionIsActive from "app/components/Table/columns/ActionIsActive";
import { ActionViewDetail } from "app/components/Table/columns/ActionViewDetail";
import ItemColumn from "app/components/Table/columns/ItemColumn";
import { Header, SelectionHeader } from "app/components/Table/header";
import { FC } from "react";
import { Column } from "react-table";

import { GroupList } from "./_models";
import { syncData } from "./_requests";

type Props = {
  id: string;
};

const SyncGroup: FC<Props> = ({ id }) => {
  return (
    <button
      type="button"
      className="hide-text btn btn-info btn-sm d-inline-flex align-items-center"
      onClick={() => {
        if (id)
          syncData({ group: [id] }).then(() => {
            alertSuccess("Yêu cầu thành công, vui lòng chờ trong giây lát");
          });
      }}
    >
      <i className="bi bi-arrow-repeat"></i>
    </button>
  );
};

const COLUMNS: ReadonlyArray<Column<GroupList>> = [
  {
    Header: (props) => (
      <SelectionHeader useListView={useListView} tableProps={props} />
    ),
    id: "selection",
    Cell: ({ ...props }) => (
      <SelectionCell
        useListView={useListView}
        id={props.data[props.row.index].id}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Tên group"
        className="min-w-300px"
      />
    ),
    id: "item",
    Cell: ({ ...props }) => (
      <ItemColumn
        customLink={`/group/${props.data[props.row.index].id}/view-detail`}
        value={props.data[props.row.index]}
        isAlias={false}
        isEdit={false}
      />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Trạng thái"
        className="min-w-200px"
      />
    ),
    accessor: "isActive",
    Cell: ({ ...props }) => (
      <IsActiveCell value={props.data[props.row.index].isActive} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: "Hành động",
    id: "action",
    Cell: (props: any) => (
      <div className="text-end d-flex gap-2 min-w-350px justify-content-end">
        <ActionViewDetail
          dataDetail={props.row.original}
          path={`/group/${props.data[props.row.index].id}/view-detail`}
        ></ActionViewDetail>
        <ActionIsActive
          dataDetail={props.row.original}
          useQueryResponse={useQueryResponse}
        />
        <SyncGroup id={props.data[props.row.index].id} />
      </div>
    ),
  },
];

export { COLUMNS };
