import { ID, Response } from "_metronic/helpers";

import { initialSpecifications } from "../components/tabs/contants";
export type ItemModel = {
  id?: ID;
  image: string;
  title: string;
  collectionsIds: any;
  variantTables?: any;
  variants?: any;
  statusCode?: number;
  alias: string;
  sku: string;
  description: string;
  upsellProduct?: any;
  attributes?: any;
  attributeItems?: any;
  excerpt: string;
  isActive?: boolean;
  seoTitle: string;
  seoDescription: string;
  brand?: any;
  seoKeyword: string;
  seoImage: string;
  parent?: string;
  createdBy?: any;
  createdAt?: any;
  updatedAt?: any;
  updatedBy?: any;
};

// export const initialData: ItemModel = {
//   title: "",
//   description: "",
//   excerpt: "",
//   seoTitle: "",
//   seoDescription: "",
//   seoKeyword: "",
//   seoImage: "",
//   image: "",
//   alias: "",
//   sku: "",
//   isActive: true,
// };

export interface formEditType {
  gallery: string[];
  barcode: string;
  isGift: boolean;
  variants?: any;
  dealInformation?: any;
  isRental?: boolean;
  description?: string;
  shortDescription?: string;
  rentPrices?: any;
  sku: string;
  shortId?: string;
  productId?: string;
  attributeVariantItems: Record<string, string[]>;
  brandId: string;
  collectionIds?: any[];
  category: any;
  upsellProduct?: any;
  brand?: any;
  collections?: any;
  productBuyWith?: any;
  thumbnail: string;
  isPhysical: boolean;
  hasVariant: boolean;
  categoryIds: string[];
  // accompanyingGifts?: any;
  // specifications?: any;
  platform?: any;
  title: string;
  alias: string;
  tax: boolean;

  productTypeIds?: string[];
  attributes: string[];
  attributesProduct: string[];
  attributeItems: Record<string, string[]>;
  attributeItemsProduct: Record<string, string[]>;
  variantTables: Array<VariantItemData>;
  dataAttribute?: any;
  seoTitle: string;
  seoDescription: string;
  seoKeyword: string;
  seoImage: string;
  isActive: boolean;
  isFeature: boolean;
  price: number;
  sellPrice: number;
  isPrimary: boolean;
  stock: number;
  selectSpecifications?: any;
  specifications?: string;
  accompanyingGifts?: string;
  // variantCombination: Array<any>;
  tags: string[];
  information?: string;
  platformDetail?: string;
  informationDetail?: {
    information?: string;
    specifications?: string;
    accompanyingGifts?: string;
    description?: string;
    shortDescription?: string;
  };
}

export interface VariantItemData {
  id?: string;
  attributeItems: string[];
  productVariantId?: string;
  image: string;
  price: string;
  sellPrice: string;
  barcode: string;
  sku: string;
}

export const initRentalPrice = [
  {
    type: "hour",
    price: "",
  },
  {
    type: "day",
    price: "",
  },
  {
    type: "month",
    price: "",
  },
  {
    type: "year",
    price: "",
  },
  {
    type: "minute-fee",
    price: "",
  },
];

export const defaultInformationProduct = `<table border="1" cellpadding="1" cellspacing="1" style="min-width:500px">
<tbody>
    <tr>
        <td><strong>&nbsp;Nhà sản xuất</strong></td>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td><strong>&nbsp;Nhà xuất khẩu</strong></td>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td><strong>&nbsp;Nhà phân phối</strong></td>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td><strong>&nbsp;Năm sản xuất</strong></td>
        <td>&nbsp;</td>
    </tr>
</tbody>
</table>`;

export const initialValue: formEditType = {
  gallery: [],
  barcode: "",
  sku: "",
  isRental: false,
  brandId: "",
  isGift: false,
  // collectionIds: [],
  category: {},
  isPhysical: false,
  hasVariant: false,
  isActive: false,
  isFeature: false,
  price: 0,
  // sellPrice: 0,
  categoryIds: [],
  productTypeIds: [],
  // image: "",

  thumbnail: "",
  title: "",
  alias: "",
  seoDescription: "",
  seoImage: "",
  seoKeyword: "",
  seoTitle: "",
  attributes: [],
  attributesProduct: [],
  attributeItems: {},
  attributeVariantItems: {},
  attributeItemsProduct: {},
  variantTables: [] as VariantItemData[],
  tax: false,
  // variantCombination: [],
  isPrimary: false,
  sellPrice: 0,
  stock: 0,
  rentPrices: initRentalPrice,
  selectSpecifications: initialSpecifications[0].id,
  tags: [],
  informationDetail: {
    shortDescription: "",
    description: "",
    specifications: "",
    information: "",
  },
};

export type QueryResponse = Response<Array<ItemModel>>;
