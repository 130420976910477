import { Response } from "_metronic/helpers";
import moment from "moment";

export type ItemModel = {
  stores: Array<any>;
  fromDate: Date;
  toDate: Date;
  totalRevenue?: number;
  yesterdayRevenue?: number;
  lastMonthRevenue?: number;
};

export const initialStore = [
  {
    label: "Tất cả",
    value: "all",
  },
];

export const initialData: ItemModel = {
  stores: initialStore,
  fromDate: moment().startOf("day").toDate(),
  toDate: moment().toDate(),
  totalRevenue: 0,
  yesterdayRevenue: 0,
  lastMonthRevenue: 0,
};

export type QueryResponse = Response<Array<ItemModel>>;
