import Button from "app/components/Button";
import Repeater from "app/components/Form/Repeater";

const Logs = ({ data, formik }: { data: any; formik: any }) => {
  return (
    <>
      <div className="card card-flush mt-6 mt-xl-9">
        {/*begin::Card header*/}
        <div className="card-header mt-5">
          {/*begin::Card title*/}
          <div className="card-title flex-column">
            <h3 className="fw-bold mb-1">Logs</h3>
          </div>
          {/*begin::Card title*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body pt-0">
          <div className="fv-row mb-7">
            <Repeater
              deletable={false}
              label=""
              name="logs"
              formik={formik}
              //   type="feature"
              //   disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              //   onBlur={formik.handleBlur}
              values={formik.values.logs}
              headerItem={["Nội dung", "Ngày tạo"]}
              initItem={[
                {
                  content: "",
                  createdAt: Date.now(),
                },
              ]}
              inputItem={[
                {
                  type: "text",
                  name: "content",
                  //   disabled: true,
                },
                {
                  type: "datetime",
                  name: "createdAt",
                  disabled: true,
                },
              ]}
            />
          </div>

          <div className="text-end">
            <Button
              type="button"
              className="btn btn-primary "
              onClick={() => formik.handleSubmit()}
            >
              Cập nhật
            </Button>
          </div>
        </div>
        {/*end::Card body*/}
      </div>
    </>
  );
};

export default Logs;
