import { KTCard, KTCardBody } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import { Input, Switcher } from "app/components/Form";
import { FormField } from "app/components/Form/Field";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { CONTENT_VALIDATE_RULE } from "constants/validate-rule";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { initialData } from "../core/_models";
import {
  createItem,
  editItem,
  getItem,
  getListCategories,
  getListTag,
} from "../core/_requests";

export const EditPage = () => {
  const [data, setData] = useState<any | null>(initialData);
  const [tags, setTags] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const validation = Yup.object().shape({
    ...CONTENT_VALIDATE_RULE,
    tags: Yup.array().of(
      Yup.object().shape({
        key: Yup.string(),
        value: Yup.string(),
      })
    ),
    categories: Yup.array().of(
      Yup.object().shape({
        key: Yup.string(),
        value: Yup.string(),
      })
    ),
  });

  const submit = async (query: any) => {
    try {
      if (id) {
        await editItem(
          { ...query, isActive: query.isPreview ? false : query.isActive },
          id
        ).then(updateSuccess);
      } else {
        await createItem({
          ...query,
          isActive: query.isPreview ? false : query.isActive,
        }).then(() => {
          createSuccess();
          if (query.isPreview) {
            window.open(
              `${process.env.REACT_APP_WEBSITE_URL}/blogs/${query.alias}`
            );
          } else {
            navigate("/post");
          }
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getListCategories().then((data) => setCategories(mapValue(data)));
    initialPage(id);
  }, [id]);

  const initialPage = async (id?: string) => {
    if (id) {
      const result: any = await getItem(id);
      setData({
        ...result,
        tags: mapValue(result.tags),
        categories: mapValue(result.categories),
      });
    } else {
      setData(initialData);
    }

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const query = {
          ...values,
          isActive: Boolean(values?.isActive),
          tags: values?.tags.map((option: any) => option.value),
          categories: values?.categories.map((option: any) => option.value),
        };
        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "title",
      required: true,
      type: "text",
    },
    {
      label: "Alias",
      name: "alias",
      required: true,
      type: "alias",
    },
    {
      label: "Nội dung",
      name: "description",
      required: false,
      type: "editor",
    },
    {
      label: "Mô tả ngắn",
      name: "excerpt",
      type: "textarea",
      max: 250,
    },
    {
      label: "Danh mục",
      name: "categories",
      required: false,
      type: "multiple",
      displayValue: "label",
      options: categories,
      onSearch: (x: string) =>
        getListCategories(x).then((data) => setCategories(mapValue(data))),
    },
    {
      label: "Tags",
      name: "tags",
      required: false,
      type: "multiple",
      displayValue: "label",
      options: tags,
      onSearch: (x: string) =>
        getListTag(x).then((data) => setTags(mapValue(data?.data))),
    },
  ];

  return (
    <>
      <PageTitle>{id ? data.title : "Thêm mới"}</PageTitle>
      <form onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard>
              <KTCardBody>
                {FIELDS.map((field: any) => {
                  const fieldData = { ...field, formik };
                  return (
                    <div key={fieldData.name} className="fv-row mb-7">
                      {FormField(fieldData)}
                    </div>
                  );
                })}
              </KTCardBody>
            </KTCard>
            <SEO className="mt-7" formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isEdit={!!id}
              isShowActive={true}
              isLoading={isLoading}
              onSubmit={formik.handleSubmit}
            >
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 border"
                path="/post"
              />
              <div className="mt-4">
                <Input
                  label="Ngày đăng"
                  placeholder="Ngày đăng"
                  type="datetime-local"
                  name="publishedDate"
                  disabled={formik.isSubmitting}
                  onChange={(val) => formik.setFieldValue("publishedDate", val)}
                  onBlur={formik.handleBlur}
                  values={formik.values.publishedDate}
                />
              </div>
              <div className="mt-4 mb-4">
                <Switcher
                  label="Tour"
                  name="isTour"
                  onChange={formik.handleChange}
                  value={formik.values.isTour}
                  direction="horizontal"
                />
              </div>
              <div className="fv-row mb-7">
                <Switcher
                  label="Xem trước"
                  name="isPreview"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={formik.values.isPreview}
                />
              </div>
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
