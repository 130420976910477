import { getData } from "services/api";

import { QueryResponse } from "./_models";

const URL = `report`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, query);

export { getList };
