import { KTCard } from "_metronic/helpers";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import { Input } from "app/components/Form";
import { FormField } from "app/components/Form/Field";
import Label from "app/components/Form/Label";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem, getItem } from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) =>
        setData({
          ...x,
          platform: x?.platform?.map((item?: string) => ({
            label: item,
            value: item,
          })),
        })
      )
      .catch((e) => {
        console.log(e);
        navigate("/product-category");
      });
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (id) {
          await editItem(
            {
              ...values,
              platform: [process.env.REACT_APP_PLATFORM],
              isShowHome: !!values?.isShowHome,
              isActive: !!values?.isActive,
            },
            id
          ).then(updateSuccess);
        } else {
          await createItem({
            ...values,
            platform: [process.env.REACT_APP_PLATFORM],
            isShowHome: !!values?.isShowHome,
            isActive: !!values?.isActive,
          }).then(() => {
            createSuccess();
            navigate("/product-category");
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "title",
      required: true,
      type: "text",
    },
    {
      label: "Alias",
      name: "alias",
      required: true,
      type: "alias",
    },
  ];

  return (
    <>
      {id && <PageTitle>{data.title}</PageTitle>}
      <form className="form" onSubmit={formik.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="mb-10 flex-lg-row-fluid mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };
                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}

              {/* <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={Object.keys(PlatformEnum).map((item) => ({
                    label: PlatformEnum[item as keyof typeof PlatformEnum],
                    value: item,
                  }))}
                  label="Nền tảng đăng sản phẩm"
                  name="platform"
                  onSelect={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  values={formik.values.platform}
                />
              </div> */}
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={formik.handleSubmit}
            >
              <Label>Thumnail</Label>
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto mb-10 border w-100"
                path="/product-category"
              />

              <div className="fv-row mb-7">
                <Input
                  type="number"
                  label="Thứ tự sắp xếp"
                  placeholder="Thứ tự sắp xếp"
                  name="orderNumber"
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.orderNumber || 0}
                />
              </div>

              {/* <div className="fv-row mb-7">
                <Switcher
                  label="Hiển thị trang chủ"
                  name="isShowHome"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={!!formik.values.isShowHome}
                />
              </div> */}
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
