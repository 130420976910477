import { ID, KTCard } from "_metronic/helpers";
import { updateSuccess } from "_metronic/helpers/toastify";
import Button from "app/components/Button";
import { InputDropdown } from "app/components/Form";
import CustomTabs from "app/components/Tabs";
import { BikeServiceEnum } from "constants/enum";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { initialData, ItemModel } from "../core/_models";
import { editItem, getItem } from "../core/_requests";
import GeneralData from "./GeneralData";
import Logs from "./Logs";
import PriceTable from "./PriceTable";

const EditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<ItemModel>(initialData);

  const submit = async (query?: any) => {
    await editItem(query, id).then(updateSuccess);
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const query = {
          status: values.status,
          logs: values.logs || [],
        };

        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const tabs = [
    {
      id: "general",
      name: "Thông tin chung",
      content: () => (
        <>
          <KTCard className="p-10 mb-7">
            <div className="fv-row mb-7">
              <InputDropdown
                formik={formik}
                label={"Trạng thái đơn hàng"}
                options={
                  Object.keys(BikeServiceEnum)?.map((item: string) => {
                    return {
                      label:
                        BikeServiceEnum[item as keyof typeof BikeServiceEnum],
                      value: item,
                    };
                  }) || []
                }
                name="status"
                classNameLabel="form-label fs-6 fw-bold"
                classNameDropdown="form-select form-select-solid fw-bolder"
                values={formik.values.status}
              />
            </div>

            <div className="text-end">
              <Button
                type="button"
                className="btn btn-primary "
                onClick={() => formik.handleSubmit()}
                // disabled={status === formik?.values?.status}
              >
                Cập nhật
              </Button>
            </div>
          </KTCard>

          <GeneralData data={data} />

          <PriceTable data={data} />
        </>
      ),
    },

    // {
    //   id: "transactions",
    //   name: "Lịch sử thanh toán",
    //   content: () => (
    //     <>
    //       <Transaction data={data?.logs} />
    //     </>
    //   ),
    // },

    {
      id: "logs",
      name: "Logs",
      content: () => (
        <>
          <Logs data={data?.logs} formik={formik} />
        </>
      ),
    },
  ];

  useEffect(() => {
    initialPage(id);
  }, [id]);

  const initialPage = async (id?: ID) => {
    getItem(id)
      .then((data) => {
        // setStatus(data?.status);
        setData({ ...data });

        // Promise.all([
        //   getDetailWards(data?.shippingInformation?.ward),
        //   getDetailDistrict(data?.shippingInformation?.district),
        //   getDetailProvinces(data?.shippingInformation?.province),
        // ]).then((values) =>
        //   setDetailLocation(
        //     `${values[0]?.name || ""}, ${values[1]?.name || ""}, ${
        //       values[2]?.name || ""
        //     }`
        //   )
        // );
      })
      .catch((e) => {
        console.log(e);
        navigate("/orders");
      });
  };

  return (
    <>
      <CustomTabs
        className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
        items={tabs}
      />
    </>
  );
};

export default EditPage;
