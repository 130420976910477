import { QUERIES } from "_metronic/helpers";
import {
  ListViewProvider,
  useListView,
} from "_metronic/helpers/provider/ListViewProvider";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "_metronic/helpers/provider/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "_metronic/helpers/provider/QueryResponseProvider";
import ListManagement from "app/components/Page";
import { useNavigate, useParams } from "react-router-dom";

import { COLUMNS_DETAIL } from "../core/_columnsDetail";
import { bulkDelete, deleteItem, getListDetail } from "../core/_requests";

const List = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <ListManagement
        columns={COLUMNS_DETAIL}
        actionAddNew={() => {
          navigate(`/bike-rental/${id}/create`);
        }}
        useQueryResponseLoading={useQueryResponseLoading}
        queryString={[QUERIES.BIKE_RENTAL_LIST]}
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryResponseData={useQueryResponseData}
        useListView={useListView}
        useQueryRequest={useQueryRequest}
        useQueryResponse={useQueryResponse}
        bulkDelete={bulkDelete}
        deleteItem={deleteItem}
      />
    </>
  );
};

export const ListDetailWrapper = () => {
  const { id } = useParams();

  return (
    <QueryRequestProvider>
      <QueryResponseProvider
        queryString={QUERIES.BIKE_RENTAL_LIST}
        getList={() => getListDetail(id)}
      >
        <ListViewProvider>
          <List />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};
