import { useQueryRequest } from "_metronic/helpers/provider/QueryRequestProvider";
import {
  CreatedByColumn,
  DefaultCell,
  UpdatedByColumn,
} from "app/components/Table/columns";
import { Header } from "app/components/Table/header";
import { Column } from "react-table";

import { ItemModel } from "./_models";

const COLUMNS: ReadonlyArray<Column<ItemModel>> = [
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Admin"
        className="min-w-200px"
      />
    ),
    accessor: "target",
    Cell: ({ ...props }) => (
      <DefaultCell
        value={props.data[props.row.index].target?.fullname || "-"}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Method"
        className="min-w-100px"
      />
    ),
    accessor: "method",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].method} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="URL"
        className="min-w-200px"
      />
    ),
    accessor: "url",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].url} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="IP"
        className="min-w-150px"
      />
    ),
    accessor: "ip",
    Cell: ({ ...props }) => (
      <DefaultCell value={props.data[props.row.index].ip} />
    ),
  },

  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Ngày tạo"
        className="min-w-200px"
      />
    ),
    accessor: "createdAt",
    Cell: ({ ...props }) => (
      <CreatedByColumn value={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <Header
        useQueryRequest={useQueryRequest}
        tableProps={props}
        title="Cập nhật"
        className="min-w-150px"
      />
    ),
    accessor: "updatedAt",
    Cell: ({ ...props }) => (
      <UpdatedByColumn value={props.data[props.row.index]} />
    ),
  },
  // {
  //   Header: "Hành động",
  //   id: "action",
  //   Cell: (props: any) => (
  //     <div className=" d-flex gap-2 min-w-200px justify-content-between">
  //       <ActionEditCell
  //         data={props.row.original}
  //         useListView={useListView}
  //         isModal={false}
  //         path={`/payment/${props.data[props.row.index].id}/edit`}
  //       />
  //       {/* <ActionDeleteCell
  //         data={props.row.original}
  //         useListView={useListView}
  //         hideText={true}
  //       /> */}
  //     </div>
  //   ),
  // },
];

export { COLUMNS };
