/* eslint-disable */
import { Dispatch, SetStateAction } from "react";

export type ID = undefined | null | number | string;

export type PaginationState = {
  page: number;
  limit: 10 | 30 | 50 | 100;
  pageTotal?: any;
  total?: any;
  links?: Array<{
    label: string;
    active: boolean;
    url: string | null;
    page: number | null;
  }>;
};

export type SortState = {
  sortBy?: string;
  orderBy?: "asc" | "desc";
};

export type FilterState = {
  [x: string]: any;
  filter?: any;
  isActive?: boolean;
  fromDate?: string;
  toDate?: string;
};

export type SearchState = {
  search?: string;
};

export type Response<T> = {
  [x: string]: any;
  data?: T;
  pageTotal?: number | string;
  total?: number;
  page?: number;
  limit?: number;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
    pagination?: PaginationState;
  };
};

export type QueryState = PaginationState &
  SortState &
  FilterState &
  SearchState;

export type QueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
  sortBy: "createdAt",
  orderBy: "desc",
};

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
};

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined;
  dataListRole?: Response<Array<T>> | undefined;
  dataListTag?: Response<Array<T>> | undefined;
  dataListPermission?: Response<Array<T>> | undefined;
  dataListCatelogies?: Response<Array<T>> | undefined;
  refetch: () => void;
  isLoading: boolean;
  query: string;
};

export type QueryResponseRoleListContextProps<T> = {
  // dataListRole?: any;
  refetch: () => void;
  isLoading: boolean;
  query: string;
};

export const initialQueryResponse = {
  refetch: () => {},
  isLoading: false,
  query: "",
};

export type ListViewContextProps = {
  selected: Array<ID>;
  onSelect: (selectedId: ID) => void;
  onSelectAll: () => void;
  clearSelected: () => void;
  total?: number | string;
  setTotal?: any;
  pageTotal?: number | string;
  setPageTotal?: any;
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID;
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
  itemDelete?: any;
  setItemDelete: Dispatch<SetStateAction<ID>>;
  isAllSelected: boolean;
  disabled: boolean;
};

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemDelete: () => {},
  isAllSelected: false,
  disabled: false,
  total: "",
  setTotal: () => {},
  pageTotal: "",
  setPageTotal: () => {},
};
