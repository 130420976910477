import { ID, Response } from "_metronic/helpers";

export type MenuItem = {
  id?: ID;
  name?: string;
  url?: string;
  parent?: string;
  orderNumber?: number;
};

export type ItemModel = {
  alias?: string;
  items?: Array<MenuItem>;
  createdBy?: any;
  createdAt?: string;
  updatedAt?: string;
};

export const initialData = {
  id: "",
  alias: "",
  items: [],
};

export const initialMenuItemData = {
  name: "",
  url: "",
  orderNumber: 0,
};

export type QueryResponse = Response<Array<ItemModel>>;
