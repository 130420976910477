import { KTCard } from "_metronic/helpers";

import FileManager from "../../../../components/FileManager";

const MediaPage = () => {
  return (
    <>
      <KTCard>
        <FileManager />
      </KTCard>
    </>
  );
};

export default MediaPage;
