import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import { GroupListWrapper } from "./components/GroupList";
import ViewDetail from "./components/ViewDetail";

const Breadcrumbs: Array<PageLink> = [
  {
    title: "Danh danh nhóm",
    path: "/group",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "Danh sách nhóm",
    path: "/group",
    isSeparator: true,
    isActive: false,
  },
];

const GroupPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Danh sách nhóm</PageTitle>
              <GroupListWrapper></GroupListWrapper>
            </>
          }
        />
        <Route
          path=":id/view-detail"
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Chi tiết nhóm</PageTitle>
              <ViewDetail></ViewDetail>
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default GroupPage;
