interface PropsType {
  value?: any;
}

export const CreatedByColumn = (props: PropsType) => {
  const { value } = props;
  if (!value) return <></>;
  return (
    <>
      <div>
        Vào lúc <span className="text-dark">{value.createdAt || "-"}</span>
      </div>
      {value.createdBy ? (
        <div>
          bởi{" "}
          <span className="text-primary">
            {value.createdBy.fullname || "-"}
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CreatedByColumn;
