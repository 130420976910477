export interface DataSpecificationsType {
  title: string;
  value: string;
}

export interface SpecificationsType {
  id: string;
  title: string;
  data: DataSpecificationsType[];
}

export const initialSpecificationsBicycle = [
  {
    title: "Kích cỡ/Size",
    value: "",
  },
  {
    title: "Màu sắc/Colors",
    value: "",
  },
  {
    title: "Chất liệu khung/Frame",
    value: "",
  },
  {
    title: "Phuộc/Fork",
    value: "",
  },
  {
    title: "Giảm xóc/Shock",
    value: "",
  },
  {
    title: "Vành xe/Rims",
    value: "",
  },
  {
    title: "Đùm/Hubs",
    value: "",
  },
  {
    title: "Căm/Spokes",
    value: "",
  },
  {
    title: "Lốp xe/Tires",
    value: "",
  },
  {
    title: "Ghi đông/Handlebar",
    value: "",
  },
  {
    title: "Pô tăng/Stem",
    value: "",
  },
  {
    title: "Cốt yên/Seatpost",
    value: "",
  },
  {
    title: "Yên/Saddle",
    value: "",
  },
  {
    title: "Bàn đạp/Pedals",
    value: "",
  },
  {
    title: "Tay đề/Shifters",
    value: "",
  },
  {
    title: "Chuyển đĩa/Front Derailleur",
    value: "",
  },
  {
    title: "Chuyển líp/Rear Derailleur",
    value: "",
  },
  {
    title: "Bộ thắng/Brakes",
    value: "",
  },
  {
    title: "Tay thắng/Brake Levers",
    value: "",
  },
  {
    title: "Bộ líp/Cassette",
    value: "",
  },
  {
    title: "Sên xe/Chain",
    value: "",
  },
  {
    title: "Giò dĩa/Crankset",
    value: "",
  },
  {
    title: "B.B/Bottom Bracket",
    value: "",
  },
  {
    title: "Trọng lượng/Weight",
    value: "",
  },
  {
    title: "Kích thước đóng thùng / Packing size",
    value: "",
  },
];

export const initialXedien = [
  {
    title: "Kích cỡ/Sizes",
    value: "",
  },
  {
    title: "Màu sắc/Colors",
    value: "",
  },
  {
    title: "Ắc quy/Battery",
    value: "",
  },
  {
    title: "Công suất động cơ/Motor",
    value: "",
  },
  {
    title: "Vận tốc tối đa/Max speed",
    value: "",
  },
  {
    title: "Quãng đường di chuyền/Range",
    value: "",
  },
  {
    title: "Bánh xe trước/Front Wheel",
    value: "",
  },
  {
    title: "Bánh xe sau/Rear Wheel",
    value: "",
  },
  {
    title: "Thắng trước/Front Brakes",
    value: "",
  },
  {
    title: "Thắng sau/Rear Brakes",
    value: "",
  },
  {
    title: "Sạc điện/Charge",
    value: "",
  },
  {
    title: "Thêm/Extras",
    value: "",
  },
  {
    title: "Trọng lượng/Weight",
    value: "",
  },
];

export const initialBikeCardNew = [
  {
    title: "Loại xe/Type",
    value: "",
  },
  {
    title: "Thương hiệu/Brand",
    value: "",
  },
  {
    title: "Xuất xứ/Origin",
    value: "",
  },
  {
    title: "Phanh(trước/sau)/Brake (Front/Rear)",
    value: "",
  },
  {
    title: "Vành(trước/sau)/Rim (Front/Rear)",
    value: "",
  },
  {
    title: "Ác quy/Battery",
    value: "",
  },
  {
    title: "Dung lượng/Capacity",
    value: "",
  },
  {
    title: "Công suất xe/Power",
    value: "",
  },
  {
    title: "Size xe/Dimension",
    value: "",
  },
  {
    title: "Quãng đường tối đa/Speed",
    value: "",
  },
  {
    title: "Vận tốc tối đa/Speed",
    value: "",
  },
  {
    title: "Thời gian sạc/Charging time",
    value: "",
  },
  {
    title: "Trọng lượng/Weight",
    value: "",
  },
];

export const initialBoshing = [
  {
    title: "Chế độ/Function",
    value: "",
  },
  {
    title: "Công suất/Power",
    value: "",
  },
  {
    title: "Pin/Battery",
    value: "",
  },
  {
    title: "Thời gian sử dụng/Battery Life",
    value: "",
  },
  {
    title: "Thời gian sạc/Charging time",
    value: "",
  },
];

export const initialKhungSuon = [
  {
    title: "Kích cỡ/Sizes",
    value: "",
  },
  {
    title: "Màu sắc/Colors",
    value: "",
  },
  {
    title: "Chất liệu khung/frame",
    value: "",
  },
  {
    title: "Phuộc/fork",
    value: "",
  },
  {
    title: "Cốt yên/Seatpost",
    value: "",
  },
];

export const initialRuotxe = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialYenXe = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialKhoaCotYen = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Hướng dẫn sử dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialPoTang = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Hướng dẫn sử dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialChanBun = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialQueGan = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialGhiDongPoTang = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialDayQuan = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Màu sắc",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialBanhTruocSau = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialVoXeCoRuotKhongRuot = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialTuiSuonYen = [
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Công dụng",
    value: "",
  },
  {
    title: "Hướng dẫn sử dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialBaga = [
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Hướng dẫn sử dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialBaoVeSenXe = [
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Công dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialBinhNuoc = [
  {
    title: "Dung tích",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
  {
    title: "Thông tin khác",
    value: "",
  },
];

export const initialGongNuoc = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Loại bình nước phù hợp",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialChenCo = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Công dụng",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialKhoaDay = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Hướng dẫn sử dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialDungCuNaiVo = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialOngBom = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Thông tin khác",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialNonBaoHiem = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Hướng dẫn chọn size",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialBaoTay = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Hướng dẫn chọn size",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialGheTreEm = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Thông tin khác",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialDongHoDoTocDo = [
  {
    title: "Kích thước",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Cách sử dụng",
    value: "",
  },
  {
    title: "Công dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialDauBoiTron = [
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Cách sử dụng",
    value: "",
  },
  {
    title: "Công dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialThietBiTapLuyen = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Cách sử dụng",
    value: "",
  },
  {
    title: "Công dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialWahooDongHoDoTocDo = [
  {
    title: "Chất liệu",
    value: "",
  },

  {
    title: "Công dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialWahooThietBiDoGuongchan = [
  {
    title: "Chất liệu",
    value: "",
  },

  {
    title: "Công dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialWahooThietBiTapLuyen = [
  {
    title: "Chất liệu",
    value: "",
  },

  {
    title: "Dòng xe phù họp",
    value: "",
  },
  {
    title: "Thông tin khác",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialDoNhipTim = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Thông tin khác",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialChanChong = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Dòng xe phù hợp",
    value: "",
  },
  {
    title: "Hướng dẫn sử dụng",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialMatKinh = [
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Thông tin khác",
    value: "",
  },
  {
    title: "Bảo hành",
    value: "",
  },
];

export const initialDen = [
  {
    title: "Xuất xứ",
    value: "",
  },
  {
    title: "Chất liệu",
    value: "",
  },
  {
    title: "Chế độ sáng",
    value: "",
  },
  {
    title: "Thông tin khác",
    value: "",
  },
  {
    title: "Pin",
    value: "",
  },
];

export const initialXeCu = [
  {
    title: "Kích cỡ/Size",
    value: "",
  },
  {
    title: "Chất liệu khung/Frame",
    value: "",
  },
  {
    title: "Phuộc/Fork",
    value: "",
  },
  {
    title: "Vành xe/Rims",
    value: "",
  },
  {
    title: "Đùm/Hubs",
    value: "",
  },
  {
    title: "Căm/Spokes",
    value: "",
  },
  {
    title: "Lốp xe/Tires",
    value: "",
  },
  {
    title: "Ghi đông/Handlebar",
    value: "",
  },
  {
    title: "Pô tăng/Stem",
    value: "",
  },
  {
    title: "Cốt yên/Seatpost",
    value: "",
  },
  {
    title: "Yên/Saddle",
    value: "",
  },
  {
    title: "Tay đề/Shifters",
    value: "",
  },
  {
    title: "Chuyển đĩa/Front Derailleur",
    value: "",
  },
  {
    title: "Chuyển líp/Rear Derailleur",
    value: "",
  },
  {
    title: "Bộ thắng/Brakes",
    value: "",
  },
  {
    title: "Tay thắng/Brake Levers",
    value: "",
  },
  {
    title: "Bộ líp/Cassette",
    value: "",
  },
  {
    title: "Sên xe/Chain",
    value: "",
  },
  {
    title: "Giò dĩa/Crankset",
    value: "",
  },
];

export const initialSpecifications: SpecificationsType[] = [
  {
    id: "xe-dap",
    title: "Xe đạp",
    data: initialSpecificationsBicycle,
  },
  {
    id: "xe-dien",
    title: "Xe điện",
    data: initialXedien,
  },
  {
    id: "bike-card-moi",
    title: "Bike card mới",
    data: initialBikeCardNew,
  },
  {
    id: "den-xe-dap-boshing",
    title: "Đèn xe đạp boshing",
    data: initialBoshing,
  },
  {
    id: "khung-suon",
    title: "Khung sườn",
    data: initialKhungSuon,
  },
  {
    id: "ruot-xe",
    title: "Ruột xe",
    data: initialRuotxe,
  },
  {
    id: "yen-xe",
    title: "Yên xe",
    data: initialYenXe,
  },
  {
    id: "khoa-cot-yen",
    title: "Khoá cốt yên",
    data: initialKhoaCotYen,
  },
  {
    id: "po-tang",
    title: "Pô tăng",
    data: initialPoTang,
  },
  {
    id: "chan-bun",
    title: "Chắn bùn",
    data: initialChanBun,
  },
  {
    id: "que-gan",
    title: "Quê gắn",
    data: initialQueGan,
  },
  {
    id: "ghi-dong-po-tang",
    title: "Ghi đông Pô tăng",
    data: initialGhiDongPoTang,
  },
  {
    id: "day-quan",
    title: "Dây quấn",
    data: initialDayQuan,
  },
  {
    id: "banh-truoc-sau",
    title: "Bánh trước/sau",
    data: initialBanhTruocSau,
  },
  {
    id: "vo-xe-co-ruot-khong-ruot",
    title: "Vỏ xe có ruột/không ruột",
    data: initialVoXeCoRuotKhongRuot,
  },
  {
    id: "tui-suon-yen",
    title: "Túi sườn/Yên",
    data: initialTuiSuonYen,
  },
  {
    id: "baga",
    title: "Baga",
    data: initialBaga,
  },
  {
    id: "bao-ve-sen-xe",
    title: "Bảo vệ sên xe",
    data: initialBaoVeSenXe,
  },
  {
    id: "binh-nuoc",
    title: "Bình nước",
    data: initialBinhNuoc,
  },
  {
    id: "gong-nuoc",
    title: "Gọng nước",
    data: initialGongNuoc,
  },
  {
    id: "chen-co",
    title: "Chén cổ",
    data: initialChenCo,
  },
  {
    id: "khoa-day",
    title: "Khoá dây",
    data: initialKhoaDay,
  },
  {
    id: "dung-cu-nai-vo",
    title: "Dụng cụ nại vỏ",
    data: initialDungCuNaiVo,
  },
  {
    id: "ong-bom",
    title: "Ống bơm",
    data: initialOngBom,
  },
  {
    id: "non-bao-hiem",
    title: "Nón bảo hiểm",
    data: initialNonBaoHiem,
  },
  {
    id: "bao-tay",
    title: "Bao tay",
    data: initialBaoTay,
  },
  {
    id: "ghe-tre-em",
    title: "Ghế trẻ em",
    data: initialGheTreEm,
  },
  {
    id: "dong-ho-do-toc-do",
    title: "Đồng hồ đo tốc độ",
    data: initialDongHoDoTocDo,
  },
  {
    id: "dau-boi-tron",
    title: "Dầu bôi trơn",
    data: initialDauBoiTron,
  },
  {
    id: "wahoo-dong-ho-do-toc-do-dinh-vi",
    title: "Wahoo-Đồng hồ đo tốc độ/định vị",
    data: initialWahooDongHoDoTocDo,
  },
  {
    id: "wahoo-thiet-bi-do-guong-chan-tao-do-doc-tham",
    title: "Wahoo-Thiết bị đo guồng chân, tạo độ dốc, thảm",
    data: initialWahooThietBiDoGuongchan,
  },
  {
    id: "wahoo-thiet-bi-tap-luyen",
    title: "Thiết bị tập luyện",
    data: initialWahooThietBiTapLuyen,
  },
  {
    id: "wahoo-day-do-nhip-tim-dong-ho",
    title: "Wahoo-Dây đo nhịp tim/Đồng hồ",
    data: initialDoNhipTim,
  },
  {
    id: "chan-chong",
    title: "Chân chống",
    data: initialChanChong,
  },
  {
    id: "mat-kinh",
    title: "Mắt kính",
    data: initialMatKinh,
  },
  {
    id: "den",
    title: "Đèn",
    data: initialDen,
  },
  {
    id: "xe-cu",
    title: "Xe cũ",
    data: initialXeCu,
  },
];
