import { checkIsActiveWithSub, KTIcon, WithChildren } from "_metronic/helpers";
import { useLayout } from "_metronic/layout/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const { pathname } = useLocation();
  const [isActive, setActive] = useState<boolean>(false);
  const { config } = useLayout();
  const { app } = config;

  useEffect(() => {
    setActive(false);
    if (children && Array.isArray(children)) {
      children.forEach((child) => {
        if (checkIsActiveWithSub(pathname, child.props.to)) {
          setActive(true);
          return;
        }
      });
    }
  }, [children]);

  return (
    <div
      className={clsx(
        "menu-item menu-lg-down-accordion menu-sub-lg-down-indention",
        { "here show": isActive },
        ""
      )}
      data-kt-menu-trigger="click"
    >
      <span className="menu-link gap-2">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
          <span className="menu-icon">
            <KTIcon iconName={icon} className="fs-2" />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title">{title}</span>
        <span className="menu-arrow"></span>
      </span>
      <div
        className={clsx(
          "menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-2 py-4 w-200px mh-75 overflow-auto",
          {
            "menu-active-bg": isActive,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export { SidebarMenuItemWithSub };
