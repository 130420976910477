import { KTCard } from "_metronic/helpers";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { swallError } from "_metronic/helpers/swal";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { FormField } from "app/components/Form/Field";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { PERCENTAGE_VALIDATE } from "constants/validate-rule";
import { VOUCHER_OPTION } from "constants/voucher";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { initialData, ItemCampaignModel } from "../core/_models";
import { createItem, editItem, getItem } from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<ItemCampaignModel>(initialData);

  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();

  const validation = Yup.object().shape({
    ...PERCENTAGE_VALIDATE,
  });

  const submit = async (query: any) => {
    try {
      if (query.discountType === "percentage" && query.discountValue > 100) {
        swallError("Giá trị giảm không phù hợp", "Phần trăm phải nhỏ hơn 100");

        return;
      }

      if (id) {
        await editItem(query, id).then(updateSuccess);
      } else {
        await createItem(query).then(() => {
          createSuccess();
          navigate("/campaigns");
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) =>
        setData({
          ...x,
          platform: x?.platform?.map((item: string) => ({
            label: item,
            value: item,
          })),
        })
      )
      .catch((e) => {
        console.log(e);
        navigate("/campaigns");
      });
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const query = {
          ...values,
          id: values?.id || null,
          isActive: !!values?.isActive,
          platform: [process.env.REACT_APP_PLATFORM],
        };
        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "name",
      required: true,
      type: "text",
    },

    {
      label: "Loại giảm giá",
      name: "discountType",
      required: true,
      type: "dropdown",
      options: VOUCHER_OPTION.discountType,
    },

    {
      label: "Giá trị giảm",
      name: "discountValue",
      required: false,
      type: "number",
      min: 0,
      max: formik.values.discountType === "percentage" ? 100 : null,
    },

    {
      label: "Ngày bắt đầu",
      name: "startDate",
      required: false,
      type: "datetime-local",
      onChange: (val?: any) => {
        formik.setFieldValue("startDate", val);
      },
    },

    {
      label: "Ngày hết hạn",
      name: "endDate",
      required: false,
      type: "datetime-local",
      onChange: (val?: any) => {
        formik.setFieldValue("endDate", val);
      },
    },
  ];

  return (
    <>
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };
                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={() => formik.handleSubmit()}
            >
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 border mb-10"
                path="/deal"
              />

              {/* <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={Object.keys(PlatformEnum).map((item) => ({
                    label: PlatformEnum[item as keyof typeof PlatformEnum],
                    value: item,
                  }))}
                  label="Nền tảng"
                  name="platform"
                  onSelect={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  values={formik.values.platform}
                />
              </div> */}
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
