/* eslint-disable */
// @ts-nocheck
import clsx from "clsx";
import { FC } from "react";
import { Row } from "react-table";

import { User } from "../../../pages/account-management/admin/core/_models";

type Props = {
  row: Row<User>;
  onClick?: (data?: any) => void;
};

const RowCell: FC<Props> = ({ row, onClick }) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          onClick={() => {
            onClick ? onClick(row?.original) : () => null;
          }}
          className={clsx({
            "text-end min-w-100px": cell.column.id === "actions",
            "text-primary":
              cell.column.id === "fullname" || cell.column.id === "name",
          })}
        >
          {cell.render("Cell")}
        </td>
      );
    })}
  </tr>
);

export { RowCell };
