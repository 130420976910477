import { QUERIES } from "_metronic/helpers";
import {
  ListViewProvider,
  useListView,
} from "_metronic/helpers/provider/ListViewProvider";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "_metronic/helpers/provider/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "_metronic/helpers/provider/QueryResponseProvider";
import ListManagement from "app/components/Page";

import { COLUMNS } from "../core/_columns";
import { bulkDelete, deleteItem, getList } from "../core/_requests";
import ItemModal from "./Modal";

const List = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView();

  return (
    <>
      <ListManagement
        columns={COLUMNS}
        actionAddNew={() => setItemIdForUpdate(null)}
        useQueryResponseLoading={useQueryResponseLoading}
        queryString={[QUERIES.TAG_LIST]}
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryResponseData={useQueryResponseData}
        useListView={useListView}
        useQueryRequest={useQueryRequest}
        useQueryResponse={useQueryResponse}
        bulkDelete={bulkDelete}
        deleteItem={deleteItem}
      />
      {itemIdForUpdate !== undefined && <ItemModal data={itemIdForUpdate} />}
    </>
  );
};

const ListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider queryString={QUERIES.TAG_LIST} getList={getList}>
      <ListViewProvider>
        <List />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export default ListWrapper;
