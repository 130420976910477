import { KTCard } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess } from "_metronic/helpers/toastify";
import { MultiSelectDropdown } from "app/components/Form";
import { FormField } from "app/components/Form/Field";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getItem as getListUser } from "../../../community-management/user/core/_requests";
import { initialData, ItemModel } from "../core/_models";
import { createItem, getItem } from "../core/_requests";

const EditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading } = useQueryResponse();
  const [userList, setUserList] = useState([]);
  const [data, setData] = useState<ItemModel>(initialData);

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await createItem({
          ...values,
          to: values?.to?.map((item?: any) => item.value) || [],
        }).then(() => {
          createSuccess();
          navigate("/notification");
        });
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "name",
      required: true,
      type: "text",
      disabled: !!id,
    },
    {
      label: "Nội dung",
      name: "content",
      required: true,
      type: "text",
      disabled: !!id,
    },
    {
      label: "Số lượt gửi thông báo",
      name: "sendCount",
      required: true,
      type: "number",
      disabled: !!id,
      hidden: !id,
    },
    {
      label: "Số lượt đã xem",
      name: "readCount",
      required: true,
      type: "number",
      disabled: !!id,
      hidden: !id,
    },
    // {
    //   label: "URL",
    //   name: "url",
    //   required: true,
    //   type: "text",
    // },
  ];

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) =>
        setData({
          ...x,
          to: x?.to ? mapValue(x?.to) : [],
        })
      )
      .catch((e) => {
        console.log(e);
        navigate("/notification");
      });
  };

  useEffect(() => {
    if (id) {
      initPage(id);
    }

    getListUser({ limit: 6, page: 1 }).then((data) => {
      setUserList(data?.data as any);
    });
  }, []);

  return (
    <>
      <form className="form" onSubmit={formik.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any, index: number) => {
                const fieldData = { ...field, formik };
                return (
                  <React.Fragment key={index}>
                    {!field?.hidden && (
                      <div key={fieldData.name} className="fv-row mb-7">
                        {FormField(fieldData)}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}

              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={mapValue(userList)}
                  label="Chỉ định người dùng"
                  name="to"
                  onSelect={(values) => {
                    formik.setFieldValue("to", values);
                  }}
                  onSearch={async (x: any) => {
                    const result = await getListUser({
                      search: x,
                      limit: 10,
                      page: 1,
                    });
                    setUserList(result.data || ([] as any));
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("to", values);
                  }}
                  values={formik.values.to}
                />
              </div>
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              isShowActive={false}
              formik={formik}
              isLoading={isLoading}
              isEdit={false}
              onSubmit={formik.handleSubmit}
              hideSubmitButton={!!id}
            ></StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
