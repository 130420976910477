import { KTCard } from "_metronic/helpers";
import { removeDuplicates } from "_metronic/helpers/convertData/remove-duplicate";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import { MultiSelectDropdown } from "app/components/Form";
import { FormField } from "app/components/Form/Field";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { PlatformEnum } from "constants/enum";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getList as getProductCategory } from "../../product-category/core/_requests";
import { getList as getProductTypes } from "../../type/core/_requests";
import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem, getItem } from "../core/_requests";

const EditPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productTypes, setProductTypes] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) => {
        setData({
          ...x,
          categoryIds: mapValue(x.categories),
          productTypeIds: x.types?.map((item: any) => ({
            label: `${item?.title} (${item?.alias})`,
            value: item?.id || item?.value,
          })),
          platform:
            x?.platform?.map((item?: string) => ({
              label: item,
              value: item,
            })) || [],
        });
      })
      .catch((e) => {
        console.log(e);
        navigate("/brand");
      });
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (id) {
          await editItem(
            {
              ...values,
              categories: values.categories.map((x) => ({ id: x.id })),
              isActive: !!values?.isActive,
              platform: values?.platform?.map((item?: any) => item.value),
              categoryIds: values?.categoryIds?.map((item?: any) => item.value),
              productTypeIds: values?.productTypeIds?.map(
                (item?: any) => item.value
              ),
            },
            id
          ).then(updateSuccess);
        } else {
          await createItem({
            productTypeIds: values?.productTypeIds?.map(
              (item?: any) => item.value
            ),
            categoryIds: values?.categoryIds?.map((item?: any) => item.value),
            title: values.title,
            alias: values.alias,
            image: values.image,
            platform: values?.platform?.map((item?: any) => item.value),
          }).then(() => {
            createSuccess();
            navigate("/brand");
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Tên",
      name: "title",
      required: true,
      type: "text",
    },
    {
      label: "Alias",
      name: "alias",
      required: true,
      type: "alias",
    },
    {
      label: "Danh mục",
      name: "categoryIds",
      required: true,
      type: "multiple",
      options: removeDuplicates(
        mapValue([...(formik.values.categories || []), ...productCategoryList]),
        "value"
      ),
      onSearch: (e: string) => {
        getProductCategory({ search: e, limit: 10, page: 1 }).then(
          (data?: any) => {
            setProductCategoryList(data?.data);
          }
        );
      },
    },
    {
      label: "Loại sản phẩm",
      name: "productTypeIds",
      required: true,
      type: "multiple",
      options: removeDuplicates(
        [...(formik.values.types || []), ...productTypes]?.map((item: any) => ({
          label: `${item?.title} (${item?.alias})`,
          value: item?.id || item?.value,
        })),
        "value"
      ),
      onSearch: (e: string) => {
        getProductTypes({ search: e, limit: 10, page: 1 }).then(
          (data?: any) => {
            setProductTypes(data?.data);
          }
        );
      },
    },
  ];

  return (
    <>
      {id ? (
        <PageTitle>{formik.values.title}</PageTitle>
      ) : (
        <PageTitle>Tạo mới</PageTitle>
      )}
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };
                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}

              <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={Object.keys(PlatformEnum).map((item) => ({
                    label: PlatformEnum[item as keyof typeof PlatformEnum],
                    value: item,
                  }))}
                  label="Nền tảng đăng sản phẩm"
                  name="platform"
                  onSelect={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  values={formik.values.platform}
                />
              </div>
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={formik.handleSubmit}
            >
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 border mb-10"
                path="/brand"
              />
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
