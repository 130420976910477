import { QUERIES } from "_metronic/helpers";
import {
  ListViewProvider,
  useListView,
} from "_metronic/helpers/provider/ListViewProvider";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "_metronic/helpers/provider/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "_metronic/helpers/provider/QueryResponseProvider";
import ListManagement from "app/components/Page";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { COLUMNS } from "../core/_columns";
import { bulkDelete, deleteItem, getList } from "../core/_requests";

type Props = {
  isTour: boolean;
};

const List: FC<Props> = ({ isTour }) => {
  const navigate = useNavigate();

  return (
    <>
      <ListManagement
        columns={COLUMNS}
        actionAddNew={() => {
          if (isTour) {
            navigate("/tour-category/create");
          } else {
            navigate("/event-category/create");
          }
        }}
        useQueryResponseLoading={useQueryResponseLoading}
        queryString={[`${QUERIES.EVENTS_CATEGORIES_LIST + isTour}`]}
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryResponseData={useQueryResponseData}
        useListView={useListView}
        useQueryRequest={useQueryRequest}
        useQueryResponse={useQueryResponse}
        bulkDelete={bulkDelete}
        deleteItem={deleteItem}
      />
    </>
  );
};

const ListWrapper = ({ isTour }: { isTour: boolean }) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider
        queryString={QUERIES.EVENTS_CATEGORIES_LIST + isTour}
        getList={(query: any) => getList({ ...query, isTour })}
      >
        <ListViewProvider>
          <List isTour={isTour} />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export { ListWrapper };
