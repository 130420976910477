import { KTCard } from "_metronic/helpers";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { createSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import Repeater from "app/components/Form/Repeater";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import { BikeRentalPriceEnum, BikeRentalStatusEnum } from "constants/enum";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getList as getStoreList } from "../../../content-management/store/core/_requests";
import { initialData, ItemModel } from "../core/_models";
import { createItem, getItem } from "../core/_requests";

const EditPage = () => {
  const { id: productID, child } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();
  const [storeList, setStoreList] = useState();

  useEffect(() => {
    if (child) {
      initPage(child);
    }
  }, [child]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) => {
        setData({ ...x, storeId: x?.store?.id, productId: productID });
      })
      .catch((e) => {
        console.log(e);
        navigate("/bike-rental");
      });
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const errorData: any[] = [];

        const bulkCreate = async () => {
          if (formik.values.data) {
            formik.values.data?.forEach(async (item?: any, index?: number) => {
              await createItem({
                store: item.storeId,
                storeId: item.storeId,
                code: item?.code,
                status: BikeRentalStatusEnum.AVAILABLE,
                product: productID,
                productId: productID,
              }).then((res) => {
                if (res.statusCode && res.statusCode > 300) {
                  errorData.push(item);
                  return;
                }
                // navigate(`/bike-rental`);
                createSuccess();
                navigate(`/bike-rental/${productID}/detail`);
              });
            });
          }
        };

        await bulkCreate();

        if (errorData?.length > 0) {
          formik.setFieldValue("data", errorData);
          return;
        } else {
          //   navigate(`/bike-rental/${productID}/detail`);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      {child ? (
        <PageTitle>{formik.values.code}</PageTitle>
      ) : (
        <PageTitle>Tạo mới</PageTitle>
      )}
      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              <div className="fv-row mb-7">
                <Repeater
                  // isModal
                  label="Dữ liệu thêm"
                  name="data"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  // values={formik.values.items}
                  headerItem={["Code", "Cửa hàng"]}
                  initItem={[]}
                  inputItem={[
                    {
                      style: { width: "200px" },
                      type: "text",
                      name: "code",
                      label: "Code",
                      required: true,
                    },

                    {
                      type: "dropdown",
                      name: "storeId",
                      label: "Cửa hàng",
                      options: mapValue(storeList),
                      onSearch: (e: string) => {
                        getStoreList({
                          search: e,
                          limit: 10,
                          page: 1,
                          isActive: true,
                        })
                          .then((data?: any) => {
                            setStoreList(data?.data);
                          })
                          .catch((x) => console.log(x));
                      },
                    },
                  ]}
                />
              </div>

              {child && (
                <div className="fv-row mb-7">
                  <Repeater
                    deletable={false}
                    maxLength={4}
                    label="Giá thuê theo thời gian"
                    name="product.rentPrices"
                    formik={formik}
                    //   type="feature"
                    //   disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    //   onBlur={formik.handleBlur}
                    values={formik.values?.product?.rentPrices}
                    headerItem={["Loại thời gian", "Giá tiền"]}
                    initItem={[
                      {
                        type: "",
                        price: "",
                      },
                    ]}
                    inputItem={[
                      {
                        type: "dropdown",
                        name: "type",
                        options: Object.keys(BikeRentalPriceEnum).map(
                          (item) => ({
                            label:
                              BikeRentalPriceEnum[
                                item as keyof typeof BikeRentalPriceEnum
                              ],
                            value: item,
                          })
                        ),
                        disabled: true,
                      },
                      {
                        type: "number",
                        name: "price",
                        min: 0,
                        disabled: true,
                      },
                    ]}
                  />
                </div>
              )}
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              isShowActive={false}
              formik={formik}
              isLoading={isLoading}
              isEdit={!!child}
              onSubmit={() => {
                formik.handleSubmit();
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
