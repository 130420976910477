import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import { ListWrapper } from "./components/List";

const Breadcrumbs: Array<PageLink> = [
  {
    title: "Danh danh thể thao",
    path: "/sport",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "Danh sách thể thao",
    path: "/sport",
    isSeparator: true,
    isActive: false,
  },
];

const SportPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>
                Danh sách thể thao
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default SportPage;
