import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import { ListWrapper } from "./components/List";

const breadbrumbs: Array<PageLink> = [
  {
    title: "Lịch sử hoạt động",
    path: "/log",
  },
  {
    isSeparator: true,
  },
];

const ActivityLogManagement = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/"
          element={
            <>
              <PageTitle breadcrumbs={breadbrumbs}>
                Danh sách lịch sử hoạt động
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ActivityLogManagement;
