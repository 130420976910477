import { QueryResponse } from "app/modules/comment/core/_models";
import { TargetModelEnum } from "constants/enum";
import { getData } from "services/api";

const URL = `comment`;

const getList = async (query: any): Promise<QueryResponse> =>
  getData(URL, { targetModel: TargetModelEnum.Event, ...query });

export { getList };
