import { KTCard, KTCardBody, KTIcon } from "_metronic/helpers";
import { InputDropdown } from "app/components/Form";
import { FormikProvider, useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";

import { formEditType } from "../../core/_models";
import {
  //   getItem as getDetailProduct,
  getList as getProductList,
} from "../../core/_requests";

interface Props {
  selectedOptions?: {
    label: string;
    value: string;
    isPrimary: boolean;
    variants?: {
      label: string;
      value: string;
    }[];
  }[];
}

const ProductUpsell = ({ selectedOptions = [] }: Props) => {
  const formik = useFormikContext<formEditType>();

  const [products, setProducts] = useState([]);
  //   const [productsVariants, setProductVariants] = useState([]);

  const variantOptions = useMemo<any>(() => {
    const resutl = products
      .reduce((r, i: any) => {
        const duplicateItem = selectedOptions.find((e) => e.value === i.id);
        if (!duplicateItem) {
          r.push(i);
        }
        return r;
      }, [] as any[])
      .map((item: any) => {
        return {
          label: item.title,
          value: item.id,
          isPrimary: item.isPrimary,
          variants: item.variants?.map((variant: any) => ({
            label: "SKU: " + variant.sku,
            value: variant.id,
          })),
        };
      });

    return [...selectedOptions, ...resutl];
  }, [products, selectedOptions]);

  const searchProduct = (input?: string) => {
    getProductList({
      limit: 10,
      page: 1,
      search: input,
      isActive: true,
      platform: process.env.REACT_APP_PLATFORM,
    }).then((data?: any) => {
      setProducts(data?.data);
    });
  };

  useEffect(() => {
    getProductList({
      limit: 10,
      page: 1,
      isActive: true,
      platform: process.env.REACT_APP_PLATFORM,
    }).then((x: any) => {
      setProducts(x?.data);
    });
  }, []);

  return (
    <KTCard>
      <KTCardBody>
        <div className="fv-row mb-7">
          <FormikProvider value={formik}>
            <div className="form">
              <div className="d-flex flex-column flex-lg-row">
                <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                  <div className="fv-row mb-7">
                    <label className="fw-bold fs-6 ">Sản phẩm</label>

                    {formik.values &&
                      formik?.values?.productBuyWith?.map(
                        (item: any, index: number) => (
                          <React.Fragment key={index}>
                            <div className="mt-6 mx-6 row justify-content-between w-100">
                              <div className="col-md-10">
                                <InputDropdown
                                  onSearch={(input?: string) => {
                                    searchProduct(input);
                                  }}
                                  classNameLabel="form-label fs-6 fw-bold d-none"
                                  classNameDropdown="form-select form-select-solid fw-bolder"
                                  options={variantOptions}
                                  allowNoneValue={false}
                                  values={item?.product}
                                  onChange={(i) => {
                                    if (!i) return;
                                    formik.values.productBuyWith[index] = {
                                      product: i.value,
                                      productVariant: null,
                                    };

                                    formik.setFieldValue(
                                      "productBuyWith",
                                      formik.values.productBuyWith
                                    );
                                  }}
                                />
                              </div>
                              <div className="col-md-2 align-items-center justify-content-center d-flex gap-6">
                                <button
                                  type="button"
                                  className="hide-text btn btn-danger btn-sm d-flex align-items-center"
                                  onClick={() => {
                                    formik.values.productBuyWith.splice(
                                      index,
                                      1
                                    );

                                    formik.setFieldValue(
                                      "productBuyWith",
                                      formik.values.productBuyWith
                                    );
                                  }}
                                >
                                  <KTIcon iconName="basket" />
                                </button>
                              </div>
                            </div>

                            {item?.product &&
                            !variantOptions.find(
                              (list: any) => list.value === item.product
                            )?.isPrimary ? (
                              <div className="mx-12 row mt-4">
                                <div className="col-md-10">
                                  <InputDropdown
                                    classNameLabel="form-label fs-6 fw-bold d-none"
                                    classNameDropdown="form-select form-select-solid fw-bolder"
                                    options={
                                      variantOptions.find(
                                        (list: any) =>
                                          list.value === item.product
                                      )?.variants
                                    }
                                    allowNoneValue={false}
                                    values={item?.productVariant}
                                    onChange={(i) => {
                                      if (!i) return;
                                      formik.values.productBuyWith[index] = {
                                        product: item.product,
                                        productVariant: i.value,
                                      };

                                      formik.setFieldValue(
                                        "productBuyWith",
                                        formik.values.productBuyWith
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        )
                      )}

                    <div className="row mx-6 mt-7">
                      <div className="w-200px">
                        <button
                          className="btn btn-sm btn-light-primary"
                          type="button"
                          onClick={() => {
                            const data = [
                              ...(formik.values?.productBuyWith || []),
                              {
                                product: null,
                                productVariant: null,
                              },
                            ];

                            formik.setFieldValue("productBuyWith", data);
                          }}
                        >
                          <i className="ki-duotone ki-plus fs-3"></i>
                          Thêm tùy chọn khác
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormikProvider>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default ProductUpsell;
