import { KTCard } from "_metronic/helpers";
import { removeDuplicates } from "_metronic/helpers/convertData/remove-duplicate";
import { mapValue } from "_metronic/helpers/formatData";
import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { updateSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import { FormField } from "app/components/Form/Field";
import Repeater from "app/components/Form/Repeater";
import SEO from "app/components/Form/SEO/Index";
import { StickyEditSidebar } from "app/components/Sidebar";
import { BikeRentalPriceEnum, BikeRentalStatusEnum } from "constants/enum";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getList as getStoreList } from "../../../content-management/store/core/_requests";
import { initialData, ItemModel } from "../core/_models";
import { editItem, getItem } from "../core/_requests";

const EditPage = () => {
  const { id: productID, child } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();
  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    if (child) {
      initPage(child);
    }
  }, [child]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) => {
        setData({
          ...x,
          prices: x?.prices?.length === 0 ? x?.product?.rentPrices : x?.prices,
          storeId: x?.store?.id,
          productId: productID,
        });
      })
      .catch((e) => {
        console.log(e);
        navigate("/bike-rental");
      });
  };

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (child) {
          editItem(
            { ...values, store: values.storeId, product: productID },
            child
          ).then(updateSuccess);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const FIELDS = [
    {
      label: "Cửa hàng",
      name: "storeId",
      required: true,
      type: "dropdown",
      options: removeDuplicates(
        Object.keys(formik.values.store || {})?.length
          ? mapValue([formik.values.store, ...storeList])
          : mapValue([...storeList]),
        "value"
      ),
      onSearch: (e: string) => {
        getStoreList({ search: e, limit: 10, page: 1 }).then((data?: any) => {
          setStoreList(data?.data);
        });
      },
    },

    {
      label: "Code",
      name: "code",
      required: true,
      disabled: !!child,
      type: "text",
    },
    {
      label: "Tình trạng",
      name: "status",
      required: true,
      type: "dropdown",
      options: Object.keys(BikeRentalStatusEnum)?.map((key) => ({
        label: key,
        value: BikeRentalStatusEnum[key as keyof typeof BikeRentalStatusEnum],
      })),
    },
  ];

  return (
    <>
      {child ? (
        <PageTitle>{formik.values.code}</PageTitle>
      ) : (
        <PageTitle>Tạo mới</PageTitle>
      )}

      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <KTCard className="p-10 mb-7">
              {FIELDS.map((field: any) => {
                const fieldData = { ...field, formik };
                return (
                  <div key={fieldData.name} className="fv-row mb-7">
                    {FormField(fieldData)}
                  </div>
                );
              })}

              {child && (
                <div className="fv-row mb-7">
                  <Repeater
                    // deletable={false}
                    maxLength={5}
                    label="Giá thuê theo thời gian"
                    name="prices"
                    formik={formik}
                    //   type="feature"
                    //   disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    //   onBlur={formik.handleBlur}
                    values={formik.values?.prices}
                    headerItem={["Loại thời gian", "Giá tiền"]}
                    initItem={[
                      {
                        type: "",
                        price: 0,
                      },
                    ]}
                    inputItem={[
                      {
                        type: "dropdown",
                        name: "type",
                        options: Object.keys(BikeRentalPriceEnum).map(
                          (item) => ({
                            label:
                              BikeRentalPriceEnum[
                                item as keyof typeof BikeRentalPriceEnum
                              ],
                            value: item,
                          })
                        ),
                        // disabled: true,
                      },
                      {
                        type: "number",
                        name: "price",
                        min: 0,
                      },
                    ]}
                  />
                </div>
              )}
            </KTCard>
            <SEO formik={formik} isLoading={isLoading} />
          </div>

          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              isShowActive={false}
              formik={formik}
              isLoading={isLoading}
              isEdit={!!child}
              onSubmit={() => {
                formik.handleSubmit();
              }}
            ></StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
