import { PageLink, PageTitle } from "_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";

import { ListWrapper } from "./components/List";

const Breadcrumbs: Array<PageLink> = [
  {
    title: "Danh sách kết nối strava",
    path: "/strava",
  },
  {
    isSeparator: true,
  },
];

const StravaPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=""
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>
                Danh sách kết nối strava
              </PageTitle>
              <ListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default StravaPage;
